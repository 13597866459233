import { channel, Channel } from 'redux-saga';

import { LOGGED_IN, LOGGED_OUT } from '../config';

export type LoggedActions = {
  redirectTo: 'login' | 'welcome';
};

export type LoggedState = {
  type: string;
  actions?: LoggedActions;
};

export const loggedIn = (actions?: LoggedActions): LoggedState => ({ type: LOGGED_IN, actions });
export const loggedOut = (actions?: LoggedActions): LoggedState => ({ type: LOGGED_OUT, actions });

export const chan: Channel<LoggedState> = channel();
