import React from 'react';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { AddApaStructureItem } from './AddApaStructureItem';
import { AddDefaultStructureItem } from './AddDefaultStructureItem';

export const AddStructureItem = () => {
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isApa = book.type === 'apa';

  return isApa ? <AddApaStructureItem /> : <AddDefaultStructureItem />;
};
