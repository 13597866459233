import React from 'react';
import ReactDOM from 'react-dom';

import 'focus-visible';

import { MaintenanceMode } from '@pages/maintenance-mode';
import '@shared/styles/fonts.css';
import '@shared/styles/global.css';
import '@shared/styles/ck-editor.css';
import '@shared/styles/loading-dot.css';

import { isMaintenanceModeEnabled } from '@shared/config';

import App from './app';

ReactDOM.render(
  <React.StrictMode>{isMaintenanceModeEnabled ? <MaintenanceMode /> : <App />}</React.StrictMode>,
  document.getElementById('root')
);
