import { InviteController } from '@features/invite-control';
import { UserBook } from '@shared/types';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { exportModel } from '@features/book-export';
import { bookDeletionModel } from '@features/book-deletion';
import { Dropdown } from '@shared/ui';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import { bookTypeData } from '@features/book-creating';
import { redirectActions } from '@entities/history';

type Props = {
  book: UserBook;
  handleBookClick: (book: UserBook) => void;
  selectedTabName: string;
  hasAccess: boolean;
};
export const Book: FC<Props> = ({ book, handleBookClick, selectedTabName, hasAccess }) => {
  const dispatch = useDispatch();
  const disabled = !hasAccess && selectedTabName === 'my-books';

  return (
    <div className='relative'>
      <div className='flex flex-col h-full'>
        <div
          className={`relative ${disabled ? 'pointer-events-none' : ''} w-full transition-all duration-300
                      transform pt-2-width ${book._inviteAccepted ? 'hover:-translate-y-2' : ''}`}
        >
          <a
            className={`${book._inviteAccepted ? 'cursor-pointer' : 'cursor-default'}`}
            onClick={() => book._inviteAccepted && handleBookClick(book)}
          >
            {disabled && <div className='absolute top-0 left-0 z-10 w-full h-full opacity-50 bg-neutrals-100'></div>}
            <div className={`absolute top-0 left-0 w-full h-full ${!book._inviteAccepted ? 'opacity-50' : ''}`}>
              {book._coverImageURL ? (
                <div className='h-full border rounded overflow-hidden border-neutrals-300'>
                  <img src={book._coverImageURL} alt='book cover' className='object-cover h-full' />
                </div>
              ) : (
                <div
                  className={`
                            relative flex flex-col items-center justify-between h-full px-6 pt-10 pb-4
                            text-center border rounded border-neutrals-200 bg-neutrals-100
                            `}
                >
                  <h3 className='max-w-full font-semibold text-m text-primary-800 line-clamp-3'>{book._title}</h3>
                  <p className='max-w-full text-xs text-neutrals-500 line-clamp-2'>{book._author}</p>
                  <div className='absolute top-0 w-2 h-full left-2 bg-neutrals-300' />
                </div>
              )}
            </div>
          </a>
          {!book._inviteAccepted && <InviteController book={book} />}
        </div>

        {selectedTabName === 'my-books' ? (
          <div className='mt-1.5 flex items-center justify-between'>
            <p className={`text-xs ${disabled ? 'text-neutrals-400' : 'text-neutrals-500'}`}>
              {bookTypeData.find(i => i.type === book._bookType)?.name}
            </p>
            <Dropdown
              triggerComponent={
                <Trigger
                  disabled={disabled}
                  className={`inline-flex ${disabled ? 'pointer-events-none' : ''} items-center justify-center px-3`}
                >
                  <Icon
                    glyph={IconMap.BookMenu}
                    className={classNames('fill-primary-800', {
                      'grayscale filter opacity-10': disabled,
                    })}
                  />
                </Trigger>
              }
              items={[
                {
                  label: 'Continue Writing',
                  onClick: () => dispatch(redirectActions.toBookClick({ bookId: book.id })),
                },
                {
                  label: 'Edit',
                  onClick: () => dispatch(redirectActions.toEditBookClick({ bookId: book.id })),
                },
                { label: 'Export', onClick: () => dispatch(exportModel.actions.startBookExport({ bookId: book.id })) },
                {
                  label: 'Delete',
                  onClick: () => dispatch(bookDeletionModel.actions.startBookDeletion({ bookId: book.id })),
                },
              ]}
            />
          </div>
        ) : (
          <>
            <p className='text-xs text-neutrals-500 mt-1.5'>Shared by {book._sharedBy}</p>
          </>
        )}
      </div>
    </div>
  );
};
