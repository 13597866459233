import { appDataModel } from '@entities/app-data';
import { redirect } from '@entities/history';
import { FirestoreUserData, UserBook } from '@shared/types';
import { call, select } from 'redux-saga/effects';
import MixpanelService from '@features/mixpanel';

export function* stripeOnboardingWorker() {
  const firestoreUser: FirestoreUserData = yield select(appDataModel.selectors.selectFirestoreUserDataWithError);
  const userBooks: UserBook[] = yield select(appDataModel.selectors.selectBooksWithError);

  if (firestoreUser.category) {
    yield call(redirect.toSubscriptions);
  }

  if (firestoreUser.subscriptionInfo !== null || firestoreUser.role !== null) {
    yield call(redirect.toSubscriptions);
  }

  if (userBooks.some(i => i._role !== 'owner')) {
    yield call(redirect.toBooks);
  }

  MixpanelService.trackPaywallShowed();
}
