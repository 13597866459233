import { appDataModel } from '@entities/app-data';

import { MixpanelUser } from 'blooksy-backend';
import { FirestoreUserData, UserBook } from '@shared/types';
import { select } from 'redux-saga/effects';
import { transformBooksData, transformUserData } from './lib';
import MixpanelService from '.';

const MIXPANEL_KEY = 'mixpanelCurrentUserId';

export function* mixpanelFirstIdentify() {
  const user: FirestoreUserData = yield select(appDataModel.selectors.selectFirestoreUserDataWithError);

  if (localStorage.getItem(MIXPANEL_KEY) === user.userId) {
    return;
  }

  const books: UserBook[] = yield select(appDataModel.selectors.selectBooksWithError);
  const mixpanelUser: MixpanelUser = {
    ...transformUserData(user),
    ...transformBooksData(books.length, books.filter(i => i._role !== 'owner').length),
  };
  MixpanelService.setUser(user.userId, mixpanelUser);
  localStorage.setItem(MIXPANEL_KEY, user.userId);
}
