import { call, put } from 'redux-saga/effects';

import { authChannelModel } from '@entities/auth-channel';
import { authModel } from '@entities/auth-operation';
import { register } from '@shared/api/cloud-functions';
import { auth } from '@shared/config/firebase';
import { FirebaseUserCredential } from '@shared/types';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { sendEmailVerification } from '../email-verification';
import MixpanelService from '@features/mixpanel';

export function* signUp(signUpAction: ReturnType<typeof authModel.actions.signUpClick>) {
  const {
    payload: { email, password, firstName, lastName },
  } = signUpAction;

  yield call(authModel.sagas.authOperation, 'Unknown sign up error', function* () {
    const displayName = `${firstName} ${lastName}`;
    yield register(email, password, displayName);

    const userCredential: FirebaseUserCredential = yield signInWithEmailAndPassword(auth, email, password);
    if (!userCredential.user) {
      throw new Error('Something went wrong. User not created.');
    }

    MixpanelService.register(userCredential.user.uid, displayName, email);

    yield call(sendEmailVerification);

    yield put(authChannelModel.chan, authChannelModel.loggedIn({ redirectTo: 'welcome' }));
  });
}
