import { put } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';

import { matchParams } from '@shared/lib';

export function* folderPageWorker(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);

  yield put(appDataModel.actions.setSelectedSidebarItem({ type: 'folder', docId: params?.folderId || '' }));
}
