import { Button, Input } from '@shared/ui';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authModel } from '@entities/auth-operation';
import { SignInAsSuperuserRequestData, signInAsSuperuserValidationSchema } from '@features/admin/config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const SuperUserForm: FC = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);

  const { control, handleSubmit } = useForm<SignInAsSuperuserRequestData>({
    resolver: yupResolver(signInAsSuperuserValidationSchema),
    defaultValues: { email: '' },
  });

  const signInAsSuperuser = ({ email }: SignInAsSuperuserRequestData) => {
    dispatch(authModel.actions.signInAsSuperuser({ email }));
  };

  return (
    <form className='mb-6' onSubmit={handleSubmit(signInAsSuperuser)} noValidate>
      <div className='mb-4'>
        <Input control={control} name='email' type='email' label='Email' />
      </div>

      {authError && <p className='px-4 py-1 text-xs rounded bg-error-100 text-error-700'>{authError}</p>}
      <div className='mt-6'>
        <Button variant='text' color='primary' type='submit' fullWidth>
          Log In
        </Button>
      </div>
    </form>
  );
};
