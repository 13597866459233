import React, { MouseEventHandler } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '@entities/auth-operation';
import { redirectActions } from '@entities/history';
import { Input, Button, Link, AuthFormWrapper, AuthLayout } from '@shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SignInRequestData, singInValidationSchema } from '../config';

export const SignIn = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const authLoading = useSelector(authModel.selectors.selectLoading);

  const { control, handleSubmit } = useForm<SignInRequestData>({
    resolver: yupResolver(singInValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const signIn = ({ email, password }: SignInRequestData) => {
    dispatch(authModel.actions.signInWithEmailAndPasswordClick({ email, password }));
  };

  const handleSignUpClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    dispatch(redirectActions.toRegisterClick());
  };

  const handleForgotPasswordClick = () => {
    dispatch(authModel.actions.forgotPasswordClick());
  };

  return (
    <>
      <Helmet title='Log In' />
      <AuthLayout>
        <AuthFormWrapper title='Log In'>
          <div className='mb-6'>
            <span className='text-s'>Do not have an account? </span>
            <Link href='#' size='medium' onClick={handleSignUpClick}>
              Sign Up
            </Link>
          </div>

          <form className='mb-6' onSubmit={handleSubmit(signIn)} noValidate>
            <div className='mb-4'>
              <Input control={control} name='email' type='email' label='Email' />
            </div>
            <div className='mb-4'>
              <Input control={control} name='password' type='password' label='Password' />
            </div>
            {authError && <p className='px-4 py-1 text-xs rounded bg-error-100 text-error-700'>{authError}</p>}
            <div className='mt-6'>
              <Button
                variant='text'
                color='primary'
                disabled={authLoading}
                loading={authLoading}
                type='submit'
                fullWidth
              >
                Log In
              </Button>
            </div>
          </form>

          <div className='mb-6'>
            <Button variant='text' color='tertiary' type='button' fullWidth onClick={handleForgotPasswordClick}>
              Forgot password?
            </Button>
          </div>
        </AuthFormWrapper>
      </AuthLayout>
    </>
  );
};
