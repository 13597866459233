import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SignInWithEmailAndPasswordAction, SignUpAction } from './types';

type State = {
  authError?: string;
  authIsLoading: boolean;
};
const initialState: State = {
  authError: undefined,
  authIsLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError: (state, { payload: authError }: PayloadAction<string>) => ({
      ...state,
      authError,
    }),
    setAuthIsLoading: (state, { payload: authIsLoading }: PayloadAction<boolean>) => ({
      ...state,
      authIsLoading,
    }),
  },
});
export const actions = {
  ...reducerActions,
  signInWithEmailAndPasswordClick: createAction<SignInWithEmailAndPasswordAction>(
    `auth/signInWithEmailAndPasswordClick`
  ),
  signUpClick: createAction<SignUpAction>('auth/signUpClick'),
  signOutClick: createAction<void>('auth/signOutClick'),
  signInAsSuperuser: createAction<{ email: string }>('auth/signInAsSuperuser'),
  forgotPasswordClick: createAction<void>('profileSetting/forgotPasswordClick'),
};
