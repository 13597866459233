import React, { FC } from 'react';
import { styled, keyframes } from '@stitches/react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

// Docs reference with usage example - https://www.radix-ui.com/docs/primitives/components/tooltip

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  length: 0,
});

type Props = {
  trigger: React.ReactNode;
};
export const Tooltip: FC<Props> = ({ trigger, children }) => {
  return (
    <TooltipPrimitive.Root delayDuration={0}>
      <TooltipPrimitive.TooltipTrigger asChild>
        <div>{trigger}</div>
      </TooltipPrimitive.TooltipTrigger>
      <StyledContent className='rounded bg-neutrals-1000 text-neutrals-0 px-2 py-1 max-w-72 text-xs'>
        {children}
        <TooltipPrimitive.Arrow offset={4} />
      </StyledContent>
    </TooltipPrimitive.Root>
  );
};
