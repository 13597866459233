// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

const pxToNumber = (size: string) => {
  return parseInt(size, 10);
};
export const smScreen = pxToNumber(fullConfig.theme.screens.sm.max);
export const mdScreen = pxToNumber(fullConfig.theme.screens.md.max);
export const lgScreen = pxToNumber(fullConfig.theme.screens.lg.max);
export const xlRightTabsScreen = lgScreen + 100;
