import React, { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { EditModeOneLevelListItem } from './EditModeOneLevelListItem';
import { BookStructureValue } from '@shared/types';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { isSelectedItem } from '@widgets/sidebar-left/lib';
import { bookTypesDictionary } from '@shared/config';
import { Button } from '@shared/ui';
import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import { BookType } from 'blooksy-backend';

type Props = {
  item: BookStructureValue;
  index: number;
  itemsLength: number;
  bookType: BookType;
  onDelete: () => void;
  onDocDelete: (id: string) => void;
};
export const EditModeTwoLevelListItem: FC<Props> = ({ item, index, itemsLength, bookType, onDelete, onDocDelete }) => {
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {provided => (
        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div
            className={`${isSelectedItem(item.id, selectedSidebarItem) && 'font-semibold bg-neutrals-100'}
            py-1.5 px-6 w-full text-left flex justify-between items-center rounded relative`}
          >
            <Icon
              glyph={IconMap.DragLines}
              className={`w-3 absolute top-1/2 -translate-y-1/2 transform left-1 fill-neutrals-400`}
            />

            <div className='h-5 inline-flex w-full'>
              <span className='inline-flex items-center justify-center align-middle w-3 h-5 mr-3 flex-shrink-0'>
                <Icon glyph={IconMap.ArrowSmallTop} className={`object-contain text-primary-800`} />
              </span>
              <p className='truncate break-words'>
                {item.title || `Untitled ${bookTypesDictionary[bookType].folderName}`}
              </p>
            </div>
            <div className='absolute right-1 w-3'>
              {itemsLength > 1 && (
                <Button
                  fullWidth
                  variant='icon'
                  color='tertiary'
                  icon={<Icon glyph={IconMap.Delete} />}
                  onClick={onDelete}
                />
              )}
            </div>
          </div>
          <Droppable droppableId={item.id} type={'docs'}>
            {docsProvided => (
              <ul {...docsProvided.droppableProps} ref={docsProvided.innerRef}>
                {item.children.map((page, idx) => (
                  <EditModeOneLevelListItem
                    id={page.id}
                    key={page.id}
                    idx={idx}
                    isActive={isSelectedItem(page.id, selectedSidebarItem)}
                    title={page.title || `Untitled ${bookTypesDictionary[bookType].docName}`}
                    onDelete={() => onDocDelete(page.id)}
                    items={item.children}
                    isSecondLevel
                  />
                ))}
                {docsProvided.placeholder}
              </ul>
            )}
          </Droppable>
        </li>
      )}
    </Draggable>
  );
};
