import { ExtraFields } from 'blooksy-backend';

export const extraFields: ExtraFields[] = [
  'dedication',
  'foreword',
  'introduction',
  'aboutAuthor',
  'acknowledgements',
  'preface',
  'abstract',
  'approvedBy',
  'listOfTables',
  'listOfFigures',
  'references',
];
