import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { redirect } from '@entities/history';
import { matchParams } from '@shared/lib';
import { Doc } from '@shared/types';

export function* docPageWorker(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);

  const doc: Doc | undefined = yield select(appDataModel.selectors.findDoc(params?.docId));

  if (!doc) {
    yield call(redirect.toBooks);
    return;
  }

  yield put(appDataModel.actions.setSelectedSidebarItem({ type: 'doc', docId: doc.id }));
}
