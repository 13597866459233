import { call, put } from 'redux-saga/effects';

import * as FirestoreService from '@shared/api/firestore';
import { DocSnap, Book } from '@shared/types';

import { actions } from '../model/reducer';

export function* updateCurrentBook(bookSnap: DocSnap) {
  const book = bookSnap.data() as Book;
  yield put(actions.setCurrentBook(book));
}

export function* setBook(bookId: string) {
  const bookSnap: DocSnap = yield call(FirestoreService.getBook, bookId);

  if (!bookSnap.exists) {
    throw new Error(`Book ${bookId} already not exists or you have no access to it`);
  }

  const book = bookSnap.data() as Book;
  yield put(actions.setCurrentBook(book));
  return book;
}
