import React, { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';

export type Props = {
  name: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ name, placeholder, defaultValue, className, disabled = false, onChange, onKeyPress }, ref) => {
    return (
      <TextareaAutosize
        className={cn(
          `title-font w-full p-0 border-none resize-none focus:outline-none
        focus:ring-0 placeholder-neutrals-500 text-neutrals-1000 disabled:bg-transparent`,
          className
        )}
        ref={ref}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    );
  }
);

Textarea.displayName = 'Textarea';
