import * as yup from 'yup';

import AnthologyBook from '@shared/images/anthology-book.png';
import CookBook from '@shared/images/cookbook.png';
import DissertationBook from '@shared/images/dissertation.png';
import FictionBook from '@shared/images/fiction-book.png';
import GrantBook from '@shared/images/grant.png';
import NonFictionBook from '@shared/images/non-fiction-book.png';
import PoetryBook from '@shared/images/poetry-book.png';
import SyllabusBook from '@shared/images/syllabus.png';
import ApaBook from '@shared/images/apa.png';

import { BookTypeDescription } from './types';

export const bookTypeData: BookTypeDescription[] = [
  {
    type: 'non-fiction',
    name: 'Non-Fiction',
    img: NonFictionBook,
  },
  {
    type: 'poetry-book',
    name: 'Poetry',
    img: PoetryBook,
  },

  {
    type: 'fiction',
    name: 'Fiction',
    img: FictionBook,
  },
  {
    type: 'dissertation',
    name: 'Academic article / Dissertation',
    img: DissertationBook,
  },
  {
    type: 'apa',
    name: 'APA Paper',
    img: ApaBook,
  },
  {
    type: 'grant',
    name: 'Grant',
    img: GrantBook,
  },

  {
    type: 'journal',
    name: 'Journal',
    img: AnthologyBook,
  },
  {
    type: 'cook-book',
    name: 'CookBook',
    img: CookBook,
  },
  {
    type: 'syllabus',
    name: 'Syllabus',
    img: SyllabusBook,
  },
];

export const bookTypeDataWithoutApa = bookTypeData.filter(book => book.type !== 'apa');

export const AVAILABILITY_INDEX = 4;

export const bookMetadataValidationSchema = yup.object({
  title: yup.string().trim().required('Title is required.'),
  subtitle: yup.string(),
  author: yup.string(),
});
export type BookMetaData = yup.InferType<typeof bookMetadataValidationSchema>;
