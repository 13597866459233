import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  appDataModel,
  setBook,
  setBookUsers,
  setDocsData,
  setAppendicesData,
  setFoldersData,
} from '@entities/app-data';
import { selectedBookId } from '@entities/app-data/model/selectors';
import { bookModel } from '@entities/book';
import { docModel } from '@entities/doc';
import { redirect, redirectActions } from '@entities/history';
import { shareAccessModel } from '@features/share-access';
import { logger, matchParams } from '@shared/lib';
import { hasBookAccess } from '@shared/lib/permissions';
import { Saga, Book, BookUser, FirestoreUserData, SelectedBookId, SidebarItemType } from '@shared/types';

import { folderModel } from '@entities/folder';
import { avenModel } from '@features/Aven-AI';
import { appendixModel } from '@entities/appendix';

const isFrontBackMatterPageHidden = (book: Book, sidebarItemType: SidebarItemType) => {
  switch (sidebarItemType) {
    case 'approvedBy':
      return book.extra.approvedBy.hidden;
    case 'acknowledgements':
      return book.extra.acknowledgements.hidden;
    case 'dedication':
      return book.extra.dedication.hidden;
    case 'foreword':
      return book.extra.foreword.hidden;
    case 'introduction':
      return book.extra.introduction.hidden;
    case 'preface':
      return book.extra.preface.hidden;
    case 'abstract':
      return book.extra.abstract.hidden;
    case 'listOfTables':
      return book.extra.listOfTables.hidden;
    case 'listOfFigures':
      return book.extra.listOfFigures.hidden;
    case 'references':
      return book.extra.references.hidden;
    case 'about':
      return book.extra.aboutAuthor.hidden;
    default:
      return false;
  }
};

export function withEditor(sidebarItemType?: SidebarItemType, saga?: Saga) {
  return function* (location: Location, route: string) {
    yield takeEvery(redirectActions.toFrontBackMatterClick, redirect.bookFrontBackMatterPagesRedirect);
    yield takeEvery(redirectActions.toDocPageClick, redirect.docPageRedirect);
    yield takeEvery(redirectActions.toAppendixPageClick, redirect.appendixPageRedirect);
    yield takeEvery(redirectActions.toFolderPageClick, redirect.folderPageRedirect);
    yield takeEvery(folderModel.actions.addFolderClick, folderModel.sagas.addFolder);
    yield takeEvery(docModel.actions.addDocClick, docModel.sagas.addDoc);
    yield takeEvery(appendixModel.actions.addAppendixClick, appendixModel.sagas.addAppendix);
    yield takeEvery(redirectActions.toBooksClick, redirect.toBooks);
    yield takeEvery(docModel.actions.changeDocOrderAction, docModel.sagas.changeDocOrder);
    yield takeEvery(docModel.actions.changeDocOrderActionInsideFolders, docModel.sagas.changeDocOrderInsideFolders);
    yield takeEvery(appendixModel.actions.changeAppendixOrderAction, appendixModel.sagas.changeAppendixOrder);
    yield takeEvery(folderModel.actions.changeFolderOrderAction, folderModel.sagas.changeFolderOrder);
    yield takeEvery(docModel.actions.deleteDocClick, docModel.sagas.deleteDoc);
    yield takeEvery(appendixModel.actions.deleteAppendixClick, appendixModel.sagas.deleteAppendix);
    yield takeEvery(folderModel.actions.deleteFolderClick, folderModel.sagas.deleteFolder);
    yield takeEvery(bookModel.actions.changeFolderClick, bookModel.sagas.changeFolder);
    yield takeEvery(bookModel.actions.saveBookDetailsAction, bookModel.sagas.saveBookDetails);
    yield takeEvery(bookModel.actions.changeExtraVisability, bookModel.sagas.changeExtraVisability);
    yield takeEvery(docModel.actions.saveDocDetailsAction, docModel.sagas.saveDocDetails);
    yield takeEvery(appendixModel.actions.saveAppendixDetailsAction, appendixModel.sagas.saveAppendixDetails);
    yield takeEvery(folderModel.actions.saveFolderDetailsAction, folderModel.sagas.saveFolderDetails);
    yield takeEvery(shareAccessModel.actions.setAccessClick, shareAccessModel.sagas.setAccessSaga);

    const { pathname } = location;
    const params = matchParams(pathname, route);
    const newSelectedBookId = params?.bookId || '';

    let book: Book | undefined = yield select(appDataModel.selectors.currentBook);
    const bookUsers: BookUser[] | undefined = yield select(appDataModel.selectors.selectBookUsers);
    const firestoreUser: FirestoreUserData = yield select(appDataModel.selectors.selectFirestoreUserDataWithError);

    if (!book) {
      try {
        book = yield call(setBook, newSelectedBookId);
      } catch (e: unknown) {
        logger.error(e);
        yield call(redirect.toBooks);
        return;
      }
    }
    if (!hasBookAccess(firestoreUser) && book?.authorUserId === firestoreUser.userId) yield call(redirect.toBooks);
    if (!bookUsers) {
      yield call(setBookUsers, newSelectedBookId);
    }

    if (sidebarItemType) {
      if (book && isFrontBackMatterPageHidden(book, sidebarItemType)) yield call(redirect.toTitle, book.id);

      yield put(appDataModel.actions.setSelectedSidebarItem({ type: sidebarItemType }));
    }

    yield call(changeBook, newSelectedBookId);
    yield fork(appDataModel.sagas.watchCurrentBookWorker);
    yield fork(appDataModel.sagas.watchBookUsersWorker);
    yield fork(avenModel.saga.avenWorker);
    if (saga) yield call(saga, location, route);
  };
}

export function* changeBook(id: string) {
  const currentId: SelectedBookId | null = yield select(selectedBookId);

  if (currentId !== id) {
    yield put(appDataModel.actions.setSelectedBookId(id));
    yield call(setDocsData, id);
    yield call(setAppendicesData, id);
    yield call(setFoldersData, id);
  }
}
