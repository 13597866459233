import React from 'react';

import {
  ABOUT_BOOK_ROUTE,
  ACKNOWLEDGEMENTS_BOOK_ROUTE,
  BOOKS_ROUTE,
  DOC_ID_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  COPYRIGHT_BOOK_ROUTE,
  CREATE_FIRST_BOOK_ROUTE,
  DEDICATION_BOOK_ROUTE,
  FOREWORD_BOOK_ROUTE,
  INTRODUCTION_BOOK_ROUTE,
  LOGIN_ROUTE,
  NEW_BOOK_ROUTE,
  NOT_FOUND_ROUTE,
  PREFACE_BOOK_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  TABLE_OF_CONTENTS_BOOK_ROUTE,
  TITLE_BOOK_ROUTE,
  WELCOME_ROUTE,
  FOLDER_ID_ROUTE,
  EDIT_BOOK_ROUTE,
  EDIT_BOOK_ROUTE_ID,
  ABSTRACT_BOOK_ROUTE,
  LIST_OF_TABLES_BOOK_ROUTE,
  REFERENCES_BOOK_ROUTE,
  LIST_OF_FIGURES_BOOK_ROUTE,
  APPENDIX_ID_ROUTE,
  APPROVED_BY_BOOK_ROUTE,
} from '@entities/history';
import { Books } from '@pages/books';
import { DocPage } from '@pages/doc-page';
import { AppendixPage } from '@pages/appendix-page';
import { CopyrightPage } from '@pages/copyright-page';
import { CreateBook } from '@pages/create-book';
import { CreatePassword } from '@pages/create-password';
import { ForgotPassword } from '@pages/forgot-password';
import { FrontBackMatterPage } from '@pages/front-back-matter-page';
import { NotFound } from '@pages/not-found';
import { Settings } from '@pages/settings';
import { SignIn } from '@pages/sign-in';
import { SignUp } from '@pages/sign-up';
import { TableOfContents } from '@pages/table-of-contents';
import { TitlePage } from '@pages/title-page';
import { StripeOnboardingPage } from '@pages/stripe-onboarding-page';
import { FolderPage } from '@pages/folder-page';
import { EditBookPage } from '@pages/edit-book';
import { ApprovedByPage } from '@pages/approved-by-page';
import { ListPage } from '@pages/list-page';

export const MAIN_LAYOUT_ROUTES = {
  [SETTINGS_ROUTE]: () => <Settings />,
  [CREATE_FIRST_BOOK_ROUTE]: () => <CreateBook isWelcome />,
  [WELCOME_ROUTE]: () => <StripeOnboardingPage />,
  [NEW_BOOK_ROUTE]: () => <CreateBook />,
  [BOOKS_ROUTE]: () => <Books />,
};
export const EDITOR_LAYOUT_ROUTES = {
  [TITLE_BOOK_ROUTE]: () => <TitlePage />,
  [DOC_ID_ROUTE]: () => <DocPage />,
  [APPENDIX_ID_ROUTE]: () => <AppendixPage />,
  [FOLDER_ID_ROUTE]: () => <FolderPage />,
  [COPYRIGHT_BOOK_ROUTE]: () => <CopyrightPage />,
  [TABLE_OF_CONTENTS_BOOK_ROUTE]: () => <TableOfContents />,
  [APPROVED_BY_BOOK_ROUTE]: () => <ApprovedByPage />,
  [DEDICATION_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='dedication' />,
  [FOREWORD_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='foreword' />,
  [PREFACE_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='preface' />,
  [INTRODUCTION_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='introduction' />,
  [ACKNOWLEDGEMENTS_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='acknowledgements' />,
  [ABSTRACT_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='abstract' />,
  [LIST_OF_TABLES_BOOK_ROUTE]: () => <ListPage type='table' />,
  [LIST_OF_FIGURES_BOOK_ROUTE]: () => <ListPage type='img' />,
  [REFERENCES_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='references' />,
  [ABOUT_BOOK_ROUTE]: () => <FrontBackMatterPage pageType='aboutAuthor' />,
};
export const PUBLIC_ROUTES = {
  [REGISTER_ROUTE]: () => <SignUp />,
  [LOGIN_ROUTE]: () => <SignIn />,
  [RESET_PASSWORD_ROUTE]: () => <ForgotPassword />,
  [COMPLETE_PASSWORD_RESET_ROUTE]: () => <CreatePassword />,
  [NOT_FOUND_ROUTE]: () => <NotFound />,
};

export const EDIT_BOOK_LAYOUT_ROUTES = {
  [EDIT_BOOK_ROUTE]: () => <EditBookPage />,
  [EDIT_BOOK_ROUTE_ID]: () => <EditBookPage />,
};
