import React from 'react';
import { useDispatch } from 'react-redux';

import { redirectActions } from '@entities/history';
import { Button } from '@shared/ui';
import { Tab } from '@pages/books/config';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const BooksEmpty = ({ selectedTab, hasAccess }: { selectedTab: Tab; hasAccess: boolean }) => {
  const dispatch = useDispatch();
  return (
    <div className='flex-grow flex items-center justify-center'>
      <div id='empty-books-centering' className=''>
        <div
          className={`flex flex-col items-center justify-center h-full
          col-start-1 col-end-7 text-center md:col-end-5 sm-col-end-3`}
        >
          {selectedTab.name === 'my-books' &&
            (hasAccess ? (
              <>
                <p className='mb-2 font-semibold text-m text-neutrals-1000'>Your books will show up here</p>
                <p className='mb-4 text-s text-neutrals-1000'>Start writing by creating a new book.</p>
                <Button
                  variant='icon-text'
                  icon={<Icon glyph={IconMap.Plus} />}
                  color='primary'
                  type='button'
                  onClick={() => {
                    dispatch(redirectActions.toNewBookClick());
                  }}
                >
                  Create Book
                </Button>
              </>
            ) : (
              <>
                <p className='mb-2 font-semibold text-m text-neutrals-1000'>Ready to write your bestseller?</p>
                <p className='mb-4 text-s text-neutrals-1000'>Click below to activate your subscription.</p>
                <Button
                  variant='text'
                  color='primary'
                  type='button'
                  onClick={() => {
                    dispatch(redirectActions.toSubscriptionsClick());
                  }}
                >
                  Activate Subscription
                </Button>
              </>
            ))}
        </div>
      </div>

      {selectedTab.name === 'shared-books' && (
        <div
          className={`flex flex-col items-center justify-center h-full
        col-start-1 col-end-7 text-center md:col-end-5 sm-col-end-3`}
        >
          <p className='mb-2 font-semibold text-m text-neutrals-1000'>Shared books will show up here</p>
          <p className='mb-4 text-s text-neutrals-1000'>Ask your friend to share their book with you.</p>
        </div>
      )}
    </div>
  );
};
