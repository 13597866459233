import { call } from 'redux-saga/effects';

import { redirect } from '@entities/history';

import { RECOVER_EMAIL_TYPE, RESET_PASSWORD_TYPE, VERIFY_AND_CHANGE_EMAIL_TYPE, VERIFY_EMAIL_TYPE } from '../config';

import { recoverEmail } from './recover-email';
import { verifyAndChangeEmail } from './verify-and-change-email';
import { verifyEmail } from './verify-email';

export function* authActionHandler({ search, isAuth }: { search: string; isAuth: boolean }) {
  const params = new URLSearchParams(search);
  const code = params.get('oobCode') || '';
  const mode = params.get('mode');

  switch (mode) {
    case RECOVER_EMAIL_TYPE:
      yield call(recoverEmail, code);
      break;
    case VERIFY_EMAIL_TYPE:
      yield call(verifyEmail, code);
      break;
    case VERIFY_AND_CHANGE_EMAIL_TYPE:
      yield call(verifyAndChangeEmail, code);
      break;
    case RESET_PASSWORD_TYPE:
      yield call(redirect.toCompletePassword, code);
      break;
    default:
      if (isAuth) {
        yield call(redirect.toBooks);
      } else {
        yield call(redirect.toLogin);
      }
  }
}
