import { Listbox, Transition } from '@headlessui/react';

import useWindowWidth from '@shared/hooks/useWindowWidth';

import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import React, { FC, Fragment } from 'react';
import { SortMethodItem, sortMethods } from '../../config';

type Props = { onChange: (sortMethod: SortMethodItem) => void; sortMethod: SortMethodItem };

export const SortBox: FC<Props> = ({ onChange, sortMethod }) => {
  const { isMobile } = useWindowWidth();

  return (
    <Listbox value={sortMethod} onChange={onChange}>
      {({ open }) => (
        <div className='relative mr-4'>
          <Listbox.Button
            className={`
                        box-border justify-between relative flex items-center rounded border
                        transition-colors duration-300 h-10 ease-in-out
                        ${open ? 'border-primary-800' : 'border-neutrals-200'} ${
              isMobile ? 'w-fit-content px-3.5' : 'w-64 px-4'
            }`}
          >
            <span className={`${isMobile && 'hidden'} mr-6 text-s text-neutrals-1000`}>{sortMethod.title}</span>
            <Icon
              glyph={IconMap.ArrowSmallBottom}
              className={`${
                open ? 'fill-primary-800 rotate-180 transform' : 'fill-neutrals-400'
              } transition-all duration-500 ${isMobile && 'hidden'}`}
            />
            <Icon glyph={IconMap.SelectLines} className={`${isMobile ? 'block' : 'hidden'}`} />
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options
              className='absolute right-0 z-10 min-w-full overflow-auto border rounded  w-fit-content
                          border-neutrals-200 text-s bg-neutrals-0 focus:outline-none'
            >
              {sortMethods.map(method => (
                <Listbox.Option
                  key={method.name}
                  className={({ active }) =>
                    `${active ? 'bg-neutrals-100' : 'bg-neutrals-0'}
                            select-none relative py-2.5 px-4 whitespace-nowrap cursor-pointer`
                  }
                  value={method}
                >
                  {method.title}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
