import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authUserModel } from '@entities/auth-user';
import { userInitials } from '@shared/lib';
import { Button } from '@shared/ui';

import { profileSettingModel } from '..';

export const ChangePhotoForm = () => {
  const dispatch = useDispatch();

  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const userPhotoError = useSelector(profileSettingModel.selectors.selectUserPhotoError);

  const [file, setFile] = useState<File | null>(null);
  const [fileURL, setFileURL] = useState<string | undefined>('');
  const [fileError, setFileError] = useState<string | null>(null);
  const [isPhotoRemoving, setIsPhotoRemoving] = useState<boolean>(false);

  const photoError = userPhotoError || fileError;

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileError(null);
    const { files } = e.target;

    if (!files || !files.length) {
      return;
    }

    if (!files[0].type.startsWith('image')) {
      setFileError('The selected file must be an image');
      return;
    }

    setFileURL(URL.createObjectURL(files[0]));
    setFile(files[0]);
    dispatch(profileSettingModel.actions.updateUserPhotoClick({ file: files[0] }));
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleUploadPhotoClick = () => {
    hiddenFileInput.current?.click();
    dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setUserPhotoError' }));
  };

  const handleDeletePhotoClick = () => {
    setIsPhotoRemoving(true);
    setFileURL('');
    setFile(null);
    dispatch(profileSettingModel.actions.deletePhotoClick({ url: user.photoURL, cb: () => setIsPhotoRemoving(false) }));
    dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setUserPhotoError' }));
  };

  return (
    <div>
      <div className='flex justify-between gap-4 sm:mb-0 sm:justify-start'>
        <div className='flex flex-col'>
          <span className='text-s text-neutrals-500'>Photo</span>
          <div
            className={`flex items-center rounded justify-center text-5xl
              font-semibold h-31 w-31 overflow-hidden bg-primary-100 text-primary-800`}
          >
            {file ? (
              <img src={fileURL} className='object-cover w-full h-full' alt='profile photo' />
            ) : user.photoURL ? (
              <img src={user.photoURL} className='object-cover w-full h-full' alt='profile photo' />
            ) : (
              <div>{userInitials(user)}</div>
            )}
          </div>
        </div>
        <div className='flex flex-col gap-4 pt-5 min-w-14 items-end'>
          <input type='file' className='hidden' onChange={onFileChange} accept='image/*' ref={hiddenFileInput} />

          <Button
            type='button'
            color='tertiary'
            variant='text'
            onClick={handleUploadPhotoClick}
            disabled={isPhotoRemoving}
          >
            {!user.photoURL && !fileURL ? 'Upload' : 'Change'}
          </Button>
          <div className={!user.photoURL && !fileURL ? 'hidden' : 'visible'}>
            <Button
              type='reset'
              color='tertiary'
              variant='text'
              onClick={handleDeletePhotoClick}
              disabled={isPhotoRemoving}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
      {photoError && <p className='px-4 py-1 mt-5 text-xs rounded bg-error-100 text-error-700'>{photoError}</p>}
    </div>
  );
};
