import { defaultAcademicSections } from '@entities/dissertation-book-type';
import { db } from '@shared/config';

import {
  ColSnap,
  DocRef,
  CreateBookAction,
  ChangeDocOrderActionInsideFoldersItem,
  Book,
  Doc,
  FirestoreUserData,
  Folder,
  UserBook,
} from '@shared/types';

import {
  collection,
  writeBatch,
  doc as docFb,
  getDoc as getDocFb,
  getDocs as getDocsFb,
  addDoc as addDocFb,
  setDoc as setDocFb,
  deleteDoc as deleteDocFb,
  updateDoc as updateDocFb,
  query,
  where,
} from 'firebase/firestore';

import {
  bookUserConverter,
  docConverter,
  userBookConverter,
  createDefaultBook,
  generateCopyrightText,
  createDefaultDoc,
  bookConverter,
  firestoreUserConverter,
  folderConverter,
  createDefaultFolder,
} from './lib';

let _userId: string | undefined;

export const setUserId = (id?: string) => {
  _userId = id;
};

const getUserId = () => {
  if (!_userId) {
    throw new Error('User is undefined');
  }
  return _userId;
};

export function getUserRef(userId = getUserId()) {
  const usersCollectionRef = collection(db, 'users');

  return docFb(usersCollectionRef, userId).withConverter(firestoreUserConverter);
}
export function getUserBooksRef() {
  return collection(getUserRef(), '_userBooks').withConverter(userBookConverter);
}

export function getDocsRef(bookId: string) {
  const bookCollectionRef = collection(db, 'books');
  const bookDocumentRef = docFb(bookCollectionRef, bookId);

  return collection(bookDocumentRef, 'docs').withConverter(docConverter);
}

export function getAppendicesRef(bookId: string) {
  const bookCollectionRef = collection(db, 'books');
  const bookDocumentRef = docFb(bookCollectionRef, bookId);

  return collection(bookDocumentRef, 'appendices').withConverter(docConverter);
}

export function getFoldersRef(bookId: string) {
  const bookCollectionRef = collection(db, 'books');
  const bookDocumentRef = docFb(bookCollectionRef, bookId);

  return collection(bookDocumentRef, 'folders').withConverter(folderConverter);
}

export const getFolders = async (bookId: string) => {
  const data = await getDocsFb(getFoldersRef(bookId));
  return data.docs.map(i => i.data());
};

export function getBooksRef() {
  return collection(db, 'books').withConverter(bookConverter);
}

export function getBook(bookId: string) {
  const bookRef = docFb(getBooksRef(), bookId);
  return getDocFb(bookRef);
}

export function getBookUsersRef(bookId: string) {
  const bookRef = docFb(getBooksRef(), bookId);
  return collection(bookRef, 'bookUsers').withConverter(bookUserConverter);
}

export function getBookUsers(bookId: string) {
  return getDocsFb(getBookUsersRef(bookId));
}

export async function getUserBooks(): Promise<UserBook[]> {
  const booksRef = getUserBooksRef();
  const booksSnap: ColSnap = await getDocsFb(booksRef);
  return booksSnap.docs.map(snap => snap.data()) as UserBook[];
}

export async function addBook(book: Book): Promise<DocRef> {
  const booksRef = getBooksRef();
  return addDocFb(booksRef, book);
}

export async function updateBookData(bookId: string, book: Partial<Book>) {
  const bookRef = docFb(getBooksRef(), bookId);
  await setDocFb(bookRef, book, { merge: true });
}

export async function createNewBook(initialBookData: CreateBookAction) {
  const newBook: Book = {
    ...createDefaultBook(generateCopyrightText(initialBookData.author, initialBookData.type), initialBookData.type),
    ...initialBookData,
    authorUserId: getUserId(),
  };
  const { id: bookId } = await addDocFb(getBooksRef(), newBook);

  let docId: string;
  let appendixId: string;

  switch (initialBookData.type) {
    case 'fiction': {
      const { id: folderId } = await addFolder(bookId, createDefaultFolder());

      docId = (await addDoc(bookId, { ...createDefaultDoc(), folderId })).id;

      await updateBookData(bookId, { _firstChapterId: docId, _firstFolderId: folderId });
      break;
    }

    case 'dissertation': {
      const batch = writeBatch(db);
      defaultAcademicSections.forEach((i, idx) => {
        batch.set(docFb(getDocsRef(bookId)), { ...createDefaultDoc(), title: i, order: idx });
      });
      await batch.commit();
      docId = (await getDocsFb(query<Doc>(getDocsRef(bookId), where('order', '==', 0)))).docs[0].id;
      await updateBookData(bookId, { _firstChapterId: docId });
      break;
    }

    case 'apa': {
      docId = (await addDoc(bookId, createDefaultDoc())).id;
      appendixId = (await addAppendix(bookId, createDefaultDoc())).id;
      await updateBookData(bookId, { _firstChapterId: docId, _firstAppendixId: appendixId });
      break;
    }

    default: {
      docId = (await addDoc(bookId, createDefaultDoc())).id;
      await updateBookData(bookId, { _firstChapterId: docId });
      break;
    }
  }

  return { bookId, docId };
}

export function addDoc(bookId: string, doc: Doc): Promise<DocRef> {
  const docsRef = getDocsRef(bookId);
  return addDocFb(docsRef, doc);
}

export function addAppendix(bookId: string, appendix: Doc): Promise<DocRef> {
  const appendicesRef = getAppendicesRef(bookId);
  return addDocFb(appendicesRef, appendix);
}

export function addFolder(bookId: string, doc: Folder): Promise<DocRef> {
  const foldersRef = getFoldersRef(bookId);
  return addDocFb(foldersRef, doc);
}

export async function updateDocData(bookId: string, docId: string, doc: Doc) {
  const docRef = docFb(getDocsRef(bookId), docId);
  await setDocFb(docRef, doc, { merge: true });
}

export async function updateAppendixData(bookId: string, docId: string, appendix: Doc) {
  const appendixRef = docFb(getAppendicesRef(bookId), docId);
  await setDocFb(appendixRef, appendix, { merge: true });
}

export async function updateFolderData(bookId: string, folderId: string, folder: Folder) {
  const folderRef = docFb(getFoldersRef(bookId), folderId);
  await setDocFb(folderRef, folder, { merge: true });
}

export async function getDocs(bookId: string): Promise<Doc[]> {
  const docsRef = getDocsRef(bookId);
  const docsSnap: ColSnap = await getDocsFb(docsRef);
  return docsSnap.docs.map(snap => snap.data()) as Doc[];
}

export async function getAppendices(bookId: string): Promise<Doc[]> {
  const appendicesRef = getAppendicesRef(bookId);
  const appendicesSnap: ColSnap = await getDocsFb(appendicesRef);
  return appendicesSnap.docs.map(snap => snap.data()) as Doc[];
}

export async function changeDocsOrder(bookId: string, docsIds: string[]) {
  const batch = writeBatch(db);

  const bookRef = docFb(getBooksRef(), bookId);

  for (let i = 0; i < docsIds.length; i++) {
    const docRef = docFb(getDocsRef(bookId), docsIds[i]);
    batch.update(docRef, { order: i });
  }

  batch.update(bookRef, { _firstChapterId: docsIds[0] });
  await batch.commit();
}

export async function changeAppendicesOrder(bookId: string, docsIds: string[]) {
  const batch = writeBatch(db);

  const bookRef = docFb(getBooksRef(), bookId);

  for (let i = 0; i < docsIds.length; i++) {
    const appendixRef = docFb(getAppendicesRef(bookId), docsIds[i]);
    batch.update(appendixRef, { order: i });
  }

  batch.update(bookRef, { _firstAppendixId: docsIds[0] });
  await batch.commit();
}

export async function changeDocsOrderWithFolders(
  bookId: string,
  destination: ChangeDocOrderActionInsideFoldersItem,
  source?: ChangeDocOrderActionInsideFoldersItem
) {
  const batch = writeBatch(db);
  const bookRef = docFb(getBooksRef(), bookId);
  const bookSnap = await getDocFb(bookRef);
  const book = bookSnap.data();
  if (!book) {
    throw new Error('Book not found');
  }

  for (let i = 0; i < destination.ids.length; i++) {
    const docRef = docFb(getDocsRef(bookId), destination.ids[i]);
    batch.update(docRef, { order: i, folderId: destination.folderId });
  }
  if (book._firstFolderId === destination.folderId) {
    batch.update(bookRef, { _firstChapterId: destination.ids[0] });
  }

  if (source) {
    for (let i = 0; i < source.ids.length; i++) {
      const docRef = docFb(getDocsRef(bookId), source.ids[i]);
      batch.update(docRef, { order: i, folderId: source.folderId });
    }
    if (book._firstFolderId === source.folderId) {
      batch.update(bookRef, { _firstChapterId: source.ids[0] });
    }
  }
  await batch.commit();
}

export async function changeFoldersOrder(bookId: string, foldersIds: string[]) {
  const batch = writeBatch(db);

  const bookRef = docFb(getBooksRef(), bookId);

  for (let i = 0; i < foldersIds.length; i++) {
    const docRef = docFb(getFoldersRef(bookId), foldersIds[i]);
    batch.update(docRef, { order: i });
  }

  batch.update(bookRef, { _firstFolderId: foldersIds[0] });
  await batch.commit();
}

export async function deleteDoc(bookId: string, docId: string, docs: Doc[]) {
  const bookRef = docFb(getBooksRef(), bookId);
  const docRef = docFb(getDocsRef(bookId), docId);

  await deleteDocFb(docRef);

  const filteredDocs = docs.filter(c => c.id !== docId);

  if (!filteredDocs.length) {
    throw new Error('filteredDocs must be non-empty');
  }
  await updateDocFb(bookRef, { _firstChapterId: filteredDocs[0].id });

  return filteredDocs;
}

export async function deleteAppendix(bookId: string, docId: string, appendices: Doc[]) {
  const bookRef = docFb(getBooksRef(), bookId);
  const appendixRef = docFb(getAppendicesRef(bookId), docId);

  await deleteDocFb(appendixRef);

  const filteredAppendices = appendices.filter(c => c.id !== docId);

  if (!filteredAppendices.length) {
    throw new Error('filteredAppendices must be non-empty');
  }
  await updateDocFb(bookRef, { _firstAppendixId: filteredAppendices[0].id });

  return filteredAppendices;
}

export async function deleteFolder(bookId: string, folderId: string, folders: Folder[]) {
  const bookRef = docFb(getBooksRef(), bookId);
  const folderRef = docFb(getFoldersRef(bookId), folderId);

  await deleteDocFb(folderRef);

  const filteredDocs = folders.filter(c => c.id !== folderId);

  if (!filteredDocs.length) {
    throw new Error('filteredDocs must be non-empty');
  }
  await updateDocFb(bookRef, { _firstFolderId: filteredDocs[0].id });

  return filteredDocs;
}

export async function deleteBook(bookId: string) {
  const bookRef = docFb(getBooksRef(), bookId);

  await deleteDocFb(bookRef);
}

export async function getUserData() {
  const userDocument = await getDocFb(getUserRef());
  return userDocument.data() as FirestoreUserData;
}

export function getUsersRef() {
  return collection(db, 'users').withConverter(firestoreUserConverter);
}

export async function getUsersData() {
  const usersSnap: ColSnap = await getDocsFb(getUsersRef());
  return usersSnap.docs.map(snap => snap.data()) as FirestoreUserData[];
}

export async function updateUsersCategory(users: FirestoreUserData[]) {
  const batch = writeBatch(db);

  for (const user of users) {
    const userRef = getUserRef(user.userId);
    batch.update(userRef, { category: user.category });
  }
  await batch.commit();
}
