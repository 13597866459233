import React, { FC } from 'react';
import { AppendixBookOutline } from './appendix';
import { DefaultBookOutline } from './default';

type Props = {
  isEditMode: boolean;
  onClose: () => void;
  isAppendices?: boolean;
};

export const BookOutline: FC<Props> = ({ isEditMode, onClose, isAppendices = false }) => {
  return isAppendices ? (
    <AppendixBookOutline isEditMode={isEditMode} onClose={onClose} />
  ) : (
    <DefaultBookOutline isEditMode={isEditMode} onClose={onClose} />
  );
};
