import React, { FC, ReactElement, RefObject, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { SelectRole } from '@entities/role-select';
import { shareAccessModel } from '@features/share-access';
import { Role } from 'blooksy-backend';
import { Button } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

type Props = {
  usersListRef: RefObject<HTMLUListElement | null>;
  avatar: ReactElement;
  userEmail: string;
  title: string;
  subtitle: string;
  role: Role;
};
export const UserListItem: FC<Props> = ({ avatar, userEmail, title, subtitle, usersListRef, role }) => {
  const dispatch = useDispatch();
  const bookId = useSelector(appDataModel.selectors.selectedBookIdWithError);
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <li className='flex items-center pt-2.5 mb-2' key={userEmail}>
      <div
        className={`flex items-center justify-center w-9 h-9 mr-4 overflow-hidden
                  text-base font-semibold rounded-full sm:mr-6 bg-primary-100 text-primary-800`}
      >
        {avatar}
      </div>
      <p className='flex-grow flex flex-col'>
        <span className='text-s'>{title}</span>
        <span className='text-xs text-neutrals-500'>{subtitle}</span>
      </p>
      <SelectRole
        parentRef={usersListRef}
        isFixed={true}
        selectedRole={role}
        disabled={!isOwner || role === 'owner' || isLoading}
        onChange={newRole => {
          if (newRole !== role) {
            setIsLoading(true);
            dispatch(
              shareAccessModel.actions.setAccessClick({
                email: userEmail,
                bookId,
                role: newRole,
                cb: () => setIsLoading(false),
              })
            );
          }
        }}
      />
      {role === 'owner' || !isOwner ? (
        <span className='block w-10 h-10'></span>
      ) : (
        <Button
          variant='icon'
          icon={<Icon glyph={IconMap.Cross} className='fill-primary-800' />}
          disabled={isLoading}
          color='secondary'
          onClick={() => {
            setIsLoading(true);
            dispatch(shareAccessModel.actions.setAccessClick({ email: userEmail, bookId }));
          }}
        />
      )}
    </li>
  );
};
