import { FirestoreUserData } from '@shared/types';
import differenceInDays from 'date-fns/differenceInDays';

export const hasFailRenewAccess = (user: FirestoreUserData) =>
  user.subscriptionInfo?.status === 'past_due' &&
  differenceInDays(new Date(Date.now()), user.subscriptionInfo?.currentPeriodStart || 0) < 7;

const activeSubscriptionStatuses = ['active', 'trialing'];

export const getStripePermission = (user: FirestoreUserData) => {
  if (!user.role) {
    return permissions.disabled;
  }

  if (!user.subscriptionInfo) {
    return permissions.disabled;
  }

  if (activeSubscriptionStatuses.includes(user.subscriptionInfo.status || '') || hasFailRenewAccess(user)) {
    return permissions[user.role];
  }

  return permissions.disabled;
};

export const getPermission = (user: FirestoreUserData) => {
  if (!user.category) {
    return getStripePermission(user);
  }

  if (!permissionNames.includes(user.category)) {
    return permissions.default;
  }

  return permissions[user.category as PermissionNames];
};

export const permissions = {
  wordsmith: {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: false,
  },
  'wordsmith-yearly': {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: false,
  },
  kartra: {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: false,
  },
  admin: {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: true,
  },
  KSU: {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: true,
  },
  default: {
    hasBookAccess: true,
    hasUnlimitedAvenCoins: true,
  },
  disabled: {
    hasBookAccess: false,
    hasUnlimitedAvenCoins: false,
  },
} as const;

export const permissionNames = Object.keys(permissions);
export type PermissionNames = keyof typeof permissions;
