import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const Tag = ({ color, children }: { color: string; children: ReactNode }) => {
  const style = `px-1 py-0.5 rounded text-xs`;

  return (
    <span
      className={`${classNames(style, {
        'bg-error-100 text-error-500': color === 'red',
        'bg-primary-100 text-primary-800': color === 'blue',
        'bg-success-100 text-success-500': color === 'green',
        'bg-warning-100 text-warning-600': color === 'orange',
        'bg-neutrals-100 text-neutrals-500': color === 'grey',
      })}`}
    >
      {children}
    </span>
  );
};
