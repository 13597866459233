import { call, put } from 'redux-saga/effects';

import { ColSnap, Folder } from '@shared/types';

import { actions } from '../model/reducer';
import * as FirestoreService from '@shared/api/firestore';

export function* updateFolders(docsSnap: ColSnap) {
  const folders = docsSnap.docs.map(snap => snap.data()) as Folder[];
  yield put(actions.setFolders(folders));
}

export function* setFoldersData(uid: string) {
  const folders: Folder[] = yield call(FirestoreService.getFolders, uid);
  yield put(actions.setFolders(folders));
}
