import { Dialog } from '@headlessui/react';
import React from 'react';

import { Button, ButtonVariant } from '.';

export interface ModalProps {
  opened: boolean;
  title: string;
  description?: JSX.Element | JSX.Element[] | string | string[];
  leftButtonVariant?: ButtonVariant;
  leftButtonText?: string;
  rightButtonVariant?: ButtonVariant;
  rightButtonColor?: 'primary' | 'destructive';
  rightButtonText?: string;
  onConfirmClick?: () => void;
  onCancelClick?: () => void;
  children?: JSX.Element | JSX.Element[] | string | string[];
  classNameBody?: string;
  isLoading?: boolean;
}
// TODO: full refactoring
export function Modal({
  opened,
  title,
  description,
  leftButtonVariant = 'text',
  leftButtonText = 'Cancel',
  rightButtonVariant = 'text',
  rightButtonColor = 'primary',
  rightButtonText,
  onConfirmClick,
  onCancelClick,
  children,
  classNameBody = 'w-100',
  isLoading,
}: ModalProps) {
  {
    /*https://github.com/tailwindlabs/headlessui/issues/505 official issue*/
  }
  if (!opened) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        onCancelClick && onCancelClick();
      }}
      className='fixed inset-0 z-50 overflow-y-auto'
    >
      <div className='flex items-center justify-center min-h-screen'>
        <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />

        <div
          className={`relative flex flex-col rounded p-4 mx-auto my-12 bg-neutrals-0 h-fit-content ${classNameBody}`}
        >
          <Dialog.Title className='mb-8 font-semibold text-m'>{title}</Dialog.Title>
          <Dialog.Description className='text-s'>{description}</Dialog.Description>

          {Boolean(children) && <div className='my-2.5'>{children}</div>}

          <div className='flex justify-between mt-8'>
            <Button color='secondary' variant={leftButtonVariant} onClick={onCancelClick}>
              {leftButtonText}
            </Button>
            <Button
              color={rightButtonColor}
              disabled={isLoading}
              loading={isLoading}
              variant={rightButtonVariant}
              onClick={onConfirmClick}
            >
              {rightButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
