import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { Button } from '.';

export const ErrorBlock: FC<{ error: Error }> = ({ error }) => {
  return (
    <>
      <Helmet title='Error' />
      <div
        className='flex flex-col justify-start min-h-full h-fit-content
         px-10 py-10 sm:py-6 sm:px-0 md:px-6'
      >
        <div className='flex-grow text-center flex flex-col justify-center items-center'>
          <h1 className='mb-4 font-semibold text-l'>We couldn’t load the page</h1>
          <p className='mb-4 text-s'>{error.message}</p>
          <Button variant='text' onClick={() => document.location.reload()} color='primary'>
            Reload
          </Button>
        </div>
      </div>
    </>
  );
};
