import { Location } from 'history';
import { call } from 'redux-saga/effects';

import { RouteHandler } from '../config/types';

import { pathMatchesRoute } from './utils';

export function* onNavigate(location: Location<string>, routeHandlers: RouteHandler[], toRedirect: () => void) {
  const { pathname } = location;
  const routeHandler = routeHandlers.find(({ route }) => pathMatchesRoute(pathname, route));

  if (routeHandler) {
    const { saga, route } = routeHandler;
    yield call(saga, location, route);
  } else {
    yield call(toRedirect);
  }
}
