import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { StripePlans } from './stripePlans';

import { Link } from '@shared/ui';

import { isKartra } from '@shared/lib/permissions';
import { capitalizeFirstLetter } from '@shared/lib';
import { SubscriptionInfoLine } from './subscriptionInfoLine';

const SubscriptionKartra = () => (
  <div className='text-s'>
    <p className='mb-5'>
      Thank you so much for being on board with Blooksy from the beginning. As an early bird subscriber, you locked in
      our early bird pricing.
    </p>
    <p>
      If you need to change your subscription, update your card, or have any questions, please contact{' '}
      <Link size='medium' href='mailto:blooksy@blooksy.com'>
        blooksy@blooksy.com
      </Link>
      .
    </p>
  </div>
);

const SubscriptionDefault: FC<{ category: string }> = ({ category }) => (
  <div className='text-s'>
    <p>
      You have subscription type <b>{capitalizeFirstLetter(category)}</b>
    </p>
  </div>
);

export const Subscription: FC<{ isWelcome?: boolean }> = ({ isWelcome }) => {
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);

  if (isKartra(firestoreUser)) {
    return <SubscriptionKartra />;
  }

  if (firestoreUser.category) {
    return <SubscriptionDefault category={firestoreUser.category} />;
  }

  return (
    <div className='col-start-3 col-end-11 md:col-start-1 md:col-end-9 sm:col-start-1 sm:col-end-5'>
      {!isWelcome && <SubscriptionInfoLine />}
      {!isWelcome && (
        <div className='flex items-center justify-between sm:flex-col sm:items-start mb-4'>
          <h3 className='font-semibold text-m sm:mb-4'>Plans</h3>
        </div>
      )}
      <StripePlans isWelcome={isWelcome} />
    </div>
  );
};

export * from './booksStripeInfoLine';
