import React, { FC, useState } from 'react';
import { Button, Input, Select } from '@shared/ui';
import { Category } from '@features/admin/lib';
import { useDispatch, useSelector } from 'react-redux';
import { adminModel } from '@features/admin/model';
import { useCategorySelect } from '@features/admin/ui/manage-users/hooks';
import { Dialog } from '@headlessui/react';
import { isEmail } from '@shared/lib';
import { EmailTextarea } from '@features/admin/config/const';

type Props = {
  categories: Category[];
  cb?: () => void;
};

type InputsErrors = {
  text?: string;
};

export const CreateUser: FC<Props> = ({ categories, cb }) => {
  const dispatch = useDispatch();

  const isCreateUsersLoading = useSelector(adminModel.selectors.selectIsCreateUsersLoading);

  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');

  const [inputsErrors, setInputsErrors] = useState<InputsErrors>({});

  const {
    extendedCategories,
    selectedCategory,
    newCategory,
    showNewCategory,
    inputsErrors: inputsCategorySelectErrors,
    setSelectedCategory,
    setNewCategory,
    checkIsErrors: checkIsCategorySelectErrors,
    reset: resetCategorySelect,
    getCategoryId,
  } = useCategorySelect(categories);

  const onCancelClick = () => {
    setText('');
    setInputsErrors({});

    resetCategorySelect();
    setIsOpen(false);
  };

  const onConfirmClick = () => {
    const errors: InputsErrors = {};
    const emails = [
      ...new Set(
        text
          .replace(/\r?\n/g, ',')
          .split(',')
          .map(e => e.trim())
          .filter(Boolean)
      ),
    ];

    if (!text.trim() || !emails.every(email => isEmail(email))) {
      errors.text = EmailTextarea.errorMessage;
    }

    setInputsErrors(errors);

    if (checkIsCategorySelectErrors() || Object.keys(errors).length !== 0) return;

    const category = getCategoryId();

    dispatch(
      adminModel.actions.createUsers({
        emails,
        category,
        cb: () => {
          cb?.();
          onCancelClick();
        },
      })
    );
  };

  return (
    <div>
      <Button variant='text' color='primary' type='button' onClick={() => setIsOpen(true)}>
        Create user
      </Button>
      <Dialog open={isOpen} onClose={onCancelClick} className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex items-center justify-center min-h-screen'>
          <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />
          <div
            className='relative flex flex-col rounded gap-y-4 p-4 mx-auto
        my-12 bg-neutrals-0 w-170 min-h-80 h-fit-content'
          >
            <Dialog.Title className='font-semibold text-m'>Create users</Dialog.Title>
            <div>
              <Input
                name='emails'
                label='Emails'
                isTextArea
                minRows={8}
                value={text}
                onChange={e => setText(e.target.value)}
                placeholder={EmailTextarea.placeholder}
                isError={!!inputsErrors.text}
                messageText={inputsErrors.text}
              />
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <p className='text-neutrals-500 text-s'>Assign Category</p>
                <Select
                  selectedItem={selectedCategory}
                  items={extendedCategories}
                  onChange={setSelectedCategory}
                  isError={!!inputsCategorySelectErrors.category}
                />
              </div>
              {showNewCategory && (
                <div>
                  <p className='text-neutrals-500 text-s'>Category Name</p>
                  <Input
                    type='text'
                    value={newCategory}
                    onChange={e => setNewCategory(e.target.value)}
                    isError={!!inputsCategorySelectErrors.newCategory}
                    messageText={
                      inputsCategorySelectErrors.newCategory === 'Required'
                        ? ''
                        : inputsCategorySelectErrors.newCategory
                    }
                  />
                </div>
              )}
            </div>
            <div className='flex justify-between mt-auto gap-x-4'>
              <Button color='secondary' variant='text' onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                variant='text'
                color='primary'
                type='button'
                onClick={onConfirmClick}
                disabled={isCreateUsersLoading}
                loading={isCreateUsersLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
