import { put } from 'redux-saga/effects';

import { profileSettingModel } from '@features/profile-settings';

export function* authErrorHandler(
  setAuthErrorAction: ReturnType<typeof profileSettingModel.actions.setAuthErrorAction>
) {
  const {
    payload: { type, isError },
  } = setAuthErrorAction;

  yield put(profileSettingModel.actions[type](isError || ''));
}
