import { call } from 'redux-saga/effects';

import { LoggedActions } from '@entities/auth-channel/model/auth-chanel';
import { redirect } from '@entities/history';

export function* beforeAuth(actions?: LoggedActions) {
  if (!actions) {
    return;
  }

  switch (actions.redirectTo) {
    case 'login':
      yield call(redirect.toLogin);
      break;
    case 'welcome':
      yield call(redirect.toWelcome);
      break;
    default:
      return;
  }
}
