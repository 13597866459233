import { call } from 'redux-saga/effects';

import { setUserData } from '@entities/auth-user';
import { deleteProfileImage } from '@features/profile-settings/lib';
import { updateProfile } from '@shared/api/cloud-functions';

import { actions } from '../reducer';

export function* deletePhoto(deletePhotoClick: ReturnType<typeof actions.deletePhotoClick>) {
  const {
    payload: { url, cb },
  } = deletePhotoClick;

  if (url) {
    yield updateProfile({ photoURL: '' });
    yield call(deleteProfileImage, url);
  }

  cb && cb();

  yield call(setUserData);
}
