import { call, put } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { authUserModel } from '@entities/auth-user';
import { adminModel } from '@features/admin/model';

export function* clearData() {
  yield put(adminModel.actions.setFirestoreUsers(undefined));
  yield put(authUserModel.actions.setUser(undefined));
  yield put(appDataModel.actions.setBooks(undefined));

  yield call(clearEditModeData);
}

export function* clearEditModeData() {
  yield put(appDataModel.actions.setSelectedBookId(undefined));
  yield put(appDataModel.actions.setDocs(undefined));
  yield put(appDataModel.actions.setAppendices(undefined));
  yield put(appDataModel.actions.setFolders(undefined));
  yield put(appDataModel.actions.setSelectedSidebarItem(undefined));
}
