import { FirestoreUserData } from '@shared/types';
import { GUser, Timestamp } from 'blooksy-backend';
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

export const firestoreUserConverter: FirestoreDataConverter<FirestoreUserData> = {
  toFirestore(data: PartialWithFieldValue<FirestoreUserData>): DocumentData {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): FirestoreUserData {
    const { subscriptionInfo, ...data } = snapshot.data(options) as GUser<Timestamp>;
    if (!subscriptionInfo) {
      return { ...data, subscriptionInfo: null };
    }
    const { currentPeriodEnd, currentPeriodStart, cancelAt, canceledAt, endedAt, created } = subscriptionInfo;
    return {
      ...data,
      subscriptionInfo: {
        ...subscriptionInfo,
        currentPeriodStart: currentPeriodStart?.toMillis() || null,
        currentPeriodEnd: currentPeriodEnd?.toMillis() || null,
        cancelAt: cancelAt?.toMillis() || null,
        canceledAt: canceledAt?.toMillis() || null,
        endedAt: endedAt?.toMillis() || null,
        created: created.toMillis(),
      },
    };
  },
};
