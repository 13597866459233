import React, { FC, ReactElement, ReactNode } from 'react';
import { SentryErrorBoundary } from '@features/sentry-connect';

import { logger } from '@shared/lib';

type Props = {
  children: ReactNode;
  renderError: (error: Error) => ReactElement;
};

export const ErrorBoundary: FC<Props> = ({ renderError, children }) => {
  return (
    <SentryErrorBoundary
      fallback={({ error, componentStack }) => {
        logger.error('Uncaught error:', error, componentStack);
        return renderError(error);
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};
