import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';

import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';

import { Book, Folder, SelectedSidebarItem, DocRef } from '@shared/types';

import { actions } from './actions';
import { docModel } from '@entities/doc';
import { redirect } from '@entities/history';

export function* saveFolderDetails(saveFolderDetailsAction: ReturnType<typeof actions.saveFolderDetailsAction>) {
  const {
    payload: { bookId, id: folderId, content, fieldName },
  } = saveFolderDetailsAction;
  try {
    if (!bookId || !folderId) {
      throw new Error('bookId or folderId is undefined');
    }

    const currentFolder: Folder | undefined = yield select(appDataModel.selectors.selectedFolder);

    const book: Book = yield select(appDataModel.selectors.selectedBookWithError);

    if (!currentFolder) {
      throw new Error('Folder is undefined');
    }
    yield call(FirestoreService.updateFolderData, bookId, folderId, { ...currentFolder, [fieldName]: content });

    yield call(FirestoreService.updateBookData, bookId, book);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.saveFolderDetails))
    );
    logger.error(e);
  }
}

export function* addFolder(addFolderAction: ReturnType<typeof actions.addFolderClick>) {
  const {
    payload: { title, order, bookId },
  } = addFolderAction;
  try {
    if (!bookId) {
      throw new Error('bookId is undefined');
    }

    const { id: folderId }: DocRef = yield call(FirestoreService.addFolder, bookId, {
      ...FirestoreService.createDefaultFolder(),
      title,
      order,
    });
    yield put(docModel.actions.addDocClick({ title: '', order: 0, folderId, bookId }));
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.addDoc)));
    logger.error(e);
  }
}

export function* deleteFolder(deleteAction: ReturnType<typeof actions.deleteFolderClick>) {
  const {
    payload: { bookId, id: folderId },
  } = deleteAction;
  try {
    if (!bookId || !folderId) {
      throw new Error('bookId or docId is undefined');
    }

    const folders: Folder[] = yield select(appDataModel.selectors.selectedFoldersWithError);

    const filteredFolders: Folder[] = yield call(FirestoreService.deleteFolder, bookId, folderId, folders);

    const selectedSidebarItem: SelectedSidebarItem = yield select(appDataModel.selectors.selectedSideBarItemWithError);

    if (selectedSidebarItem.type === 'folder' && filteredFolders.length) {
      yield call(redirect.toFolder, bookId, filteredFolders[filteredFolders.length - 1].id);
    }
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.deleteDoc)));
    logger.error(e);
  }
}

export function* changeFolderOrder(changeFolderOrderAction: ReturnType<typeof actions.changeFolderOrderAction>) {
  const {
    payload: { bookId, ids },
  } = changeFolderOrderAction;
  try {
    if (!bookId || ids.length === 0) {
      throw new Error('bookId or foldersIds is undefined');
    }

    yield call(FirestoreService.changeFoldersOrder, bookId, ids);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.changeDocOrder))
    );
    logger.error(e);
  }
}
