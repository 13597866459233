import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetAccessAction } from './types';

type State = { isModalOpen: boolean };
const initialState: State = {
  isModalOpen: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'shareAccess',
  initialState,
  reducers: {
    setIsModalOpen: (state, { payload: isModalOpen }: PayloadAction<boolean>) => ({
      ...state,
      isModalOpen,
    }),
  },
});
export const actions = {
  ...reducerActions,
  setAccessClick: createAction<SetAccessAction>('shareAccess/set'),
};
