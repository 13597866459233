import { call, put } from 'redux-saga/effects';

import { setUserId } from '@shared/api/firestore';
import { User } from '@shared/types';

import { actions } from '../model/reducer';

import { getUserProfile } from '.';

export function* setUserData() {
  const user: User = yield call(getUserProfile);
  setUserId(user.uid);
  yield put(actions.setUser(user));
}
