import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import { Plugin } from '@ckeditor/ckeditor5-core';
import DecoupledEditorBase from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';

import ImageBlock from '@ckeditor/ckeditor5-image/src/imageblock';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import { imageUploaderAuthToken, imageUploaderUrl } from '@shared/config';

class DecoupledEditor extends DecoupledEditorBase {}

const decoupledPlugins: typeof Plugin[] = [
  BoldPlugin,
  AlignmentPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  BlockQuotePlugin,
  HeadingPlugin,
  LinkPlugin,
  ListPlugin,
  ParagraphPlugin,
  EssentialsPlugin,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  ImageBlock,
  ImageInsert,
  ImageToolbar,
  ImageStyle,
  ImageResize,
  SimpleUploadAdapter,
];
DecoupledEditor.builtinPlugins = decoupledPlugins;

const decoupledConfig = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'alignment',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'link',
    'blockQuote',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'insertImage',
    'insertTable',
    '|',
    'comment',
    'trackChanges',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h5', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'heading5', view: 'h6', title: 'Heading 5', class: 'ck-heading_heading5' },
    ],
  },
  placeholder: 'Write your content here…',
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties'],
  },
  image: {
    upload: { types: ['jpeg', 'png', 'gif', 'bmp'] },
    styles: {
      options: ['block', 'alignBlockLeft', 'alignBlockRight', 'alignLeft', 'alignRight'],
    },
    toolbar: [
      'imageStyle:alignLeft',
      'imageStyle:alignRight',
      '|',
      'imageStyle:alignBlockLeft',
      'imageStyle:block',
      'imageStyle:alignBlockRight',
    ],
  },
  simpleUpload: {
    uploadUrl: imageUploaderUrl,
    headers: {
      Authorization: `Bearer ${imageUploaderAuthToken}`,
    },
  },
};

DecoupledEditor.defaultConfig = decoupledConfig;

export default DecoupledEditor;
