import { useEffect, useRef } from 'react';
import useWindowWidth from './useWindowWidth';

export const useHeightCssVariable = <T extends HTMLElement>(variableName: string, ...extraDeps: unknown[]) => {
  const { windowWidth, windowHeight } = useWindowWidth();
  const ref = useRef<T>(null);
  useEffect(() => {
    if (ref.current) {
      document.body.style.setProperty(variableName, `${ref.current.getBoundingClientRect().height}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, windowHeight, variableName, ...extraDeps]);
  return ref;
};
