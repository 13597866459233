import { FirestoreUserData } from '@shared/types';
import { PlanName, MixpanelUserData, MixpanelBooksInfo, MixpanelSubscriptionStatus } from 'blooksy-backend';
import { MIXPANEL_BOOKS_PROPERTY, MIXPANEL_SHARED_BOOKS_PROPERTY } from '../config';
import { capitalizeFirstLetter } from '@shared/lib';

export const transformUserData = (user: FirestoreUserData): MixpanelUserData => {
  const subscriptionStatus = getSubscriptionStatus(user);

  // $name, $email - Reserved Properties for User Profiles
  // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
  return { $name: user.name, $email: user.email, 'Subscription Status': subscriptionStatus };
};

const getSubscriptionStatus = (user: FirestoreUserData) => {
  if (user.category) {
    return capitalizeFirstLetter(user.category);
  }

  if (!user.subscriptionInfo) {
    return 'Inactive';
  }

  switch (user.subscriptionInfo.status) {
    case 'active': {
      if (!user.role) {
        throw new Error('User role is not defined');
      }

      return getRole(user.role);
    }
    case 'trialing': {
      return 'Trial';
    }
    case 'canceled': {
      return 'Canceled';
    }
    case 'incomplete':
    case 'incomplete_expired':
    case 'unpaid':
    case 'past_due':
    default: {
      return 'Inactive';
    }
  }
};

export const transformBooksData = (booksLength: number, sharedBooksLength: number): MixpanelBooksInfo => {
  return {
    [MIXPANEL_BOOKS_PROPERTY]: booksLength,
    [MIXPANEL_SHARED_BOOKS_PROPERTY]: sharedBooksLength,
  };
};

const getRole = (role: PlanName): MixpanelSubscriptionStatus => {
  switch (role) {
    case 'wordsmith': {
      return 'Wordsmith';
    }
    case 'wordsmith-yearly': {
      return 'Wordsmith';
    }
    default: {
      const exhaustiveCheck: never = role;
      throw new Error(`Unsupported user role: ${exhaustiveCheck}`);
    }
  }
};
