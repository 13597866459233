import React, { FC } from 'react';
import EvenAiRobot from '@shared/images/even-ai-robot.png';
import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import { Button } from '@shared/ui';

export const Placeholder: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className='flex flex-col gap-y-3 mb-3'>
    <p className='text-s text-neutrals-1000 font-semibold'>
      Unlock Your Writing Potential with Aven - the Artificial Intelligence Writing Assistant
    </p>
    <img className='self-center block w-30' src={EvenAiRobot} alt='Even AI Robot' />
    <p className='text-xs text-neutrals-1000'>
      Aven is our artificial intelligence writing assistant that helps spark ideas, create original content and help you
      break through writer&apos;s block so you can finish your writing project 10x faster.
    </p>
    <p className='text-xs text-neutrals-1000'>She has read everything on the internet so that she can:</p>
    <ul className='flex flex-col gap-y-2 text-xs text-neutrals-1000'>
      <li className='flex gap-x-2 flex-nowrap'>
        <Icon glyph={IconMap.ListCheckMark} className='flex-shrink-0 text-primary-500 mt-0.5' width={16} />
        <p>Brainstorm new ideas or add more content to your current ideas</p>
      </li>
      <li className='flex gap-x-2 flex-nowrap'>
        <Icon glyph={IconMap.ListCheckMark} className='flex-shrink-0 text-primary-500 mt-0.5' width={16} />
        <p>Inspire you by providing a different perspective on a topic</p>
      </li>
      <li className='flex gap-x-2 flex-nowrap'>
        <Icon glyph={IconMap.ListCheckMark} className='flex-shrink-0 text-primary-500 mt-0.5' width={16} />
        <p>Unlock your imagination in ways that you never considered before</p>
      </li>
    </ul>
    <p className='text-xs text-neutrals-1000'>
      Aven is the perfect writing assistant. She helps when you need her to, but never seeks the credit.
    </p>
    <p className='text-xs text-neutrals-1000'>
      So don’t just sit there, go ahead and give Aven a try and see how much of a difference she makes.
    </p>
    <Button type='button' color='primary' variant='text' fullWidth onClick={onClick}>
      Try Aven AI
    </Button>
  </div>
);
