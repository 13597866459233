import { Subscription } from '@features/subscriptions';
import React from 'react';
import { Helmet } from 'react-helmet';

export const StripeOnboardingPage = () => {
  return (
    <>
      <Helmet titleTemplate='Welcome to Blooksy' />
      <div className='px-10 pt-7 md:px-6 sm:px-5'>
        <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
          <div
            className='col-start-5 col-end-9 lg:col-start-4 lg:col-end-9 md:col-start-1
            md:col-end-9 sm:col-start-1 sm:col-end-5'
          >
            <div className='flex items-center justify-between mb-2'>
              <h2 className='mr-4 font-semibold text-l text-neutrals-1000'>Welcome to Blooksy!</h2>
            </div>
            <p className='mb-6 font-regular text-s text-neutrals-1000'>Activate your subscription to get started</p>
            <Subscription isWelcome />
          </div>
        </div>
      </div>
    </>
  );
};
