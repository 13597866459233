import { BookStructure, Doc, Folder } from '@shared/types';
import { put, select } from 'redux-saga/effects';
import { appDataModel } from '..';
import { selectedDocs, selectedFolders } from '../selectors';

export function* watchBookStructure() {
  const folders: Folder[] | undefined = yield select(selectedFolders);
  const docs: Doc[] | undefined = yield select(selectedDocs);
  if (!folders || !docs || folders.length === 0) {
    yield put(appDataModel.actions.setBookStructure(undefined));
    return;
  }

  const bookStructure = folders.reduce<BookStructure>((acc, folder) => {
    acc[folder.id] = { ...folder, children: docs.filter(i => i.folderId === folder.id) };
    return acc;
  }, {});
  yield put(appDataModel.actions.setBookStructure(bookStructure));
}
