import { all, call } from 'redux-saga/effects';

import { connectivityModel } from '@entities/connectivity-indicator';
import { snackbarModel } from '@features/snackbar';
import { onAuth } from '@processes/auth-handling';
import { handleSignedIn } from '@processes/private-worker';
import { handleSignedOut } from '@processes/public-worker';

export function* saga() {
  yield all([call(onAuth, handleSignedIn, handleSignedOut), call(connectivityModel.saga), call(snackbarModel.saga)]);
}
