import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { bookCreatingModel } from '@features/book-creating';
import { Loader } from '@shared/ui';

import { authUserModel } from '../auth-user';

type WrapperProps = {
  children: React.ReactNode;
  isEditor?: boolean;
  isEditBook?: boolean;
};

const Wrapper: FC<WrapperProps> = ({ children, isEditor = false, isEditBook = false }) => {
  const books = useSelector(appDataModel.selectors.selectBooks);
  const user = useSelector(authUserModel.selectors.selectUser);
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserData);
  const book = useSelector(appDataModel.selectors.currentBook);
  const docs = useSelector(appDataModel.selectors.selectedDocs);
  const appendices = useSelector(appDataModel.selectors.selectedAppendices);
  const folders = useSelector(appDataModel.selectors.selectedFolders);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItem);
  const bookUsers = useSelector(appDataModel.selectors.selectBookUsers);
  const isBookCreating = useSelector(bookCreatingModel.selectors.selectIsBookCreating);
  const canAppRender = Boolean(books && user && firestoreUser);

  const canEditorRender = () => {
    const canRender = book && docs && selectedSidebarItem && bookUsers && !isBookCreating;
    if (book?.type === 'fiction') {
      return Boolean(folders && canRender);
    }

    if (book?.type === 'apa') {
      return Boolean(appendices && canRender);
    }

    return Boolean(canRender);
  };

  if (!canAppRender) {
    return <Loader />;
  }

  if (isEditor && !canEditorRender()) {
    return <Loader />;
  }

  if (isEditBook && !book) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default Wrapper;
