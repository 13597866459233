import React from 'react';

export const Link = ({
  size,
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  size: 'small' | 'medium';
}) => {
  let style = `font-semibold text-primary-800 hover:text-primary-600 active:text-primary-500`;

  switch (size) {
    case 'small':
      style += ' text-xs';
      break;
    case 'medium':
      style += ' text-s';
      break;
  }

  return (
    <a className={`${style} ${className}`} {...rest}>
      {children}
    </a>
  );
};
