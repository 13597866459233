import { call, put } from 'redux-saga/effects';

import { getUserFromFb, setUserData } from '@entities/auth-user';
import { deleteProfileImage } from '@features/profile-settings/lib';
import { updateProfile } from '@shared/api/cloud-functions';
import { storage } from '@shared/config/firebase';
import { generateImageName, getErrorMessage, logger } from '@shared/lib';
import { FirebaseUser } from '@shared/types';

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { actions } from '../reducer';

export function* updateUserPhoto(updateUserPhotoClick: ReturnType<typeof actions.updateUserPhotoClick>) {
  const {
    payload: { file },
  } = updateUserPhotoClick;

  const fbUser: FirebaseUser = yield getUserFromFb();

  let photo = '';

  try {
    if (file) {
      yield call(deleteProfileImage, fbUser.photoURL);
      photo = yield call(uploadProfileImage, file);
    }

    yield updateProfile({
      photoURL: photo ? photo : fbUser.photoURL || '',
    });

    yield call(setUserData);
  } catch (e: unknown) {
    yield put(actions.setUserPhotoError(getErrorMessage(e, 'Unknown error')));
    logger.error(e);
  }
}

function* uploadProfileImage(file: File) {
  const profilesRef = ref(storage, 'profile');
  const profileRef = ref(profilesRef, generateImageName(file.name));

  yield uploadBytes(profileRef, file);

  const path: string = yield getDownloadURL(profileRef);
  return path;
}
