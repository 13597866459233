import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { ApaTitlePage } from './apa-title-page';
import { DefaultTitlePage } from './default-title-page';

export const TitlePage: FC = () => {
  const book = useSelector(appDataModel.selectors.selectedBookWithError);

  const isApaPaper = book.type === 'apa';

  return isApaPaper ? <ApaTitlePage /> : <DefaultTitlePage />;
};
