import { createAction } from '@reduxjs/toolkit';
import {
  AddAppendixOrFolderAction,
  ChangeAppendixOrderActionInsideFolders,
  ChangeAppendixOrFolderOrderAction,
  DeleteAppendixOrFolderAction,
  SaveAppendixOrFolderDetailsAction,
} from '@shared/types';

export const actions = {
  changeAppendixOrderAction: createAction<ChangeAppendixOrFolderOrderAction>('appendix/changeAppendixOrderAction'),
  changeAppendixOrderActionInsideFolders: createAction<ChangeAppendixOrderActionInsideFolders>(
    'appendix/changeAppendixOrderActionWitsFolders'
  ),
  addAppendixClick: createAction<AddAppendixOrFolderAction>('appendix/addAppendixClick'),
  deleteAppendixClick: createAction<DeleteAppendixOrFolderAction>('appendix/deleteAppendixAction'),
  saveAppendixDetailsAction: createAction<SaveAppendixOrFolderDetailsAction>('appendix/saveAppendixDetailsAction'),
};
