import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import './index.css';

export const Loader: FC<{ isFullScreen?: boolean; size?: 'sm' | 'lg' }> = ({ isFullScreen = true, size = 'lg' }) => {
  return (
    <>
      {isFullScreen && <Helmet titleTemplate='Loading...' defaultTitle='Loading...' />}
      <div className={`grid place-items-center ${isFullScreen ? 'h-screen' : ''}`}>
        <div className={`lds-ring ${size}`}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  );
};
