import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { bookModel } from '@entities/book';
import { dictionary } from '@shared/config';
import { Textarea } from '@shared/ui';

import cn from 'classnames';

export const CopyrightPage: FC = () => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isApaPaper = book.type === 'apa';
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handleSaveCopyrightContent = (content: string, bookId: string) => {
    dispatch(
      bookModel.actions.saveBookDetailsAction({
        bookId,
        book: {
          ...book,
          copyright: {
            ...book.copyright,
            contentText: content,
          },
        },
      })
    );
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveCopyrightContent = useCallback(debounce(handleSaveCopyrightContent, 300), []);

  const handleChangeCopyrightContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveCopyrightContent(e.target.value, book.id);
  };
  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  return (
    <div className='h-full px-4 pt-4 sm:p-0 bg-neutrals-0'>
      <div className='min-h-11'></div>
      <div className='h-full max-w-170 mx-auto bg-neutrals-0'>
        <Textarea
          ref={textAreaRef}
          placeholder={dictionary.placeholders.copyright}
          name='copyright'
          className={cn(`px-10 mx-auto text-base -mb-2.5 pt-60`, {
            'text-center': isApaPaper,
            'text-justify': !isApaPaper,
          })}
          defaultValue={book.copyright.contentText}
          key={book.id}
          onChange={handleChangeCopyrightContent}
          disabled={!isOwner}
        />
        {!isApaPaper && (
          <div className='title-font w-full px-10 pb-10 bg-neutrals-0'>Published by: Blooksy Publishing</div>
        )}
      </div>
    </div>
  );
};
