import queryString from 'query-string';

type BaseDeepgramFeatures = {
  model?: 'general' | 'meeting' | 'phonecall';
  tier?: 'enhanced' | 'base';
  version?: string;
  replace?: string[];
  language?: 'en' | 'en-AU' | 'en-GB' | 'en-IN' | 'en-NZ' | 'en-US' | 'fr' | 'de' | 'pt' | 'es' | 'tr' | 'uk';
  punctuate?: boolean;
  profanity_filter?: boolean;
  redact?: string[];
  diarize?: boolean;
  diarize_version?: string;
  multichannel?: boolean;
  alternatives?: number;
  numbers?: boolean;
  numerals?: boolean;
  numbers_spaces?: boolean;
  search?: string[];
  keywords?: string[];
  dates?: boolean;
  date_format?: string;
  times?: boolean;
  dictation?: boolean;
  measurements?: boolean;
  smart_format?: boolean;
  tag?: string;
};

//eslint-disable-next-line
export type LiveStreamDeepgramFeatures = {
  interim_results?: boolean;
  endpointing?: boolean;
  vad_turnoff?: number;
  encoding?: string;
  channels?: number;
  sample_rate?: number;
} & BaseDeepgramFeatures;

//eslint-disable-next-line
export type PreRecordedDeepgramFeatures = {
  paragraphs?: boolean;
  summarize?: boolean;
  detect_topics?: boolean;
  utterances?: boolean;
} & BaseDeepgramFeatures;

export const getParams = <T extends Record<string, unknown>>(params: T) => queryString.stringify(params);

export const getDeepgram = ({ token, query = '' }: { token: string; query?: string }) =>
  new WebSocket(`wss://api.deepgram.com/v1/listen${query ? `?${query}` : query}`, ['token', token]);
