import { Dialog } from '@headlessui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from '@shared/ui';

import { bookDeletionModel } from '..';
import { selectDeletedBookId } from '../model/selectors';
import { BookDeleteFormData, bookDeleteValidationSchema, EXPECTED_CONFIRMATION } from '../config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export function DeleteBookModal() {
  const bookId = useSelector(selectDeletedBookId);
  const isModalOpen = useSelector(bookDeletionModel.selectors.selectModalIsOpen);

  const { handleSubmit, reset, clearErrors, control } = useForm<BookDeleteFormData>({
    resolver: yupResolver(bookDeleteValidationSchema),
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
    defaultValues: { confirmation: '' },
  });

  const dispatch = useDispatch();

  if (!isModalOpen) {
    return null;
  }

  const onCancelClick = () => {
    dispatch(bookDeletionModel.actions.endBookDeletion());
    reset();
  };

  const onBookDelete = () => {
    dispatch(bookDeletionModel.actions.deleteBookClick({ bookId: bookId || '', cb: onCancelClick }));
  };

  return (
    <Dialog open={true} onClose={onCancelClick} className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen'>
        <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />

        <form
          onSubmit={handleSubmit(onBookDelete)}
          className='relative flex flex-col rounded p-4 mx-auto my-12 bg-neutrals-0 w-100 h-fit-content'
        >
          <Dialog.Title className='mb-8 font-semibold text-m'>Delete Book</Dialog.Title>
          <Dialog.Description className='text-s'>
            Are you sure you want to delete the book? You will delete the book permanently, and you cannot undo it.
          </Dialog.Description>

          <div className='mt-4'>
            <Input
              control={control}
              name='confirmation'
              type='text'
              disableAutoComplete
              label={`Type “${EXPECTED_CONFIRMATION}” to confirm`}
              messageText=''
              onChange={() => clearErrors('confirmation')}
            />
          </div>

          <div className='flex justify-between mt-8'>
            <Button color='secondary' variant='text' onClick={onCancelClick}>
              Cancel
            </Button>
            <Button color='destructive' variant='text' type='submit'>
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
