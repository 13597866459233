import { ExportBookAction } from '@features/book-export/model/types';
import { auth, isDevelopment, isFirebaseStaging, mixpanelToken } from '@shared/config';
import { BookType, MixpanelUser, Role } from 'blooksy-backend';

import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from './config';
import { MixpanelEvent } from './types';
const FLEXUM_DOMAIN = '@flexum.io';
const EXCLUDED_EMAILS = ['mrajoiner@gmail.com', 'blooksy@blooksy.com', 'developer@blooksy.com'];
class MixpanelService {
  constructor() {
    mixpanel.init(mixpanelToken, {
      debug: isDevelopment,
    });
  }

  setUser(userId: string, user: MixpanelUser) {
    mixpanel.people.set(user);
    mixpanel.identify(userId);
  }

  register(userId: string, name: string, email: string) {
    mixpanel.alias(userId);
    mixpanel.people.set({ $name: name, $email: email });
    this.trackSignUpCompleted();
  }

  login(userId: string) {
    mixpanel.identify(userId);
  }

  logOut() {
    mixpanel.reset();
  }

  private track<D extends Record<string, unknown>>(event: MixpanelEvent, data?: D) {
    mixpanel.track(event, data);
  }

  private trackOnlyCustomers<D extends Record<string, unknown>>(event: MixpanelEvent, data?: D) {
    const userEmail = auth.currentUser?.email;

    if (isFirebaseStaging || !userEmail || !userEmail.endsWith(FLEXUM_DOMAIN) || !EXCLUDED_EMAILS.includes(userEmail)) {
      this.track(event, data);
      return;
    } else {
      // do nothing
    }
  }

  trackSignUpStarted() {
    this.trackOnlyCustomers(mixpanelEvents.signUpStarted);
  }

  trackSignUpCompleted() {
    this.trackOnlyCustomers(mixpanelEvents.signUpCompleted);
  }

  trackLoginOpened() {
    this.trackOnlyCustomers(mixpanelEvents.loginOpened);
  }

  trackPaywallShowed() {
    this.trackOnlyCustomers(mixpanelEvents.onboardingPaywallShowed);
  }

  trackOnboardingBookCreationShowed() {
    this.trackOnlyCustomers(mixpanelEvents.onboardingBookCreationShowed);
  }

  trackBookCreated(bookType: BookType) {
    this.trackOnlyCustomers(mixpanelEvents.bookCreated, {
      'Book Type': bookType,
    });
  }

  trackBookDeleted() {
    this.trackOnlyCustomers(mixpanelEvents.bookDeleted);
  }

  trackBookExported(action: ExportBookAction) {
    this.trackOnlyCustomers(mixpanelEvents.bookExported, {
      Format: action.exportType,
      'Page size': action.exportSize,
    });
  }

  trackSharedAccess(role: Role) {
    this.trackOnlyCustomers(mixpanelEvents.bookSharedAccess, {
      'Access level': role,
    });
  }

  trackRevokedAccess() {
    this.trackOnlyCustomers(mixpanelEvents.bookRevokedAccess);
  }

  startTranscriptionTimer() {
    mixpanel.time_event(mixpanelEvents.transcriptionStarted);
  }

  trackTranscriptionStarted() {
    this.trackOnlyCustomers(mixpanelEvents.transcriptionStarted);
  }

  trackAIStarted(tool: string) {
    this.trackOnlyCustomers(mixpanelEvents.aiStarted, {
      'AI Tool': tool,
    });
  }

  trackBuyCoinsModalOpened() {
    this.trackOnlyCustomers(mixpanelEvents.coinsModalOpened);
  }

  trackCoinsPurchased(coinsCount: number) {
    this.trackOnlyCustomers(mixpanelEvents.coinsPurchased, { 'Coins Count': coinsCount });
  }
}

export default new MixpanelService();
