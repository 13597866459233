import React from 'react';
import { createColumnHelper, FilterFn, PaginationState } from '@tanstack/react-table';
import { TableUser } from '@features/admin/lib';
import { Checkbox } from '@shared/ui';
import { UserLogo } from '@widgets/header-general';
import { capitalizeFirstLetter, fl } from '@shared/lib';

declare module '@tanstack/table-core' {
  interface FilterFns {
    arrIncludesItems: FilterFn<unknown>;
  }
}

export const arrIncludesItems: FilterFn<unknown> = (row, columnId, filterValue: string[]) => {
  if (!filterValue.length) return true;

  const value = row.getValue(columnId) as string;

  if (!value && filterValue.includes('regular')) {
    return true;
  }

  return filterValue.includes(value);
};

const columnHelper = createColumnHelper<TableUser>();

export const columns = [
  columnHelper.display({
    id: 'select',
    header: ({ table, header }) => (
      <Checkbox
        {...{
          id: header.id,
          checked: table.getIsAllPageRowsSelected(),
          indeterminate: table.getIsSomePageRowsSelected(),
          onChange: table.getToggleAllPageRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row, cell }) => (
      <Checkbox
        {...{
          id: cell.id,
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  }),
  columnHelper.accessor('name', {
    header: () => 'Name',
    cell: ({ cell, row }) => {
      const { photoUrl } = row.original;
      const name = cell.getValue();
      const shortName = name.split(' ').map(fl).join('');

      return (
        <div className='flex gap-x-3 items-center'>
          <UserLogo photoURL={photoUrl} name={shortName} />
          <p className='whitespace-nowrap'>{name}</p>
        </div>
      );
    },
  }),
  columnHelper.accessor('email', {
    header: () => 'Email',
  }),
  columnHelper.accessor(row => row.category?.replace(/\s+/g, '-') || '', {
    id: 'category',
    header: () => 'Category',
    cell: ({ cell }) => {
      const value = cell.getValue();
      const formattedValue = value ? capitalizeFirstLetter(value) : 'Regular User';

      return <>{formattedValue}</>;
    },
    enableGlobalFilter: false,
    filterFn: 'arrIncludesItems',
  }),
];

type GetCountRows = {
  pagination: PaginationState;
  rowsLength: number;
  allRowsLength: number;
};

export const getCountRows = ({ pagination, rowsLength, allRowsLength }: GetCountRows) => {
  const { pageSize, pageIndex } = pagination;
  const inc = pageIndex === 0 ? 1 : 0;

  return `Showing ${pageSize * pageIndex + inc}-${pageSize * pageIndex + rowsLength} of ${allRowsLength} users`;
};
