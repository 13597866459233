import { User } from '@shared/types';

export function fl(s: string): string {
  return s ? s[0]?.toUpperCase() : '';
}

export function userInitials(user: User): string {
  const { firstName, lastName } = user;
  return fl(firstName) + fl(lastName);
}
