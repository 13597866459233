import { actions } from '@features/admin/model/reducer';
import { call, put } from 'redux-saga/effects';
import * as FirestoreService from '@shared/api/firestore';
import { getErrorMessage, logger } from '@shared/lib';
import { createUsers as createUsersAPI } from '@shared/api/cloud-functions';
import { dictionary } from '@shared/config';
import { createNotification, snackbarModel } from '@features/snackbar';

export function* updateUsersCategory(updateUsersCategoryAction: ReturnType<typeof actions.updateUsersCategory>) {
  const {
    payload: { users, category },
  } = updateUsersCategoryAction;

  try {
    yield call(
      FirestoreService.updateUsersCategory,
      users.map(u => ({ ...u, category }))
    );
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.default)));
    logger.error(e);
  }
}

export function* createUsers(createUsersAction: ReturnType<typeof actions.createUsers>) {
  const {
    payload: { emails, category, cb },
  } = createUsersAction;

  try {
    yield put(actions.setCreateUsersIsLoading(true));
    yield call(createUsersAPI, emails, category);

    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('success', dictionary.success.createUsers))
    );
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', getErrorMessage(e, dictionary.error.default))
      )
    );
    logger.error(e);
  } finally {
    yield put(actions.setCreateUsersIsLoading(false));
    cb?.();
  }
}
