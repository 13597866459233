import { call } from 'redux-saga/effects';

import { updateBooks } from '@entities/app-data';
import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getUserBooksRef } from '@shared/api/firestore';

export function* watchBooksWorker() {
  const booksRef = getUserBooksRef();
  const booksChannel = queryColChannel(booksRef, false);

  yield call(onSnapshot, booksChannel, updateBooks, 'Books');
}
