import { useEffect, useMemo, useState } from 'react';
import { Category } from '@features/admin/lib';

type InputsErrors = {
  newCategory?: string;
  category?: string;
};

export const useCategorySelect = (categories: Category[]) => {
  const [selectedCategory, setSelectedCategory] = useState<Category>({} as Category);
  const [newCategory, setNewCategory] = useState('');
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [inputsErrors, setInputsErrors] = useState<InputsErrors>({});

  const extendedCategories: Category[] = useMemo(
    () => [...categories, { id: 'new-category', name: 'New Category...' }],
    [categories]
  );

  useEffect(() => {
    setShowNewCategory(selectedCategory?.id === 'new-category');
  }, [selectedCategory]);

  const checkIsErrors = () => {
    const errors: InputsErrors = {};

    if (!selectedCategory?.id) {
      errors.category = 'Required';
    }

    if (selectedCategory?.id === 'new-category' && !newCategory.trim().length) {
      errors.newCategory = 'Required';
    }

    if (selectedCategory?.id === 'new-category' && categories.find(c => c.id === newCategory.toLowerCase().trim())) {
      errors.newCategory = 'Category already exists.';
    }

    setInputsErrors(errors);

    return Object.keys(errors).length !== 0;
  };

  const reset = () => {
    setInputsErrors({});
    setShowNewCategory(false);
    setNewCategory('');
    setSelectedCategory({} as Category);
  };

  const getCategoryId = () => {
    if (selectedCategory?.id === 'new-category') {
      return newCategory.trim();
    }
    if (selectedCategory?.id === 'regular') {
      return '';
    }
    return selectedCategory?.id || '';
  };

  return {
    reset,
    checkIsErrors,
    setSelectedCategory,
    setNewCategory,
    getCategoryId,
    extendedCategories,
    inputsErrors,
    selectedCategory,
    newCategory,
    showNewCategory,
  };
};
