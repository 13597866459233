import { put, select } from '@redux-saga/core/effects';

import { NotificationItem } from '@shared/types';

import { snackbarModel } from '..';

export function* addNotification(action: ReturnType<typeof snackbarModel.actions.addNotificationAction>) {
  const notifications: NotificationItem[] = yield select(snackbarModel.selectors.selectNotifications);

  yield put(snackbarModel.actions.setNotifications([...notifications, action.payload]));
}
