import { nanoid } from '@reduxjs/toolkit';
import { IconMap } from '@shared/sprite';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useMemo,
  useState,
} from 'react';
import { Icon } from './icon';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { InfoTooltip } from './info-tooltip';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

export type InputElement = HTMLInputElement | HTMLTextAreaElement;
export type InputChangeEvent = ChangeEvent<InputElement>;
export type InputChangeEventHandler = (event: InputChangeEvent) => void;
export type InputFocusEvent = ChangeEvent<InputElement>;
export type InputFocusEventHandler = (event: InputChangeEvent) => void;

type BaseProps = {
  value?: string;
  label?: string;
  iconSrc?: string;
  isError?: boolean;
  isMandatory?: boolean;
  messageText?: string;
  info?: string;
  maxCharacters?: number;
  onChange?: InputChangeEventHandler;
  onBlur?: InputFocusEventHandler;
  onFocus?: InputFocusEventHandler;
};

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur' | 'onFocus'> & {
  isTextArea?: false;
  disableAutoComplete?: boolean;
};

type TextAreaProps = Omit<TextareaAutosizeProps, 'onChange' | 'onBlur' | 'onFocus'> & {
  type?: 'text';
  isTextArea: true;
  disableAutoComplete?: false;
};

export type Props = BaseProps & (InputProps | TextAreaProps);
const InputInner = forwardRef<InputElement, Props>(function InputInnerComponent(
  {
    type = 'text',
    value,
    label,
    name,
    iconSrc,
    isError = false,
    isMandatory = false,
    messageText,
    info,
    maxCharacters,
    isTextArea,
    className,
    onChange,
    disabled = false,
    disableAutoComplete = false,
    ...restInputProps
  },
  ref
) {
  const onChangeHandler: InputChangeEventHandler = e => {
    if (!onChange) {
      return;
    }
    if (maxCharacters && e.target.value.length > maxCharacters) {
      return;
    }
    onChange(e);
  };

  const [inputType, setInputType] = useState<HTMLInputTypeAttribute>(type);
  const changeInputType = () => {
    setInputType(prevType => (prevType === 'text' ? 'password' : 'text'));
  };

  const uniqueId = useMemo(nanoid, []);
  const baseProps = {
    name: name || label,
    value,
    disabled,
    id: uniqueId,
    onChange: onChangeHandler,
  };

  const baseInputStyle = `border rounded w-full h-10 text-s
     focus:ring-0 focus:border-primary-800
     disabled:bg-neutrals-100 disabled:border-neutrals-200
     disabled:text-neutrals-500 placeholder-neutrals-500 px-4 border-neutrals-200`;

  return (
    <div>
      <div className='flex items-center'>
        {label && (
          <label htmlFor={uniqueId} className='text-neutrals-500 text-s'>
            {label}
            {isMandatory && <span className='text-s text-error-500 ml-0.5'>*</span>}
          </label>
        )}
        {info && (
          <div className='ml-auto'>
            <InfoTooltip>{info}</InfoTooltip>
          </div>
        )}
      </div>
      <div className='relative'>
        {iconSrc && (
          <div className='absolute left-1.5 w-8.5 h-10 flex justify-center items-center'>
            <img className='focus:outline-none' src={iconSrc} alt='' />
          </div>
        )}
        {isTextArea ? (
          <TextareaAutosize
            {...baseProps}
            {...(restInputProps as TextAreaProps)}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            className={classNames(baseInputStyle, className, 'resize-none max-h-44', {
              'pl-10 pr-4': iconSrc,
              'border-error-700': isError,
            })}
          />
        ) : (
          <input
            {...baseProps}
            {...(restInputProps as InputProps)}
            ref={ref as ForwardedRef<HTMLInputElement>}
            type={inputType}
            autoComplete={disableAutoComplete ? 'off' : inputType}
            className={classNames(baseInputStyle, className, 'leading-10', {
              'pl-10 pr-4': iconSrc,
              'border-error-700': isError,
            })}
          />
        )}
        {type === 'password' && (
          <div className='absolute top-0 flex items-center h-10 right-4'>
            <button className='focus:outline-none ' type='button' onClick={changeInputType}>
              <Icon glyph={inputType === 'password' ? IconMap.Eye : IconMap.CrossedEye} />
            </button>
          </div>
        )}
        {messageText && (
          <p
            className={classNames('text-xs text-neutrals-500', {
              'text-error-700': isError && !disabled,
            })}
          >
            {messageText}
          </p>
        )}
        {maxCharacters && (
          <p className='text-right text-xs text-neutrals-500'>
            {(value || '').trim().length}/{maxCharacters}
          </p>
        )}
      </div>
    </div>
  );
});

type InputWithController<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
};
type InputWithoutController = {
  control?: undefined;
};

type WrapperProps<T extends FieldValues> = Props & (InputWithController<T> | InputWithoutController);
export const Input = <T extends FieldValues>(props: WrapperProps<T>) => {
  if (!props.control) {
    return <InputInner {...props} />;
  }

  const { control, disabled, onChange, onBlur, ...restInputProps } = props;
  return (
    <Controller
      name={props.name}
      control={control}
      render={({
        field: { name, ref, value, onChange: onChangeField, onBlur: onBlurField, disabled: disabledField },
        fieldState: { error },
      }) => (
        <InputInner
          isError={!!error}
          aria-invalid={!!error}
          messageText={error?.message}
          {...restInputProps}
          ref={ref}
          value={value}
          name={name}
          disabled={disabled ?? disabledField}
          onChange={e => {
            onChangeField(e);
            onChange?.(e);
          }}
          onBlur={e => {
            onBlurField();
            onBlur?.(e);
          }}
        />
      )}
    />
  );
};
