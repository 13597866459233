import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal } from '@shared/ui';

import { ChangePasswordRequestData, changePasswordValidationSchema } from '../config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { profileSettingModel } from '../model';

export const ChangePasswordFrom = () => {
  const dispatch = useDispatch();

  const passwordError = useSelector(profileSettingModel.selectors.selectPasswordError);

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);

  const { control, handleSubmit, reset } = useForm<ChangePasswordRequestData>({
    resolver: yupResolver(changePasswordValidationSchema),
    defaultValues: {
      password: '',
      newPassword: '',
    },
  });

  const handlePasswordCancelClick = () => {
    setIsPasswordChanging(false);
    setIsPasswordModalOpen(false);
    reset();

    dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setPasswordError' }));
  };

  const changePassword = ({ password, newPassword }: ChangePasswordRequestData) => {
    setIsPasswordChanging(true);
    dispatch(
      profileSettingModel.actions.updatePasswordClick({
        currentPassword: password,
        newPassword: newPassword,
        cb: handlePasswordCancelClick,
      })
    );
  };

  return (
    <>
      <div
        className='mt-6.75 flex justify-between items-center gap-4 pb-6.75
        border-b border-neutrals-200 sm:flex-col sm:items-start'
      >
        <h3 className='font-semibold text-m'>Password</h3>

        <Button type='button' color='tertiary' variant='text' onClick={() => setIsPasswordModalOpen(true)}>
          Change Password
        </Button>
      </div>

      <Modal
        opened={isPasswordModalOpen}
        title='Change Password'
        leftButtonVariant='text'
        leftButtonText='Cancel'
        rightButtonVariant='text'
        rightButtonColor='primary'
        rightButtonText='Change'
        isLoading={isPasswordChanging}
        onConfirmClick={handleSubmit(changePassword)}
        onCancelClick={handlePasswordCancelClick}
      >
        <>
          <div className='mb-4'>
            <Input control={control} name='password' type='password' label='Current Password' />
          </div>
          <Input control={control} name='newPassword' type='password' label='New Password' />

          {passwordError && (
            <p className='px-4 py-1 mt-5 overflow-hidden text-xs rounded bg-error-100 text-error-700'>
              {passwordError}
            </p>
          )}
        </>
      </Modal>
    </>
  );
};
