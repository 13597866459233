import { put } from 'redux-saga/effects';

import { authChannelModel } from '@entities/auth-channel';
import { setUserId } from '@shared/api/firestore';
import { auth } from '@shared/config';
import MixpanelService from '@features/mixpanel';

export function* signOut() {
  yield auth.signOut();
  setUserId();

  MixpanelService.logOut();
  yield put(authChannelModel.chan, authChannelModel.loggedOut({ redirectTo: 'login' }));
}
