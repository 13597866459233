import { Modal } from '@shared/ui';
import React, { FC } from 'react';

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
  onConfirm: () => void;
  title: string;
};

export const UnsavedModal: FC<Props> = ({ opened, setOpened, onConfirm, children, title }) => {
  return (
    <Modal
      opened={opened}
      title={title}
      leftButtonVariant='text'
      leftButtonText='Go Back'
      rightButtonText='Leave'
      rightButtonVariant='text'
      rightButtonColor='primary'
      onCancelClick={() => {
        setOpened(false);
      }}
      onConfirmClick={onConfirm}
    >
      <div className='space-y-1 text-sm -my-3'>{children}</div>
    </Modal>
  );
};
