import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { ErrorBoundary } from '@entities/error-boundary';
import { history } from '@entities/history';
import { StagingIndicator } from '@entities/staging-indicator';
import { SnackBar } from '@features/snackbar';
import { ErrorPage } from '@pages/error-page';
import { Routing } from '@pages/index';

import '@features/sentry-connect';

import store from './model';

const App: FC = () => {
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;

      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', setAppHeight);
    setAppHeight();
    return () => window.removeEventListener('resize', setAppHeight);
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary renderError={error => <ErrorPage error={error} />}>
        <Router history={history}>
          <Routing />
          <SnackBar />
          <StagingIndicator />
        </Router>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
export { RootState, AppDispatch } from './model';
