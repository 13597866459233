import { call } from 'redux-saga/effects';

import { updateDocs } from '@entities/app-data';
import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getDocsRef } from '@shared/api/firestore';

import { actions } from '../reducer';

export function* watchDocsWorker(setSelectedBookIdAction: ReturnType<typeof actions.setSelectedBookId>) {
  const { payload: bookId } = setSelectedBookIdAction;

  if (!bookId) {
    return;
  }

  const docsRef = getDocsRef(bookId);
  const docsChannel = queryColChannel(docsRef, false);

  yield call(onSnapshot, docsChannel, updateDocs, 'Docs');
}
