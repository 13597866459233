import { Folder } from '@shared/types';
import { GFolder } from 'blooksy-backend';

import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

export const folderConverter: FirestoreDataConverter<Folder> = {
  toFirestore(data: PartialWithFieldValue<Folder>): DocumentData {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _, __isDoc: __, ...rest } = data;
    return {
      ...rest,
      avenSavedResults: data.avenSavedResults || [],
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Folder {
    const id = snapshot.id;
    const data = snapshot.data(options) as GFolder;

    return {
      id,
      ...data,
      avenSavedResults: data.avenSavedResults || [],
      __isDoc: false,
    };
  },
};
