import { appDataModel } from '@entities/app-data';
import { bookModel } from '@entities/book';
import { redirectActions } from '@entities/history';
import { IconMap } from '@shared/sprite';
import { Button } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FrontBackMatterSidebarItem } from '../config';
import { AccordionMenuItem } from './AccordionMenuItem';
import classNames from 'classnames';

type Props = {
  title: string;
  onClick: () => void;
  items: FrontBackMatterSidebarItem[];
  onItemClick: () => void;
  isEditMode: boolean;
};

export const FrontBackMatterAccordionItem: FC<Props> = ({ title, onClick, items, onItemClick, isEditMode }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);

  const allItemsHidden = items.every(item => {
    const { canBeHidden } = item;
    return canBeHidden && item.extraFieldName && book.extra[item.extraFieldName].hidden;
  });

  if (allItemsHidden && !isEditMode) {
    return null;
  }

  return (
    <AccordionMenuItem title={title} onClick={onClick}>
      <ul className='mb-4'>
        {items.map(item => {
          const { type, label, routePath, canBeHidden } = item;
          const isActive = selectedSidebarItem.type === type;
          const isHidden = canBeHidden && item.extraFieldName && book.extra[item.extraFieldName].hidden;

          if (!isEditMode && isHidden) {
            return;
          }

          return (
            <li key={type}>
              {isEditMode ? (
                <div
                  className={classNames(
                    `flex justify-between items-center py-1.5 pl-7 rounded
                    w-full text-left disabled:cursor-auto outline-button`,
                    {
                      'font-semibold bg-neutrals-100': isActive,
                      'hover:bg-neutrals-0': !isActive && isEditMode,
                    }
                  )}
                >
                  <p className={isHidden ? 'text-neutrals-400' : ''}>{label}</p>
                  {canBeHidden && item.extraFieldName && (
                    <div>
                      <Button
                        fullWidth
                        variant='icon'
                        color='tertiary'
                        icon={<Icon glyph={isHidden ? IconMap.CrossedEye : IconMap.Eye} />}
                        onClick={() => {
                          dispatch(
                            bookModel.actions.changeExtraVisability({
                              bookId: book.id,
                              extraField: item.extraFieldName,
                              hidden: !isHidden,
                              isActive: isActive,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    onItemClick();
                    dispatch(
                      redirectActions.toFrontBackMatterClick({
                        routePath,
                        bookId: book.id,
                      })
                    );
                  }}
                  className={classNames(
                    `flex justify-between items-center py-1.5 pl-7 rounded
                    w-full text-left disabled:cursor-auto outline-button`,
                    {
                      'font-semibold bg-neutrals-100': isActive,
                    }
                  )}
                >
                  <p className={isHidden ? 'text-neutrals-400' : ''}>{label}</p>
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </AccordionMenuItem>
  );
};
