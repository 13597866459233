import { appDataModel } from '@entities/app-data';

import { redirectActions } from '@entities/history';
import { bookTypesDictionary } from '@shared/config';

import React, { FC, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { DeletingItemType } from '../../config';
import { handleDocsDragEndEvent, HandleDragEndConfig, handleFolderDragEndEvent, isSelectedItem } from '../../lib';
import { EditModeOneLevelListItem } from '../BookContentList/EditModeOneLevelListItem';
import { EditModeTwoLevelListItem } from '../BookContentList/EditModeTwoLevelListItem';
import { OneLevelListItem } from '../BookContentList/OneLevelListItem';
import { TwoLevelListItem } from '../BookContentList/TwoLevelListItem';
import { DeleteModal } from '../DeleteModal';

type Props = {
  isEditMode: boolean;
  onClose: () => void;
};

export const DefaultBookOutline: FC<Props> = ({ isEditMode, onClose }) => {
  const dispatch = useDispatch();
  const folders = useSelector(appDataModel.selectors.selectedFolders);
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const docs = useSelector(appDataModel.selectors.selectedDocsWithError);
  const bookStructure = useSelector(appDataModel.selectors.selectBookStructure);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);
  const [deletingDocId, setDeletingDocId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingItemType, setDeletingItemType] = useState<DeletingItemType>('doc');

  const isSelectedSidebarItem = (docId: string) => isSelectedItem(docId, selectedSidebarItem);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
    const config: HandleDragEndConfig = {
      folders,
      book,
      dispatch,
      docs,
      bookStructure,
    };

    if (type === 'folders') {
      handleFolderDragEndEvent(result, config);
    } else if (type === 'docs') {
      handleDocsDragEndEvent(result, config);
    } else {
      throw new Error('Unknown type');
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        deletingItemType={deletingItemType}
        deletingDocId={deletingDocId}
        confirmationNeeded={true}
      />
      <ul className='mb-4'>
        {isEditMode ? (
          book.type === 'fiction' && bookStructure ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='folders' type='folders'>
                {foldersProvided => (
                  <ul className='mb-4' ref={foldersProvided.innerRef} {...foldersProvided.droppableProps}>
                    {Object.values(bookStructure).map((i, idx) => {
                      return (
                        <EditModeTwoLevelListItem
                          key={i.id}
                          item={i}
                          index={idx}
                          itemsLength={Object.values(bookStructure).length}
                          bookType={book.type}
                          onDelete={() => {
                            setDeletingItemType('folder');
                            setDeletingDocId(i.id);
                            setIsModalOpen(true);
                          }}
                          onDocDelete={docId => {
                            setDeletingItemType('doc');
                            setDeletingDocId(docId);
                            setIsModalOpen(true);
                          }}
                        />
                      );
                    })}
                    {foldersProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='docs' type='docs'>
                {docsProvided => (
                  <ul className='mb-4' ref={docsProvided.innerRef} {...docsProvided.droppableProps}>
                    {docs.map((page, index) => (
                      <EditModeOneLevelListItem
                        id={page.id}
                        key={page.id}
                        idx={index}
                        isActive={isSelectedSidebarItem(page.id)}
                        title={page.title || `Untitled ${bookTypesDictionary[book.type].docName}`}
                        onDelete={() => {
                          setDeletingItemType('doc');
                          setDeletingDocId(page.id);
                          setIsModalOpen(true);
                        }}
                        items={docs}
                      />
                    ))}
                    {docsProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )
        ) : book.type === 'fiction' && bookStructure ? (
          Object.values(bookStructure).map(i => (
            <TwoLevelListItem
              key={i.id}
              isActive={isSelectedSidebarItem(i.id)}
              folder={i}
              label={i.title || `Untitled ${bookTypesDictionary[book.type].folderName}`}
              onClick={() => dispatch(redirectActions.toFolderPageClick({ bookId: book.id, routePath: i.id }))}
              onSecondLevelItemClick={docId => {
                onClose();
                dispatch(redirectActions.toDocPageClick({ bookId: book.id, routePath: docId }));
              }}
              bookType={book.type}
              preExpanded={Object.keys(bookStructure)}
            />
          ))
        ) : (
          docs.map(({ id: docId, title }) => (
            <OneLevelListItem
              key={docId}
              isActive={isSelectedSidebarItem(docId)}
              label={title || `Untitled ${bookTypesDictionary[book.type].docName}`}
              onClick={() => {
                onClose();
                dispatch(
                  redirectActions.toDocPageClick({
                    routePath: docId,
                    bookId: book.id,
                  })
                );
              }}
            />
          ))
        )}
      </ul>
    </>
  );
};
