import { call, put } from 'redux-saga/effects';

import * as FirestoreService from '@shared/api/firestore';
import { ColSnap, Doc } from '@shared/types';

import { actions } from '../model/reducer';

export function* updateDocs(docsSnap: ColSnap) {
  const docs = docsSnap.docs.map(snap => snap.data()) as Doc[];
  yield put(actions.setDocs(docs.sort((a, b) => a.order - b.order)));
}

export function* setDocsData(uid: string) {
  const docs: Doc[] = yield call(FirestoreService.getDocs, uid);
  yield put(actions.setDocs(docs.sort((a, b) => a.order - b.order)));
}
