import React, { FC, ReactNode } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled, keyframes } from '@stitches/react';

const scaleIn = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.95)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

const StyledContent = styled(DropdownMenu.Content, {
  transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
  animation: `${scaleIn} 0.3s ease-out`,
  length: 0,
});

type DropdownItem = {
  onClick: () => void;
  label: ReactNode;
};
type Props = {
  triggerComponent: ReactNode;
  items: DropdownItem[];
  itemStyles?: string;
  listStyles?: string;
};
export const Dropdown: FC<Props> = ({ triggerComponent, items, itemStyles, listStyles }) => {
  return (
    <DropdownMenu.Root>
      {triggerComponent}
      <StyledContent
        align='end'
        sideOffset={10}
        className={`border rounded ${listStyles} bg-neutrals-0 divide-solid border-neutrals-200 focus:outline-none`}
      >
        <>
          {items.map((i, idx) => {
            return (
              <DropdownMenu.Item
                key={idx}
                onSelect={() => {
                  // Dropdown from the "@radix-ui" library and Dialog from the "headless-ui" library
                  // conflict with each other.
                  // When you click which should open the modal,
                  // it closes (only on android or in Chrome with the enabled responsive mode).
                  setTimeout(() => i.onClick(), 0);
                }}
                className='outline-none'
              >
                <button
                  className={`${itemStyles} text-left whitespace-nowrap w-full px-4
                  py-2.5 text-s transition-colors focus:outline-none
                  hover:bg-neutrals-100 active:bg-neutrals-200`}
                >
                  {i.label}
                </button>
              </DropdownMenu.Item>
            );
          })}
        </>
      </StyledContent>
    </DropdownMenu.Root>
  );
};
