import { call, put } from 'redux-saga/effects';

import { authChannelModel } from '@entities/auth-channel';
import { authModel } from '@entities/auth-operation';
import { loginSuperUser } from '@shared/api/cloud-functions';
import { auth } from '@shared/config';
import { signInWithCustomToken } from 'firebase/auth';

export function* signInAsSuperuser(action: ReturnType<typeof authModel.actions.signInAsSuperuser>) {
  const { email } = action.payload;

  yield call(authModel.sagas.authOperation, 'Unknown sign up error', function* () {
    const { data: token } = yield call(loginSuperUser, email);
    yield auth.signOut();
    yield signInWithCustomToken(auth, token);
    yield put(authChannelModel.chan, authChannelModel.loggedIn());
  });
}
