export const defaultAcademicSections = [
  'Acknowledgments',
  'Abstract',
  'Abbreviations',
  'Glossary',
  'Introduction',
  'Literature Review',
  'Methodology	',
  'Implementation',
  'Results',
  'Discussion',
  'Conclusion',
  'Availability and requirements',
  'Declarations',
  'Consent for publication',
  'Author’s contribution',
  'Reference list',
  'Appendix',
];
