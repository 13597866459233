import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { IconMap } from '@shared/sprite';
import { Icon } from './icon';

export type NotificationType = 'error' | 'primary' | 'success' | 'warning';

export const Notification = ({
  type,
  children,
  onClick,
}: {
  type: NotificationType;
  children: ReactNode;
  onClick?: () => void;
}) => {
  const style = `flex justify-between items-start mx-auto mb-4
                 text-s px-4.5 py-2.5 rounded sm:w-full`;

  return (
    <div
      className={classNames(`${style} w-80 border`, {
        ['bg-primary-100 border-primary-500']: type === 'primary',
        ['bg-warning-100 border-warning-500']: type === 'warning',
        ['bg-error-100 border-error-500']: type === 'error',
        ['bg-success-100 border-success-500']: type === 'success',
      })}
    >
      <div className='flex items-start'>
        <Icon
          glyph={IconMap.Toast}
          className={classNames('mr-4.5', {
            ['text-primary-500']: type === 'primary',
            ['text-warning-500']: type === 'warning',
            ['text-error-500']: type === 'error',
            ['text-success-500']: type === 'success',
          })}
        />
        {children}
      </div>
      <button onClick={onClick}>
        <Icon
          glyph={IconMap.Cross}
          className={classNames({
            ['text-primary-500']: type === 'primary',
            ['text-warning-500']: type === 'warning',
            ['text-error-500']: type === 'error',
            ['text-success-500']: type === 'success',
          })}
        />
      </button>
    </div>
  );
};
