import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { ErrorBoundary } from '@entities/error-boundary';

import { ErrorBlock } from '@shared/ui';
import { HeaderEditor } from '@widgets/header-editor';
import { SidebarLeft } from '@widgets/sidebar-left';
import { SidebarRight } from '@widgets/sidebar-right';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import classNames from 'classnames';
import { bookStyles } from '@pages/edit-book';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';

type Props = {
  children?: JSX.Element | JSX.Element[] | string | string[];
};

let toolbarContainer: HTMLDivElement | null = null;

export const getToolbarContainer = () => {
  if (!toolbarContainer) {
    throw new Error('toolbarContainer is null');
  }
  return toolbarContainer;
};

export const EditorLayout: FC<Props> = ({ children }) => {
  const selectedBook = useSelector(appDataModel.selectors.selectedBookWithError);
  const selectedDoc = useSelector(appDataModel.selectors.selectedDoc);
  const selectedFolder = useSelector(appDataModel.selectors.selectedFolder);
  const selectedNotesItem = selectedDoc || selectedFolder;

  const [isEditMode, onEditModeChange] = useState(false);
  const dispatch = useDispatch();
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItem);
  useEffect(() => {
    return () => {
      dispatch(appDataModel.actions.setCurrentBook());
    };
  }, [dispatch]);

  useEffect(() => {
    document.body.style.setProperty('--current-title-font', bookStyles[selectedBook.settings.style].titleFontName);
    document.body.style.setProperty('--current-text-font', bookStyles[selectedBook.settings.style].textFontName);

    return () => {
      document.body.style.removeProperty('--current-title-font');
      document.body.style.removeProperty('--current-text-font');
    };
  }, [selectedBook.settings.style]);

  const toolbarRef = useHeightCssVariable<HTMLDivElement>('--toolbar-height');
  useEffect(() => {
    toolbarContainer = toolbarRef.current;
    return () => {
      toolbarContainer = null;
    };
  }, [toolbarRef]);

  useEffect(() => {
    if (!toolbarContainer) {
      return;
    }
    toolbarContainer.innerHTML = '';
  }, [selectedSidebarItem]);

  return (
    <>
      <Helmet title={selectedBook.title} />

      <div className='flex flex-col h-full overflow-hidden'>
        <div
          className={`${isRightSidebarOpen ? 'sm:hidden' : 'sm:block'} bg-neutrals-0 hidden z-40 md:block fixed
          bottom-4 left-4 rounded-2.5xl border border-neutrals-200 w-10 h-10`}
        >
          {isLeftSidebarOpen ? (
            <button
              className='w-full h-full flex justify-center items-center rounded-2.5xl'
              onClick={() => {
                setIsLeftSidebarOpen(false);
                onEditModeChange(false);
              }}
            >
              <Icon glyph={IconMap.Cross} className='text-primary-800' />
            </button>
          ) : (
            <button
              className='w-full h-full flex justify-center items-center rounded-2.5xl'
              onClick={() => {
                setIsLeftSidebarOpen(true);
              }}
            >
              <Icon glyph={IconMap.BookStructure} />
            </button>
          )}
        </div>
        {selectedNotesItem && !isEditMode && (
          <div
            className={`${isLeftSidebarOpen ? 'sm:hidden' : 'sm:block'} bg-neutrals-0 hidden z-40 md:block fixed
                      bottom-4 right-4 rounded-2.5xl border border-neutrals-200 w-10 h-10`}
          >
            <button
              className='w-full h-full flex justify-center items-center rounded-2.5xl'
              onClick={() => {
                setIsRightSidebarOpen(!isRightSidebarOpen);
              }}
            >
              {isRightSidebarOpen ? (
                <Icon glyph={IconMap.Cross} className='text-primary-800' />
              ) : (
                <Icon glyph={IconMap.Notes} />
              )}
            </button>
          </div>
        )}
        <HeaderEditor selectedBook={selectedBook} />
        <div className='relative flex-grow px-10 sm:px-0'>
          {(isLeftSidebarOpen || isRightSidebarOpen) && (
            <button
              className='absolute top-0 left-0 z-10 hidden w-full h-full bg-transparent md:block'
              onClick={() => {
                setIsLeftSidebarOpen(false);
                setIsRightSidebarOpen(false);
                onEditModeChange(false);
              }}
            />
          )}
          <div className='relative grid h-full grid-cols-12 md:grid-cols-8 gap-x-4'>
            <SidebarLeft
              onEditModeChange={onEditModeChange}
              isEditMode={isEditMode}
              isOpen={isLeftSidebarOpen}
              onClose={() => setIsLeftSidebarOpen(false)}
            />
            <div
              className={classNames(
                `col-start-4 z-20 border-l border-r sm:border-none md:col-start-1
                md:col-end-9 border-neutrals-200 bg-neutrals-0`,
                {
                  'col-end-10': isOwner,
                  'col-end-13': !isOwner,
                  'bg-neutrals-100': selectedNotesItem?.__isDoc === false,
                }
              )}
            >
              <div className='relative'>
                <div ref={toolbarRef} className='min-h-11'></div>
              </div>

              <div id='copyright' className='flex flex-col'>
                <ErrorBoundary renderError={error => <ErrorBlock error={error} />}>{children}</ErrorBoundary>
              </div>
            </div>
            {isEditMode && <div className='absolute top-0 left-0 z-20 w-full h-full opacity-80 bg-neutrals-0' />}
            {isOwner && selectedNotesItem && (
              <SidebarRight
                selectedNotesItem={selectedNotesItem}
                selectedBook={selectedBook}
                isOpen={isRightSidebarOpen && !isEditMode}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
