import { appDataModel } from '@entities/app-data';
import { appendixModel } from '@entities/appendix';
import { DropResult } from 'react-beautiful-dnd';
import { HandleAppendicesDragEndConfig } from './types';

export const handleAppendicesDragEndEvent = (result: DropResult, config: HandleAppendicesDragEndConfig) => {
  const { destination, source } = result;
  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return;
  }
  handleBasicAppendicesDragEndEvent(result, config);
};

const handleBasicAppendicesDragEndEvent = (
  { source, destination }: DropResult,
  { appendices, dispatch, book }: HandleAppendicesDragEndConfig
) => {
  if (!destination) {
    throw new Error('Drag end event property "destination" not found');
  }
  const orderedAppendices = appendices.slice().sort((a, b) => a.order - b.order);

  const [movedAppendix] = orderedAppendices.splice(source.index, 1);

  orderedAppendices.splice(destination.index, 0, movedAppendix);

  const appendicesIds = orderedAppendices.map(doc => doc.id);

  dispatch(
    appendixModel.actions.changeAppendixOrderAction({
      bookId: book.id,
      ids: appendicesIds,
    })
  );

  dispatch(appDataModel.actions.setAppendices(orderedAppendices));
};
