import { call, put } from 'redux-saga/effects';

import * as FirestoreService from '@shared/api/firestore';
import { ColSnap, Doc } from '@shared/types';

import { actions } from '../model/reducer';

export function* updateAppendices(docsSnap: ColSnap) {
  const appendices = docsSnap.docs.map(snap => snap.data()) as Doc[];
  yield put(actions.setAppendices(appendices.sort((a, b) => a.order - b.order)));
}

export function* setAppendicesData(uid: string) {
  const appendices: Doc[] = yield call(FirestoreService.getAppendices, uid);
  yield put(actions.setAppendices(appendices.sort((a, b) => a.order - b.order)));
}
