import { isEmulatorsUsing } from '@shared/config';
import * as yup from 'yup';

export const url = isEmulatorsUsing
  ? process.env.REACT_APP_CLOUD_FUNCTIONS_URL_WITH_EMULATORS
  : process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const EXPECTED_CONFIRMATION = 'delete book';

export const bookDeleteValidationSchema = yup.object({
  confirmation: yup.string().trim().lowercase().oneOf([EXPECTED_CONFIRMATION]),
});
export type BookDeleteFormData = yup.InferType<typeof bookDeleteValidationSchema>;
