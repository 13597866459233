import { call, put, select } from 'redux-saga/effects';

import { authUserModel } from '@entities/auth-user';
import { redirect } from '@entities/history';
import * as FirestoreService from '@shared/api/firestore';
import { ColSnap, BookUser, User } from '@shared/types';

import { actions } from '../model/reducer';

export function* updateBookUsers(usersSnap: ColSnap) {
  const users = usersSnap.docs.map(snap => snap.data()) as BookUser[];

  yield put(actions.setCurrentBookUsers(users));
}

export function* setBookUsers(bookId: string) {
  const usersSnap: ColSnap = yield call(FirestoreService.getBookUsers, bookId);

  const users = usersSnap.docs.map(doc => doc.data()) as BookUser[];
  const user: User = yield select(authUserModel.selectors.selectUserWithError);
  if (!users.some(i => i.id === user.uid && i.inviteAccepted)) {
    yield call(redirect.toBooks);
  }
  yield put(actions.setCurrentBookUsers(users));
}
