import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { redirect } from '@entities/history';
import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';
import { DocRef, Book, Doc, SelectedSidebarItem } from '@shared/types';

import { actions } from './actions';

export function* addDoc(addDocAction: ReturnType<typeof actions.addDocClick>) {
  const {
    payload: { title, order, bookId, folderId },
  } = addDocAction;
  try {
    if (!bookId) {
      throw new Error('bookId is undefined');
    }
    const newDoc: Doc = {
      ...FirestoreService.createDefaultDoc(),
      title,
      order,
      ...(folderId ? { folderId } : undefined),
    };

    const { id: docId }: DocRef = yield call(FirestoreService.addDoc, bookId, newDoc);

    yield call(redirect.toDoc, bookId, docId);
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.addDoc)));
    logger.error(e);
  }
}

export function* changeDocOrder(ChangeDocOrderAction: ReturnType<typeof actions.changeDocOrderAction>) {
  const {
    payload: { bookId, ids },
  } = ChangeDocOrderAction;
  try {
    if (!bookId || ids.length === 0) {
      throw new Error('bookId or docsIds is undefined');
    }

    yield call(FirestoreService.changeDocsOrder, bookId, ids);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.changeDocOrder))
    );
    logger.error(e);
  }
}

export function* changeDocOrderInsideFolders(action: ReturnType<typeof actions.changeDocOrderActionInsideFolders>) {
  const {
    payload: { bookId, source, destination },
  } = action;
  try {
    if (!bookId) {
      throw new Error('bookId or docsIds is undefined');
    }

    yield call(FirestoreService.changeDocsOrderWithFolders, bookId, destination, source);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.changeDocOrder))
    );
    logger.error(e);
  }
}

export function* deleteDoc(DeleteDocAction: ReturnType<typeof actions.deleteDocClick>) {
  const {
    payload: { bookId, id: docId },
  } = DeleteDocAction;
  try {
    if (!bookId || !docId) {
      throw new Error('bookId or docId is undefined');
    }

    const docs: Doc[] = yield select(appDataModel.selectors.selectedDocsWithError);

    const filteredDocs: Doc[] = yield call(FirestoreService.deleteDoc, bookId, docId, docs);

    const selectedSidebarItem: SelectedSidebarItem = yield select(appDataModel.selectors.selectedSideBarItemWithError);

    if (selectedSidebarItem.type === 'doc' && filteredDocs.length) {
      yield call(redirect.toDoc, bookId, filteredDocs[filteredDocs.length - 1].id);
    }
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.deleteDoc)));
    logger.error(e);
  }
}

export function* saveDocDetails(saveDocDetailsAction: ReturnType<typeof actions.saveDocDetailsAction>) {
  const { payload } = saveDocDetailsAction;
  const { bookId, id: docId, content, fieldName } = payload;
  try {
    if (!bookId || !docId) {
      throw new Error('bookId or docId is undefined');
    }

    const docs: Doc[] = yield select(appDataModel.selectors.selectedDocsWithError);
    const currentDoc = docs.find(doc => doc.id === docId);
    const book: Book = yield select(appDataModel.selectors.selectedBookWithError);

    if (!currentDoc) {
      throw new Error('Doc is undefined');
    }

    if (payload.fieldName === 'headings') {
      const isHeadingsChanged =
        currentDoc.headings?.length !== payload.content.length ||
        currentDoc.headings.some(
          ({ value, level }, i) => payload.content[i].value !== value || payload.content[i].level !== level
        );
      if (!isHeadingsChanged) return;
    }

    if (payload.fieldName === 'tableTitles') {
      const isTitlesChanged =
        currentDoc.tableTitles?.length !== payload.content.length ||
        currentDoc.tableTitles.some((title, i) => payload.content[i] !== title);
      if (!isTitlesChanged) return;
    }

    if (payload.fieldName === 'imageTitles') {
      const isTitlesChanged =
        currentDoc.imageTitles?.length !== payload.content.length ||
        currentDoc.imageTitles.some((title, i) => payload.content[i] !== title);
      if (!isTitlesChanged) return;
    }

    yield call(FirestoreService.updateDocData, bookId, docId, { ...currentDoc, [fieldName]: content });

    yield call(FirestoreService.updateBookData, bookId, book);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.saveDocDetails))
    );
    logger.error(e);
  }
}
