import React, { FC } from 'react';
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion';

import { ItemHeading } from './ItemHeading';

type Props = {
  title: string;
  onClick: () => void;
};
export const AccordionMenuItem: FC<Props> = ({ title, onClick, children }) => {
  return (
    <AccordionItem key={title} uuid={title.replace(/\s/g, '')}>
      <ItemHeading title={title} bold onClick={onClick} />
      <AccordionItemPanel>{children}</AccordionItemPanel>
    </AccordionItem>
  );
};
