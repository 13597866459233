import React, { FC } from 'react';

type Props = {
  isActive: boolean;
  label: string;
  onClick: () => void;
  isSecondLevel?: boolean;
};
export const OneLevelListItem: FC<Props> = ({ isActive, label, onClick, isSecondLevel }) => {
  const activeStyle = isActive ? 'font-semibold bg-neutrals-100' : '';
  return (
    <li>
      <button onClick={onClick} className={`${activeStyle} rounded py-1.5 px-6 w-full text-left outline-button`}>
        <p className={`break-words truncate ${isSecondLevel ? 'pl-6' : ''}`}>
          <span className='inline-flex items-center align-middle w-3'>
            <span className={`w-1 h-1 rounded-sm ${isActive ? 'bg-neutrals-1000' : 'bg-neutrals-300'} `}></span>
          </span>
          {label}
        </p>
      </button>
    </li>
  );
};
