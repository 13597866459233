import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserBook } from '@shared/types';
import { Button } from '@shared/ui';

import { inviteControlModel } from '..';

type Props = {
  book: UserBook;
};

export const InviteController: FC<Props> = ({ book }) => {
  const activeInvites = useSelector(inviteControlModel.selectors.selectActiveInvites);
  const currentInvite = activeInvites.find(i => i.bookId === book.id);

  const dispatch = useDispatch();
  return (
    <div className='p-4 w-full absolute left-1/2 transform -translate-x-1/2 bottom-0'>
      <div
        className=' bg-neutrals-0 border
                          border-neutrals-200 rounded-md p-4 text-center'
      >
        <p className='text-s mb-4'>
          <b>{book._sharedBy}</b> has invited you to the book <b>“{book._title}”</b>
        </p>

        <div className='mb-4'>
          <Button
            variant='text'
            color='primary'
            className='w-full'
            disabled={Boolean(currentInvite)}
            loading={currentInvite?.action === 'accepting'}
            onClick={() => {
              dispatch(inviteControlModel.actions.acceptInviteClick(book.id));
            }}
          >
            Accept
          </Button>
        </div>
        <Button
          className='w-full'
          variant='text'
          color='secondary'
          disabled={Boolean(currentInvite)}
          loading={currentInvite?.action === 'rejecting'}
          onClick={() => {
            dispatch(inviteControlModel.actions.rejectInviteClick(book.id));
          }}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};
