import { createAction } from '@reduxjs/toolkit';
import {
  AddDocOrFolderAction,
  ChangeDocOrderActionInsideFolders,
  ChangeDocOrFolderOrderAction,
  DeleteDocOrFolderAction,
  SaveDocOrFolderDetailsAction,
} from '@shared/types';

export const actions = {
  changeDocOrderAction: createAction<ChangeDocOrFolderOrderAction>('doc/changeDocOrderAction'),
  changeDocOrderActionInsideFolders: createAction<ChangeDocOrderActionInsideFolders>(
    'doc/changeDocOrderActionWitsFolders'
  ),
  addDocClick: createAction<AddDocOrFolderAction>('doc/addDocClick'),
  deleteDocClick: createAction<DeleteDocOrFolderAction>('doc/deleteDocAction'),
  saveDocDetailsAction: createAction<SaveDocOrFolderDetailsAction>('doc/saveDocDetailsAction'),
};
