import { avenModel } from '@features/Aven-AI';
import { createNotification, snackbarModel } from '@features/snackbar';
import { dictionary } from '@shared/config';
import { IconMap } from '@shared/sprite';
import { Button } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

export const SavedItem: FC<{ id: string; text: string }> = ({ text, id }) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  return (
    <li className='mb-3 border-b border-neutrals-200'>
      <div className='relative'>
        <p
          className={classNames('text-sm h-20 overflow-hidden text-neutrals-1000 whitespace-pre-line', {
            'h-auto': expanded,
          })}
        >
          {text}
        </p>
        {!expanded && <div className='absolute h-10 bottom-0 left-0 w-full overlay-gradient'></div>}
      </div>

      <div className='flex py-3 items-center justify-between'>
        <div className='flex gap-4'>
          <Button
            fullWidth
            variant='icon'
            color='tertiary'
            title='Copy to clipboard'
            icon={<Icon glyph={IconMap.Copy} />}
            onClick={() => {
              navigator.clipboard.writeText(text);
              dispatch(
                snackbarModel.actions.addNotificationAction(
                  createNotification('success', dictionary.success.textCopied)
                )
              );
            }}
          />
          <Button
            fullWidth
            variant='icon'
            color='tertiary'
            title='Delete'
            icon={<Icon glyph={IconMap.Delete} />}
            onClick={() => {
              dispatch(avenModel.actions.deleteAvenResult({ item: { id, text } }));
            }}
          />
        </div>
        <div>
          <Button
            variant='icon'
            color='tertiary'
            className='w-5 h-5'
            title={expanded ? 'Close' : 'See more'}
            icon={
              <Icon
                glyph={IconMap.ArrowSmallTop}
                className={classNames('transition-all duration-500', { 'rotate-180 transform': !expanded })}
              />
            }
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </div>
      </div>
    </li>
  );
};
