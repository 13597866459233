export const AUTH_ROUTE = '/auth';
export const INDEX_ROUTE = '/';
export const EDIT_BOOK_ROUTE = '/edit-book';
export const EDIT_BOOK_ROUTE_ID = '/edit-book/:bookId';
export const BOOK_ROUTE = '/book';
export const BOOK_ID_ROUTE = '/book/:bookId';
export const COVER_BOOK_ROUTE = '/book/:bookId/cover';
export const DOC_ROUTE = '/book/:bookId/chapter';
export const LOADING_ROUTE = '/loading';

export const NOT_FOUND_ROUTE = '/404';
export const CLOSE_WINDOW_ROUTE = '/close';

export const REGISTER_ROUTE = '/register';
export const LOGIN_ROUTE = '/login';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const COMPLETE_PASSWORD_RESET_ROUTE = '/complete-reset-password';
export const SETTINGS_ROUTE = '/settings';
export const SUBSCRIPTION_ROUTE = '/settings/subscription';
export const PROFILE_SETTINGS_ROUTE = '/settings/profile';
export const SUPERUSER_ROUTE = '/settings/superuser';
export const MANAGE_USERS_ROUTE = '/settings/user-management';
export const WELCOME_ROUTE = '/welcome';
export const CREATE_FIRST_BOOK_ROUTE = '/welcome/create-first-book';
export const BOOKS_ROUTE = '/books';
export const NEW_BOOK_ROUTE = '/new-book';

export const TITLE_BOOK_ROUTE = '/book/:bookId/title';
export const COPYRIGHT_BOOK_ROUTE = '/book/:bookId/copyright';
export const APPROVED_BY_BOOK_ROUTE = '/book/:bookId/approved-by';
export const DEDICATION_BOOK_ROUTE = '/book/:bookId/dedication';
export const TABLE_OF_CONTENTS_BOOK_ROUTE = '/book/:bookId/table-of-contents';
export const ACKNOWLEDGEMENTS_BOOK_ROUTE = '/book/:bookId/acknowledgements';
export const FOREWORD_BOOK_ROUTE = '/book/:bookId/foreword';
export const PREFACE_BOOK_ROUTE = '/book/:bookId/preface';
export const INTRODUCTION_BOOK_ROUTE = '/book/:bookId/introduction';
export const ABSTRACT_BOOK_ROUTE = '/book/:bookId/abstract';
export const LIST_OF_TABLES_BOOK_ROUTE = '/book/:bookId/list-of-tables';
export const LIST_OF_FIGURES_BOOK_ROUTE = '/book/:bookId/list-of-figures';
export const REFERENCES_BOOK_ROUTE = '/book/:bookId/references';
export const ABOUT_BOOK_ROUTE = '/book/:bookId/about';
export const DOC_ID_ROUTE = '/book/:bookId/chapter/:docId';
export const APPENDIX_ID_ROUTE = '/book/:bookId/appendix/:docId';
export const FOLDER_ROUTE = '/book/:bookId/folder';
export const FOLDER_ID_ROUTE = '/book/:bookId/folder/:folderId';
