import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { ConnectivityIndicator } from '@entities/connectivity-indicator';
import { redirectActions } from '@entities/history';
import { bookTypeData } from '@features/book-creating';
import { bookDeletionModel, DeleteBookModal } from '@features/book-deletion';
import { ExportModal, exportModel } from '@features/book-export';
import { shareAccessModel, ShareWithModal } from '@features/share-access';

import { Book } from '@shared/types';
import { Button, Dropdown, Link, Tag } from '@shared/ui';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';
import { SupportForm } from '@features/support';
import { accessLevelDictionary } from '@features/share-access/config';

type Props = {
  selectedBook: Book;
};
let presenceListContainer: HTMLDivElement | null = null;

export const getPresenceListContainer = () => {
  // TODO: throw error
  return presenceListContainer;
};

export const HeaderEditor = ({ selectedBook }: Props) => {
  const exportIsLoading = useSelector(exportModel.selectors.selectExportIsLoading);

  const dispatch = useDispatch();
  const handleBackToBooksClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    dispatch(redirectActions.toBooksClick());
  };

  const [isSupportOpened, setSupportOpened] = useState(false);

  const presenceListRef = useRef<HTMLDivElement>(null);
  const headerRef = useHeightCssVariable('--header-editor-height');
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const role = useSelector(appDataModel.selectors.selectRoleWithError);

  useEffect(() => {
    presenceListContainer = presenceListRef.current;
    return () => {
      presenceListContainer = null;
    };
  }, []);

  return (
    <header
      ref={headerRef}
      className='w-full flex items-center px-10 py-3.5 md:px-6
      sm:px-5 bg-neutrals-0 border-b border-neutrals-200 gap-x-4'
    >
      <DeleteBookModal />
      <SupportForm
        isOpen={isSupportOpened}
        onClose={() => {
          setSupportOpened(false);
        }}
      />
      <div className='flex-initial'>
        <Link href='#' size='medium' onClick={handleBackToBooksClick}>
          <Icon glyph={IconMap.ArrowLeft} className='fill-primary-800' />
        </Link>
      </div>
      <Icon glyph={IconMap.ShortLogo} className='mr-2 sm:hidden' />
      <div className='flex flex-col items-start flex-1 min-w-0 w-full'>
        {/* eslint-disable-next-line max-len */}
        <p className='max-w-full overflow-hidden font-semibold text-m text-neutrals-1000 overflow-ellipsis whitespace-nowrap'>
          {selectedBook.title}
        </p>
        <div className='flex items-center w-inherit'>
          <p className='max-w-full overflow-hidden text-xs text-neutrals-500 overflow-ellipsis whitespace-nowrap mr-2'>
            {bookTypeData.find(bookType => bookType.type === selectedBook.type)?.name}
            {selectedBook.author && ` by ${selectedBook.author}`}
          </p>
          {!isOwner && <Tag color='blue'>{accessLevelDictionary[role]}</Tag>}
        </div>
      </div>
      <div className='flex items-center justify-end flex-initial'>
        <div className='flex items-center justify-start space-x-4 sm:mr-0 md:mr-5'>
          <ConnectivityIndicator />
          <div className='flex items-center'>
            <div className='sm:hidden'>
              <Button type='button' variant='text' color='secondary' onClick={() => setSupportOpened(true)}>
                Contact Support
              </Button>
            </div>
            <div className='presence-list-wrapper' ref={presenceListRef}></div>
          </div>
          {isOwner && (
            <>
              <div className='sm:hidden'>
                <Button
                  type='button'
                  color='secondary'
                  variant='icon'
                  icon={<Icon glyph={IconMap.Share} />}
                  onClick={() => dispatch(shareAccessModel.actions.setIsModalOpen(true))}
                  title='Share Book'
                />
                <ShareWithModal />
              </div>
              <div className='sm:hidden'>
                <Button
                  type='button'
                  color='secondary'
                  disabled={exportIsLoading}
                  variant='icon'
                  icon={<Icon glyph={IconMap.Export} />}
                  title='Export'
                  onClick={() => dispatch(exportModel.actions.startBookExport({ bookId: selectedBook.id }))}
                />
                <ExportModal />
              </div>
              <Dropdown
                listStyles='w-40'
                triggerComponent={
                  <Trigger
                    className='box-border flex items-center justify-center w-10 h-10 font-semibold border rounded
                               whitespace-nowrap text-s min-w-10 bg-neutrals-0 border-neutrals-200 text-primary-800
                             hover:bg-neutrals-100 hover:text-primary-600 active:bg-neutrals-200 active:text-primary-500
                             disabled:bg-neutrals-100 disabled:text-neutrals-400'
                  >
                    <Icon glyph={IconMap.BookMenu} className='fill-primary-800' />
                  </Trigger>
                }
                items={[
                  {
                    label: 'Edit Book',
                    onClick: () => dispatch(redirectActions.toEditBookClick({ bookId: selectedBook.id })),
                  },
                  {
                    label: 'Delete Book',
                    onClick: () => dispatch(bookDeletionModel.actions.startBookDeletion({ bookId: selectedBook.id })),
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </header>
  );
};
