import * as yup from 'yup';

export type RequestType = 'blooksyHelp' | 'accountHelp' | 'issue';

export type DropDownRequestType = RequestType | '';

export type RequestTypeConfigItem = { id: number; label: string; value: DropDownRequestType };

export const requestTypeConfig: RequestTypeConfigItem[] = [
  { id: 1, label: 'Help using Blooksy', value: 'blooksyHelp' },
  { id: 2, label: 'Help with my account', value: 'accountHelp' },
  { id: 3, label: 'Report a technical issue', value: 'issue' },
];

export const MAX_UPLOAD_FILES_AMOUNT = 3;

export const supportFormValidationSchema = yup.object({
  subject: yup.string().trim().required('Please enter a subject.'),
  message: yup.string().trim().required('Please enter a message.'),
});
export type SupportFormData = yup.InferType<typeof supportFormValidationSchema>;
