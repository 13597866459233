import { call, put } from 'redux-saga/effects';

import { authChannelModel } from '@entities/auth-channel';
import { authModel } from '@entities/auth-operation';
import { getUser, getUserFromFb } from '@entities/auth-user';
import { redirect } from '@entities/history';
import { auth } from '@shared/config/firebase';
import { logger } from '@shared/lib';
import { FirebaseUser } from '@shared/types';
import { createNotification, snackbarModel } from '@features/snackbar';
import { applyActionCode } from 'firebase/auth';

export function* verifyEmail(code: string) {
  yield call(authModel.sagas.authOperationWithAlert, 'email verification', function* () {
    yield applyActionCode(auth, code);
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('success', 'The email has been verified!'))
    );
  });
  try {
    const fbUser: FirebaseUser = yield getUserFromFb();
    yield fbUser.reload();
    yield getUser();
    yield put(authChannelModel.chan, authChannelModel.loggedIn({ redirectTo: 'welcome' }));
  } catch (e: unknown) {
    logger.error(e);
    yield call(redirect.toLogin);
  }
}
