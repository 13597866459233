import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '@entities/auth-operation';
import { redirectActions } from '@entities/history';
import { profileSettingModel } from '@features/profile-settings';
import { Input, Button, AuthLayout, AuthFormWrapper } from '@shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ForgotPasswordRequestData, forgotPasswordValidationSchema } from '../config';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const loading = useSelector(authModel.selectors.selectLoading);

  const { handleSubmit, control, formState, watch } = useForm<ForgotPasswordRequestData>({
    resolver: yupResolver(forgotPasswordValidationSchema),
    defaultValues: { email: '' },
  });

  const { email } = watch();

  const resetPassword = (data: ForgotPasswordRequestData) => {
    dispatch(profileSettingModel.actions.resetPasswordClick(data));
  };

  const handleBackToLoginClick = () => {
    dispatch(redirectActions.toLoginClick());
  };

  const isError = formState.errors.email || authError;
  const formVisible = !formState.isSubmitSuccessful || isError || loading;

  return (
    <>
      <Helmet title='Forgot Password' />
      <AuthLayout>
        <AuthFormWrapper title='Forgot Password'>
          {formVisible ? (
            <>
              <div className='mb-6'>
                <span className='text-s'>Please enter your email address to reset your password.</span>
              </div>
              <form onSubmit={handleSubmit(resetPassword)} noValidate>
                <div>
                  <Input control={control} name='email' type='email' label='Email' />
                </div>

                {authError && <p className='mt-4 px-4 py-1 text-xs rounded bg-error-100 text-error-700'>{authError}</p>}

                <div className='my-6'>
                  <Button type='submit' disabled={loading} loading={loading} color='primary' variant='text' fullWidth>
                    Send
                  </Button>
                </div>
              </form>

              <div className='border-t border-neutrals-200' />
            </>
          ) : (
            <div>
              <span className='text-s'>
                An email regarding your password change has been sent to your email address: <strong>{email}</strong>.
              </span>
            </div>
          )}

          <div className='mt-6'>
            <Button
              type='button'
              color={formVisible ? 'secondary' : 'primary'}
              variant='text'
              fullWidth
              onClick={handleBackToLoginClick}
            >
              Back to Log In
            </Button>
          </div>
        </AuthFormWrapper>
      </AuthLayout>
    </>
  );
};
