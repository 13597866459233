import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { bookModel } from '@entities/book';
import { Textarea } from '@shared/ui';

export const ApprovedByPage: FC = () => {
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);

  const handleSaveTitle = (content: string, bookId: string) => {
    if (content !== '') {
      dispatch(
        bookModel.actions.saveBookDetailsAction({
          book: { ...book, title: content },
          bookId,
        })
      );
    }
  };
  const handleSaveAuthor = (content: string, bookId: string) => {
    dispatch(
      bookModel.actions.saveBookDetailsAction({
        book: { ...book, author: content },
        bookId,
      })
    );
  };
  const handleSaveApprovedByContent = (content: string, bookId: string) => {
    dispatch(
      bookModel.actions.saveBookDetailsAction({
        book: { ...book, approvedByContent: content },
        bookId,
      })
    );
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveTitle = useCallback(debounce(handleSaveTitle, 300), []);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveAuthor = useCallback(debounce(handleSaveAuthor, 300), []);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveApprovedByContent = useCallback(debounce(handleSaveApprovedByContent, 300), []);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveTitle(e.target.value, book.id);
  };
  const handleChangeAuthor = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveAuthor(e.target.value, book.id);
  };
  const handleChangeApprovedByContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveApprovedByContent(e.target.value, book.id);
  };

  const titleRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    titleRef.current?.focus();
  }, []);

  return (
    <div className='h-full px-4 pt-4 sm:p-0 bg-neutrals-0'>
      <div className='min-h-11'></div>
      <div className='flex flex-col items-center justify-center h-full gap-4 p-10 mx-auto max-w-170 bg-neutrals-0'>
        <Textarea
          className='text-base leading-8 font-bold text-center'
          name='title'
          placeholder='Your title, which must be in sentence case, contain no special characters,
               and be in inverted pyramid format if longer than one line—use Shift+Enter to force a new line'
          ref={titleRef}
          disabled={!isOwner}
          defaultValue={book.title}
          onChange={handleChangeTitle}
        />
        <p
          className='text-font w-full p-0 text-center border-none resize-none placeholder-neutrals-500
                text-neutrals-1000 disabled:bg-transparent focus:outline-none focus:ring-0 text-base leading-8'
        >
          By
        </p>
        <Textarea
          className='text-base leading-8 text-center'
          name='subtitle'
          placeholder='Your name here'
          disabled={!isOwner}
          defaultValue={book.author}
          onChange={handleChangeAuthor}
        />
        <p
          className='text-font w-full p-0 text-left border-none resize-none placeholder-neutrals-500
                text-neutrals-1000 disabled:bg-transparent focus:outline-none focus:ring-0 text-base leading-8'
        >
          Approved:
        </p>
        <Textarea
          className='pt-4 text-base text-center'
          name='approved-by'
          disabled={!isOwner}
          defaultValue={book?.approvedByContent ?? ''}
          onChange={handleChangeApprovedByContent}
        />
      </div>
    </div>
  );
};
