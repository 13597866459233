import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FirestoreUserData } from '@shared/types';
import { CreateUsersAction, UpdateUsersCategoryAction } from '@features/admin/model/types';

type State = {
  firestoreUsers?: FirestoreUserData[];
  isFirestoreUsersLoading: boolean;
  isCreateUsersLoading: boolean;
};

const initialState: State = {
  firestoreUsers: undefined,
  isFirestoreUsersLoading: false,
  isCreateUsersLoading: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setFirestoreUsers: (state, { payload: firestoreUsers }: PayloadAction<FirestoreUserData[] | undefined>) => ({
      ...state,
      firestoreUsers,
    }),
    setIsFirestoreUsersLoading: (state, { payload: isFirestoreUsersLoading }: PayloadAction<boolean>) => ({
      ...state,
      isFirestoreUsersLoading,
    }),
    setCreateUsersIsLoading: (state, { payload: isCreateUsersLoading }: PayloadAction<boolean>) => ({
      ...state,
      isCreateUsersLoading,
    }),
  },
});

export const actions = {
  ...reducerActions,
  updateUsersCategory: createAction<UpdateUsersCategoryAction>(`admin/updateUsersCategory`),
  createUsers: createAction<CreateUsersAction & { cb: () => void }>(`admin/createUsers`),
};
