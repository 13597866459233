import { put, takeEvery } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { redirect, redirectActions } from '@entities/history';
import { signInWithEmailAndPassword } from '@features/auth/sign-in';
import MixpanelService from '@features/mixpanel';
export function* signInWorker() {
  MixpanelService.trackLoginOpened();

  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(authModel.actions.signInWithEmailAndPasswordClick, signInWithEmailAndPassword);
  yield takeEvery(redirectActions.toRegisterClick, redirect.toRegister);
  yield takeEvery(authModel.actions.forgotPasswordClick, redirect.toResetPassword);
}
