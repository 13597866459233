import * as yup from 'yup';

export const MIN_PASSWORD_LENGTH = 8;
export const USER_NAME_REGEX = /^\s*[a-z' -]+\s*$/i;

export const EMAIL_SCHEMA = yup.string().required('Email is required.').email('Invalid email.');
const createPasswordValidationError = 'Password is required. Password has to be at least 8 characters.';
export const CREATE_PASSWORD_SCHEMA = yup
  .string()
  .required(createPasswordValidationError)
  .min(MIN_PASSWORD_LENGTH, createPasswordValidationError);
export const PASSWORD_SCHEMA = yup.string().required('Password is required.');
export const FIRST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required('First name is required.')
  .matches(USER_NAME_REGEX, 'Invalid first name.');
export const LAST_NAME_SCHEMA = yup
  .string()
  .trim()
  .required('Last name is required.')
  .matches(USER_NAME_REGEX, 'Invalid last name.');

export const MAX_TITLE_LENGTH = 100;

/* eslint-disable max-len */
export const apaReferenceExample = `
<p>&lt;American Psychological Association. (year). Article title: Capital letter also for subtitle. <i>Name of Journal, volume#</i>(issue#), pg#-pg#.&gt;</p>
<p>&nbsp;</p>
<p>&lt;Author(s) of essay or chapter. (year). Title of essay or chapter. In F. M. Lastname (Ed.), <i>Book title</i> (pages of essay or chapter). Publisher. <a href="https://doi.org/10.xx.xxxxxxxxxx"><u>https://</u></a>………&gt;</p>
`;
