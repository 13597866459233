import debounce from 'lodash/debounce';
import React, { useState, FC, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { appendixModel } from '@entities/appendix';
import { CKEditorCS } from '@entities/ck-editor';
import { dictionary, MAX_TITLE_LENGTH } from '@shared/config';

import { Doc, CKEditorInstance } from '@shared/types';
import { EditorPageContentWrapper, ErrorBlock, Loader, Textarea } from '@shared/ui';
import { createNotification, snackbarModel } from '@features/snackbar';

type Props = {
  appendix: Doc;
};

export const AppendixPageBase: FC<Props> = ({ appendix }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);

  const titleRef = React.createRef<HTMLTextAreaElement>();
  const subTitleRef = React.createRef<HTMLTextAreaElement>();
  const [error, setError] = useState<Error | null>(null);

  const handleSaveAppendixTitle = (content: string, bookId: string, docId: string) => {
    if (content.length > MAX_TITLE_LENGTH) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('primary', dictionary.primary.tooLongChapterName)
        )
      );
    }
    dispatch(
      appendixModel.actions.saveAppendixDetailsAction({
        content,
        bookId,
        id: docId,
        fieldName: 'title',
      })
    );
  };

  const handleSaveAppendixSubtitle = (content: string, bookId: string, docId: string) => {
    dispatch(
      appendixModel.actions.saveAppendixDetailsAction({
        content,
        bookId,
        id: docId,
        fieldName: 'subtitle',
      })
    );
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveAppendixTitle = useCallback(debounce(handleSaveAppendixTitle, 500), []);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveAppendixSubtitle = useCallback(debounce(handleSaveAppendixSubtitle, 500), []);

  const handleChangeAppendixTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveAppendixTitle(e.target.value, book.id, appendix.id);
  };
  const handleChangeAppendixSubtitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveAppendixSubtitle(e.target.value, book.id, appendix.id);
  };

  const onTitlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      subTitleRef.current?.focus();
    }
  };
  const onSubtitlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      editorRef.current?.editing.view.focus();
    }
  };

  const editorRef = useRef<CKEditorInstance>();
  useEffect(() => {
    return () => {
      editorRef.current = null;
    };
  }, []);
  if (error) {
    return <ErrorBlock error={error} />;
  }
  return (
    <EditorPageContentWrapper>
      <Textarea
        ref={titleRef}
        className='flex-none px-10 py-0 text-base font-bold text-left'
        name='title'
        placeholder={`Untitled Appendix`}
        disabled={!isOwner}
        defaultValue={appendix.title}
        key={`${appendix.id}-title`}
        onChange={e => handleChangeAppendixTitle(e)}
        onKeyPress={onTitlePress}
      />
      <Textarea
        ref={subTitleRef}
        className='flex-none px-10 py-0 text-base font-normal text-left'
        name='subtitle'
        placeholder='Title of the appendix'
        disabled={!isOwner}
        defaultValue={appendix.subtitle}
        key={`${appendix.id}-subtitle`}
        onChange={e => handleChangeAppendixSubtitle(e)}
        onKeyPress={onSubtitlePress}
      />

      <CKEditorCS
        bookId={book.id}
        onReady={editor => {
          editorRef.current = editor;

          if (appendix.title) editor.editing.view.focus();
          else titleRef.current?.focus();
        }}
        documentHash={appendix.id}
        onError={(e: Error) => {
          setError(e);
        }}
        section='appendix'
      />
    </EditorPageContentWrapper>
  );
};

export const AppendixPage = () => {
  const appendix = useSelector(appDataModel.selectors.selectedAppendix);
  return appendix ? <AppendixPageBase appendix={appendix} /> : <Loader />;
};
