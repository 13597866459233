import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { redirect } from '@entities/history';
import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';
import { DocRef, Book, Doc, SelectedSidebarItem } from '@shared/types';

import { actions } from './actions';

export function* addAppendix(addAppendixAction: ReturnType<typeof actions.addAppendixClick>) {
  const {
    payload: { title, order, bookId, folderId },
  } = addAppendixAction;
  try {
    if (!bookId) {
      throw new Error('bookId is undefined');
    }
    const newAppendix: Doc = {
      ...FirestoreService.createDefaultDoc(),
      title,
      order,
      ...(folderId ? { folderId } : undefined),
    };

    const { id: docId }: DocRef = yield call(FirestoreService.addAppendix, bookId, newAppendix);

    yield call(redirect.toAppendix, bookId, docId);
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.addAppendix)));
    logger.error(e);
  }
}

export function* changeAppendixOrder(ChangeAppendixOrderAction: ReturnType<typeof actions.changeAppendixOrderAction>) {
  const {
    payload: { bookId, ids },
  } = ChangeAppendixOrderAction;
  try {
    if (!bookId || ids.length === 0) {
      throw new Error('bookId or docsIds is undefined');
    }

    yield call(FirestoreService.changeAppendicesOrder, bookId, ids);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.changeAppendixOrder))
    );
    logger.error(e);
  }
}

export function* deleteAppendix(DeleteAppendixAction: ReturnType<typeof actions.deleteAppendixClick>) {
  const {
    payload: { bookId, id: docId },
  } = DeleteAppendixAction;
  try {
    if (!bookId || !docId) {
      throw new Error('bookId or docId is undefined');
    }

    const appendices: Doc[] = yield select(appDataModel.selectors.selectedAppendicesWithError);

    const filteredAppendices: Doc[] = yield call(FirestoreService.deleteAppendix, bookId, docId, appendices);

    const selectedSidebarItem: SelectedSidebarItem = yield select(appDataModel.selectors.selectedSideBarItemWithError);

    if (selectedSidebarItem.type === 'appendix' && filteredAppendices.length) {
      yield call(redirect.toAppendix, bookId, filteredAppendices[filteredAppendices.length - 1].id);
    }
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.deleteAppendix))
    );
    logger.error(e);
  }
}

export function* saveAppendixDetails(saveAppendixDetailsAction: ReturnType<typeof actions.saveAppendixDetailsAction>) {
  const { payload } = saveAppendixDetailsAction;
  const { bookId, id: docId, content, fieldName } = payload;
  try {
    if (!bookId || !docId) {
      throw new Error('bookId or docId is undefined');
    }

    const appendices: Doc[] = yield select(appDataModel.selectors.selectedAppendicesWithError);
    const currentAppendix = appendices.find(doc => doc.id === docId);
    const book: Book = yield select(appDataModel.selectors.selectedBookWithError);

    if (!currentAppendix) {
      throw new Error('Appendix is undefined');
    }

    if (payload.fieldName === 'tableTitles') {
      const isTitlesChanged =
        currentAppendix.tableTitles?.length !== payload.content.length ||
        currentAppendix.tableTitles.some((title, i) => payload.content[i] !== title);
      if (!isTitlesChanged) return;
    }

    if (payload.fieldName === 'imageTitles') {
      const isTitlesChanged =
        currentAppendix.imageTitles?.length !== payload.content.length ||
        currentAppendix.imageTitles.some((title, i) => payload.content[i] !== title);
      if (!isTitlesChanged) return;
    }

    yield call(FirestoreService.updateAppendixData, bookId, docId, { ...currentAppendix, [fieldName]: content });

    yield call(FirestoreService.updateBookData, bookId, book);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.saveAppendixDetails))
    );
    logger.error(e);
  }
}
