import { SelectedSidebarItem } from '@shared/types';

export const isDoc = (name: string) => name === 'doc';
export const isAppendix = (name: string) => name === 'appendix';
export const isFolder = (name: string) => name === 'folder';
export const isSelectedItem = (docId: string, selectedSidebarItem: SelectedSidebarItem) =>
  (selectedSidebarItem.type === 'doc' && selectedSidebarItem.docId === docId) ||
  (selectedSidebarItem.type === 'appendix' && selectedSidebarItem.docId === docId) ||
  (selectedSidebarItem.type === 'folder' && selectedSidebarItem.docId === docId);

export * from './onDragEnd';
