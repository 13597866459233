import { put, call, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';
import { Book } from '@shared/types';

import { actions } from './actions';
import { redirect } from '@entities/history';

export function* saveBookDetails(saveBookDetailsAction: ReturnType<typeof actions.saveBookDetailsAction>) {
  const {
    payload: { book: newBook, bookId },
  } = saveBookDetailsAction;
  try {
    if (!bookId) {
      throw new Error('bookId is undefined');
    }

    yield call(FirestoreService.updateBookData, bookId, newBook);
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.saveBookDetails))
    );
    logger.error(e);
  }
}

export function* changeFolder(changeFolderAction: ReturnType<typeof actions.changeFolderClick>) {
  const { bookId, isFolded, type } = changeFolderAction.payload;
  const isOwner: boolean = yield select(appDataModel.selectors.selectIsBookOwnerWithError);
  const book: Book = yield select(appDataModel.selectors.selectedBookWithError);
  if (!isOwner) {
    return;
  }
  yield call(FirestoreService.updateBookData, bookId, {
    ...book,
    settings: { ...book.settings, folded: { ...book.settings.folded, [type]: isFolded } },
  });
}

export function* changeExtraVisability(changeExtraVisabilityAction: ReturnType<typeof actions.changeExtraVisability>) {
  const { bookId, extraField, hidden, isActive } = changeExtraVisabilityAction.payload;

  const book: Book = yield select(appDataModel.selectors.selectedBookWithError);

  yield call(FirestoreService.updateBookData, bookId, {
    ...book,
    extra: { ...book.extra, [extraField]: { ...book.extra[extraField], hidden: hidden } },
  });

  if (isActive) {
    yield call(redirect.toTitle, bookId);
  }
}
