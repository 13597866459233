import { buffers, EventChannel, eventChannel } from 'redux-saga';
import { cancelled, CancelledEffect, takeLatest } from 'redux-saga/effects';

import { isDevelopment } from '@shared/config';
import { blocker, logger } from '@shared/lib';
import { ColRef, ColSnap, DocRef, DocSnap, Saga } from '@shared/types';

import { onSnapshot as onSnapshotFb } from 'firebase/firestore';

export const queryDocChannel = (queryRef: DocRef) =>
  eventChannel<DocSnap>(emitter => onSnapshotFb(queryRef, emitter), buffers.expanding());

export const queryColChannel = (queryRef: ColRef, emitImmediately = true) => {
  let firstEmission = true;

  return eventChannel<ColSnap>(
    emitter =>
      onSnapshotFb(queryRef, snapshot => {
        if (!firstEmission || emitImmediately) emitter(snapshot);
        firstEmission = false;
      }),
    buffers.expanding()
  );
};

export function* onSnapshot(channel: EventChannel<DocSnap | ColSnap>, sagaWorker: Saga, snapshotName = '') {
  try {
    yield takeLatest(channel, sagaWorker);
    yield blocker();
  } finally {
    const isCancel: CancelledEffect = yield cancelled();

    if (isCancel) {
      isDevelopment && logger.info(`Snapshot '${snapshotName}' has been canceled!`);
      channel.close();
    }
  }
}
