import React, { FC, SVGProps } from 'react';

export type SpriteGlyph = SVGProps<SVGSVGElement>;

export type IconProps = {
  glyph: SpriteGlyph;
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
};

export const Icon: FC<IconProps> = ({ glyph, className, width, height = width, fill }) => {
  const viewBoxSizes = glyph.viewBox?.split(' ').map(i => parseInt(i, 10));
  const iconWidth = width || viewBoxSizes?.[2];
  const iconHeight = height || viewBoxSizes?.[3];

  return (
    <svg className={className} width={iconWidth} height={iconHeight} fill={fill} viewBox={glyph.viewBox}>
      <use xlinkHref={`/sprite.svg#${glyph.id}`} />
    </svg>
  );
};
