import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AskAvenResult, AskAvenParams } from 'blooksy-backend';

import { AvailableCoinsCount } from '..';

type State = {
  isLoading: boolean;
  results: AskAvenResult[];
  isCoinsLoading: boolean;
  currentSavedResults: AskAvenResult[];
};
const initialState: State = {
  isLoading: false,
  results: [],
  isCoinsLoading: false,
  currentSavedResults: [],
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'aven',
  initialState,
  reducers: {
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => ({
      ...state,
      isLoading,
    }),
    setIsCoinsLoading: (state, { payload: isCoinsLoading }: PayloadAction<boolean>) => ({
      ...state,
      isCoinsLoading,
    }),
    setResults: (state, { payload: results }: PayloadAction<AskAvenResult[]>) => ({
      ...state,
      results,
    }),
    setCurrentSavedResults: (state, { payload: currentSavedResults }: PayloadAction<AskAvenResult[]>) => ({
      ...state,
      currentSavedResults,
    }),
  },
});

export const actions = {
  ...reducerActions,
  askAvenClick: createAction<AskAvenParams>(`aven/askAvenClick`),
  saveAvenResult: createAction<{ item: AskAvenResult; cb?: () => void }>(`aven/saveResult`),
  deleteAvenResult: createAction<{ item: AskAvenResult; cb?: () => void }>(`aven/deleteResult`),
  buyCoins: createAction<{ count: AvailableCoinsCount; cb?: () => void }>('aven/buyCoins'),
};
