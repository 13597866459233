import React, { FC, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { Checkbox } from '@shared/ui/checkbox';

type Item = {
  name: string;
  id: string; // unique
};

type Props = {
  onChange: (item: Item[]) => void;
  selectedItems: Item[];
  items: Item[];
  label: string;
};

export const SelectCheckboxes: FC<Props> = ({ selectedItems, onChange, items, label }) => {
  return (
    <Listbox value={selectedItems} onChange={onChange} multiple>
      {({ open }) => (
        <div className='relative w-full'>
          <Listbox.Button
            className={`w-full box-border justify-between relative flex items-center rounded border
                      transition-colors duration-300 h-10 ease-in-out px-4
                      ${open ? 'border-primary-800' : 'border-neutrals-200'}`}
          >
            <span className={`text-s text-neutrals-1000 whitespace-nowrap overflow-ellipsis overflow-hidden`}>
              {selectedItems.length ? `${selectedItems.length} ${label} Selected` : `All ${label}`}
            </span>
            <Icon
              glyph={IconMap.ArrowSmallBottom}
              className={`${
                open ? 'fill-primary-800 rotate-180 transform' : 'fill-neutrals-400'
              } transition-all duration-500`}
            />
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options
              className='absolute right-0 z-10 min-w-full overflow-auto border rounded  w-fit-content
                          border-neutrals-200 text-s bg-neutrals-0 focus:outline-none'
            >
              {items.map(item => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    `${active ? 'bg-neutrals-100' : 'bg-neutrals-0'}
                            select-none relative py-2.5 px-4 whitespace-nowrap cursor-pointer flex gap-2 item-center`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <Checkbox checked={selected} readOnly id={`${label}-${item.id}`} />
                      <p className='text-s text-neutrals-1000'>{item.name}</p>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
