import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { adminModel } from '@features/admin/model';
import { Users } from '@features/admin';
import { Loader } from '@shared/ui';

const UserManagement: FC = () => {
  const firestoreUsers = useSelector(adminModel.selectors.selectFirestoreUsersData);
  const isFirestoreUsersLoading = useSelector(adminModel.selectors.selectIsFirestoreUsersLoading);

  if (isFirestoreUsersLoading) {
    return (
      <div className='mt-50'>
        <Loader isFullScreen={false} />
      </div>
    );
  }

  if (!firestoreUsers || firestoreUsers.length === 0) return null;

  return (
    <div
      className='col-start-3 col-end-11
    md:col-start-1 md:col-end-9 md:max-w-screen sm:col-start-1 sm:col-end-5 sm:w-full'
    >
      <Users />
    </div>
  );
};

export default UserManagement;
