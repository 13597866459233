import { BookUser } from '@shared/types';
import { GBookUser, Timestamp } from 'blooksy-backend';

import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

export const bookUserConverter: FirestoreDataConverter<BookUser> = {
  toFirestore(data: PartialWithFieldValue<BookUser>): DocumentData {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): BookUser {
    const id = snapshot.id;
    const data = snapshot.data(options) as GBookUser<Timestamp>;

    if (data.inviteAccepted) {
      return { id, ...data, createdAt: data.createdAt.toMillis(), inviteAcceptedAt: data.inviteAcceptedAt.toMillis() };
    } else {
      return { id, ...data, createdAt: data.createdAt.toMillis() };
    }
  },
};
