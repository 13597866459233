import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isPhotoLoading: boolean;
  coverError?: string;
};
const initialState: State = {
  isPhotoLoading: false,
  coverError: undefined,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'bookEdit',
  initialState,
  reducers: {
    setIsLoading: (state, { payload: isPhotoLoading }: PayloadAction<boolean>) => ({
      ...state,
      isPhotoLoading,
    }),
    setCoverError: (state, { payload: coverError }: PayloadAction<string | undefined>) => ({
      ...state,
      coverError,
    }),
  },
});
export const actions = {
  ...reducerActions,
  uploadBookCover: createAction<File>('bookEdit/uploadBookCover'),
  deleteBookCover: createAction<string>('bookEdit/deleteBookCover'),
};
