import { appDataModel } from '@entities/app-data';
import { docModel } from '@entities/doc';
import { DropResult } from 'react-beautiful-dnd';
import { HandleDragEndConfig } from './types';

export const handleDocsDragEndEvent = (result: DropResult, config: HandleDragEndConfig) => {
  const { destination, source } = result;
  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return;
  }

  const { book } = config;

  if (book.type === 'fiction') {
    handleFictionDocsDragEndEvent(result, config);
  } else {
    handleBasicDocsDragEndEvent(result, config);
  }
};

const handleFictionDocsDragEndEvent = (
  { source, destination }: DropResult,
  { book, bookStructure, dispatch }: HandleDragEndConfig
) => {
  if (!bookStructure) {
    throw new Error('Book structure not found');
  }
  if (!destination) {
    throw new Error('Drag end event property "destination" not found');
  }

  if (source.droppableId !== destination.droppableId) {
    const sourceDocs = bookStructure[source.droppableId].children.slice().sort((a, b) => a.order - b.order);
    const destinationDocs = bookStructure[destination.droppableId].children.slice().sort((a, b) => a.order - b.order);

    const [movedDoc] = sourceDocs.splice(source.index, 1);
    destinationDocs.splice(destination.index, 0, movedDoc);

    if (!sourceDocs.length) {
      return;
    }

    dispatch(
      docModel.actions.changeDocOrderActionInsideFolders({
        bookId: book.id,
        source: { ids: sourceDocs.map(i => i.id), folderId: source.droppableId },
        destination: { ids: destinationDocs.map(i => i.id), folderId: destination.droppableId },
      })
    );
    dispatch(
      appDataModel.actions.setBookStructure({
        ...bookStructure,
        [source.droppableId]: { ...bookStructure[source.droppableId], children: sourceDocs },
        [destination.droppableId]: { ...bookStructure[destination.droppableId], children: destinationDocs },
      })
    );
  } else {
    const currentDocs = bookStructure[source.droppableId].children.slice();

    const [movedDoc] = currentDocs.splice(source.index, 1);
    currentDocs.splice(destination.index, 0, movedDoc);
    dispatch(
      docModel.actions.changeDocOrderActionInsideFolders({
        bookId: book.id,
        destination: { ids: currentDocs.map(i => i.id), folderId: destination.droppableId },
      })
    );
    dispatch(
      appDataModel.actions.setBookStructure({
        ...bookStructure,
        [destination.droppableId]: { ...bookStructure[destination.droppableId], children: currentDocs },
      })
    );
  }
};

const handleBasicDocsDragEndEvent = (
  { source, destination }: DropResult,
  { docs, dispatch, book }: HandleDragEndConfig
) => {
  if (!destination) {
    throw new Error('Drag end event property "destination" not found');
  }
  const orderedDocs = docs.slice().sort((a, b) => a.order - b.order);

  const [movedDoc] = orderedDocs.splice(source.index, 1);

  orderedDocs.splice(destination.index, 0, movedDoc);

  const docsIds = orderedDocs.map(doc => doc.id);

  dispatch(
    docModel.actions.changeDocOrderAction({
      bookId: book.id,
      ids: docsIds,
    })
  );

  dispatch(appDataModel.actions.setDocs(orderedDocs));
};
