import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';

import { CKEditorCS } from '@entities/ck-editor';
import { apaFrontBackPageDictionary, frontBackPageDictionary } from '@shared/config';

import { ExtraFields } from 'blooksy-backend';
import { EditorPageContentWrapper, ErrorBlock } from '@shared/ui';

import cn from 'classnames';

type Props = {
  pageType: ExtraFields;
};

export const FrontBackMatterPage: FC<Props> = ({ pageType }) => {
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isApaPaper = book.type === 'apa';
  const [error, setError] = useState<Error | null>(null);
  const dictionary = isApaPaper ? apaFrontBackPageDictionary : frontBackPageDictionary;
  if (error) {
    return <ErrorBlock error={error} />;
  }
  return (
    <EditorPageContentWrapper>
      <h2
        className={cn(
          `title-font px-10 w-full text-center font-semibold disabled:bg-transparent
                    focus:outline-none focus:ring-0`,
          {
            'text-l font-semibold': !isApaPaper,
            'text-base leading-8 font-bold': isApaPaper,
          }
        )}
      >
        {dictionary[pageType]?.title}
      </h2>
      <CKEditorCS
        config={{
          placeholder: dictionary[pageType]?.placeholder,
        }}
        bookId={book.id}
        documentHash={pageType}
        onReady={editor => editor.editing.view.focus()}
        onError={(e: Error) => {
          setError(e);
        }}
        example={dictionary[pageType]?.example ?? ''}
      />
    </EditorPageContentWrapper>
  );
};
