import { FirestoreUserData } from '@shared/types';
import { getPermission } from '@shared/lib/permissions/user-permissions';

export const hasBookAccess = (user: FirestoreUserData) => getPermission(user).hasBookAccess;
export const hasUnlimitedAvenCoins = (user: FirestoreUserData) => getPermission(user).hasUnlimitedAvenCoins;

export const isAdmin = (user: FirestoreUserData) => user.category === 'admin';
export const isKartra = (user: FirestoreUserData) => user.category === 'kartra';
export const isKSU = (user: FirestoreUserData) => user.category === 'KSU';
export const isBlooksyTeamMember = (user: FirestoreUserData) => user.category === 'Blooksy-Team-Member';
export const isAPA = (user: FirestoreUserData) => user.category === 'APA';
