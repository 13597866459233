import { UserBook } from '@shared/types';
import { GUserBook, Timestamp } from 'blooksy-backend';

import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

export const userBookConverter: FirestoreDataConverter<UserBook> = {
  toFirestore(data: PartialWithFieldValue<UserBook>): DocumentData {
    return data;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): UserBook {
    const id = snapshot.id;
    const data = snapshot.data(options) as GUserBook<Timestamp>;

    return {
      id,
      ...data,
      _updatedAt: data._updatedAt.toMillis(),
      _createdAt: data._createdAt.toMillis(),
      _sharedBy: data._sharedBy || data._author,
    };
  },
};
