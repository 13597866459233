import React, { FC } from 'react';
import { Button, Input, Modal, Select } from '@shared/ui';
import { Category } from '@features/admin/lib';
import { FirestoreUserData } from '@shared/types';
import { useDispatch } from 'react-redux';
import { adminModel } from '@features/admin/model';
import { useCategorySelect } from '@features/admin/ui/manage-users/hooks';

type Props = {
  users: FirestoreUserData[];
  categories: Category[];
  cb?: () => void;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditUser: FC<Props> = ({ users, categories, cb, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const {
    extendedCategories,
    selectedCategory,
    newCategory,
    showNewCategory,
    inputsErrors,
    setSelectedCategory,
    setNewCategory,
    checkIsErrors,
    reset,
    getCategoryId,
  } = useCategorySelect(categories);

  const onCancelClick = () => {
    reset();
    setIsOpen(false);
  };

  const onConfirmClick = () => {
    if (checkIsErrors()) return;

    const category = getCategoryId();

    dispatch(
      adminModel.actions.updateUsersCategory({
        users,
        category,
      })
    );

    cb?.();
    onCancelClick();
  };

  return (
    <div>
      <Button variant='text' color='secondary' type='button' onClick={() => setIsOpen(true)}>
        {`Edit ${users.length} Users`}
      </Button>

      <Modal
        opened={isOpen}
        title='Edit Users'
        rightButtonText='Save'
        onCancelClick={onCancelClick}
        onConfirmClick={onConfirmClick}
      >
        <div className='-my-6.5'>
          <p className='text-neutrals-500 text-s'>Assign Category</p>
          <Select
            selectedItem={selectedCategory}
            items={extendedCategories}
            onChange={setSelectedCategory}
            isError={!!inputsErrors.category}
          />
          {showNewCategory && (
            <>
              <p className='text-neutrals-500 text-s mt-4'>Category Name</p>
              <Input
                type='text'
                value={newCategory}
                onChange={e => setNewCategory(e.target.value)}
                isError={!!inputsErrors.newCategory}
                messageText={inputsErrors.newCategory === 'Required' ? '' : inputsErrors.newCategory}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
