import { call } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { profileSettingModel } from '@features/profile-settings';
import { auth } from '@shared/config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

export function* resetPassword(resetPasswordAction: ReturnType<typeof profileSettingModel.actions.resetPasswordClick>) {
  const {
    payload: { email },
  } = resetPasswordAction;

  yield call(authModel.sagas.authOperation, 'Unknown email error', function* () {
    yield sendPasswordResetEmail(auth, email);
  });
}
