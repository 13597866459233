import React, { FC, ReactNode, useMemo, useState } from 'react';

import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { Button, PanelRadioGroup } from '../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { stripeModel } from '../model';
import { SubscribePlanRow } from './subscribePlanRow';

export type SubscriptionMode = 'yearly' | 'lifetime';

export type SubscriptionOption = {
  id: SubscriptionMode;
  title: string;
  description: ReactNode;
};

type Props = { isWelcome?: boolean };

export const StripePlans: FC<Props> = ({ isWelcome = false }) => {
  const [selectedMode, setSelectedMode] = useState<SubscriptionMode>('yearly');
  const options: SubscriptionOption[] = useMemo(
    () => [
      {
        id: 'yearly',
        title: 'Annually',
        description: (
          <span>
            <span className='font-semibold text-m'>$300</span> per year
          </span>
        ),
      },
      {
        id: 'lifetime',
        title: 'Lifetime',
        description: (
          <span>
            <span className='font-semibold text-m'>$600</span>
          </span>
        ),
      },
    ],
    []
  );
  const dispatch = useDispatch();
  const onSubscribe = () => {
    dispatch(
      stripeModel.actions.createCheckoutSession({
        name: 'wordsmith-yearly',
        interval: selectedMode === 'lifetime' ? 'lifetime' : 'year',
      })
    );
  };

  const isLoading = useSelector(stripeModel.selectors.selectIsLoading);

  return (
    <div>
      <div className='grid grid-cols-1 gap-4 sm:grid-cols-1'>
        <div className='p-8 border border-neutrals-200'>
          <h2 className='mb-3 font-semibold text-l pb-2.75 w-full border-b border-neutrals-200'>Wordsmith</h2>

          <div className='flex flex-col gap-3 mt-6'>
            <div className='flex items-center'>
              <div className='w-3.75 mr-2.5 text-primary-800'>
                <Icon glyph={IconMap.ListCheckMark} />
              </div>
              <span className='text-s'>Book Editor</span>
            </div>
            <div className='flex items-center'>
              <div className='w-3.75 mr-2.5 text-primary-800'>
                <Icon glyph={IconMap.ListCheckMark} />
              </div>
              <span className='text-s'>Voice-To-Text</span>
            </div>
            <div className='flex items-center'>
              <div className='w-3.75 mr-2.5 text-primary-800'>
                <Icon glyph={IconMap.ListCheckMark} />
              </div>
              <span className='text-s'>Book Export</span>
            </div>
            <div className='flex items-center'>
              <div className='w-3.75 mr-2.5 text-primary-800'>
                <Icon glyph={IconMap.ListCheckMark} />
              </div>
              <span className='text-s'>Collaboration</span>
            </div>
            <div className='flex items-center'>
              <div className='w-3.75 mr-2.5 text-primary-800'>
                <Icon glyph={IconMap.ListCheckMark} />
              </div>
              <span className='text-s'>Aven AI (20 coins / month)</span>
            </div>
          </div>
          <div className='border-t pt-6 mt-6 w-full border-neutrals-200'>
            {isWelcome ? (
              <>
                <PanelRadioGroup<SubscriptionMode>
                  name='subscription-option'
                  activeId={selectedMode}
                  onChange={setSelectedMode}
                  options={options}
                />

                <Button
                  className='mt-4'
                  onClick={onSubscribe}
                  loading={isLoading}
                  disabled={isLoading}
                  fullWidth
                  variant='text'
                  color='primary'
                >
                  {selectedMode === 'yearly' ? 'Start 7-Day Trial for $1' : 'Continue to Payment'}
                </Button>
                <p className='text-neutrals-500 text-xs text-center mt-2'>
                  {selectedMode === 'yearly'
                    ? '7 days for $1, then $300 / year'
                    : 'Pay once or set up monthly payments with Affirm'}
                </p>
              </>
            ) : (
              <SubscribePlanRow options={options} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
