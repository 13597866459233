import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '@shared/types';

type State = { firebaseUser?: User };
const initialState: State = {
  firebaseUser: undefined,
};
export const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: firebaseUser }: PayloadAction<User | undefined>) => ({
      ...state,
      firebaseUser,
    }),
  },
});
