import React from 'react';
import { Helmet } from 'react-helmet';

import { AuthFormWrapper } from '@shared/ui';

import CreateAccountForm from './CreateAccountForm';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const SignUp = () => {
  return (
    <>
      <Helmet title='Sign Up' />
      <div
        className={`flex flex-col justify-start min-h-full px-10 py-10
        sm:py-6 sm:px-0 md:px-6 h-fit-content bg-primary-50`}
      >
        <div className='grid grid-cols-12 sm:block md:grid-cols-8 gap-x-4 text-neutrals-1000'>
          <div className='col-start-2 col-end-7 md:hidden sm:hidden'>
            <div className='flex justify-start mb-6'>
              <Icon glyph={IconMap.Logo} className='mr-2.5' />
            </div>
            <h2 className='pb-2 font-semibold text-l'>The Future of Digital Publishing</h2>
            <p className='text-s'>
              Online collaborative publishing platform that brings authors, their manuscripts, friends, peers together.
            </p>
          </div>
          <div className='col-start-8 col-end-12 md:col-start-3 md:col-end-7'>
            <div className='justify-center hidden mb-6 md:flex '>
              <Icon glyph={IconMap.Logo} className='mr-2.5' />
            </div>
            <AuthFormWrapper title='Create Blooksy Account'>
              <CreateAccountForm />
            </AuthFormWrapper>
          </div>
        </div>
      </div>
    </>
  );
};
