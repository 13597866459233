import { call, select } from 'redux-saga/effects';

import { updateCurrentBook } from '@entities/app-data';
import { onSnapshot, queryDocChannel } from '@entities/snapshot-listener';
import { getBooksRef } from '@shared/api/firestore';
import { doc } from 'firebase/firestore';

import { selectedBookId } from '../selectors';

export function* watchCurrentBookWorker() {
  const selectedBookIdFromStore: string | undefined = yield select(selectedBookId);
  if (!selectedBookIdFromStore) {
    throw new Error('selectedBookIdFromStore is undefined');
  }

  const bookRef = doc(getBooksRef(), selectedBookIdFromStore);
  const booksChannel = queryDocChannel(bookRef);

  yield call(onSnapshot, booksChannel, updateCurrentBook, 'Book');
}
