import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import React, { FC } from 'react';

export const AddButton: FC = ({ children }) => {
  return (
    <span
      className={`flex items-center justify-center whitespace-nowrap font-semibold text-s rounded border
              box-border p-0 bg-transparent text-primary-800 border-none hover:text-primary-600 active:text-primary-500
            disabled:text-neutrals-400`}
    >
      <Icon glyph={IconMap.Plus} className='mr-3 max-w-none fill-primary-800' />
      {children}
    </span>
  );
};
