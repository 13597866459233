import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authUserModel } from '@entities/auth-user';
import { Input } from '@shared/ui';

import { ChangeNameRequestData, changeNameValidationSchema } from '../config';
import { useForm } from 'react-hook-form';
import { profileSettingModel } from '../model';
import { yupResolver } from '@hookform/resolvers/yup';

export const ChangeNameForm = () => {
  const dispatch = useDispatch();

  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const { control, handleSubmit, reset, formState } = useForm<ChangeNameRequestData>({
    resolver: yupResolver(changeNameValidationSchema),
    mode: 'onBlur',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });

  const userNameError = useSelector(profileSettingModel.selectors.selectUserNameError);

  const changeName = ({ firstName, lastName }: ChangeNameRequestData) => {
    dispatch(
      profileSettingModel.actions.updateNamesAction({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      })
    );
  };

  useEffect(() => {
    const { firstName, lastName } = user;
    reset({ firstName, lastName });
  }, [reset, user]);

  useEffect(() => {
    if (formState.errors.firstName || formState.errors.lastName) {
      dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setUserNameError' }));
    }
  }, [dispatch, formState]);

  return (
    <div className='w-full max-w-104 sm:max-w-screen'>
      <form onBlur={handleSubmit(changeName)} noValidate>
        <div className='w-full mb-6'>
          <Input control={control} name='firstName' type='text' label='First Name' />
        </div>
        <div className='w-full'>
          <Input control={control} name='lastName' type='text' label='Last Name' />
        </div>
      </form>

      {userNameError && <p className='px-4 py-1 mt-5 text-xs rounded bg-error-100 text-error-700'>{userNameError}</p>}
    </div>
  );
};
