import { SidebarItemRoute, SidebarItemType } from '@shared/types';
import { ExtraFields } from 'blooksy-backend';

export type FrontBackMatterSidebarItem = {
  type: SidebarItemType;
  label: string;
  routePath: SidebarItemRoute;
} & (
  | {
      canBeHidden: false;
    }
  | {
      canBeHidden: true;
      extraFieldName: ExtraFields;
    }
);

export const frontMatterItems: FrontBackMatterSidebarItem[] = [
  // { type: 'coverPage', label: 'Cover', routePath: 'cover' },
  { type: 'titlePage', label: 'Title', routePath: 'title', canBeHidden: false },
  { type: 'copyrightPage', label: 'Copyright', routePath: 'copyright', canBeHidden: false },
  { type: 'dedication', label: 'Dedication', routePath: 'dedication', canBeHidden: true, extraFieldName: 'dedication' },
  { type: 'tableOfContents', label: 'Table of Contents', routePath: 'table-of-contents', canBeHidden: false },
  {
    type: 'acknowledgements',
    label: 'Acknowledgements',
    routePath: 'acknowledgements',
    canBeHidden: true,
    extraFieldName: 'acknowledgements',
  },
  { type: 'foreword', label: 'Foreword', routePath: 'foreword', canBeHidden: true, extraFieldName: 'foreword' },
  { type: 'preface', label: 'Preface', routePath: 'preface', canBeHidden: true, extraFieldName: 'preface' },
  {
    type: 'introduction',
    label: 'Introduction',
    routePath: 'introduction',
    canBeHidden: true,
    extraFieldName: 'introduction',
  },
];

export const backMatterItems: FrontBackMatterSidebarItem[] = [
  { type: 'about', label: 'About the Author', routePath: 'about', canBeHidden: true, extraFieldName: 'aboutAuthor' },
];

export type DeletingItemType = 'folder' | 'doc' | 'appendix';
