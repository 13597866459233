import { redirectActions } from '@entities/history';
import { bookTypesDictionary } from '@shared/config';
import { Book, Doc } from '@shared/types';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

export const DocPreview: FC<{ doc: Doc; book: Book }> = ({ doc, book }) => {
  const dispatch = useDispatch();

  return (
    <article className='pt-4 px-4 bg-neutrals-0 h-50 rounded overflow-hidden relative'>
      <header className='title-font font-semibold text-sm pb-2 border-b border-neutrals-200'>
        {doc.title || 'Untitled ' + bookTypesDictionary[book.type].docName}
      </header>
      <div className='text-font text-xs mt-2'>{doc._previewText}</div>
      <div className='absolute overlay-gradient bottom-0 left-0 w-full h-10'></div>

      <button
        onClick={() => {
          dispatch(
            redirectActions.toDocPageClick({
              routePath: doc.id,
              bookId: book.id,
            })
          );
        }}
        className='absolute top-0 left-0 w-full h-full bg-transparent'
      >
        <span className='sr-only'>Go to {doc.title}</span>
      </button>
    </article>
  );
};
