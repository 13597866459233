import ArrowLeft from './icons/arrow-left.svg';
import ArrowSmallBottom from './icons/arrow-sm-bottom.svg';
import ArrowSmallTop from './icons/arrow-sm-top.svg';
import BookMenu from './icons/book-menu.svg';
import Cross from './icons/cross.svg';
import DragLines from './icons/drag-lines.svg';
import KeyboardInput from './icons/keyboard-input.svg';
import Logout from './icons/log-out.svg';
import MicrophoneInput from './icons/microphone-input.svg';
import MicrophoneCrossed from './icons/microphone-crossed.svg';
import Recording from './icons/recording.svg';
import Settings from './icons/settings.svg';
import Toast from './icons/toast.svg';
import Check from './icons/check.svg';
import SelectLines from './icons/select-lines.svg';
import Notes from './icons/notes-icon.svg';
import BookStructure from './icons/book-structure.svg';
import ListCheckMark from './icons/list-check-mark.svg';
import Plus from './icons/plus.svg';
import ShortLogo from './icons/blooksy-logo.svg';
import Logo from './icons/logo.svg';
import Eye from './icons/eye-icon.svg';
import CrossedEye from './icons/crossed-eye-icon.svg';
import Share from './icons/add-contributor.svg';
import Export from './icons/export-docx-icon.svg';
import Edit from './icons/edit-icon-primary.svg';
import ShortEdit from './icons/short-edit-icon-primary.svg';
import Delete from './icons/delete-icon.svg';
import Aven from './icons/aven.svg';
import Copy from './icons/copy.svg';
import HeartOutline from './icons/heart-outline.svg';
import HeartFilled from './icons/heart-filled.svg';
import Info from './icons/info.svg';
import Email from './icons/email.svg';
import Help from './icons/help.svg';

export const IconMap = {
  ArrowLeft,
  ArrowSmallBottom,
  ArrowSmallTop,
  BookMenu,
  Cross,
  DragLines,
  KeyboardInput,
  Logout,
  MicrophoneInput,
  MicrophoneCrossed,
  Recording,
  Settings,
  Toast,
  Check,
  SelectLines,
  Notes,
  BookStructure,
  ListCheckMark,
  Plus,
  ShortLogo,
  Logo,
  Eye,
  CrossedEye,
  Share,
  Export,
  Edit,
  ShortEdit,
  Delete,
  Aven,
  Copy,
  HeartFilled,
  HeartOutline,
  Info,
  Email,
  Help,
} as const;
