import debounce from 'lodash/debounce';
import { appDataModel } from '@entities/app-data';
import { bookTypesDictionary, dictionary, MAX_TITLE_LENGTH } from '@shared/config';
import { Loader, Textarea } from '@shared/ui';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Folder } from '@shared/types';
import { DocPreview } from './DocPreview';
import { folderModel } from '@entities/folder';
import { createNotification, snackbarModel } from '@features/snackbar';

export const FolderPageBase: FC<{ folder: Folder }> = ({ folder }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const titleRef = React.createRef<HTMLTextAreaElement>();
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const bookStructure = useSelector(appDataModel.selectors.selectBookStructureWithError);
  const currentFolderDocs = bookStructure[folder.id].children;
  const handleSaveFolderTitle = (content: string, bookId: string, folderId: string) => {
    if (content.length > MAX_TITLE_LENGTH) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('primary', dictionary.primary.tooLongChapterName)
        )
      );
    }
    dispatch(
      folderModel.actions.saveFolderDetailsAction({
        content,
        bookId,
        id: folderId,
        fieldName: 'title',
      })
    );
  };

  const handleChangeFolderTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveFolderTitle(e.target.value, book.id, folder.id);
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveFolderTitle = useCallback(debounce(handleSaveFolderTitle, 500), []);

  return (
    <div className='px-4 pt-4 sm:p-0'>
      <div className='flex flex-col gap-2.5 mx-auto document-editor max-w-170'>
        <div className='bg-neutrals-0 rounded flex items-center justify-center p-4'>
          <Textarea
            className='font-semibold text-left'
            name='title'
            placeholder={`Untitled ${bookTypesDictionary[book.type].folderName}`}
            ref={titleRef}
            disabled={!isOwner}
            defaultValue={folder.title}
            key={folder.id}
            onChange={handleChangeFolderTitle}
          />
        </div>
        <div className='grid grid-cols-2 gap-4'>
          {currentFolderDocs.map(doc => (
            <DocPreview key={doc.id} doc={doc} book={book} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const FolderPage = () => {
  const folder = useSelector(appDataModel.selectors.selectedFolder);
  return folder ? <FolderPageBase folder={folder} /> : <Loader />;
};
