import { call, put } from 'redux-saga/effects';

import { createNotification, snackbarModel } from '@features/snackbar';
import { logger } from '@shared/lib';

import { actions, InviteAction } from '../model/reducer';

export function* inviteAction(action: () => void, bookId: string, actionType: InviteAction) {
  try {
    yield put(actions.addActiveInvite({ bookId, action: actionType }));
    yield call(action);
  } catch (e: unknown) {
    logger.error(e);
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', undefined, e)));
  } finally {
    yield put(actions.removeActiveInvite({ bookId, action: actionType }));
  }
}
