import { call, put } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { AUTH_ROUTE, history } from '@entities/history';
import { auth } from '@shared/config';
import { createNotification, snackbarModel } from '@features/snackbar';
import { confirmPasswordReset as confirmPasswordResetFb } from 'firebase/auth';

export function* confirmPasswordReset(code: string, password: string) {
  yield call(authModel.sagas.authOperationWithAlert, 'password reset', function* () {
    yield confirmPasswordResetFb(auth, code, password);
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('success', 'The password has been updated!'))
    );
    yield call(history.push, AUTH_ROUTE);
  });
}
