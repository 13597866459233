import { getDate } from '@shared/lib';
import { FirestoreUserData } from '@shared/types';
import { isKartra } from '@shared/lib/permissions';

export const getRenewDate = (user: FirestoreUserData) => {
  if (isKartra(user) || user.subscriptionInfo?.plan.interval === 'lifetime') {
    return getDate(genFirstDayNextMonth().getTime());
  }

  if (user.subscriptionInfo?.currentPeriodEnd) {
    return getDate(user.subscriptionInfo?.currentPeriodEnd);
  }
  throw new Error('No subscription info');
};
const genFirstDayNextMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setDate(1);
  return date;
};
