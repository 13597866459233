import { put } from 'redux-saga/effects';

import { actions } from '../reducer';

export function* handleChangeConnectivity() {
  const connected = navigator.onLine;

  function beforeUnloadListener() {
    if (!confirm('The internet connection is unstable. No data saved. Are you sure you want to leave the page?')) {
      return false;
    }
  }
  if (!connected) {
    window.onbeforeunload = beforeUnloadListener;
  } else {
    window.onbeforeunload = null;
  }
  yield put(actions.setHasInternetConnection(connected));
}
