// https://www.gstatic.com/firebasejs/8.6.2/firebase-auth.js (get from this file)

type ErrorMessages = { [key: string]: string };

//TODO Replace with clearer text, when it will be

export const errorMessages: ErrorMessages = {
  'auth/invalid-action-code':
    'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
  'auth/expired-action-code': 'The action code has expired.',
  'auth/email-already-in-use': 'The email address is already in use by another account.',
  'auth/invalid-email': 'The email address is badly formatted.',
  'auth/weak-password': 'The password must be 6 characters long or more.',
  'auth/wrong-password': 'The password is invalid.',
  'auth/user-not-found': 'No account associated with the email address.',
};
