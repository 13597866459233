import { call } from 'redux-saga/effects';

import { updateAppendices } from '@entities/app-data';
import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getAppendicesRef } from '@shared/api/firestore';

import { actions } from '../reducer';

export function* watchAppendicesWorker(setSelectedBookIdAction: ReturnType<typeof actions.setSelectedBookId>) {
  const { payload: bookId } = setSelectedBookIdAction;

  if (!bookId) {
    return;
  }

  const appendicesRef = getAppendicesRef(bookId);
  const appendicesChannel = queryColChannel(appendicesRef, false);

  yield call(onSnapshot, appendicesChannel, updateAppendices, 'Appendices');
}
