import { lgScreen, smScreen, xlRightTabsScreen } from '@shared/config';
import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(() => window.innerHeight);

  useEffect(() => {
    function updateSizes() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSizes);
    updateSizes();
    return () => window.removeEventListener('resize', updateSizes);
  }, []);

  const isDesktop = windowWidth > lgScreen;
  const isTabletOrMobile = windowWidth < lgScreen;
  const isDesktopOrTablet = windowWidth > smScreen;
  const isMobile = !isDesktopOrTablet;
  const isNeedToHideRightTabIcons = isDesktop && windowWidth <= xlRightTabsScreen;

  return {
    windowWidth,
    windowHeight,
    isDesktopOrTablet,
    isDesktop,
    isTabletOrMobile,
    isMobile,
    isNeedToHideRightTabIcons,
  };
};

export default useWindowWidth;
