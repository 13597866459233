import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemState } from 'react-accessible-accordion';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const ItemHeading = ({
  title,
  onClick,
  bold,
  className,
}: {
  title: string;
  onClick: () => unknown;
  bold?: boolean;
  className?: string;
}) => (
  <AccordionItemHeading onClick={onClick}>
    <AccordionItemButton className={`py-1.5 rounded outline-button ${className}`}>
      <AccordionItemState>
        {state => (
          <div className={`flex items-center pl-1 ${bold ? 'font-semibold' : ''} `}>
            <div className='flex items-center justify-center mr-3 w-3'>
              <Icon
                glyph={IconMap.ArrowSmallTop}
                className={`${
                  state.expanded ? '' : 'transform rotate-180'
                } object-contain text-primary-800 transition-all duration-500`}
              />
            </div>
            {title}
          </div>
        )}
      </AccordionItemState>
    </AccordionItemButton>
  </AccordionItemHeading>
);
