import { put } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { getUserFromFb } from '@entities/auth-user';
import { createNotification, snackbarModel } from '@features/snackbar';
import { updateProfile } from '@shared/api/cloud-functions';
import { providers, storage } from '@shared/config/firebase';
import { logger } from '@shared/lib';
import { FirebaseUser } from '@shared/types';
import { verifyBeforeUpdateEmail, reauthenticateWithCredential } from 'firebase/auth';

import { ref, deleteObject } from 'firebase/storage';

export function* updateUserProfile(
  fbUser: FirebaseUser,
  firstName = '',
  lastName = '',
  photoURL: string | undefined,
  email = '',
  password = ''
) {
  if (firstName && lastName) {
    yield updateProfile({ firstName, lastName });
  }

  if (photoURL) {
    yield updateProfile({ photoURL });
  }

  if (password && email && email !== fbUser.email) {
    try {
      yield reauthorizate(password);
      yield verifyBeforeUpdateEmail(fbUser, email);
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('success', `Verification email has been sent to ${email}.`)
        )
      );
    } catch (e: unknown) {
      logger.error(e);
    }
  }
}

export function* deleteProfileImage(url: string | null) {
  if (!url) {
    return;
  }

  try {
    const profileRef = ref(storage, url);

    yield deleteObject(profileRef);
  } catch (e: unknown) {
    logger.error(e);
  }
}

export function* reauthorizate(password: string) {
  yield put(authModel.actions.setAuthIsLoading(true));

  const user: FirebaseUser = yield getUserFromFb();
  const credentials = providers.EmailAuthProvider.credential(user.email || '', password || '');

  yield reauthenticateWithCredential(user, credentials);
  yield put(authModel.actions.setAuthIsLoading(false));
}
