import { ContextPlugin, Plugin } from '@ckeditor/ckeditor5-core';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { FC } from 'react';

import { isDevelopment } from '@shared/config';
import { CKEditorEvent, CKEditorInstance } from '@shared/types/ckeditor';

import editors from '../config';
import { getToolbarContainer } from '@widgets/editor-layout';

// I had to downgrade the ckeditor version (to 3.0.1) to avoid the bug ("Item with the given id was not registered").
// We follow the discussion on github and wait for the bug to be fixed.
// https://github.com/ckeditor/ckeditor5-react/issues/271

export type EditorProps = {
  documentId: string;
  onChange?: (event: CKEditorEvent, editor: CKEditorInstance) => void;
  onReady?: (editor: CKEditorInstance) => void;
  onError?: (error: Error) => void;
  config?: CKEditorConfig;
  data?: string;
};
export type CKEditorConfig = {
  plugins?: Array<typeof Plugin | typeof ContextPlugin | string>;
  collaboration?: { channelId: string };
  placeholder?: string;
  sidebar?: { container: HTMLDivElement | null };
};

export const EditorBase: FC<EditorProps> = ({ documentId, onChange, onReady, config, data, onError }) => {
  return (
    <CKEditor
      key={documentId}
      editor={editors.DecoupledEditor}
      onChange={onChange}
      data={data}
      config={config}
      onReady={(editor: CKEditorInstance) => {
        if (editor) {
          const toolbarWrapper = getToolbarContainer();
          if (toolbarWrapper) {
            toolbarWrapper.innerHTML = '';

            toolbarWrapper.append(editor.ui.view.toolbar.element);
          }
          if (isDevelopment) CKEditorInspector.attach(editor);
          onReady?.(editor);
        }
      }}
      onError={onError}
    />
  );
};
