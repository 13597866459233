import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { docModel } from '@entities/doc';
import Editor from '@entities/ck-editor/config';
import { dictionary, isDevelopment } from '@shared/config';
import { Book, CKEditorEvent, CKEditorInstance, Doc, Folder } from '@shared/types';
import { Button, SidebarRightContent } from '@shared/ui';

import { normalizeText } from '../lib';
import { folderModel } from '@entities/folder';
import { appDataModel } from '@entities/app-data';
import classNames from 'classnames';
import { Icon, IconProps } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { AvenSidebarTab } from '@features/Aven-AI/ui/AvenSidebarTab';
import useWindowWidth from '@shared/hooks/useWindowWidth';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';
import MixpanelService from '@features/mixpanel';
import { useTranscription } from '@features/transcription';

const icons: {
  [key: string]: IconProps;
} = {
  recording: {
    glyph: IconMap.Recording,
    width: 18,
    height: 18,
  },
  keyboard: {
    glyph: IconMap.KeyboardInput,
    width: 16,
    height: 16,
  },
  microphone: {
    glyph: IconMap.MicrophoneInput,
    width: 12,
    height: 17,
  },
  aven: {
    glyph: IconMap.Aven,
    width: 20,
    height: 20,
  },
};

type Props = {
  selectedBook: Book;
  selectedNotesItem: Doc | Folder;

  isOpen: boolean;
};

export const SidebarRight: FC<Props> = ({ selectedBook, selectedNotesItem, isOpen = true }) => {
  const dispatch: Dispatch = useDispatch();

  const sidebarButtonsRef = useHeightCssVariable<HTMLDivElement>('--right-sidebar-menu-height');
  const transcriptionRef = useHeightCssVariable<HTMLDivElement>('--transcription-menu-height');
  const { isTabletOrMobile, isDesktop, isDesktopOrTablet, isNeedToHideRightTabIcons } = useWindowWidth();

  const {
    stop: stopTranscription,
    start: startTranscription,
    reset: resetTranscription,
    permission,
    processing,
    listening,
    finalTranscript,
    interimTranscript,
    loading,
  } = useTranscription();

  const [transcriptionText, setTranscriptionText] = useState('');

  const tabs = useMemo(
    () => [
      ...(isDesktopOrTablet
        ? [
            {
              name: 'aven',
              label: 'Aven AI',
            },
          ]
        : []),
      {
        name: 'microphone',
        label: 'Transcribe',
      },
      {
        name: 'keyboard',
        label: 'Notes',
      },
    ],
    [isDesktopOrTablet]
  );
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const stop = useCallback(
    (needTrack = true) => {
      stopTranscription();
      if (needTrack) {
        MixpanelService.trackTranscriptionStarted();
      }
    },
    [stopTranscription]
  );

  const start = useCallback(() => {
    MixpanelService.startTranscriptionTimer();
    if (transcriptionText) {
      setTranscriptionText(prevTranscriptionText => prevTranscriptionText.replace(/\s*$/g, '') + '\n');
    }
    startTranscription();
  }, [transcriptionText, startTranscription]);

  const microphoneAction = listening ? stop : start;

  useEffect(() => {
    setTranscriptionText(selectedNotesItem.transcriptionText);
  }, [selectedNotesItem]);

  const handleSaveTranscription = useCallback(
    (content: string, bookId: string, id: string) => {
      const payload = { bookId, id, content, fieldName: 'transcriptionText' } as const;
      if (selectedSidebarItem.type === 'doc') {
        dispatch(docModel.actions.saveDocDetailsAction(payload));
      } else {
        dispatch(folderModel.actions.saveFolderDetailsAction(payload));
      }
    },
    [dispatch, selectedSidebarItem.type]
  );

  useEffect(() => {
    if (finalTranscript !== '') {
      const newTranscriptionText = normalizeText(transcriptionText, finalTranscript);
      setTranscriptionText(newTranscriptionText);
      handleSaveTranscription(newTranscriptionText, selectedBook.id, selectedNotesItem.id);

      resetTranscription();
    }
  }, [
    handleSaveTranscription,
    finalTranscript,
    resetTranscription,
    selectedBook.id,
    selectedNotesItem.id,
    transcriptionText,
  ]);

  useEffect(() => {
    stop(false);
    setSelectedTab(tabs[0]);
  }, [selectedSidebarItem, tabs, stop]);

  const handleSaveDocNotes = useCallback(
    (content: string, bookId: string, id: string) => {
      const payload = { content, bookId, id, fieldName: 'noteHTML' } as const;
      if (selectedSidebarItem.type === 'doc') {
        dispatch(docModel.actions.saveDocDetailsAction(payload));
      } else {
        dispatch(folderModel.actions.saveFolderDetailsAction(payload));
      }
    },
    [dispatch, selectedSidebarItem.type]
  );
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveTranscription = useCallback(debounce(handleSaveTranscription, 300), [
    selectedSidebarItem.type,
  ]);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveDocNotes = useCallback(debounce(handleSaveDocNotes, 300), [selectedSidebarItem.type]);

  const handleChangeTranscription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTranscriptionText(e.target.value);
    debouncedHandleSaveTranscription(e.target.value, selectedBook.id, selectedNotesItem.id);
  };

  return (
    <div
      className={`h-full col-start-10 col-end-13 md:border-l border-neutrals-200 relative md:fixed sm:absolute
                sm:right-0 sm:top-0 md:w-83 sm:w-screen sm:border-none md:bg-neutrals-0 md:top-18 md:right-0 z-10
                ${isOpen ? 'md:block z-30' : 'md:hidden'}`}
    >
      <div className='flex flex-col h-full pl-6 md:px-6'>
        <div ref={sidebarButtonsRef} className='flex items-center gap-4 border-b border-neutrals-200'>
          {isTabletOrMobile && <p className='font-semibold text-s mr-auto'>{selectedTab.label}</p>}
          {tabs.map(tab => {
            const iconProps = icons[tab.name === 'microphone' && listening ? 'recording' : tab.name];

            return (
              <button
                key={tab.name}
                title={tab.label}
                aria-label={tab.label}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                className={classNames(
                  'text-neutrals-500 -mb-px pt-3 pb-2 px-0.5 border-b-4 border-transparent flex gap-2 items-center',
                  {
                    'text-neutrals-1000 border-primary-800': selectedTab.name === tab.name,
                  }
                )}
              >
                {!isNeedToHideRightTabIcons && (
                  <Icon
                    {...iconProps}
                    className={classNames({ 'text-error-700': tab.name === 'microphone' && listening })}
                  />
                )}
                {isDesktop && <p className='font-semibold text-s whitespace-nowrap'>{tab.label}</p>}
              </button>
            );
          })}
        </div>

        {selectedTab.name === 'microphone' && (
          <SidebarRightContent>
            <div ref={transcriptionRef}>
              <div className='border-b mb-3 border-neutrals-200 pb-3'>
                <Button
                  variant='icon-text'
                  color='tertiary'
                  type='button'
                  icon={
                    <Icon
                      glyph={listening ? IconMap.MicrophoneCrossed : IconMap.MicrophoneInput}
                      className='fill-primary-800'
                    />
                  }
                  disabled={permission === 'denied' || loading}
                  onClick={microphoneAction}
                >
                  {loading ? 'Starting... Please, wait' : listening ? 'Stop recording' : 'Start recording'}
                </Button>
                {permission === 'denied' && (
                  <p className='px-2 py-1 mt-3 text-xs rounded text-error-700 bg-error-100'>
                    Unfortunately, your browser denied us access to the microphone.
                  </p>
                )}
              </div>
            </div>
            <textarea
              id='transcription-text'
              className='w-full h-full px-0 pt-0 pb-4 border-none resize-none text-s
                         focus:outline-none text-neutrals-1000 focus:ring-0'
              placeholder={
                permission === 'denied' ? 'Type your notes...' : 'Click on the microphone and begin speaking...'
              }
              onChange={handleChangeTranscription}
              value={processing ? normalizeText(transcriptionText, interimTranscript) : transcriptionText}
              disabled={listening}
            />
          </SidebarRightContent>
        )}

        {selectedTab.name === 'keyboard' && (
          <SidebarRightContent className='flex-grow document-editor balloon-editor'>
            <CKEditor
              key={selectedNotesItem.id}
              editor={Editor.BalloonEditor}
              data={selectedNotesItem.noteHTML || '<p></p>'}
              config={{
                placeholder: dictionary.placeholders.docNotes,
              }}
              onChange={(event: CKEditorEvent, editor: CKEditorInstance) => {
                const data = editor.getData();
                debouncedHandleSaveDocNotes(data, selectedBook.id, selectedNotesItem.id);
              }}
              onReady={(editor: CKEditorInstance) => {
                if (editor) {
                  if (isDevelopment) CKEditorInspector.attach(editor);
                }
              }}
            />
          </SidebarRightContent>
        )}
        {selectedTab.name === 'aven' && <AvenSidebarTab />}
      </div>
    </div>
  );
};
