import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemState } from 'react-accessible-accordion';

import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const FolderItemHeading = ({
  title,
  onClick,
  className,
}: {
  title: string;
  onClick: () => unknown;
  className?: string;
}) => (
  <div className={`py-1.5 rounded cursor-pointer outline-button ${className} flex items-center pl-1`}>
    <AccordionItemHeading>
      <AccordionItemButton className='flex items-center justify-center mr-3 w-3'>
        <AccordionItemState>
          {state => (
            <Icon
              glyph={IconMap.ArrowSmallTop}
              className={`${
                state.expanded ? '' : 'transform rotate-180'
              } object-contain text-primary-800 transition-all duration-500`}
            />
          )}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
    <h3 onClick={onClick} className='truncate'>
      {title}
    </h3>
  </div>
);
