import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateBookAction } from '@shared/types';

type State = {
  isBookCreating: boolean;
};
const initialState: State = {
  isBookCreating: false,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'bookCreation',
  initialState,
  reducers: {
    setIsBookCreating: (state, { payload: isBookCreating }: PayloadAction<boolean>) => ({
      ...state,
      isBookCreating,
    }),
  },
});
export const actions = {
  ...reducerActions,
  createBookClick: createAction<CreateBookAction>(`bookCreation/CreateBookClick`),
};
