import React, { FC } from 'react';

import { PRIVACY_LINK, TERMS_LINK } from '@shared/config';

import { Link } from '..';
import { IconMap } from '@shared/sprite';
import { Icon } from '../icon';

export const AuthLayout: FC = ({ children }) => {
  return (
    <>
      <div
        className={`flex flex-col justify-start min-h-full
                    px-10 py-10 sm:py-6 sm:px-0 md:px-6 h-fit-content bg-primary-50`}
      >
        <div className='grid grid-cols-12 sm:block md:grid-cols-8 gap-x-4'>
          <div className='col-start-5 col-end-9 md:col-start-3 md:col-end-7'>
            <div className='flex justify-center'>
              <Icon glyph={IconMap.Logo} className='mr-2.5' />
            </div>
            {children}
            <div className='text-center'>
              <span className='mr-6'>
                <Link target='_blank' rel='noreferrer' href={TERMS_LINK} size='medium'>
                  Terms of Service
                </Link>
              </span>
              <Link target='_blank' rel='noreferrer' href={PRIVACY_LINK} size='medium'>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
