import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { history, redirectActions, SETTINGS_ROUTE } from '@entities/history';
import { Button, Tabs } from '@shared/ui';

import Profile from './tabs/Profile';
import SuperUser from './tabs/SuperUser';
import UserManagement from './tabs/UserManagment';

import { Subscription } from '@features/subscriptions';
import { Route, Switch, useLocation } from 'react-router-dom';
import { isAdmin } from '@shared/lib/permissions';

type SettingsTab = {
  id: number;
  name: string;
  title: string;
  component: JSX.Element;
};
const defaultTabs: SettingsTab[] = [
  {
    id: 1,
    name: 'profile',
    title: 'Profile',
    component: <Profile />,
  },
  {
    id: 2,
    name: 'subscription',
    title: 'Subscription',
    component: <Subscription />,
  },
];

// TODO: Transition between tabs is broken. Need wrapper like <MainLayout/>

export const Settings = () => {
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState<SettingsTab[]>(defaultTabs);

  const location = useLocation();
  const currentLocationTabName = location.pathname.split('/')[2];
  const initialTab = tabs.find(tab => tab.name === currentLocationTabName) || tabs[0];

  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);

  useEffect(() => {
    const tabsToAdd = [...defaultTabs];

    if (isAdmin(firestoreUser)) {
      tabsToAdd.push({ id: 3, name: 'superuser', title: 'Superuser', component: <SuperUser /> });
      tabsToAdd.push({ id: 4, name: 'user-management', title: 'User Management', component: <UserManagement /> });
    }

    setTabs([...tabsToAdd]);
  }, [firestoreUser, tabs.length]);

  const handleBackToBooksClick = () => {
    dispatch(redirectActions.toBooksClick());
  };

  return (
    <>
      <Helmet title='Settings' />
      <div>
        <div className='px-10 pb-10 md:px-6 sm:px-5 bg-neutrals-0'>
          <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
            <div className='col-start-3 col-end-11 md:col-start-1 md:col-end-9 sm:col-start-1 sm:col-end-5 pt-7'>
              <div className='flex items-center justify-between'>
                <h2 className='font-semibold text-l text-neutrals-1000'>Settings</h2>
                <Button onClick={() => handleBackToBooksClick()} type='reset' color='secondary' variant='text'>
                  Done
                </Button>
              </div>
              <Tabs
                key={location.pathname}
                initialTab={initialTab}
                tabs={tabs}
                onSelectTab={tab => history.push(`${SETTINGS_ROUTE}/${tab.name}`)}
              >
                <Switch>
                  {tabs.map(tab => (
                    <Route path={`${SETTINGS_ROUTE}/${tab.name}`} key={tab.id} render={() => tab.component} />
                  ))}
                </Switch>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
