import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '@entities/auth-operation';
import { authUserModel } from '@entities/auth-user';
import { redirectActions } from '@entities/history';
import { userInitials } from '@shared/lib';
import { Button, Dropdown } from '@shared/ui';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';
import { SupportForm } from '@features/support';

export const UserLogo: FC<{ photoURL: string; name: string }> = ({ photoURL, name }) => (
  <div
    className={`flex items-center justify-center w-10 h-10 overflow-hidden
                  text-base font-semibold rounded-full bg-primary-100 text-primary-800`}
  >
    {photoURL ? <img src={photoURL} className='object-cover w-full h-full' alt='profile photo' /> : <div>{name}</div>}
  </div>
);

export const HeaderGeneral = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const ref = useHeightCssVariable('--header-height');
  const [isSupportOpened, setSupportOpened] = useState(false);
  return (
    <>
      <header ref={ref} className='px-10 md:px-6 sm:px-5 bg-neutrals-0 fixed w-screen z-10'>
        <SupportForm isOpen={isSupportOpened} onClose={() => setSupportOpened(false)} />
        <div
          className={`grid grid-cols-12 py-3 pr-3 border-b md:pr-1
      border-neutrals-200 md:grid-cols-8 sm:grid-cols-4 gap-x-4`}
        >
          <div className='flex items-center justify-between col-start-1 col-end-13 md:col-end-9 sm:col-end-5'>
            <button
              className='flex items-center justify-start'
              onClick={() => {
                dispatch(redirectActions.toBooksClick());
              }}
            >
              <Icon glyph={IconMap.Logo} className='mr-10 sm:hidden' />
              <Icon glyph={IconMap.ShortLogo} className='hidden mr-10 sm:mr-6 sm:block' />
            </button>
            <div className='sm:hidden ml-auto'>
              <Button type='button' variant='text' color='secondary' onClick={() => setSupportOpened(true)}>
                Contact Support
              </Button>
            </div>
            <Dropdown
              listStyles='w-50'
              itemStyles='flex items-center'
              triggerComponent={
                <Trigger className='inline-flex items-center justify-center pl-4 focus:outline-none'>
                  <div className='sm:mr-6 mr-4'>
                    <UserLogo name={userInitials(user)} photoURL={user.photoURL} />
                  </div>
                  <p className='mr-6 font-semibold sm:hidden text-s text-neutrals-1000'>
                    {`${user.firstName} ${user.lastName}`}
                  </p>
                  <Icon glyph={IconMap.ArrowSmallBottom} className='fill-primary-800' />
                </Trigger>
              }
              items={[
                {
                  label: (
                    <>
                      <div className='w-5 h-5 flex items-center justify-center mr-4'>
                        <Icon glyph={IconMap.Settings} width={17} className='text-primary-800' />
                      </div>
                      Settings
                    </>
                  ),
                  onClick: () => dispatch(redirectActions.toSettingsClick()),
                },
                {
                  label: (
                    <>
                      <div className='w-5 h-5 flex items-center justify-center mr-4'>
                        <Icon glyph={IconMap.Logout} width={17} className='text-primary-800' />
                      </div>
                      Log out
                    </>
                  ),
                  onClick: () => dispatch(authModel.actions.signOutClick()),
                },
              ]}
            />
          </div>
        </div>
      </header>
      <div className='w-full h-16' />
    </>
  );
};
