import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Doc } from '@shared/types';
import { Button } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

type Props = {
  id: string;
  idx: number;
  isActive: boolean;
  title: string;
  onDelete: () => void;
  items: Doc[];
  isSecondLevel?: boolean;
};

export const EditModeOneLevelListItem: FC<Props> = ({ id, idx, isActive, title, onDelete, items, isSecondLevel }) => {
  return (
    <Draggable draggableId={id} index={idx}>
      {provided => (
        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div
            className={`${isActive && 'font-semibold bg-neutrals-100'} ${isSecondLevel ? 'pr-7 pl-12' : 'px-6'}
            py-1.5  w-full text-left flex justify-between items-center rounded relative`}
          >
            <Icon
              glyph={IconMap.DragLines}
              className={`w-3 absolute top-1/2 -translate-y-1/2 transform left-1 fill-neutrals-400`}
            />

            <p className='truncate break-words'>
              <span className='inline-flex items-center align-middle w-3'>
                <span className={`w-1 h-1 rounded-sm ${isActive ? 'bg-neutrals-1000' : 'bg-neutrals-300'}`}></span>
              </span>
              {title}
            </p>
            <div className='absolute right-1 w-3'>
              {items.length > 1 && (
                <Button
                  fullWidth
                  variant='icon'
                  color='tertiary'
                  icon={<Icon glyph={IconMap.Delete} />}
                  onClick={onDelete}
                />
              )}
            </div>
          </div>
        </li>
      )}
    </Draggable>
  );
};
