import { appDataModel } from '@entities/app-data';
import { redirectActions } from '@entities/history';
import { Button } from '@shared/ui';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stripeModel } from '..';
import { plansDictionary } from '../config';
import { actions } from '../model/reducer';

const LineWrapper: FC = ({ children }) => (
  <div
    className='flex items-center justify-between gap-6.5 mt-6 bg-warning-100
          md:flex-col md:items-start md:gap-4 py-2 px-4 rounded'
  >
    {children}
  </div>
);

const UnActiveSubscription: FC = () => {
  const dispatch = useDispatch();
  return (
    <LineWrapper>
      <p className='text-s text-warning-700'>Activate your subscription to continue writing your books.</p>

      <Button onClick={() => dispatch(redirectActions.toSubscriptionsClick())} variant='text' color='primary'>
        Activate Subscription
      </Button>
    </LineWrapper>
  );
};

export const BooksStripeInfoLine: FC<{ booksLength: number }> = ({ booksLength }) => {
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const { subscriptionInfo, role } = firestoreUser;

  const isLoading = useSelector(stripeModel.selectors.selectIsLoading);
  const dispatch = useDispatch();

  if (firestoreUser.category) {
    return null;
  }

  if (!subscriptionInfo || !role) {
    return booksLength ? <UnActiveSubscription /> : null;
  }

  switch (subscriptionInfo.status) {
    case 'active':
    case 'trialing': {
      return null;
    }
    case 'incomplete':
    case 'past_due': {
      return (
        <LineWrapper>
          <p className='text-s text-warning-700'>
            We were unable to process payment for the <strong>{plansDictionary.planNames[role]}</strong> subscription.
            Update the payment method, or contact the bank to determine why payment is failing.
          </p>

          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={() => dispatch(actions.createPortalLink())}
            variant='text'
            color='primary'
          >
            Update Payment Method
          </Button>
        </LineWrapper>
      );
    }
    default: {
      return booksLength ? <UnActiveSubscription /> : null;
    }
  }
};
