import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { profileSettingModel } from '@features/profile-settings';
import { Input, Button, AuthLayout, AuthFormWrapper } from '@shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ResetPasswordRequestData, resetPasswordValidationSchema } from '../config';

export const CreatePassword = () => {
  const dispatch = useDispatch();

  const [isPasswordResetting, setIsPasswordResetting] = useState(false);

  const { control, handleSubmit } = useForm<ResetPasswordRequestData>({
    resolver: yupResolver(resetPasswordValidationSchema),
    defaultValues: { password: '' },
  });

  const resetPassword = ({ password }: ResetPasswordRequestData) => {
    setIsPasswordResetting(true);
    dispatch(
      profileSettingModel.actions.completeResetPasswordClick({ password, cb: () => setIsPasswordResetting(false) })
    );
  };

  return (
    <AuthLayout>
      <AuthFormWrapper title='Create Password'>
        <div className='mb-6'>
          <span className='text-s'>It’s a good idea to use strong password that you don’t use elsewhere.</span>
        </div>
        <form onSubmit={handleSubmit(resetPassword)}>
          <div>
            <Input control={control} name='password' type='password' label='Password' />
          </div>

          <div className='mt-6'>
            <Button
              type='submit'
              color='primary'
              variant='text'
              fullWidth
              disabled={isPasswordResetting}
              loading={isPasswordResetting}
            >
              Create Password
            </Button>
          </div>
        </form>
      </AuthFormWrapper>
    </AuthLayout>
  );
};
