import { authModel } from '@entities/auth-operation';
import {
  AUTH_ROUTE,
  REGISTER_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  NOT_FOUND_ROUTE,
  SETTINGS_ROUTE,
  WELCOME_ROUTE,
  BOOKS_ROUTE,
  NEW_BOOK_ROUTE,
  BOOK_ROUTE,
  BOOK_ID_ROUTE,
  COVER_BOOK_ROUTE,
  TITLE_BOOK_ROUTE,
  COPYRIGHT_BOOK_ROUTE,
  DEDICATION_BOOK_ROUTE,
  TABLE_OF_CONTENTS_BOOK_ROUTE,
  ACKNOWLEDGEMENTS_BOOK_ROUTE,
  FOREWORD_BOOK_ROUTE,
  PREFACE_BOOK_ROUTE,
  INTRODUCTION_BOOK_ROUTE,
  ABOUT_BOOK_ROUTE,
  DOC_ROUTE,
  DOC_ID_ROUTE,
  APPENDIX_ID_ROUTE,
  INDEX_ROUTE,
  LOADING_ROUTE,
  redirect,
  SUBSCRIPTION_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  SUPERUSER_ROUTE,
  CREATE_FIRST_BOOK_ROUTE,
  FOLDER_ROUTE,
  FOLDER_ID_ROUTE,
  CLOSE_WINDOW_ROUTE,
  EDIT_BOOK_ROUTE,
  EDIT_BOOK_ROUTE_ID,
  MANAGE_USERS_ROUTE,
  ABSTRACT_BOOK_ROUTE,
  LIST_OF_TABLES_BOOK_ROUTE,
  LIST_OF_FIGURES_BOOK_ROUTE,
  REFERENCES_BOOK_ROUTE,
  APPROVED_BY_BOOK_ROUTE,
} from '@entities/history';

import { booksPageWorker } from '@pages/books';
import { docPageWorker } from '@pages/doc-page';
import { createBookPageWorker } from '@pages/create-book';
import { manageUsersWorker, settingsPageWorker, superUserWorker } from '@pages/settings';
import { withEditor } from '@processes/editor-pages-worker';
import { withMain } from '@processes/main-pages-worker';
import { closeWindow, nil } from '@shared/lib';

import { clearEditModeData } from '..';
import { toFirstDoc, toFirstFolder } from '../lib';

import { RouteHandler } from './types';
import { folderPageWorker } from '@pages/folder-page/model';
import { editBookModel } from '@pages/edit-book/model';
import { stripeOnboardingWorker } from '@pages/stripe-onboarding-page';
import { appendixPageWorker } from '@pages/appendix-page';

export const privateRouteHandlers: RouteHandler[] = [
  { route: AUTH_ROUTE, saga: authModel.sagas.authAction(true) },
  { route: INDEX_ROUTE, saga: redirect.toBooks },
  { route: REGISTER_ROUTE, saga: redirect.toBooks },
  { route: LOGIN_ROUTE, saga: redirect.toBooks },
  { route: RESET_PASSWORD_ROUTE, saga: redirect.toBooks },
  { route: COMPLETE_PASSWORD_RESET_ROUTE, saga: redirect.toBooks },
  { route: NOT_FOUND_ROUTE, saga: clearEditModeData },

  { route: SETTINGS_ROUTE, saga: redirect.toSettings },
  { route: SUBSCRIPTION_ROUTE, saga: withMain(settingsPageWorker) },
  { route: PROFILE_SETTINGS_ROUTE, saga: withMain(settingsPageWorker) },
  { route: SUPERUSER_ROUTE, saga: withMain(superUserWorker) },
  { route: MANAGE_USERS_ROUTE, saga: withMain(manageUsersWorker) },
  { route: WELCOME_ROUTE, saga: withMain(stripeOnboardingWorker) },
  { route: CREATE_FIRST_BOOK_ROUTE, saga: withMain(createBookPageWorker) },
  { route: BOOKS_ROUTE, saga: withMain(booksPageWorker) },
  { route: NEW_BOOK_ROUTE, saga: withMain(createBookPageWorker) },
  { route: EDIT_BOOK_ROUTE_ID, saga: withEditor(undefined, editBookModel.saga) },
  { route: EDIT_BOOK_ROUTE, saga: redirect.toBooks },
  { route: BOOK_ROUTE, saga: redirect.toBooks },

  { route: BOOK_ID_ROUTE, saga: toFirstDoc },
  { route: COVER_BOOK_ROUTE, saga: withEditor('coverPage') },
  { route: TITLE_BOOK_ROUTE, saga: withEditor('titlePage') },
  { route: COPYRIGHT_BOOK_ROUTE, saga: withEditor('copyrightPage') },
  { route: DEDICATION_BOOK_ROUTE, saga: withEditor('dedication') },
  { route: TABLE_OF_CONTENTS_BOOK_ROUTE, saga: withEditor('tableOfContents') },
  { route: ACKNOWLEDGEMENTS_BOOK_ROUTE, saga: withEditor('acknowledgements') },
  { route: FOREWORD_BOOK_ROUTE, saga: withEditor('foreword') },
  { route: PREFACE_BOOK_ROUTE, saga: withEditor('preface') },
  { route: INTRODUCTION_BOOK_ROUTE, saga: withEditor('introduction') },
  { route: APPROVED_BY_BOOK_ROUTE, saga: withEditor('approvedBy') },
  { route: ABSTRACT_BOOK_ROUTE, saga: withEditor('abstract') },
  { route: LIST_OF_TABLES_BOOK_ROUTE, saga: withEditor('listOfTables') },
  { route: LIST_OF_FIGURES_BOOK_ROUTE, saga: withEditor('listOfFigures') },
  { route: REFERENCES_BOOK_ROUTE, saga: withEditor('references') },
  { route: INTRODUCTION_BOOK_ROUTE, saga: withEditor('introduction') },
  { route: ABOUT_BOOK_ROUTE, saga: withEditor('about') },
  { route: DOC_ROUTE, saga: toFirstDoc },
  { route: DOC_ID_ROUTE, saga: withEditor(undefined, docPageWorker) },
  { route: APPENDIX_ID_ROUTE, saga: withEditor(undefined, appendixPageWorker) },
  { route: FOLDER_ROUTE, saga: toFirstFolder },
  { route: FOLDER_ID_ROUTE, saga: withEditor(undefined, folderPageWorker) },
  { route: LOADING_ROUTE, saga: nil },
  { route: CLOSE_WINDOW_ROUTE, saga: closeWindow },
];
