import { logger } from '@shared/lib';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getDeepgram, getParams, LiveStreamDeepgramFeatures } from '@features/transcription/config';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { usePermission } from '@shared/hooks/usePermission';
import { connectivityModel } from '@entities/connectivity-indicator';
import { getDeepgramToken } from '@shared/api/cloud-functions';

const getAudio = async () => {
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });
  return new MediaRecorder(mediaStream, {
    mimeType: MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : undefined,
  });
};

const getCallbackFn = (s: WebSocket) => (e: BlobEvent) => {
  if (e.data.size > 0 && s && s.readyState === 1) {
    s.send(e.data);
  }
};

export const useTranscription = () => {
  const { permission, setPermission } = usePermission();
  const { userId } = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const hasInternetConnection = useSelector(connectivityModel.selectors.selectHasInternetConnection);

  const timer = useRef<number>();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listening, setListening] = useState(false);
  const [error, setError] = useState('');

  const [finalTranscript, setFinalTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');

  const start = useCallback(() => {
    setError('');
    setListening(true);
    setLoading(true);
  }, []);

  const reset = useCallback(() => {
    setInterimTranscript('');
    setFinalTranscript('');
  }, []);

  const stop = useCallback(() => {
    setListening(false);
  }, []);

  useEffect(() => {
    if (!listening) return;
    if (loading) return;
    if (processing) return;

    timer.current = window.setTimeout(() => stop(), 12000); // Auto stop after 12 sec
    return () => window.clearTimeout(timer.current);
  }, [processing, loading, listening, stop]);

  useEffect(() => {
    if (permission === 'denied' || !hasInternetConnection) stop();
  }, [permission, hasInternetConnection, stop]);

  useEffect(() => {
    if (!hasInternetConnection) return;
    if (!listening) return;

    let canceled = false;
    let socket: WebSocket | undefined = undefined;
    let audio: MediaRecorder | undefined = undefined;
    let callbackFn: ((e: BlobEvent) => void) | undefined = undefined;

    getDeepgramToken()
      .then(({ data: { token } }) => {
        return getAudio().then(recorder => {
          if (canceled) return;

          socket = getDeepgram({
            token,
            query: getParams<LiveStreamDeepgramFeatures>({
              tag: userId,
              punctuate: true,
              interim_results: true,
            }),
          });

          audio = recorder;

          callbackFn = getCallbackFn(socket);

          socket.onopen = () => {
            setLoading(false);

            if (callbackFn) {
              audio?.addEventListener('dataavailable', callbackFn);
              audio?.start(250);
            }
          };
          socket.onmessage = message => {
            const received = JSON.parse(message.data);
            const isFinal = Boolean(received?.is_final);
            const transcript = received?.channel?.alternatives[0]?.transcript || '';

            if (isFinal) {
              setProcessing(false);
              setFinalTranscript(transcript);
            } else {
              setProcessing(transcript.length !== 0);
              setInterimTranscript(transcript);
            }
          };

          socket.onerror = e => {
            setProcessing(false);
            setListening(false);
            setLoading(false);
            setError('Transcription error');

            logger.error(e);
          };
        });
      })
      .catch((e: unknown) => {
        if (canceled) return;

        setProcessing(false);
        setListening(false);
        setLoading(false);
        setError((e as Error)?.message || 'Unknown audio error');

        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          setPermission('denied');
        }

        logger.error(e);
      });

    return () => {
      canceled = true;
      reset();
      setProcessing(false);
      setListening(false);
      setLoading(false);

      if (callbackFn) {
        audio?.removeEventListener('dataavailable', callbackFn);
      }

      if (audio?.state === 'recording') {
        audio?.stop();
        audio?.stream.getTracks().forEach(track => {
          track.stop();
        });
      }

      if (socket) {
        socket.onopen = null;
        socket.onmessage = null;
        socket.onerror = null;
      }

      if (socket?.readyState === 1) {
        socket.send(JSON.stringify({ type: 'CloseStream' }));
      }
    };
  }, [hasInternetConnection, listening, userId, reset, setPermission]);

  return { start, stop, reset, listening, loading, processing, error, permission, interimTranscript, finalTranscript };
};
