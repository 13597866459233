import { call, fork, select, takeEvery } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { sendEmailVerification } from '@features/auth/email-verification';
import { authErrorHandler } from '@features/auth/lib';
import { signInAsSuperuser } from '@features/auth/sign-in';
import { profileSettingModel } from '@features/profile-settings';
import { FirestoreUserData } from '@shared/types';
import { appDataModel, setFirestoreUsersData } from '@entities/app-data';
import { isAdmin } from '@shared/lib/permissions';
import { redirect } from '@entities/history';
import { adminModel } from '@features/admin/model';

export function* settingsPageWorker() {
  yield takeEvery(profileSettingModel.actions.updatePasswordClick, profileSettingModel.sagas.updatePassword);
  yield takeEvery(profileSettingModel.actions.updateUserPhotoClick, profileSettingModel.sagas.updateUserPhoto);
  yield takeEvery(profileSettingModel.actions.updateUserEmailClick, profileSettingModel.sagas.updateUserEmail);
  yield takeEvery(profileSettingModel.actions.deletePhotoClick, profileSettingModel.sagas.deletePhoto);
  yield takeEvery(profileSettingModel.actions.sendEmailVerificationClick, sendEmailVerification);
  yield takeEvery(profileSettingModel.actions.setAuthErrorAction, authErrorHandler);
}

export function* superUserWorker() {
  const firestoreUser: FirestoreUserData = yield select(appDataModel.selectors.selectFirestoreUserDataWithError);
  if (!isAdmin(firestoreUser)) yield call(redirect.toSettings);

  yield takeEvery(authModel.actions.signInAsSuperuser, signInAsSuperuser);
}

export function* manageUsersWorker() {
  const firestoreUser: FirestoreUserData = yield select(appDataModel.selectors.selectFirestoreUserDataWithError);
  if (!isAdmin(firestoreUser)) yield call(redirect.toSettings);

  yield takeEvery(adminModel.actions.updateUsersCategory, adminModel.sagas.updateUsersCategory);
  yield takeEvery(adminModel.actions.createUsers, adminModel.sagas.createUsers);

  yield call(setFirestoreUsersData);
  yield fork(appDataModel.sagas.watchFirestoreUsers);
}
