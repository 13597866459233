import React, { FC } from 'react';
import { Accordion } from 'react-accessible-accordion';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { appDataModel } from '@entities/app-data';
import { bookModel } from '@entities/book';

import { backMatterItems, frontMatterItems } from '../config';
import { FrontBackMatterAccordionItem } from './FrontBackMatterAccordionItem';
import { AccordionMenuItem } from './AccordionMenuItem';

import { SidebarHeader } from './SidebarHeader';

import { BookOutline } from './BookOutline';
import { backMatterApaItems, frontMatterApaItems } from '../config/apa-paper';

// TODO: Move edit mode settings to redux
type Props = {
  isEditMode: boolean;
  onEditModeChange: (value: boolean) => unknown;
  isOpen: boolean;
  onClose: () => void;
};

export const SidebarLeft: FC<Props> = ({ isEditMode, onEditModeChange, isOpen, onClose }) => {
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const dispatch: Dispatch = useDispatch();

  const isApaPaper = book.type === 'apa';

  return (
    <div
      className={`relative md:fixed sm:absolute sm:right-0 sm:top-0 md:w-83 sm:w-screen sm:border-none
                md:border-r border-neutrals-200 md:bg-neutrals-0 md:top-18 md:left-0 z-30 h-full
                col-start-1 col-end-4 ${isOpen ? 'md:block z-30' : 'md:hidden'}`}
    >
      <div className='pr-6 sm:px-5 md:px-6'>
        <SidebarHeader isEditMode={isEditMode} onEditModeChange={onEditModeChange} />

        <div className='py-2 text-s' id='book-structure'>
          {book.type === 'dissertation' ? (
            <BookOutline isEditMode={isEditMode} onClose={onClose} />
          ) : (
            <Accordion
              key={book.id}
              preExpanded={[
                book.settings.folded.frontMatter ? '' : 'FrontMatter',
                book.settings.folded.book ? '' : isApaPaper ? 'Content' : 'Book',
                book.settings.folded.backMatter ? '' : 'BackMatter',
                book.settings.folded.appendices ? '' : 'Appendices',
              ]}
              allowMultipleExpanded
              allowZeroExpanded
            >
              <FrontBackMatterAccordionItem
                title='Front Matter'
                items={book.type === 'apa' ? frontMatterApaItems : frontMatterItems}
                onClick={() =>
                  dispatch(
                    bookModel.actions.changeFolderClick({
                      bookId: book.id,
                      isFolded: !book.settings.folded.frontMatter,
                      type: 'frontMatter',
                    })
                  )
                }
                onItemClick={onClose}
                isEditMode={isEditMode}
              />
              <AccordionMenuItem
                title={isApaPaper ? 'Content' : 'Book'}
                key='book'
                onClick={() =>
                  dispatch(
                    bookModel.actions.changeFolderClick({
                      bookId: book.id,
                      isFolded: !book.settings.folded.book,
                      type: 'book',
                    })
                  )
                }
              >
                <BookOutline isEditMode={isEditMode} onClose={onClose} />
              </AccordionMenuItem>
              <FrontBackMatterAccordionItem
                title='Back Matter'
                items={book.type === 'apa' ? backMatterApaItems : backMatterItems}
                onClick={() =>
                  dispatch(
                    bookModel.actions.changeFolderClick({
                      bookId: book.id,
                      isFolded: !book.settings.folded.backMatter,
                      type: 'backMatter',
                    })
                  )
                }
                onItemClick={onClose}
                isEditMode={isEditMode}
              />
              {isApaPaper && (
                <AccordionMenuItem
                  title='Appendices'
                  key='appendices'
                  onClick={() =>
                    dispatch(
                      bookModel.actions.changeFolderClick({
                        bookId: book.id,
                        isFolded: !book.settings.folded.appendices,
                        type: 'appendices',
                      })
                    )
                  }
                >
                  <BookOutline isEditMode={isEditMode} onClose={onClose} isAppendices={true} />
                </AccordionMenuItem>
              )}
            </Accordion>
          )}
        </div>
      </div>
    </div>
  );
};
