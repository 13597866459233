import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

export type TabContent = {
  id: number;
  name: string;
  title: string;
  component?: JSX.Element;
};

export const Tabs: FC<{
  tabs: TabContent[];
  onSelectTab?: (tab: TabContent) => void;
  initialTab?: TabContent;
}> = ({ tabs, onSelectTab, children, initialTab }) => {
  const [selectedTab, setSelectedTab] = useState<TabContent>(initialTab ? initialTab : tabs[0]);

  useEffect(() => {
    if (initialTab) setSelectedTab(initialTab);
  }, [initialTab]);

  const handleTabSelect = (tab: TabContent) => {
    setSelectedTab(tab);
    onSelectTab && onSelectTab(tab);
  };

  const style = `font-semibold text-s border-b-4 border-solid pt-3 pb-2 mr-6
    inline-block transition-colors duration-300 ease-in-out cursor-pointer relative hover:text-neutrals-1000`;

  return (
    <>
      <div className='mb-6 border-b border-neutrals-200'>
        {tabs.map(tab => (
          <a
            key={tab.id}
            onClick={() => {
              handleTabSelect(tab);
            }}
            className={classNames(style, {
              'text-neutrals-1000 border-primary-800': selectedTab.name === tab.name,
              'text-neutrals-500 border-transparent': selectedTab.name !== tab.name,
            })}
          >
            {tab.title}
          </a>
        ))}
      </div>
      {children}
    </>
  );
};
