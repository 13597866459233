import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Notification } from '@shared/ui';

import { actions } from './model/reducer';
import { selectNotifications } from './model/selectors';

export const SnackBar = () => {
  const messages = useSelector(selectNotifications);
  const dispatch = useDispatch();

  const onDeleteMessage = (id: number) => {
    dispatch(actions.removeNotificationAction(id));
  };
  return (
    <TransitionGroup style={{ zIndex: 9999 }} className={`fixed right-0 pr-4 bottom-0`}>
      {messages.map(message => {
        const ref = React.createRef<HTMLDivElement>();
        return (
          <CSSTransition
            key={message.id}
            timeout={500}
            classNames='show'
            nodeRef={ref}
            appear={true}
            onEnter={() => {
              setTimeout(() => {
                onDeleteMessage(message.id);
              }, 4000);
            }}
          >
            <div ref={ref}>
              <Notification onClick={() => onDeleteMessage(message.id)} type={message.type}>
                {message.message}
              </Notification>
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
