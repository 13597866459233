import { call, put, select } from 'redux-saga/effects';

import { changeBooksLengthChan } from '@entities/books-length-channel';
import * as FirestoreService from '@shared/api/firestore';
import { ColSnap, UserBook } from '@shared/types';

import { actions } from '../model/reducer';
import { selectBooks } from '../model/selectors';

export function* updateBooks(booksSnap: ColSnap) {
  const oldBooks: UserBook[] = yield select(selectBooks);
  const books = booksSnap.docs.map(snap => snap.data()) as UserBook[];

  yield put(actions.setBooks(books));

  if (oldBooks.length !== books.length) {
    yield put(changeBooksLengthChan, {
      operation: oldBooks.length < books.length ? 'creating' : 'deleting',
      ids: books.map(i => i.id),
    });
  }
}
export function* setBooksData() {
  const books: UserBook[] = yield call(FirestoreService.getUserBooks);

  yield put(actions.setBooks(books));
}
