import React from 'react';

export const EditorPageContentWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div id='doc-editor' className='flex flex-col flex-grow h-full px-4 pt-4 sm:p-0 bg-neutrals-0 '>
        <div
          className='flex flex-col gap-2.5 pt-10 document-editor min-h-full
          max-w-170 bg-neutrals-0 decoupled-editor mx-auto w-full'
        >
          {children}
        </div>
      </div>
    </>
  );
};
