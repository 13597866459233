import React, { FC } from 'react';

export type ToCListItemProps = {
  title?: string;
  showDots?: boolean;
  indentLeft?: number;
};

export const TableOfContentsListElement: FC<ToCListItemProps> = ({ title, showDots = true, indentLeft = 0 }) => {
  return (
    <li className='text-font flex w-full space-x-1' style={{ paddingLeft: indentLeft }}>
      <p className='text-base'>{title}</p>
      {showDots && (
        <>
          <div className='flex-auto border-dotted border-b-2 h-6' />
          <div className='w-2 border-b' />
        </>
      )}
    </li>
  );
};
