import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import classNames from 'classnames';
import React, { FC } from 'react';
import { BookStyleName, bookStyles } from '../config';

type Props = {
  onChange: (styleName: BookStyleName) => void;
  activeStyle: BookStyleName;
};

export const BookStyleSelector: FC<Props> = ({ onChange, activeStyle }) => {
  return (
    <div className='mb-6 col-start-1 col-end-5'>
      <p className='text-neutrals-500 text-s mb-1'>Book style</p>
      <ul
        className='max-h-120 overflow-auto p-4 rounded border border-neutrals-200
                   grid grid-cols-4 gap-4 sm:flex '
      >
        {Object.entries(bookStyles).map(([styleName, style]) => {
          const isActive = activeStyle === styleName;
          return (
            <li key={styleName}>
              <button
                className={classNames(
                  'p-4 border rounded h-47 overflow-hidden relative disabled:cursor-auto flex flex-col text-left',
                  'sm:min-w-32 sm:h-32 sm:py-3',
                  isActive ? 'border-primary-800' : 'border-neutrals-200',
                  { 'cursor-auto': !onChange }
                )}
                type='button'
                onClick={() => onChange(styleName as BookStyleName)}
              >
                {isActive && (
                  <div className='absolute top-2.5 right-2.5'>
                    <Icon glyph={IconMap.Check} />
                  </div>
                )}
                <h3 className={`font-bold ${style.titleFontClass} mb-2 text-lg sm:text-sm`}>Intro</h3>
                <p className={`${style.textFontClass} text-sm sm:text-xxs`}>
                  One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into
                  a horrible vermin.
                </p>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
