import { put } from 'redux-saga/effects';

import { getUserFromFb } from '@entities/auth-user';
import { createNotification, snackbarModel } from '@features/snackbar';
import { providers } from '@shared/config/firebase';
import { getErrorMessage, logger } from '@shared/lib';
import { FirebaseUser } from '@shared/types';
import { reauthenticateWithCredential, updatePassword as updatePasswordFb } from 'firebase/auth';

import { actions } from '../reducer';

export function* updatePassword(updatePasswordAction: ReturnType<typeof actions.updatePasswordClick>) {
  const {
    payload: { currentPassword, newPassword, cb },
  } = updatePasswordAction;

  try {
    const user: FirebaseUser = yield getUserFromFb();

    const credentials = providers.EmailAuthProvider.credential(user.email || '', currentPassword || '');

    try {
      yield reauthenticateWithCredential(user, credentials);
    } catch (e: unknown) {
      yield put(actions.setPasswordError(getErrorMessage(e)));
      logger.error(e);
      throw new Error('Invalid credentials');
    }

    yield updatePasswordFb(user, newPassword || '');
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Password has been successfully changed.')
      )
    );

    yield put(actions.setPasswordError(''));

    cb?.();
  } catch (e: unknown) {
    logger.error(e);
  }
}
