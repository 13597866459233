import { auth } from '@shared/config';
import { FirebaseUser } from '@shared/types';
import { onAuthStateChanged } from 'firebase/auth';

export const getUser = () =>
  new Promise<FirebaseUser | null>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      user => {
        resolve(user);
        unsubscribe();
      },
      error => {
        reject(error);
        unsubscribe();
      }
    );
  });
