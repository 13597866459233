import { createAction } from '@reduxjs/toolkit';
import {
  AddDocOrFolderAction,
  ChangeDocOrFolderOrderAction,
  DeleteDocOrFolderAction,
  SaveDocOrFolderDetailsAction,
} from '@shared/types';

export const actions = {
  changeFolderOrderAction: createAction<ChangeDocOrFolderOrderAction>('folder/changeFolderOrderAction'),
  addFolderClick: createAction<AddDocOrFolderAction>('folder/addFolderClick'),
  deleteFolderClick: createAction<DeleteDocOrFolderAction>('folder/deleteFolderAction'),
  saveFolderDetailsAction: createAction<SaveDocOrFolderDetailsAction>('folder/saveFolderDetailsAction'),
};
