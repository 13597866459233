import { logger } from '@shared/lib';
import { FirebaseUser, User } from '@shared/types';

import { getUser } from './watch-user';

export async function getUserFromFb() {
  try {
    const user: FirebaseUser | null = await getUser();

    if (!user) {
      throw new Error('Something went wrong! There is no logged user in the system!');
    }
    return user;
  } catch (e: unknown) {
    logger.error(e);
    throw e;
  }
}

export async function getUserProfile() {
  const fbUser: FirebaseUser = await getUserFromFb();

  const data: User = {
    uid: fbUser.uid,
    isVerified: fbUser.emailVerified,
    photoURL: fbUser.photoURL || '',
    firstName: fbUser.displayName?.split(' ')[0] || '',
    lastName: fbUser.displayName?.split(' ')[1] || '',
    email: fbUser.email || '',
  };
  return data;
}
