import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { redirect, redirectActions } from '@entities/history';
import * as FirestoreService from '@shared/api/firestore';
import { matchParams } from '@shared/lib';
import { ColSnap, DocSnap, Book, BookUser, UserBook } from '@shared/types';

export function* toFirstDoc(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);

  const userBook: UserBook | undefined = yield select(appDataModel.selectors.findBook(params?.bookId));

  if (!userBook) {
    yield call(redirect.toBooks);
    return;
  }

  const bookSnap: DocSnap = yield call(FirestoreService.getBook, userBook.id);
  const book = bookSnap.data() as Book;

  yield put(appDataModel.actions.setCurrentBook(book));
  yield call(redirect.toDoc, userBook.id, book._firstChapterId);
}

export function* toFirstFolder(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);

  const userBook: UserBook | undefined = yield select(appDataModel.selectors.findBook(params?.bookId));

  if (!userBook) {
    yield call(redirect.toBooks);
    return;
  }

  const bookSnap: DocSnap = yield call(FirestoreService.getBook, userBook.id);
  const book = bookSnap.data() as Book;
  if (book.type !== 'fiction' || !book._firstFolderId) {
    yield call(redirect.to404);
    return;
  }

  yield put(appDataModel.actions.setCurrentBook(book));
  yield call(redirect.toFolder, userBook.id, book._firstFolderId);
}

export function* bookRedirect(toBookAction: ReturnType<typeof redirectActions.toBookClick>) {
  const {
    payload: { bookId },
  } = toBookAction;
  const bookSnap: DocSnap = yield call(FirestoreService.getBook, bookId);
  const bookUsersSnap: ColSnap = yield call(FirestoreService.getBookUsers, bookId);
  const book = bookSnap.data() as Book;
  const users = bookUsersSnap.docs.map(doc => doc.data()) as BookUser[];
  yield put(appDataModel.actions.setCurrentBook(book));
  yield put(appDataModel.actions.setCurrentBookUsers(users));
  if (book.type === 'fiction' && book._firstFolderId) {
    yield call(redirect.toFolder, bookId, book._firstFolderId);
  } else {
    yield call(redirect.toTitle, bookId);
  }
}
