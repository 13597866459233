/* eslint-disable max-len */
export const exampleImageUrl =
  'https://firebasestorage.googleapis.com/v0/b/blooksy-252d2.appspot.com/o/examples%2Fimage-example.png?alt=media&token=12bfb8a2-e0db-4f0d-bbf4-f8d82a2e87f3';

export const apaSectionExample = `
 <div>
   <h3>&lt;Example 2nd level heading</h3>
   <p>Example Paragraph&gt;</p>
   <h4>&lt;Example 3rd level heading</h4>
   <p>Example Paragraph&gt;</p>
   <h5>&lt;Example 4th level heading</h5>
   <p>Example Paragraph&gt;</p>
   <h6>&lt;Example 5th level heading</h6>
   <p>Example Paragraph&gt;</p>
   <p><br><br>&nbsp;</p>
   <h3>&lt;Table 1&gt;</h3>
   <p><br><i>&lt;Table title&gt;</i></p>
   <figure class="table">
      <table>
         <colgroup>
            <col style="width:33.33%;">
            <col style="width:33.33%;">
            <col style="width:33.34%;">
         </colgroup>
         <tbody>
            <tr>
               <td>Replace with your table</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
            </tr>
            <tr>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
               <td>&nbsp;</td>
            </tr>
         </tbody>
      </table>
   </figure>
   <p><br>&lt;<i>Note.</i> Place all detailed caption, notes, reference, legend information, etc here&gt;</p>
   <p><br><br>&nbsp;</p>
   <h3>&lt;Figure 1&gt;</h3>
   <p><br><i>&lt;Figure title&gt;</i></p>
   <figure class="image image_resized" style="width:40% !important;">
    <img src="https://firebasestorage.googleapis.com/v0/b/blooksy-252d2.appspot.com/o/examples%2Fimage-example.png?alt=media&amp;token=12bfb8a2-e0db-4f0d-bbf4-f8d82a2e87f3" alt="Image example">
    </figure>
   <p><br>&lt;<i>Note.</i> Place all detailed caption, notes, reference, legend information, etc here&gt;</p>
</div>
`;
