import { call, put } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { signOut } from '@features/auth/log-out';
import { updateProfile } from '@shared/api/cloud-functions';
import { auth } from '@shared/config';
import { createNotification, snackbarModel } from '@features/snackbar';
import { ActionCodeInfo, checkActionCode } from 'firebase/auth';

export function* recoverEmail(code: string) {
  yield call(authModel.sagas.authOperationWithAlert, 'email recovery', function* () {
    const codeData: ActionCodeInfo = yield checkActionCode(auth, code);
    if (codeData.data.email) {
      yield call(updateProfile, { email: codeData.data.email });
    }
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'The email has been recovered! You need to login again!')
      )
    );

    yield call(signOut);
  });
}
