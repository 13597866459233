import cn from 'classnames';
import { Icon } from './icon';
import { IconMap } from '../sprite';
import React, { FC, ReactNode } from 'react';

type PanelRadioButtonProps = {
  title: string;
  description: ReactNode;
  isActive: boolean;
  onChange: () => void;
  name: string;
  disabled?: boolean;
};
export const PanelRadioButton: FC<PanelRadioButtonProps> = ({
  title,
  description,
  isActive,
  onChange,
  name,
  disabled,
}) => {
  return (
    <label
      onChange={onChange}
      className={cn(
        'rounded flex w-full border items-start justify-between',
        'cursor-pointer pl-4 pt-2 pb-2 pr-2 group hover:border-primary-600 hover:bg-primary-50',
        {
          'border-primary-600 bg-primary-50': isActive && !disabled,
          'border-neutrals-200 bg-neutrals-0': !isActive,
        }
      )}
    >
      <div className=''>
        <p className={cn('font-semibold text-sm')}>{title}</p>
        <p
          className={cn('text-sm group-hover:text-primary-700', {
            'text-primary-700': isActive && !disabled,
            'text-neutrals-600': !isActive,
          })}
        >
          {description}
        </p>
      </div>
      <input disabled={disabled} name={name} checked={isActive} type='radio' className='sr-only' />
      {!disabled && (
        <>
          {isActive ? (
            <Icon glyph={IconMap.Check} width={16} className='flex-shrink-0' />
          ) : (
            <span className='w-4 h-4 border border-neutrals-300 rounded-full flex flex-shrink-0' />
          )}
        </>
      )}
    </label>
  );
};
type Option<T extends string | number> = {
  id: T;
  title: string;
  description: ReactNode;
};
type PanelRadioGroupProps<T extends string | number> = {
  options: Option<T>[];
  activeId: T;
  onChange: (value: T) => void;
  name: string;
  disabled?: boolean;
};

export function PanelRadioGroup<T extends string | number>({
  options,
  activeId,
  onChange,
  name,
  disabled = false,
}: PanelRadioGroupProps<T>) {
  return (
    <div className='flex items-center w-full gap-4 sm:flex-col'>
      {options.map(({ title, description, id }) => (
        <PanelRadioButton
          key={id}
          title={title}
          description={description}
          isActive={activeId === id}
          name={name}
          disabled={disabled}
          onChange={() => onChange(id)}
        />
      ))}
    </div>
  );
}
