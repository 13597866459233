import React, { FC } from 'react';

export const AuthFormWrapper: FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className='p-6 mx-auto my-6 rounded sm:rounded-none bg-neutrals-0 text-neutrals-1000'>
      <h2 className='mb-4 font-semibold text-l'>{title}</h2>
      {children}
    </div>
  );
};
