import React from 'react';
import { useSelector } from 'react-redux';

import { connectivityModel } from '.';

export const ConnectivityIndicator = () => {
  const hasInternetConnection = useSelector(connectivityModel.selectors.selectHasInternetConnection);

  return hasInternetConnection ? (
    <p className='py-0.5 px-2 bg-neutrals-100 text-neutrals-500 text-xs sm:hidden'>Autosave Enabled</p>
  ) : (
    <p className='text-xs text-error-700 bg-error-100 py-0.5 px-2 rounded sm:hidden'>No Internet Connection</p>
  );
};
