import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { docModel } from '@entities/doc';
import { appendixModel } from '@entities/appendix';
import { appDataModel } from '@entities/app-data';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { bookTypesDictionary } from '@shared/config';
import { Dropdown } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { AddButton } from './AddButton';

export const AddApaStructureItem = () => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const docs = useSelector(appDataModel.selectors.selectedDocsWithError);
  const appendices = useSelector(appDataModel.selectors.selectedAppendicesWithError);

  return (
    <Dropdown
      triggerComponent={
        <Trigger>
          <AddButton>Add</AddButton>
        </Trigger>
      }
      items={[
        {
          label: (
            <span className='flex items-center whitespace-nowrap text-s p-0'>
              <Icon glyph={IconMap.Plus} className='mr-3 max-w-none text-primary-800' />
              Add {bookTypesDictionary[book.type].docName}
            </span>
          ),
          onClick: () =>
            dispatch(
              docModel.actions.addDocClick({
                title: '',
                order: docs.map(doc => doc.order).reduce((a, b) => Math.max(a, b), 0) + 1,
                bookId: book.id,
              })
            ),
        },
        {
          label: (
            <span className='flex items-center whitespace-nowrap text-s p-0'>
              <Icon glyph={IconMap.Plus} className='mr-3 max-w-none text-primary-800' />
              Add Appendix
            </span>
          ),
          onClick: () =>
            dispatch(
              appendixModel.actions.addAppendixClick({
                title: '',
                order: appendices.map(appendix => appendix.order).reduce((a, b) => Math.max(a, b), 0) + 1,
                bookId: book.id,
              })
            ),
        },
      ]}
    />
  );
};
