import { Location } from 'history';
import { call } from 'redux-saga/effects';

import { redirect } from '@entities/history';
import { onHistory, onNavigate, publicRouteHandlers } from '@processes/routing';

export function* handleSignedOut() {
  yield call(
    onHistory,
    function* (location: Location<string>) {
      yield call(onNavigate, location, publicRouteHandlers, redirect.toLogin);
    },
    'Public'
  );
}
