import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompleteResetPasswordAction,
  DeletePhotoAction,
  ResetPasswordAction,
  SetAuthErrorAction,
  UpdateNamesAction,
  UpdatePasswordAction,
  UpdateUserEmailClick,
  UpdateUserPhotoClick,
} from './types';

type State = {
  userNameError?: string;
  userPhotoError?: string;
  emailError?: string;
  passwordForEmailError?: string;
  passwordError?: string;
};
const initialState: State = {
  userNameError: undefined,
  userPhotoError: undefined,
  emailError: undefined,
  passwordForEmailError: undefined,
  passwordError: undefined,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'profileSetting',
  initialState,
  reducers: {
    setUserNameError: (state, { payload: userNameError }: PayloadAction<string>) => ({
      ...state,
      userNameError,
    }),
    setUserPhotoError: (state, { payload: userPhotoError }: PayloadAction<string>) => ({
      ...state,
      userPhotoError,
    }),
    setEmailError: (state, { payload: emailError }: PayloadAction<string>) => ({
      ...state,
      emailError,
    }),
    setPasswordForEmailError: (state, { payload: passwordForEmailError }: PayloadAction<string>) => ({
      ...state,
      passwordForEmailError,
    }),
    setPasswordError: (state, { payload: passwordError }: PayloadAction<string>) => ({
      ...state,
      passwordError,
    }),
  },
});
export const actions = {
  ...reducerActions,
  sendEmailVerificationClick: createAction<void>('profileSetting/sendEmailVerificationClick'),
  resetPasswordClick: createAction<ResetPasswordAction>('profileSetting/resetPasswordClick'),
  completeResetPasswordClick: createAction<CompleteResetPasswordAction>('profileSetting/completeResetPasswordClick'),
  updatePasswordClick: createAction<UpdatePasswordAction>('profileSetting/updatePasswordClick'),
  updateNamesAction: createAction<UpdateNamesAction>('profileSetting/updateNamesAction'),
  updateUserPhotoClick: createAction<UpdateUserPhotoClick>('profileSetting/updateUserPhotoClick'),
  updateUserEmailClick: createAction<UpdateUserEmailClick>('profileSetting/updateUserEmailClick'),
  deletePhotoClick: createAction<DeletePhotoAction>('profileSetting/deletePhotoClick'),
  setAuthErrorAction: createAction<SetAuthErrorAction>('profileSetting/setAuthErrorAction'),
};
