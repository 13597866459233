import { Dialog } from '@headlessui/react';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { authUserModel } from '@entities/auth-user';
import { allowedRoles, SelectRole, RoleItem, rolesDictionary } from '@entities/role-select';

import { Input, Button } from '@shared/ui';

import { shareAccessModel, ShareAccessRequestData, shareAccessValidationSchema } from '..';

import { UserListItem } from './components/UserListItem';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const ShareWithModal: FC = () => {
  const dispatch = useDispatch();

  const isModalOpen = useSelector(shareAccessModel.selectors.selectIsModalOpen);
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const bookUsers = useSelector(appDataModel.selectors.selectBookUsersWithError);

  const canEdit = book.authorUserId === user.uid;
  const bookId = book.id;

  const [selectedRole, setSelectedRole] = useState<RoleItem>(allowedRoles[0]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const usersListRef = useRef<HTMLUListElement>(null);
  const users = [...bookUsers].sort((a, b) => a.createdAt - b.createdAt);

  const { control, handleSubmit, reset } = useForm<ShareAccessRequestData>({
    resolver: yupResolver(shareAccessValidationSchema),
    defaultValues: { email: '' },
  });

  const onCancelClick = () => {
    dispatch(shareAccessModel.actions.setIsModalOpen(false));
    reset();
  };

  const shareAccess = ({ email }: ShareAccessRequestData) => {
    setIsLoading(true);
    dispatch(
      shareAccessModel.actions.setAccessClick({
        email,
        bookId,
        role: selectedRole.role,
        cb: () => {
          setIsLoading(false);
          reset();
        },
      })
    );
  };

  return (
    <Dialog open={isModalOpen} onClose={onCancelClick} className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen'>
        <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />

        <div className='relative flex flex-col rounded p-4 mx-auto my-12 bg-neutrals-0 w-151 h-fit-content'>
          <div className='flex mb-4 items-center'>
            <Dialog.Title className='font-semibold flex-grow text-m'>Share Book</Dialog.Title>
            <Button
              variant='icon'
              color='tertiary'
              title='Close modal'
              icon={<Icon glyph={IconMap.Cross} />}
              onClick={onCancelClick}
              className='bg-transparent border-transparent p-2'
            />
          </div>
          {canEdit && (
            <>
              <Dialog.Description className='text-s font-semibold'>Invite Collaborators</Dialog.Description>

              <div className='mt-2.5'>
                <form onSubmit={handleSubmit(shareAccess)} className='flex mb-6'>
                  <div className='w-84'>
                    <Input control={control} name='email' type='email' placeholder='Email' />
                  </div>

                  <SelectRole
                    disabled={!canEdit}
                    selectedRole={selectedRole.role}
                    onChange={role => {
                      setSelectedRole(rolesDictionary.find(roleItem => roleItem.role === role) || selectedRole);
                    }}
                  />

                  <Button disabled={isLoading} loading={isLoading} variant={'text'} color='primary' type='submit'>
                    Share Access
                  </Button>
                </form>
              </div>
            </>
          )}
          <div style={{ transform: 'translateZ(0)' }}>
            <p className='text-s font-semibold mb-2'>Book Collaborators</p>
            <ul ref={usersListRef} className='border-t border-b border-neutrals-200 overflow-auto max-h-72'>
              {users.map(currentUser => {
                const sharedProps = { key: currentUser.id, userEmail: currentUser.email, role: currentUser.role };
                return currentUser.inviteAccepted ? (
                  <UserListItem
                    {...sharedProps}
                    title={`${currentUser.name} ${currentUser.role === 'owner' ? '(You)' : ''}`}
                    subtitle={currentUser.email}
                    avatar={
                      currentUser.userPhoto ? (
                        <img src={currentUser.userPhoto} className='object-cover w-full h-full' alt='profile photo' />
                      ) : (
                        <div>{currentUser.name[0]}</div>
                      )
                    }
                    usersListRef={usersListRef}
                  />
                ) : (
                  <UserListItem
                    {...sharedProps}
                    title={currentUser.email}
                    subtitle={'Pending Invitation'}
                    avatar={<span>{currentUser.email[0]}</span>}
                    usersListRef={usersListRef}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
