import React from 'react';

import { HeaderGeneral } from '@widgets/header-general';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='pb-10 min-h-full flex flex-col'>
      <HeaderGeneral />
      {children}
    </div>
  );
};
