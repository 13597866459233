import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

export type ButtonVariant = 'text' | 'icon' | 'icon-text'; // TODO remove this prop
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'destructive';

type Props = {
  variant: ButtonVariant;
  color: ButtonColor;
  type?: ButtonType;
  disabled?: boolean;
  icon?: ReactNode;
  fullWidth?: boolean;
  onClick?: () => unknown;
  title?: string;
  className?: string;
  loading?: boolean;
};
export const Button: FC<Props> = ({
  variant,
  color,
  type = 'button',
  disabled = false,
  icon,
  fullWidth = false,
  onClick,
  title,
  children,
  className,
  loading,
}) => {
  const style = `flex items-center justify-center whitespace-nowrap font-semibold text-s rounded border box-border
    relative`;

  const primaryStyle = `border-transparent bg-primary-800 text-neutrals-0 hover:bg-primary-600
    active:bg-primary-500 disabled:border-none disabled:bg-primary-100`;

  const secondaryStyle = `bg-neutrals-0 border-neutrals-200
  text-primary-800 hover:bg-neutrals-100 hover:text-primary-600
    active:bg-neutrals-200 active:text-primary-500 disabled:bg-neutrals-100 disabled:text-neutrals-400`;

  const tertiaryStyle = `p-0 bg-transparent text-primary-800 border-none hover:text-primary-600
    active:text-primary-500 disabled:text-neutrals-400`;

  const destructiveStyle = `border-transparent bg-error-700 text-neutrals-0 hover:bg-error-600 active:bg-error-500
    disabled:bg-neutrals-300 disabled:border-none`;

  const styles = classNames(style, className, {
    'cursor-not-allowed': disabled,
    'w-full': fullWidth,
    'h-10 leading-10': color !== 'tertiary',
    'px-4': variant === 'text' && color !== 'tertiary',
    'w-10 min-w-10': variant === 'icon' && color !== 'tertiary',
    'pl-5 pr-4': variant === 'icon-text' && color !== 'tertiary',
    [primaryStyle]: color === 'primary',
    [secondaryStyle]: color === 'secondary',
    [tertiaryStyle]: color === 'tertiary',
    [destructiveStyle]: color === 'destructive',
  });
  return (
    <button type={type} disabled={disabled} className={styles} title={title} onClick={onClick}>
      {loading && variant !== 'icon' && (
        <div
          className={classNames('absolute left-0 top-0 flex items-center justify-center w-full h-full rounded', {
            ['bg-primary-100']: color === 'primary',
            ['bg-neutrals-100 text-neutrals-400']: color === 'secondary',
            ['text-neutrals-400']: color === 'tertiary',
            ['bg-neutrals-300 border-none']: color === 'destructive',
          })}
        >
          {[1, 2, 3].map(i => (
            <span key={i} className={`w-2 h-2 flex m-1 bg-primary-800 rounded loading-dot loading-dot--${i}`}></span>
          ))}
        </div>
      )}

      {icon && (
        <div className={variant === 'icon' ? 'mr-0 max-w-none' : 'mr-3 flex items-center justify-center'}>{icon}</div>
      )}
      {variant !== 'icon' && children}
    </button>
  );
};
