import React, { FC, HTMLProps } from 'react';
import classNames from 'classnames';

type Props = { indeterminate?: boolean } & HTMLProps<HTMLInputElement>;

export const Checkbox: FC<Props> = ({ indeterminate, id, ...rest }) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!ref.current) return;

    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  const styles = {
    default: 'border-neutrals-400',
    hover: 'hover:text-primary-600 hover:border-primary-600',
    checked: 'checked:text-primary-800 checked:border-primary-800',
    disabled: 'disabled:text-neutrals-300 disabled:border-neutrals-300',
  };

  return (
    <>
      <input
        id={id}
        type='checkbox'
        className={classNames(
          'border-1 w-4.5 h-4.5 rounded cursor-pointer',
          styles.default,
          styles.disabled,
          styles.hover,
          styles.checked
        )}
        ref={ref}
        {...rest}
      />
      <label htmlFor={id} className='sr-only'>
        checkbox
      </label>
    </>
  );
};
