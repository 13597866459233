import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@shared/ui';
import { appDataModel } from '@entities/app-data';
import { editBookModel } from '../model';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const ChangeCover = () => {
  const dispatch = useDispatch();

  const book = useSelector(appDataModel.selectors.selectedBookWithError);

  const coverError = useSelector(editBookModel.selectors.selectCoverError);
  const isLoading = useSelector(editBookModel.selectors.selectIsCoverLoading);

  const [fileURL, setFileURL] = useState<string | undefined>(book.coverImageURL);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editBookModel.actions.setCoverError());
    const { files } = e.target;

    if (!files || !files.length) {
      dispatch(editBookModel.actions.setCoverError('No file selected'));
      return;
    }

    if (files[0].size > MAX_FILE_SIZE) {
      dispatch(editBookModel.actions.setCoverError('File size should be less than 2MB'));
      return;
    }

    if (!files[0].type.startsWith('image')) {
      dispatch(editBookModel.actions.setCoverError('The selected file must be an image'));
      return;
    }

    setFileURL(URL.createObjectURL(files[0]));
    dispatch(editBookModel.actions.uploadBookCover(files[0]));
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleUploadPhotoClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleDeletePhotoClick = () => {
    setFileURL('');
    dispatch(editBookModel.actions.deleteBookCover(book.coverImageURL));
  };
  return (
    <>
      <div className='flex justify-between gap-4 sm:mb-0 sm:justify-start'>
        <div className='flex flex-col'>
          <span className='text-s text-neutrals-500'>Photo</span>

          {fileURL ? (
            <div
              className={`flex items-center rounded justify-center text-5xl
              font-semibold h-47 w-31 overflow-hidden bg-primary-100 text-primary-800`}
            >
              <img src={fileURL} className='object-cover w-full h-full' alt='profile photo' />
            </div>
          ) : (
            <div
              className={`relative flex flex-col items-center justify-between h-47 w-31 px-4 pt-6 pb-3
                            text-center border rounded border-neutrals-200 bg-neutrals-100`}
            >
              <h3 className='max-w-full font-semibold text-xs text-primary-800 line-clamp-3'>{book.title}</h3>
              <p className='max-w-full text-xxs text-neutrals-500 line-clamp-2'>{book.author}</p>
              <div className='absolute top-0 w-0.5 h-full left-1.5 bg-neutrals-300' />
            </div>
          )}
        </div>
        <div className='flex flex-col gap-4 pt-5'>
          <input type='file' className='hidden' onChange={onFileChange} accept='image/*' ref={hiddenFileInput} />

          <Button type='button' color='tertiary' disabled={isLoading} variant='text' onClick={handleUploadPhotoClick}>
            {!fileURL ? 'Upload' : 'Change'}
          </Button>
          {fileURL && (
            <Button disabled={isLoading} type='reset' color='tertiary' variant='text' onClick={handleDeletePhotoClick}>
              Remove
            </Button>
          )}
        </div>
      </div>
      {coverError && <p className='px-4 py-1 mt-5 text-xs rounded bg-error-100 text-error-700'>{coverError}</p>}
    </>
  );
};
