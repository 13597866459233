import { call, select } from 'redux-saga/effects';

import { updateBookUsers } from '@entities/app-data';
import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getBookUsersRef } from '@shared/api/firestore';

import { selectedBookId } from '../selectors';

export function* watchBookUsersWorker() {
  const bookId: string | undefined = yield select(selectedBookId);
  if (!bookId) {
    throw new Error('bookId is undefined');
  }

  const bookUsersRef = getBookUsersRef(bookId);
  const usersChannel = queryColChannel(bookUsersRef, false);

  yield call(onSnapshot, usersChannel, updateBookUsers, 'BookUsers');
}
