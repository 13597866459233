import { ColSnap, FirestoreUserData } from '@shared/types';
import { call, put } from 'redux-saga/effects';
import { getUsersData } from '@shared/api/firestore';
import { actions } from '@features/admin/model/reducer';

export function* setFirestoreUsersData() {
  yield put(actions.setIsFirestoreUsersLoading(true));
  const users: FirestoreUserData[] = yield call(getUsersData);
  yield put(actions.setIsFirestoreUsersLoading(false));
  yield put(actions.setFirestoreUsers(users));
}

export function* updateFirestoreUsers(snaps: ColSnap) {
  const users = snaps.docs.map(snap => snap.data()) as FirestoreUserData[];
  yield put(actions.setFirestoreUsers(users));
}
