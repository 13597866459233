import { logger } from '@shared/lib';
import { useEffect, useState } from 'react';

export const usePermission = () => {
  const [permission, setPermission] = useState<PermissionState>('prompt');

  useEffect(() => {
    let unsubscribe: PermissionStatus | null = null;

    navigator.permissions
      ?.query({ name: 'microphone' as PermissionName })
      .then(function (status) {
        setPermission(status.state);

        status.onchange = function () {
          setPermission(this.state);
        };

        unsubscribe = status;
      })
      .catch(logger.error);

    return () => {
      if (unsubscribe) {
        unsubscribe.onchange = null;
      }
    };
  }, []);

  return { permission, setPermission };
};
