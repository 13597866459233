import { dictionary } from '@shared/config';
import { getErrorMessage } from '@shared/lib';
import { NotificationType } from '@shared/ui';

import { NotificationItem } from '../model/reducer';

let notificationId = 0;

const getNotificationId = () => {
  return ++notificationId;
};

export const createNotification = (type: NotificationType, message?: string, error?: unknown): NotificationItem => {
  if (!message) {
    if (error) {
      message = getErrorMessage(error);
    } else {
      message = dictionary.error.default;
    }
  }
  return {
    id: getNotificationId(),
    message,
    type,
  };
};
