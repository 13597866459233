import { IconMap } from '@shared/sprite';

import { Button } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { avenModel } from '../../model';
type Props = {
  idx: number;
  text: string;
  id: string;
};

export const ResultItem: FC<Props> = ({ idx, text, id }) => {
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const handleSave = () => {
    if (isChecked) {
      dispatch(avenModel.actions.deleteAvenResult({ item: { id, text }, cb: () => setIsChecked(false) }));
    } else {
      dispatch(avenModel.actions.saveAvenResult({ item: { id, text }, cb: () => setIsChecked(true) }));
    }
  };

  return (
    <li className='mb-3 pb-3 grid grid-cols-1 gap-3 border-b border-neutrals-200'>
      <h3 className='font-semibold'>Result {idx + 1}</h3>
      <p className='whitespace-pre-line'>{text}</p>
      <div>
        <Button
          variant='icon'
          color='tertiary'
          icon={<Icon glyph={isChecked ? IconMap.HeartFilled : IconMap.HeartOutline} />}
          onClick={handleSave}
        />
      </div>
    </li>
  );
};
