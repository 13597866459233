import { call, put, take } from 'redux-saga/effects';

import { setBookUsers } from '@entities/app-data';

import { ChangeBooksLengthAction, changeBooksLengthChan } from '@entities/books-length-channel';
import { redirect } from '@entities/history';
import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';

import { actions } from './reducer';
import MixpanelService from '@features/mixpanel';

export function* createNewBook(createBookAction: ReturnType<typeof actions.createBookClick>) {
  yield put(actions.setIsBookCreating(true));
  const { payload } = createBookAction;

  try {
    const { bookId } = yield call(FirestoreService.createNewBook, payload);

    const action: ChangeBooksLengthAction | undefined = yield take(changeBooksLengthChan);

    if (!action) {
      return;
    }
    const { operation, ids } = action;
    if (operation === 'creating' && ids?.includes(bookId)) {
      MixpanelService.trackBookCreated(payload.type);

      yield put(actions.setIsBookCreating(false));
      yield call(setBookUsers, bookId);
      yield call(redirect.toTitle, bookId);
    }
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.createBook)));
    yield put(actions.setIsBookCreating(false));
    logger.error(e);
  }
}
