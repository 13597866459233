import React, { FC, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { adminModel } from '@features/admin/model';
import {
  Category,
  extractFirestoreUsersById,
  filterByNotStripeAndNotCurrentUser,
  getUserCategories,
} from '@features/admin/lib';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { Button, Input, SelectCheckboxes } from '@shared/ui';
import { isFirebaseStaging } from '@shared/config';
import { arrIncludesItems, columns, getCountRows } from '@features/admin/ui/manage-users/user-columns';

import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import { appDataModel } from '@entities/app-data';
import { CreateUser, EditUser } from '@features/admin';

export const Users: FC = () => {
  const user = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const users = useSelector(adminModel.selectors.selectFirestoreUsersDataWithError);
  const usersFiltered = useMemo(() => users.filter(filterByNotStripeAndNotCurrentUser(user.userId)), [users, user]);

  const categories = useMemo(() => getUserCategories(usersFiltered), [usersFiltered]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = useState('');

  const extractedUsers = useMemo(() => {
    const ids = Object.keys(rowSelection);
    return extractFirestoreUsersById(ids, usersFiltered);
  }, [rowSelection, usersFiltered]);

  const table = useReactTable({
    data: usersFiltered,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    filterFns: {
      arrIncludesItems,
    },
    getRowId: row => row.userId,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: isFirebaseStaging,
  });

  useEffect(() => {
    table.setPageSize(20);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    table.setColumnFilters([{ id: 'category', value: selectedCategories.map(c => c.id) }]);

    //eslint-disable-next-line
  }, [selectedCategories]);

  const hasSelectedRow = !!Object.keys(rowSelection).length;

  return (
    <>
      <div className='flex flex-wrap items-center gap-4 mb-5'>
        <div className='md:w-full w-60'>
          <Input
            placeholder='Search'
            type='text'
            value={globalFilter}
            onChange={e => setGlobalFilter(e.target.value)}
          />
        </div>
        <div className='md:w-full w-50'>
          <SelectCheckboxes
            label='Categories'
            items={categories}
            selectedItems={selectedCategories}
            onChange={setSelectedCategories}
          />
        </div>
        <p className='text-s text-neutrals-1000 mr-auto'>{`${table.getFilteredRowModel().rows.length} users`}</p>
        <div className='flex gap-4'>
          {hasSelectedRow && (
            <EditUser
              users={extractedUsers}
              categories={categories}
              cb={() => {
                setSelectedCategories([]);
                setRowSelection({});
              }}
              isOpen={isEditModalOpen}
              setIsOpen={setIsEditModalOpen}
            />
          )}
          <CreateUser
            categories={categories}
            cb={() => {
              setSelectedCategories([]);
              setRowSelection({});
            }}
          />
        </div>
      </div>
      <div className='overflow-x-auto relative'>
        <table className='w-full text-sm text-left'>
          <thead className='text-s text-neutrals-500 font-semibold'>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className='border-b border-neutrals-200'>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className={classNames('py-4', {
                      'px-1 w-1/100': header.id === 'select',
                      'pl-3 w-1/3': header.id === 'name',
                      'pl-6 w-1/3': header.id === 'email',
                      'pl-6 w-1/4': header.id === 'category',
                    })}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
                <th key={'edit'} className={classNames('py-4 px-2.5 w-1/100 invisible')}>
                  <Icon glyph={IconMap.ShortEdit} />
                </th>
              </tr>
            ))}
          </thead>
          <tbody className='text-s text-neutrals-1000'>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className='border-b border-neutrals-200'>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={classNames('whitespace-nowrap py-4', {
                      'px-1': cell.id.endsWith('select'),
                      'pl-3': cell.id.endsWith('name'),
                      'pl-6': cell.id.endsWith('email') || cell.id.endsWith('category'),
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                <td key={`${row.id}edit`}>
                  <button
                    type='button'
                    className={classNames('py-4 px-2.5 cursor-pointer text-neutrals-500 hover:text-neutrals-1000')}
                    onClick={() => {
                      setRowSelection({ [row.id]: true });
                      setIsEditModalOpen(true);
                    }}
                  >
                    <Icon glyph={IconMap.ShortEdit} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-between items-center gap-x-4 mt-6'>
        {table.getFilteredRowModel().rows.length ? (
          <>
            <p className='text-s text-neutrals-1000'>
              {getCountRows({
                pagination: table.getState().pagination,
                rowsLength: table.getRowModel().rows.length,
                allRowsLength: table.getFilteredRowModel().rows.length,
              })}
            </p>
            <div className='flex gap-x-4'>
              <Button
                variant='text'
                color='secondary'
                type='button'
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                Prev
              </Button>
              <Button
                variant='text'
                color='secondary'
                type='button'
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <p className='text-s text-neutrals-1000 text-center flex-grow'>No users found</p>
        )}
      </div>
    </>
  );
};
