import debounce from 'lodash/debounce';
import React, { useState, FC, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { docModel } from '@entities/doc';
import { CKEditorCS } from '@entities/ck-editor';
import { bookTypesDictionary, dictionary, MAX_TITLE_LENGTH } from '@shared/config';

import { Doc, CKEditorInstance } from '@shared/types';
import { EditorPageContentWrapper, ErrorBlock, Loader, Textarea } from '@shared/ui';
import { createNotification, snackbarModel } from '@features/snackbar';

import cn from 'classnames';
import { apaSectionExample } from './const';

type Props = {
  doc: Doc;
};

export const DocPageBase: FC<Props> = ({ doc }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);
  const isApaPaper = book.type === 'apa';
  const isFirstDoc = doc.order === 0;
  const example = isApaPaper && isFirstDoc ? apaSectionExample : '';

  const titleRef = React.createRef<HTMLTextAreaElement>();
  const subTitleRef = React.createRef<HTMLTextAreaElement>();
  const [error, setError] = useState<Error | null>(null);

  const handleSaveDocTitle = (content: string, bookId: string, docId: string) => {
    if (content.length > MAX_TITLE_LENGTH) {
      dispatch(
        snackbarModel.actions.addNotificationAction(
          createNotification('primary', dictionary.primary.tooLongChapterName)
        )
      );
    }
    dispatch(
      docModel.actions.saveDocDetailsAction({
        content,
        bookId,
        id: docId,
        fieldName: 'title',
      })
    );
  };

  const handleSaveDocSubtitle = (content: string, bookId: string, docId: string) => {
    dispatch(
      docModel.actions.saveDocDetailsAction({
        content,
        bookId,
        id: docId,
        fieldName: 'subtitle',
      })
    );
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveDocTitle = useCallback(debounce(handleSaveDocTitle, 500), []);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSaveDocSubtitle = useCallback(debounce(handleSaveDocSubtitle, 500), []);

  const handleChangeDocTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveDocTitle(e.target.value, book.id, doc.id);
  };
  const handleChangeDocSubtitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleSaveDocSubtitle(e.target.value, book.id, doc.id);
  };

  const editorRef = useRef<CKEditorInstance>();
  useEffect(() => {
    return () => {
      editorRef.current = null;
    };
  }, []);

  const focusEditor = () => {
    editorRef.current?.editing.view.focus();
  };

  const onTitlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      isApaPaper ? subTitleRef.current?.focus() : focusEditor();
    }
  };
  const onSubtitlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      focusEditor();
    }
  };

  if (error) {
    return <ErrorBlock error={error} />;
  }

  return (
    <EditorPageContentWrapper>
      <Textarea
        ref={titleRef}
        className={cn(`flex-none px-10 py-0 text-center`, {
          'text-l font-semibold': !isApaPaper,
          'text-base leading-8 font-bold': isApaPaper,
        })}
        name='title'
        placeholder={`Untitled ${bookTypesDictionary[book.type].docName}`}
        disabled={!isOwner}
        defaultValue={doc.title}
        key={`${doc.id}-title`}
        onChange={e => handleChangeDocTitle(e)}
        onKeyPress={onTitlePress}
      />

      {isApaPaper && (
        <Textarea
          ref={subTitleRef}
          className='flex-none px-10 py-0 text-base font-normal text-center'
          name='subtitle'
          placeholder='Chapter title must be in sentence case, contain no special characters,
        and be in inverted pyramid format if longer than one line—use Shift+Enter to force a new line'
          disabled={!isOwner}
          defaultValue={doc.subtitle}
          key={`${doc.id}-subtitle`}
          onChange={e => handleChangeDocSubtitle(e)}
          onKeyPress={onSubtitlePress}
        />
      )}

      <CKEditorCS
        bookId={book.id}
        onReady={editor => {
          editorRef.current = editor;

          if (doc.title) editor.editing.view.focus();
          else titleRef.current?.focus();
        }}
        documentHash={doc.id}
        onError={(e: Error) => {
          setError(e);
        }}
        example={example}
        section='content'
      />
    </EditorPageContentWrapper>
  );
};

export const DocPage = () => {
  const doc = useSelector(appDataModel.selectors.selectedDoc);
  return doc ? <DocPageBase doc={doc} /> : <Loader />;
};
