import { call } from 'redux-saga/effects';

import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getFoldersRef } from '@shared/api/firestore';

import { actions } from '../reducer';
import { updateFolders } from '@entities/app-data';

export function* watchFoldersWorker(setSelectedBookIdAction: ReturnType<typeof actions.setSelectedBookId>) {
  const { payload: bookId } = setSelectedBookIdAction;

  if (!bookId) {
    return;
  }

  const foldersRef = getFoldersRef(bookId);
  const foldersChannel = queryColChannel(foldersRef, false);

  yield call(onSnapshot, foldersChannel, updateFolders, 'Folders');
}
