import { IconMap } from '@shared/sprite';
import { Button, SidebarRightContent } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import { InfoTooltip } from '@shared/ui/info-tooltip';
import React, { useState } from 'react';
import { CreateTextModal } from './components/CreateTextModal';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { SavedItem } from '@features/Aven-AI/ui/components/SavedItem';
import { Placeholder } from '@features/Aven-AI/ui/components/Placeholder';

export const AvenSidebarTab = () => {
  const results = useSelector(appDataModel.selectors.selectedNotesItemWithError).avenSavedResults;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <SidebarRightContent>
      <CreateTextModal opened={isModalOpen} setOpened={setIsModalOpen} />
      {!!results.length && (
        <div className='mb-3 border-b border-neutrals-200 pb-3 flex items-center justify-between'>
          <Button
            variant='icon-text'
            color='tertiary'
            type='button'
            icon={<Icon glyph={IconMap.Plus} className='fill-primary-800' />}
            onClick={() => setIsModalOpen(true)}
          >
            Use Aven AI
          </Button>
          <InfoTooltip>Aven is an AI content generation tool that helps you write your book in Blooksy.</InfoTooltip>
        </div>
      )}
      <>
        {results.length ? (
          <ul>
            {results.map(i => (
              <SavedItem key={i.id} id={i.id} text={i.text} />
            ))}
          </ul>
        ) : (
          <Placeholder onClick={() => setIsModalOpen(true)} />
        )}
      </>
    </SidebarRightContent>
  );
};
