import { createNotification, snackbarModel } from '@features/snackbar';
import { requestSupport } from '@shared/api/cloud-functions';
import { dictionary } from '@shared/config';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from './reducer';
import { storage } from '@shared/config';
import { generateImageName, logger } from '@shared/lib';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';

function* makeSupportRequest({ payload }: ReturnType<typeof actions.sendRequest>) {
  const { cb, ...params } = payload;
  try {
    yield put(actions.setIsRequestSending(true));

    yield call(requestSupport, params);

    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('success', dictionary.success.makeSupportRequest))
    );
    cb();
  } catch (error: unknown) {
    yield put(actions.setError((error as Error).message));
  } finally {
    yield put(actions.setIsRequestSending(false));
  }
}

function* deletePhoto({ payload: url }: ReturnType<typeof actions.deletePhoto>) {
  if (!url) {
    return;
  }
  try {
    yield call(deleteFileFromStorage, url);
  } catch (e: unknown) {
    logger.error(e);
  }
}

function* deleteFileFromStorage(url: string) {
  const imageRef = ref(storage, url);
  yield call(deleteObject, imageRef);
}

function* uploadPhoto({ payload: { file, cb } }: ReturnType<typeof actions.uploadPhoto>) {
  try {
    const storageRef = ref(storage, 'support');
    const imageRef = ref(storageRef, generateImageName(file.name));

    yield call(uploadBytes, imageRef, file);

    cb(imageRef.fullPath);
  } catch (e: unknown) {
    logger.error(e);
  }
}

export function* supportWorker() {
  yield takeLatest(actions.sendRequest, makeSupportRequest);
  yield takeEvery(actions.uploadPhoto, uploadPhoto);
  yield takeLatest(actions.deletePhoto, deletePhoto);
}
