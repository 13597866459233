import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

type ActiveInvite = {
  bookId: string;
  action: 'accepting' | 'rejecting';
};
export type InviteAction = 'accepting' | 'rejecting';

type State = { activeInvites: ActiveInvite[] };
const initialState: State = {
  activeInvites: [],
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    addActiveInvite: (state, { payload: activeInvite }: PayloadAction<ActiveInvite>) => ({
      ...state,
      activeInvites: [...state.activeInvites, activeInvite],
    }),
    removeActiveInvite: (state, { payload: activeInvite }: PayloadAction<ActiveInvite>) => ({
      ...state,
      activeInvites: state.activeInvites.filter(i => i.bookId !== activeInvite.bookId),
    }),
  },
});
export const actions = {
  ...reducerActions,
  acceptInviteClick: createAction<string>('invite/accept'),
  rejectInviteClick: createAction<string>('invite/reject'),
};
