import { call, put } from 'redux-saga/effects';

import { authChannelModel } from '@entities/auth-channel';
import { authModel } from '@entities/auth-operation';
import { auth } from '@shared/config/firebase';
import { signInWithEmailAndPassword as signInWithEmailAndPasswordFB, UserCredential } from 'firebase/auth';
import MixpanelService from '@features/mixpanel';

export function* signInWithEmailAndPassword(
  signInWithEmailAndPasswordAction: ReturnType<typeof authModel.actions.signUpClick>
) {
  const {
    payload: { email, password },
  } = signInWithEmailAndPasswordAction;

  yield call(authModel.sagas.authOperation, 'Unknown sign in error', function* () {
    const userCredential: UserCredential = yield signInWithEmailAndPasswordFB(auth, email, password);

    MixpanelService.login(userCredential.user.uid);

    yield put(authChannelModel.chan, authChannelModel.loggedIn());
  });
}
