import { call, put } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { getUserFromFb } from '@entities/auth-user';
import { FirebaseUser } from '@shared/types';
import { createNotification, snackbarModel } from '@features/snackbar';
import { sendEmailVerification as sendEmailVerificationFb } from 'firebase/auth';

export function* sendEmailVerification() {
  yield call(authModel.sagas.authOperationWithAlert, 'sending verification email', function* () {
    const user: FirebaseUser = yield getUserFromFb();
    yield sendEmailVerificationFb(user);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('primary', "We've sent an email to verify your account!")
      )
    );
  });
}
