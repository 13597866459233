import { Location } from 'history';
import { call, takeEvery, CancelledEffect, cancelled, fork, takeLatest } from 'redux-saga/effects';

import { appDataModel, setBooksData, setFirestoreUserData } from '@entities/app-data';
import { authModel } from '@entities/auth-operation';
import { setUserData } from '@entities/auth-user';
import { redirect, redirectActions } from '@entities/history';
import { signOut } from '@features/auth/log-out';
import { bookDeletionModel } from '@features/book-deletion';
import { exportModel } from '@features/book-export';
import { profileSettingModel } from '@features/profile-settings';
import { clearData, onHistory, onNavigate, privateRouteHandlers } from '@processes/routing';
import { supportModel } from '@features/support';
import { mixpanelFirstIdentify } from '@features/mixpanel/saga';

export function* handleSignedIn() {
  yield takeLatest(appDataModel.actions.setSelectedBookId, appDataModel.sagas.watchDocsWorker);
  yield takeLatest(appDataModel.actions.setSelectedBookId, appDataModel.sagas.watchAppendicesWorker);
  yield takeLatest(appDataModel.actions.setSelectedBookId, appDataModel.sagas.watchFoldersWorker);
  yield takeLatest(
    [appDataModel.actions.setDocs, appDataModel.actions.setFolders],
    appDataModel.sagas.watchBookStructure
  );

  yield takeEvery(authModel.actions.signOutClick, signOut);
  yield takeEvery(profileSettingModel.actions.updateNamesAction, profileSettingModel.sagas.updateUserFirstAndLastName);
  yield takeEvery(redirectActions.toEditBookClick, function* (action) {
    yield call(redirect.toBookEdit, action.payload.bookId);
  });
  yield takeEvery(redirectActions.backFromBookEditClick, redirect.backFromBookEdit);

  // Get initial data for the app
  yield call(setUserData);
  yield call(setBooksData);
  yield call(setFirestoreUserData);
  yield call(mixpanelFirstIdentify);
  // Watch books
  yield fork(appDataModel.sagas.watchBooksWorker);
  yield fork(exportModel.saga);
  yield fork(bookDeletionModel.saga);
  yield fork(appDataModel.sagas.watchFirestoreUser);
  yield fork(supportModel.saga);
  try {
    yield call(
      onHistory,
      function* (location: Location<string>) {
        yield call(onNavigate, location, privateRouteHandlers, redirect.to404);
      },
      'Private'
    );
  } finally {
    const isCancel: CancelledEffect = yield cancelled();
    if (isCancel) {
      yield call(clearData);
    }
  }
}
