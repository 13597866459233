import { RootState } from '@app/index';
import { selectWithError } from '@shared/lib';

export const currentBook = ({ appData }: RootState) => appData.currentBook;
export const selectBooks = ({ appData }: RootState) => appData.books;

export const selectedBookId = ({ appData }: RootState) => appData.selectedBookId;

export const selectedSideBarItem = ({ appData }: RootState) => appData.selectedSidebarItem;

export const selectedDocs = ({ appData }: RootState) => appData.docs;
export const selectedAppendices = ({ appData }: RootState) => appData.appendices;
export const selectedFolders = ({ appData }: RootState) => appData.folders;

export const selectedDoc = ({ appData }: RootState) =>
  appData.docs?.find(doc => {
    const docId = appData.selectedSidebarItem?.type === 'doc' ? appData.selectedSidebarItem?.docId : undefined;
    return doc.id === docId;
  });

export const selectedAppendix = ({ appData }: RootState) =>
  appData.appendices?.find(doc => {
    const docId = appData.selectedSidebarItem?.type === 'appendix' ? appData.selectedSidebarItem?.docId : undefined;
    return doc.id === docId;
  });

export const selectedFolder = ({ appData }: RootState) =>
  appData.folders?.find(folder => {
    const folderId = appData.selectedSidebarItem?.type === 'folder' ? appData.selectedSidebarItem?.docId : undefined;
    return folder.id === folderId;
  });

export const findBook =
  (id: string | undefined) =>
  ({ appData }: RootState) =>
    appData.books?.find(b => b.id === id);

export const findDoc =
  (id: string | undefined) =>
  ({ appData }: RootState) =>
    appData.docs?.find(c => c.id === id);

export const findAppendix =
  (id: string | undefined) =>
  ({ appData }: RootState) =>
    appData.appendices?.find(c => c.id === id);

export const selectBookUsers = ({ appData }: RootState) => appData.currentBookUsers;

export const selectIsBookOwner = (state: RootState) =>
  state.user.firebaseUser?.uid === state.appData.currentBook?.authorUserId;

export const selectRole = (state: RootState) => {
  const id = state.user.firebaseUser?.uid;
  return state.appData.currentBookUsers?.find(i => i.id === id)?.role;
};

export const selectFirestoreUserData = ({ appData: { firestoreUserData } }: RootState) => firestoreUserData;
export const selectBookStructure = ({ appData: { bookStructure } }: RootState) => bookStructure;

export const selectedNotesItem = (state: RootState) => {
  const doc = selectedDoc(state);
  const appendix = selectedAppendix(state);
  const folder = selectedFolder(state);
  return doc || appendix || folder;
};

export const selectedBookWithError = selectWithError(currentBook, 'currentBook');
export const selectedBookIdWithError = selectWithError(selectedBookId, 'selectedBookId');
export const selectedSideBarItemWithError = selectWithError(selectedSideBarItem, 'selectedSideBarItem');
export const selectedDocsWithError = selectWithError(selectedDocs, 'Docs');
export const selectedDocWithError = selectWithError(selectedDoc, 'selectedDoc');
export const selectedAppendicesWithError = selectWithError(selectedAppendices, 'Appendices');
export const selectedAppendixWithError = selectWithError(selectedAppendix, 'selectedAppendix');

export const selectBookUsersWithError = selectWithError(selectBookUsers, 'bookUsers');
export const selectBooksWithError = selectWithError(selectBooks, 'userBooks');

export const selectIsBookOwnerWithError = selectWithError(selectIsBookOwner, 'isBookOwner');
export const selectFirestoreUserDataWithError = selectWithError(selectFirestoreUserData, 'Firestore User');
export const selectBookStructureWithError = selectWithError(selectBookStructure, 'Book Structure');
export const selectedFoldersWithError = selectWithError(selectedFolders, 'folders');
export const selectedNotesItemWithError = selectWithError(selectedNotesItem, 'notes item');
export const selectRoleWithError = selectWithError(selectRole, 'role');
