import { Listbox, Transition } from '@headlessui/react';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, RadioButton } from '@shared/ui';

import { exportModel } from '..';
import { ExportBookSize, ExportBookType } from '../model/types';
import { appDataModel } from '@entities/app-data';
import { createExportSizeLabel } from '../lib';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

const exportSizeValue: ExportBookSize[] = ['5in 8in', '5.5in 8.5in', '6in 9in', '8.5in 11in'];

export const ExportModal: FC = () => {
  const bookId = useSelector(exportModel.selectors.selectExportedBookId);
  const exportedBook = useSelector(appDataModel.selectors.findBook(bookId));
  const isOpenModal = useSelector(exportModel.selectors.selectModalIsOpen);

  const isDocumentPaper = exportedBook?._bookType === 'dissertation' || exportedBook?._bookType === 'apa';
  const defaultSizeExport = isDocumentPaper ? exportSizeValue[3] : exportSizeValue[1];

  const isDisabled = exportedBook?._bookType === 'apa';

  const dispatch = useDispatch();
  const [exportType, setExportType] = useState<ExportBookType>('docx');
  const [exportSize, setExportSize] = useState<ExportBookSize>(defaultSizeExport);

  const onChangeExportType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ExportBookType;
    setExportType(value);
  };

  const onChangeExportSize = (value: ExportBookSize) => {
    setExportSize(value);
  };

  const clearExportState = () => {
    setExportType('docx');
    setExportSize(defaultSizeExport);
    dispatch(exportModel.actions.endBookExport());
  };

  const onConfirmClick = () => {
    dispatch(exportModel.actions.exportBook({ bookId: bookId || '', exportType, exportSize }));
    clearExportState();
  };

  const onCancelClick = () => {
    clearExportState();
  };

  useEffect(() => {
    setExportType('docx');
    setExportSize(defaultSizeExport);
  }, [defaultSizeExport, exportedBook]);

  return (
    <Modal
      opened={isOpenModal}
      title='Export Book'
      leftButtonVariant='text'
      leftButtonText='Cancel'
      rightButtonText='Export'
      rightButtonVariant='text'
      rightButtonColor='primary'
      onCancelClick={onCancelClick}
      onConfirmClick={onConfirmClick}
    >
      <div className='space-y-4 -my-2.5'>
        <div>
          <p className='text-neutrals-500 text-sm'>Page size</p>
          <Listbox value={exportSize} onChange={onChangeExportSize} disabled={isDisabled}>
            {({ open }) => (
              <div className='relative'>
                <Listbox.Button
                  className={`
                        box-border justify-between relative flex items-center rounded border
                        transition-colors duration-300 h-10 ease-in-out w-full px-4
                        ${open ? 'border-primary-800' : 'border-neutrals-200'}
                        ${isDisabled ? 'cursor-default bg-neutrals-100' : 'cursor-pointer'}
                        `}
                >
                  <span className={`mr-6 text-s ${isDisabled ? ' text-neutrals-300 ' : ' text-neutrals-1000'}`}>
                    {createExportSizeLabel(exportSize)}
                  </span>
                  <Icon
                    glyph={IconMap.ArrowSmallBottom}
                    className={`${
                      open ? 'fill-primary-800 rotate-180 transform' : 'fill-neutrals-400'
                    } transition-all duration-500`}
                  />
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options
                    className='absolute right-0 z-10 min-w-full overflow-auto border rounded  w-fit-content
                          border-neutrals-200 text-s bg-neutrals-0 focus:outline-none'
                  >
                    {exportSizeValue.map(size => (
                      <Listbox.Option
                        key={size}
                        className={({ active }) =>
                          `${active ? 'bg-neutrals-100' : 'bg-neutrals-0'}
                            select-none relative py-2.5 px-4 whitespace-nowrap cursor-pointer`
                        }
                        value={size}
                      >
                        {createExportSizeLabel(size)}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
        <div>
          <p className='mb-1 text-neutrals-500 text-sm'>Format</p>
          <div className='flex space-x-6'>
            <RadioButton
              name='exportType'
              value='docx'
              onChange={onChangeExportType}
              label='DOCX'
              defaultChecked={true}
              disabled={isDisabled}
            />
            <RadioButton
              name='exportType'
              value='pdf'
              onChange={onChangeExportType}
              label='PDF'
              disabled={isDisabled}
            />
          </div>
          <p className='bg-primary-50 text-neutrals-1000 py-1 px-2 text-xs rounded mt-4.5'>
            Make sure all suggestions have been resolved before exporting.
          </p>
        </div>
      </div>
    </Modal>
  );
};
