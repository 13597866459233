import { EMAIL_SCHEMA, FIRST_NAME_SCHEMA, LAST_NAME_SCHEMA, CREATE_PASSWORD_SCHEMA } from '@shared/config';
import * as yup from 'yup';

export const createAccountValidationSchema = yup.object({
  email: EMAIL_SCHEMA,
  password: CREATE_PASSWORD_SCHEMA,
  firstName: FIRST_NAME_SCHEMA,
  lastName: LAST_NAME_SCHEMA,
});

export type CreateAccountRequestData = yup.InferType<typeof createAccountValidationSchema>;
