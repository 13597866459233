import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authUserModel } from '@entities/auth-user';
import { BookTypeSelect } from '@entities/book-type-select';
import { Button, Input } from '@shared/ui';

import { bookCreatingModel, AVAILABILITY_INDEX } from '..';
import { bookTypeData, bookTypeDataWithoutApa, BookMetaData, bookMetadataValidationSchema } from '../config';
import { isFirebaseStaging } from '@shared/config';
import { generateTitle } from './utils';
import { isAdmin, isAPA, isBlooksyTeamMember, isKSU } from '@shared/lib/permissions';
import { appDataModel } from '@entities/app-data';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const CreateBookForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserModel.selectors.selectUserWithError);
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const isBookCreating = useSelector(bookCreatingModel.selectors.selectIsBookCreating);
  const [bookTypeIdx, setBookTypeIdx] = useState<number | null>(null);

  const showApaTemplate =
    isKSU(firestoreUser) || isAdmin(firestoreUser) || isBlooksyTeamMember(firestoreUser) || isAPA(firestoreUser);

  const books = showApaTemplate ? bookTypeData : bookTypeDataWithoutApa;
  const availabilityIndex = showApaTemplate ? AVAILABILITY_INDEX : AVAILABILITY_INDEX - 1;

  const bookInfoRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (bookTypeIdx !== null) {
      bookInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [bookInfoRef, bookTypeIdx]);

  const { control, handleSubmit, setValue } = useForm<BookMetaData>({
    resolver: yupResolver(bookMetadataValidationSchema),
    defaultValues: {
      title: '',
      subtitle: '',
      author: `${user.firstName} ${user.lastName}`,
    },
  });

  const createBook = ({ title, subtitle, author }: BookMetaData) => {
    if (bookTypeIdx === null) {
      return;
    }
    dispatch(
      bookCreatingModel.actions.createBookClick({
        title: title,
        subtitle: subtitle || '',
        author: author || '',
        type: books[bookTypeIdx].type,
        style: 'default',
      })
    );
  };

  return (
    <>
      <div className='px-10 md:px-6 sm:pl-5 sm:pr-0'>
        <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
          <div
            className={`grid grid-cols-4 col-start-3 col-end-11 mb-6 sm:overflow-x-scroll gap-x-4 gap-y-4
              md:col-start-1 md:col-end-10 sm:col-end-5 sm:flex sm:flex-nowrap`}
          >
            {books.slice(0, isFirebaseStaging ? undefined : availabilityIndex + 1).map((book, idx) => (
              <BookTypeSelect
                icon={book.img}
                type={book.name}
                key={book.type}
                isUnavailable={idx > availabilityIndex}
                isActive={idx === bookTypeIdx}
                onSelect={() => {
                  setBookTypeIdx(idx);
                  setValue('title', generateTitle(user.firstName, book.type));
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {bookTypeIdx !== null && (
        <div ref={bookInfoRef} className='px-10 md:px-6 sm:px-5'>
          <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
            <div className='col-start-3 col-end-7 md:col-start-1 md:col-end-5 sm:col-start-1 sm:col-end-5'>
              <p className='mb-4 font-semibold text-s text-neutrals-1000'>Fill out the basic book details</p>
              <form onSubmit={handleSubmit(createBook)}>
                <div className='mb-4'>
                  <Input control={control} name='title' type='text' label='Title' />
                </div>
                <div className='mb-4'>
                  <Input control={control} name='subtitle' type='text' label='Subtitle' />
                </div>
                <div className='mb-6'>
                  <Input control={control} name='author' type='text' label='Author' />
                </div>
                <Button disabled={isBookCreating} loading={isBookCreating} variant='text' color='primary' type='submit'>
                  Create Book
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
