import { auth, functions } from '@shared/config';
import {
  Intervals,
  PlanName,
  Role,
  GetTokenParams,
  SetAccessParams,
  SetAccessResponse,
  RegisterParams,
  UpdateProfileParams as FBUpdateProfileParams,
  AcceptRejectInviteParams,
  SignInAsSuperuserParams,
  CreateStripePortalLinkParams,
  CreateSubscriptionCheckoutSessionParams,
  AskAvenParams,
  AskAvenResponse,
  CreateAvenCoinsCheckoutSessionParams,
  RequestSupportParams,
  CreateUsersParams,
  GetDeepgramTokenResponse,
} from 'blooksy-backend';

import { CloudFunctionResponse, UpdateProfileParams } from './types';

import { UserInfo } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

export const getCKEditorToken = (bookId: string): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<GetTokenParams, string>(functions, 'getCKEditorToken')({ bookId });

export const setAccess = (
  email: string,
  bookId: string,
  role: Role | undefined
): Promise<CloudFunctionResponse<SetAccessResponse>> =>
  httpsCallable<SetAccessParams, SetAccessResponse>(functions, 'setAccess')({ bookId, email, role });

export const register = (email: string, password: string, displayName: string): Promise<CloudFunctionResponse<void>> =>
  httpsCallable<RegisterParams, void>(functions, 'register')({ email, password, displayName });

export const updateProfile = async ({
  lastName,
  photoURL,
  email,
  firstName,
}: Partial<UpdateProfileParams>): Promise<CloudFunctionResponse<UserInfo>> => {
  let displayName = '';
  if (firstName && lastName) {
    displayName = `${firstName} ${lastName}`;
  }
  const user = await httpsCallable<FBUpdateProfileParams, UserInfo>(
    functions,
    'updateProfile'
  )({
    email,
    photoURL,
    displayName,
  });
  if (!email) {
    await auth.currentUser?.reload();
  }
  return user;
};

export const acceptInvite = (bookId: string): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<AcceptRejectInviteParams, string>(functions, 'acceptInvite')({ bookId });

export const rejectInvite = (bookId: string): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<AcceptRejectInviteParams, string>(functions, 'rejectInvite')({ bookId });

export const loginSuperUser = (email: string): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<SignInAsSuperuserParams, string>(functions, 'signInAsSuperuser')({ email });

export const createUsers = (emails: string[], category: string): Promise<CloudFunctionResponse<void>> =>
  httpsCallable<CreateUsersParams, void>(functions, 'createUsers')({ emails, category });

export const createStripePortalLink = (params: CreateStripePortalLinkParams): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<CreateStripePortalLinkParams, string>(functions, 'createStripePortalLink')(params);

export type PlanConfig = {
  name: PlanName;
  interval: Intervals;
};

export const createStripeCheckoutSession = (
  params: CreateSubscriptionCheckoutSessionParams
): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<CreateSubscriptionCheckoutSessionParams, string>(functions, 'createStripeCheckoutSession')(params);
export const askAven = (params: AskAvenParams): Promise<CloudFunctionResponse<AskAvenResponse>> =>
  httpsCallable<AskAvenParams, AskAvenResponse>(functions, 'askAven')(params);

export const getDeepgramToken = (): Promise<CloudFunctionResponse<GetDeepgramTokenResponse>> =>
  httpsCallable<unknown, GetDeepgramTokenResponse>(functions, 'getDeepgramToken')();

export const buyAvenCoins = (params: CreateAvenCoinsCheckoutSessionParams): Promise<CloudFunctionResponse<string>> =>
  httpsCallable<CreateAvenCoinsCheckoutSessionParams, string>(functions, 'buyCoins')(params);

export const requestSupport = (params: RequestSupportParams): Promise<CloudFunctionResponse<void>> =>
  httpsCallable<RequestSupportParams, void>(functions, 'requestSupport')(params);
