import {
  CREATE_PASSWORD_SCHEMA,
  EMAIL_SCHEMA,
  FIRST_NAME_SCHEMA,
  LAST_NAME_SCHEMA,
  PASSWORD_SCHEMA,
} from '@shared/config';
import * as yup from 'yup';

export const changeNameValidationSchema = yup.object({
  firstName: FIRST_NAME_SCHEMA,
  lastName: LAST_NAME_SCHEMA,
});
export type ChangeNameRequestData = yup.InferType<typeof changeNameValidationSchema>;

export const changeEmailValidationSchema = yup.object({
  email: EMAIL_SCHEMA,
  password: PASSWORD_SCHEMA,
});
export type ChangeEmailRequestData = yup.InferType<typeof changeEmailValidationSchema>;

export const changePasswordValidationSchema = yup.object({
  password: PASSWORD_SCHEMA,
  newPassword: CREATE_PASSWORD_SCHEMA,
});
export type ChangePasswordRequestData = yup.InferType<typeof changePasswordValidationSchema>;
