import { appDataModel } from '@entities/app-data';

import { folderModel } from '@entities/folder';
import { BookStructure } from '@shared/types';
import { DropResult } from 'react-beautiful-dnd';
import { HandleDragEndConfig } from './types';

export const handleFolderDragEndEvent = (
  result: DropResult,
  { folders, book, docs, dispatch }: HandleDragEndConfig
) => {
  const { destination, source } = result;
  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return;
  }

  if (!folders) {
    throw new Error('Folders is undefined');
  }

  const orderedFolders = folders.slice().sort((a, b) => a.order - b.order);

  const [movedDoc] = orderedFolders.splice(source.index, 1);
  orderedFolders.splice(destination.index, 0, movedDoc);

  dispatch(
    folderModel.actions.changeFolderOrderAction({
      bookId: book.id,
      ids: orderedFolders.map(doc => doc.id),
    })
  );
  dispatch(
    appDataModel.actions.setBookStructure(
      orderedFolders.reduce<BookStructure>((acc, folder) => {
        acc[folder.id] = { ...folder, children: docs.filter(i => i.folderId === folder.id) };
        return acc;
      }, {})
    )
  );
};
