import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlanConfig } from '@shared/api/cloud-functions';

type State = { isStripeLoading: boolean };
const initialState: State = {
  isStripeLoading: false,
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setIsStripeLoading: (state, { payload: isStripeLoading }: PayloadAction<boolean>) => ({
      ...state,
      isStripeLoading,
    }),
  },
});

type CreateCheckoutSessionAction = PlanConfig & { successUrl?: string };
export const actions = {
  ...reducerActions,
  createCheckoutSession: createAction<CreateCheckoutSessionAction>('stripe/createCheckoutSession'),
  createPortalLink: createAction('stripe/createPortalLink'),
};
