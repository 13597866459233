import { Location } from 'history';
import { call, takeEvery } from 'redux-saga/effects';

import { confirmPasswordReset } from '@features/auth/code-operation';
import { profileSettingModel } from '@features/profile-settings';

export function* completeResetPasswordWorker(location: Location<string>) {
  const { state: code } = location;

  yield takeEvery(profileSettingModel.actions.completeResetPasswordClick, function* (completeResetPasswordAction) {
    const {
      payload: { password, cb },
    } = completeResetPasswordAction;

    yield call(confirmPasswordReset, code, password);
    cb && cb();
  });
}
