import { Listbox, Transition } from '@headlessui/react';
import React, { FC, Fragment, RefObject, useEffect, useRef, useState } from 'react';

import { Role } from 'blooksy-backend';

import { allowedRoles, RoleItem, rolesDictionary } from './config';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const SelectRole: FC<{
  selectedRole: Role;
  onChange: (role: Role) => void;
  disabled: boolean;
  isFixed?: boolean;
  parentRef?: RefObject<HTMLUListElement | null>;
}> = ({ selectedRole, onChange, disabled, isFixed = false, parentRef }) => {
  const selectedRoleItem: RoleItem = {
    role: selectedRole,
    label: rolesDictionary.find(i => i.role === selectedRole)?.label || 'ERROR',
  };
  const selectRef = useRef<HTMLButtonElement>(null);
  const [topSelectBounding, setTopSelectBounding] = useState<DOMRect | null>(null);

  useEffect(() => {
    function listener() {
      if (selectRef.current) {
        setTopSelectBounding(selectRef.current.getBoundingClientRect());
      }
    }

    if (isFixed) {
      parentRef?.current?.addEventListener('scroll', listener);
      const parentRefNode = parentRef?.current;

      return () => {
        parentRefNode?.removeEventListener('scroll', listener);
      };
    }
  }, [isFixed, parentRef]);

  const getOptionListStyle = () => {
    if (topSelectBounding) {
      const { width, bottom } = topSelectBounding;
      if (parentRef?.current) {
        return {
          top: bottom - parentRef.current.getBoundingClientRect().top + 30,
          right: '3rem',
          width: width,
        };
      }
    }
  };

  return (
    <Listbox value={selectedRoleItem.role} disabled={disabled} onChange={onChange}>
      {({ open }) => (
        <div className='mx-2 relative'>
          <Listbox.Button
            ref={selectRef}
            className={`box-border justify-between relative flex items-center rounded border
              transition-colors duration-300 h-10 ease-in-out w-32 px-4
              ${open ? 'border-primary-800' : 'border-neutrals-200'}
              ${disabled ? 'bg-neutrals-100 text-neutrals-500 cursor-auto' : 'text-neutrals-1000'}`}
          >
            <span className='text-s'>{selectedRoleItem.label}</span>
            <Icon
              glyph={IconMap.ArrowSmallBottom}
              className={`${
                open ? 'fill-primary-800 rotate-180 transform' : 'fill-neutrals-400'
              } transition-all duration-500`}
            />
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options
              style={isFixed ? getOptionListStyle() : undefined}
              className={`${isFixed ? 'fixed' : 'absolute'} z-20 overflow-auto border rounded  w-fit-content
              border-neutrals-200 text-s bg-neutrals-0 focus:outline-none`}
            >
              {allowedRoles.map(({ role, label }) => {
                return (
                  <Listbox.Option
                    key={role}
                    className={({ active }) =>
                      `${active ? 'bg-neutrals-100' : 'bg-neutrals-0'}
                            select-none relative py-2.5 px-4 whitespace-nowrap cursor-pointer`
                    }
                    value={role}
                  >
                    {label}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export { RoleItem, rolesDictionary, allowedRoles };
