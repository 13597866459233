import { call, put } from 'redux-saga/effects';

import { createNotification, snackbarModel } from '@features/snackbar';
import { setAccess } from '@shared/api/cloud-functions';
import { logger } from '@shared/lib';

import { actions } from '../reducer';
import MixpanelService from '@features/mixpanel';

export function* setAccessSaga(action: ReturnType<typeof actions.setAccessClick>) {
  const { email, bookId, role, cb } = action.payload;
  try {
    if (role) {
      MixpanelService.trackSharedAccess(role);
    } else {
      MixpanelService.trackRevokedAccess();
    }

    yield call(setAccess, email, bookId, role);
  } catch (e: unknown) {
    logger.error(e);
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', 'Can not set access')));
  } finally {
    cb && cb();
  }
}
