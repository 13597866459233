import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeleteBookAction } from './types';

type State = {
  deletedBooks: string[];
  modalIsOpen: boolean;
  deletedBookId?: string;
};
const initialState: State = {
  deletedBooks: [],
  modalIsOpen: false,
  deletedBookId: undefined,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'bookDeletion',
  initialState,
  reducers: {
    addDeletedBook: (state, { payload: bookId }: PayloadAction<string>) => ({
      ...state,
      deletedBooks: [...state.deletedBooks, bookId],
    }),
    removeDeletedBook: (state, { payload: bookId }: PayloadAction<string>) => ({
      ...state,
      deletedBooks: state.deletedBooks.filter(i => i !== bookId),
    }),
    setModalIsOpen: (state, { payload: modalIsOpen }: PayloadAction<boolean>) => ({
      ...state,
      modalIsOpen,
    }),
    setDeletedBookId: (state, { payload: deletedBookId }: PayloadAction<string | undefined>) => ({
      ...state,
      deletedBookId,
    }),
  },
});
export const actions = {
  ...reducerActions,
  deleteBookClick: createAction<DeleteBookAction>('bookDeletion/deleteBookClick'),
  startBookDeletion: createAction<{ bookId: string }>('bookDeletion/start'),
  endBookDeletion: createAction('bookDeletion/end'),
};
