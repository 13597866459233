import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationType } from '@shared/ui';

export type NotificationItem = {
  id: number;
  type: NotificationType;
  message: string;
};

type State = { notifications: NotificationItem[] };
const initialState: State = {
  notifications: [],
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setNotifications: (state, { payload: notifications }: PayloadAction<NotificationItem[]>) => ({
      ...state,
      notifications,
    }),
  },
});
export const actions = {
  ...reducerActions,
  removeNotificationAction: createAction<number>('snackbar/removeNotificationAction'),
  addNotificationAction: createAction<NotificationItem>('snackbar/addNotificationAction'),
};
