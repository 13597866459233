import { call, put, select } from 'redux-saga/effects';

import { appDataModel } from '@entities/app-data';
import { redirect } from '@entities/history';
import { matchParams } from '@shared/lib';
import { Doc } from '@shared/types';

export function* appendixPageWorker(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);

  const appendix: Doc | undefined = yield select(appDataModel.selectors.findAppendix(params?.docId));

  if (!appendix) {
    yield call(redirect.toBooks);
    return;
  }

  yield put(appDataModel.actions.setSelectedSidebarItem({ type: 'appendix', docId: appendix.id }));
}
