import { BOOKS_ROUTE, SUBSCRIPTION_ROUTE } from '@entities/history';
import { createNotification, snackbarModel } from '@features/snackbar';
import { createStripeCheckoutSession, createStripePortalLink } from '@shared/api/cloud-functions';
import { CloudFunctionResponse } from '@shared/api/cloud-functions/types';
import { dictionary, publicAppUrl } from '@shared/config';
import { logger } from '@shared/lib';
import { call, put, takeEvery } from 'redux-saga/effects';
import { actions, reducer } from './reducer';
import * as selectors from './selectors';

function* stripeBaseSaga(worker: () => void) {
  try {
    yield put(actions.setIsStripeLoading(true));
    yield call(worker);
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.default)));
    logger.error(e);
  } finally {
    yield put(actions.setIsStripeLoading(false));
  }
}

function* stripeWorker() {
  yield takeEvery(actions.createPortalLink, function* () {
    yield call(stripeBaseSaga, function* () {
      const res: CloudFunctionResponse<string> = yield call(createStripePortalLink, {
        returnUrl: publicAppUrl + SUBSCRIPTION_ROUTE,
      });
      window.open(res.data, '_parent');
    });
  });

  yield takeEvery(actions.createCheckoutSession, function* (action) {
    yield call(stripeBaseSaga, function* () {
      const res: CloudFunctionResponse<string> = yield call(createStripeCheckoutSession, {
        cancel_url: publicAppUrl + SUBSCRIPTION_ROUTE,
        success_url: action.payload.successUrl || publicAppUrl + BOOKS_ROUTE,
        planConfig: action.payload,
      });
      window.open(res.data, '_parent');
    });
  });
}

export const stripeModel = { actions, stripeWorker, reducer, selectors };
