import { appDataModel } from '@entities/app-data';
import { getDate } from '@shared/lib';
import { PlanName } from 'blooksy-backend';
import { Button } from '@shared/ui';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stripeModel } from '..';
import { plansDictionary } from '../config';

import { actions } from '../model/reducer';
import { SubscriptionInfo } from '@shared/types';

const ActiveSubscription: FC<{ subscriptionInfo: SubscriptionInfo; planName: PlanName; isLoading: boolean }> = ({
  subscriptionInfo,
  planName,
  isLoading,
}) => {
  const { plan, created } = subscriptionInfo;
  const dispatch = useDispatch();

  return (
    <div className='flex items-center min-h-10 justify-between gap-6.5 mb-6 md:flex-col md:items-start md:gap-4'>
      <p className='text-s'>
        {subscriptionInfo.plan.interval === 'lifetime' ? (
          <>
            You are using the <strong>{plansDictionary.planNames[planName]}</strong> plan. Your access is lifetime, with
            no further renewals.
          </>
        ) : (
          <>
            You are using the <strong>{plansDictionary.planNames[planName]}</strong> plan. Your plan renews{' '}
            <strong>{plansDictionary.renews[plan.interval]}</strong> on
            <strong> {getDate(created)}</strong> for $<strong>{plansDictionary.price[plan.interval][planName]}</strong>.
          </>
        )}
      </p>
      {subscriptionInfo.plan.interval !== 'lifetime' && (
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={() => dispatch(actions.createPortalLink())}
          variant='text'
          color='secondary'
        >
          Manage Subscription
        </Button>
      )}
    </div>
  );
};

const UnActiveSubscription: FC = () => {
  return (
    <div className='flex items-center min-h-10 justify-between gap-6.5 mb-6 md:flex-col md:items-start md:gap-4'>
      <p className='text-s'>Activate your subscription to start writing your book.</p>
    </div>
  );
};

const IncompleteSubscription: FC<{ planName: PlanName; isLoading: boolean }> = ({ planName, isLoading }) => {
  const dispatch = useDispatch();
  return (
    <div className='flex items-center justify-between gap-6.5 mb-6 md:flex-col md:items-start md:gap-4'>
      <p className='text-s text-warning-700'>
        We were unable to process payment for the <strong>{plansDictionary.planNames[planName]}</strong> subscription.
        Update the payment method, or contact the bank to determine why payment is failing.
      </p>

      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={() => dispatch(actions.createPortalLink())}
        variant='text'
        color='primary'
      >
        Update Payment Method
      </Button>
    </div>
  );
};

const ExpiredSubscription: FC = () => {
  return (
    <div className='flex items-center min-h-10 justify-between gap-6.5 mb-6 md:flex-col md:items-start md:gap-4'>
      <p className='text-s text-error-700'>Your subscription has expired.</p>
    </div>
  );
};

const CanceledSubscription: FC<{ planName: PlanName; canceledAt: number; isLoading: boolean }> = ({
  planName,
  canceledAt,
  isLoading,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='flex items-center justify-between gap-6.5 mb-6 md:flex-col md:items-start md:gap-4'>
      <p className='text-s text-warning-700'>
        Your <strong>{plansDictionary.planNames[planName]}</strong> subscription will expire on {getDate(canceledAt)},
        and you will no longer have access to Blooksy.
      </p>

      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={() => dispatch(actions.createPortalLink())}
        variant='text'
        color='primary'
      >
        Renew Subscription
      </Button>
    </div>
  );
};

export const SubscriptionInfoLine: FC = () => {
  const { subscriptionInfo, role } = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const isLoading = useSelector(stripeModel.selectors.selectIsLoading);
  if (!subscriptionInfo || !role) {
    return <UnActiveSubscription />;
  }
  if (subscriptionInfo.cancelAtPeriodEnd && subscriptionInfo?.cancelAt) {
    return <CanceledSubscription isLoading={isLoading} planName={role} canceledAt={subscriptionInfo?.cancelAt} />;
  }
  switch (subscriptionInfo.status) {
    case 'active':
    case 'trialing': {
      return <ActiveSubscription isLoading={isLoading} subscriptionInfo={subscriptionInfo} planName={role} />;
    }
    case 'incomplete':
    case 'past_due': {
      return <IncompleteSubscription isLoading={isLoading} planName={role} />;
    }
    case 'incomplete_expired':
    case 'canceled':
    case 'unpaid': {
      return <ExpiredSubscription />;
    }
  }
};
