import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';
import { redirectActions } from '@entities/history';
import { bookDeletionModel, DeleteBookModal } from '@features/book-deletion';

import { logger } from '@shared/lib';
import { hasBookAccess } from '@shared/lib/permissions';
import { UserBook } from '@shared/types';
import { Button, Tabs } from '@shared/ui';

import useWindowWidth from '../../../shared/hooks/useWindowWidth';

import { BooksEmpty } from './books-empty';
import { Book } from './book';
import { SortBox } from './sort-box';
import { sortMethods, Tab } from '../config';
import { ExportModal } from '@features/book-export';
import { BooksStripeInfoLine } from '@features/subscriptions';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';

export const Books = () => {
  const dispatch = useDispatch();
  const books = useSelector(appDataModel.selectors.selectBooksWithError);
  const firestoreUser = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const deletedBooks = useSelector(bookDeletionModel.selectors.selectDeletedBooks);

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: 1,
        name: 'my-books',
        title: 'My Books',
        bookList: books.filter(book => book._role === 'owner' && !deletedBooks.includes(book.id)),
      },
      {
        id: 2,
        name: 'shared-books',
        title: 'Books Shared with Me',
        bookList: books.filter(book => book._role !== 'owner' && !deletedBooks.includes(book.id)),
      },
    ],
    [books, deletedBooks]
  );

  const { isMobile } = useWindowWidth();

  const [sortMethod, setSortMethod] = useState(sortMethods[4]);

  const [selectedTab, setSelectedTab] = useState<Tab>(
    tabs[0].bookList.length || !tabs[1].bookList.length ? tabs[0] : tabs[1]
  );

  const [visibleBooks, setVisibleBooks] = useState(selectedTab.bookList);

  useEffect(() => {
    setVisibleBooks(tabs.find(i => i.name === selectedTab.name)?.bookList || tabs[0].bookList);
  }, [selectedTab, books, tabs, deletedBooks]);

  const handleSelectBookClick = (book: UserBook) => {
    if (hasBookAccess(firestoreUser) || book._role !== 'owner') {
      dispatch(redirectActions.toBookClick({ bookId: book.id }));
    }
  };

  const sortedBooks = useMemo(() => {
    switch (sortMethod.name) {
      case 'alpha':
        return [...visibleBooks].sort((a, b) => a._title.toLowerCase().localeCompare(b._title.toLowerCase()));
      case 'alpha-reverse':
        return [...visibleBooks].sort((a, b) => b._title.toLowerCase().localeCompare(a._title.toLowerCase()));
      case 'created':
        return [...visibleBooks].sort((a, b) => b._createdAt - a._createdAt);
      case 'created-reverse':
        return [...visibleBooks].sort((a, b) => a._createdAt - b._createdAt);
      case 'last-edited':
        return [...visibleBooks].sort((a, b) => b._updatedAt - a._updatedAt);
      case 'last-edited-reverse':
        return [...visibleBooks].sort((a, b) => a._updatedAt - b._updatedAt);
      default:
        logger.error('Unknown sort method');
        return [];
    }
  }, [sortMethod, visibleBooks]);

  return (
    <>
      <Helmet title='Books' />
      <div className='px-10'>
        <BooksStripeInfoLine booksLength={tabs[0].bookList.length} />
      </div>
      <div className='grid grid-cols-12 px-10 md:grid-cols-8 md:px-6 sm:grid-cols-4 sm:px-5 gap-x-4'>
        <div className='col-start-1 col-end-13 md:col-end-9 sm:col-end-5'>
          <div className='flex items-center justify-between mt-6 mb-2'>
            <h2 className='mr-4 h-10 flex items-center font-semibold text-l text-neutrals-1000'>Books</h2>
            {!!sortedBooks.length && (
              <div className='flex'>
                <SortBox sortMethod={sortMethod} onChange={setSortMethod} />
                {selectedTab.name === 'my-books' && (
                  <Button
                    variant={isMobile ? 'icon' : 'icon-text'}
                    icon={<Icon glyph={IconMap.Plus} />}
                    color='primary'
                    disabled={!hasBookAccess(firestoreUser)}
                    type='button'
                    onClick={() => {
                      dispatch(redirectActions.toNewBookClick());
                    }}
                  >
                    Create Book
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='px-10 md:px-6 sm:px-5 flex-grow flex flex-col'>
        <Tabs
          tabs={tabs}
          initialTab={selectedTab}
          onSelectTab={(tab: Tab) => {
            setSelectedTab(tab);
          }}
        >
          <>
            {!sortedBooks.length ? (
              <BooksEmpty hasAccess={hasBookAccess(firestoreUser)} selectedTab={selectedTab} />
            ) : (
              <div className='grid grid-cols-6 md:grid-cols-4 sm:grid-cols-2 gap-x-4 gap-y-7'>
                {sortedBooks.map((book: UserBook) => (
                  <Book
                    key={book.id}
                    book={book}
                    selectedTabName={selectedTab.name}
                    hasAccess={hasBookAccess(firestoreUser)}
                    handleBookClick={handleSelectBookClick}
                  />
                ))}
              </div>
            )}
          </>
        </Tabs>
      </div>
      <ExportModal />
      <DeleteBookModal />
    </>
  );
};

export default Books;
