import { appDataModel } from '@entities/app-data';

import { redirectActions } from '@entities/history';

import React, { FC, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { DeletingItemType } from '../../config';
import { HandleAppendicesDragEndConfig, handleAppendicesDragEndEvent, isSelectedItem } from '../../lib';
import { EditModeOneLevelListItem } from '../BookContentList/EditModeOneLevelListItem';
import { OneLevelListItem } from '../BookContentList/OneLevelListItem';
import { DeleteModal } from '../DeleteModal';

type Props = {
  isEditMode: boolean;
  onClose: () => void;
};

export const AppendixBookOutline: FC<Props> = ({ isEditMode, onClose }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const appendices = useSelector(appDataModel.selectors.selectedAppendicesWithError);
  const bookStructure = useSelector(appDataModel.selectors.selectBookStructure);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);
  const [deletingAppendixId, setDeletingAppendixId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingItemType, setDeletingItemType] = useState<DeletingItemType>('appendix');

  const isSelectedSidebarItem = (docId: string) => isSelectedItem(docId, selectedSidebarItem);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
    const config: HandleAppendicesDragEndConfig = {
      book,
      dispatch,
      appendices,
      bookStructure,
    };

    if (type === 'appendices') {
      handleAppendicesDragEndEvent(result, config);
    } else {
      throw new Error('Unknown type');
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        deletingItemType={deletingItemType}
        deletingDocId={deletingAppendixId}
        confirmationNeeded={true}
      />
      <ul className='mb-4'>
        {isEditMode ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='appendices' type='appendices'>
              {appendicesProvided => (
                <ul className='mb-4' ref={appendicesProvided.innerRef} {...appendicesProvided.droppableProps}>
                  {appendices.map((page, index) => (
                    <EditModeOneLevelListItem
                      id={page.id}
                      key={page.id}
                      idx={index}
                      isActive={isSelectedSidebarItem(page.id)}
                      title={page.title || `Untitled Appendix`}
                      onDelete={() => {
                        setDeletingItemType('appendix');
                        setDeletingAppendixId(page.id);
                        setIsModalOpen(true);
                      }}
                      items={appendices}
                    />
                  ))}
                  {appendicesProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          appendices.map(({ id: docId, title }) => (
            <OneLevelListItem
              key={docId}
              isActive={isSelectedSidebarItem(docId)}
              label={title || `Untitled Appendix`}
              onClick={() => {
                onClose();
                dispatch(
                  redirectActions.toAppendixPageClick({
                    routePath: docId,
                    bookId: book.id,
                  })
                );
              }}
            />
          ))
        )}
      </ul>
    </>
  );
};
