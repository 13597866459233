import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '@features/snackbar';
import { dictionary, publicAppUrl } from '@shared/config';
import { logger } from '@shared/lib';

import { actions } from './reducer';
import { askAven, buyAvenCoins } from '@shared/api/cloud-functions';
import { CloudFunctionResponse } from '@shared/api/cloud-functions/types';
import { selectAvenResults } from './selectors';
import { Book, Doc, Folder, SelectedSidebarItem } from '@shared/types';
import { appDataModel } from '@entities/app-data';
import * as FirestoreService from '@shared/api/firestore';
import { CLOSE_WINDOW_ROUTE } from '@entities/history';
import { avenModel } from '.';
import { AskAvenResult, AskAvenResponse } from 'blooksy-backend';

function* askAvenSaga(action: ReturnType<typeof actions.askAvenClick>) {
  yield put(actions.setIsLoading(true));
  const { payload } = action;

  try {
    const [{ data }]: [CloudFunctionResponse<AskAvenResponse>] = yield all([call(askAven, payload), delay(1000)]);
    if (data.status === 'error') {
      yield put(snackbarModel.actions.addNotificationAction(createNotification('error', data.message)));
      return;
    }
    const currentResults: AskAvenResult[] = yield select(selectAvenResults);
    yield put(actions.setResults([...currentResults, ...data.items]));
  } catch (e: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.createTextWithAven))
    );
    logger.error(e);
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* getSelectedDocOrFolder() {
  const selectedSidebarItem: SelectedSidebarItem = yield select(appDataModel.selectors.selectedSideBarItemWithError);

  if (selectedSidebarItem.type === 'doc') {
    const doc: Doc | undefined = yield select(appDataModel.selectors.selectedDoc);
    if (!doc) {
      throw new Error('doc is undefined');
    }
    return doc;
  } else if (selectedSidebarItem.type === 'folder') {
    const folder: Folder | undefined = yield select(appDataModel.selectors.selectedFolder);
    if (!folder) {
      throw new Error('folder is undefined');
    }
    return folder;
  } else {
    throw new Error('Unknown selectedSidebarItem type');
  }
}

function* updateDocOrFolder(bookId: string, docOrFolder: Doc | Folder, updates: Partial<Doc | Folder>) {
  if (docOrFolder.__isDoc) {
    yield call(FirestoreService.updateDocData, bookId, docOrFolder.id, {
      ...docOrFolder,
      ...(updates as Partial<Doc>),
    });
  } else {
    yield call(FirestoreService.updateFolderData, bookId, docOrFolder.id, {
      ...docOrFolder,
      ...(updates as Partial<Folder>),
    });
  }
}

function* updateAvenResult(
  action: ReturnType<typeof actions.saveAvenResult | typeof actions.deleteAvenResult>,
  getUpdatedResult: (items: AskAvenResult[], changedItem: AskAvenResult) => AskAvenResult[]
) {
  const { item, cb } = action.payload;

  try {
    const book: Book = yield select(appDataModel.selectors.selectedBookWithError);
    const docOrFolder: Doc | Folder = yield call(getSelectedDocOrFolder);
    const currentSavedResults: AskAvenResult[] = yield select(avenModel.selectors.selectCurrentSavedResults);
    yield call(updateDocOrFolder, book.id, docOrFolder, {
      avenSavedResults: getUpdatedResult(docOrFolder.avenSavedResults, item),
    });
    yield put(actions.setCurrentSavedResults(getUpdatedResult(currentSavedResults, item)));

    cb?.();

    yield call(FirestoreService.updateBookData, book.id, book);
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.default)));
    logger.error(e);
  }
}

function* saveAvenResult(action: ReturnType<typeof actions.saveAvenResult>) {
  yield call(updateAvenResult, action, (avenSavedResults, item) => [...avenSavedResults, item]);
}

function* deleteAvenResult(action: ReturnType<typeof actions.deleteAvenResult>) {
  yield call(updateAvenResult, action, (avenSavedResults, item) => avenSavedResults.filter(i => i.id !== item.id));
}

function* buyAvenCoinsSaga(action: ReturnType<typeof actions.buyCoins>) {
  const { count, cb } = action.payload;
  try {
    yield put(actions.setIsCoinsLoading(true));
    const res: CloudFunctionResponse<string> = yield call(buyAvenCoins, {
      cancel_url: publicAppUrl + CLOSE_WINDOW_ROUTE,
      success_url: publicAppUrl + CLOSE_WINDOW_ROUTE,
      coinsCount: count,
    });
    window.open(res.data, '_blank');
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.default)));
    logger.error(e);
  } finally {
    yield put(actions.setIsCoinsLoading(false));
    cb?.();
  }
}

export function* avenWorker() {
  yield takeEvery(actions.askAvenClick, askAvenSaga);
  yield takeEvery(actions.buyCoins, buyAvenCoinsSaga);
  yield takeEvery(actions.saveAvenResult, saveAvenResult);
  yield takeEvery(actions.deleteAvenResult, deleteAvenResult);
}
