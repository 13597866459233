import React from 'react';

import { AuthLayout } from '@shared/ui';
import { SuperUserForm } from '@features/admin';

const SuperUser = () => {
  return (
    <>
      <AuthLayout>
        <div className='p-6 mx-auto my-6 rounded sm:rounded-none bg-neutrals-0 text-neutrals-1000'>
          <h2 className='mb-4 font-semibold text-sm'>Log In as Superuser</h2>
          <SuperUserForm />
        </div>
      </AuthLayout>
    </>
  );
};

export default SuperUser;
