import { UserBook } from '@shared/types';

export type SortMethod =
  | 'alpha'
  | 'alpha-reverse'
  | 'created'
  | 'created-reverse'
  | 'last-edited'
  | 'last-edited-reverse';
export type SortMethodItem = { name: SortMethod; title: string };
export const sortMethods: SortMethodItem[] = [
  {
    name: 'alpha',
    title: 'Alphabetical (A-Z)',
  },
  {
    name: 'alpha-reverse',
    title: 'Alphabetical (Z-A)',
  },
  {
    name: 'created',
    title: 'Date Created (Newest First)',
  },
  {
    name: 'created-reverse',
    title: 'Date Created (Oldest First)',
  },
  {
    name: 'last-edited',
    title: 'Last Edited (Newest First)',
  },
  {
    name: 'last-edited-reverse',
    title: 'Last Edited (Oldest First)',
  },
];

export type Tab = {
  id: number;
  name: string;
  title: string;
  bookList: UserBook[];
};
