import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appDataModel } from '../../../entities/app-data';
import { StripeStatus } from '../../../shared/types';
import { Button } from '../../../shared/ui';
import { stripeModel } from '../model';
import { SubscriptionMode, SubscriptionOption } from './stripePlans';

type Props = {
  options: SubscriptionOption[];
};

const expiredStatuses: StripeStatus[] = ['incomplete_expired', 'canceled', 'unpaid'];

export const SubscribePlanRow: FC<Props> = ({ options }) => {
  const user = useSelector(appDataModel.selectors.selectFirestoreUserDataWithError);
  const [loadingId, setLoadingId] = useState<SubscriptionMode>('lifetime');

  const isLoading = useSelector(stripeModel.selectors.selectIsLoading);
  const dispatch = useDispatch();

  const onSubscribe = (mode: SubscriptionMode) => {
    setLoadingId(mode);
    dispatch(
      stripeModel.actions.createCheckoutSession({
        name: 'wordsmith-yearly',
        interval: mode === 'lifetime' ? 'lifetime' : 'year',
      })
    );
  };

  const getButtonText = (mode: SubscriptionMode) => {
    if (mode === 'lifetime') {
      return 'Buy Now';
    }
    if (user.trialWasUsed) {
      return 'Activate Subscription';
    } else {
      return 'Start 7-Day Trial of $1';
    }
  };

  return (
    <div className='w-full flex items-center justify-center gap-4 sm:flex-col'>
      {options.map(({ title, description, id }) => (
        <div
          key={title}
          className='rounded flex w-full border border-neutrals-200 items-start
          justify-between pl-4 pt-2 pb-2 pr-2'
        >
          <div className=''>
            <p className='font-semibold text-sm'>{title}</p>
            <p className='text-sm text-neutrals-600'>{description}</p>
            {(!user.subscriptionInfo || expiredStatuses.includes(user.subscriptionInfo.status)) && (
              <Button
                disabled={isLoading}
                loading={isLoading && loadingId === id}
                onClick={() => onSubscribe(id)}
                variant='text'
                className='mt-2'
                color='primary'
              >
                {getButtonText(id)}
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
