import { call, put } from 'redux-saga/effects';

import { setUserData } from '@entities/auth-user';
import { updateProfile } from '@shared/api/cloud-functions';
import { getErrorMessage, logger } from '@shared/lib';

import { actions } from '../reducer';

export function* updateUserFirstAndLastName(updateNamesAction: ReturnType<typeof actions.updateNamesAction>) {
  const {
    payload: { firstName = '', lastName = '' },
  } = updateNamesAction;

  try {
    yield call(updateProfile, { firstName, lastName });
    yield call(setUserData);
  } catch (e: unknown) {
    yield put(actions.setUserNameError(getErrorMessage(e, 'Unknown error')));
    logger.error(e);
  }
}
