import { BookType, ExtraFields } from 'blooksy-backend';
import { apaReferenceExample } from './const';

export const dictionary = {
  primary: {
    preparingDocxExport: 'Preparing for download...',
    tooLongChapterName: 'Blooksy recommends keeping chapter names under 100 characters long.',
  },
  error: {
    createBook: 'Something went wrong. Book has not been created',
    saveBookDetails: 'Something went wrong. Book details have not been saved.',
    addDoc: 'Something went wrong. Doc has not been created',
    addAppendix: 'Something went wrong. Appendix has not been created',
    addFolder: 'Something went wrong. Folder has not been created',
    changeDocOrder: "Something went wrong. Can't change the order of docs",
    changeAppendixOrder: "Something went wrong. Can't change the order of appendices",
    changeFolderOrder: "Something went wrong. Can't change the order of folders",
    deleteDoc: 'Something went wrong. Doc has not been deleted',
    deleteAppendix: 'Something went wrong. Appendix has not been deleted',
    saveDocDetails: 'Something went wrong. Doc details have not been saved.',
    saveAppendixDetails: 'Something went wrong. Appendix details have not been saved.',
    saveFolderDetails: 'Something went wrong. Folder details have not been saved.',
    deleteBook: 'Something went wrong. Book has not been deleted',
    createTextWithAven: 'Something went wrong. Text has not been created',
    notEnoughCoins: "You don't have enough coins for this action",
    default: 'Something went wrong. Try again',
  },
  success: {
    createUsers: 'Users successfully created. We sent invite for them with credentials for login.',
    deleteBook: 'Book has been successfully deleted.',
    textCopied: 'Text has been copied.',
    makeSupportRequest: 'Message has been successfully sent. We will get back to you soon.',
  },
  placeholders: {
    copyright:
      'This page will contain your copyright information, which includes the book title, ' +
      'year of publication, the author or publisher, disclaimers, and ISBN number. ',
    docNotes: `Hint: to format your ideas in the notes,
      just select it and choose the option from the toolbar.`,
  },
} as const;

export const bookTypesDictionary: {
  [key in BookType]: {
    docName: string;
    folderName?: string;
  };
} = {
  'non-fiction': { docName: 'Chapter' },
  'poetry-book': { docName: 'Poem' },
  fiction: { docName: 'Scene', folderName: 'Chapter' },
  dissertation: { docName: 'Section' },
  grant: { docName: 'Chapter' },
  'cook-book': { docName: 'Chapter' },
  journal: { docName: 'Chapter' },
  syllabus: { docName: 'Chapter' },
  apa: { docName: 'Section' },
};

export const frontBackPageDictionary: {
  [K in ExtraFields]?: {
    title: string;
    placeholder: string;
    example?: string;
  };
} = {
  aboutAuthor: {
    title: 'About the Author',
    placeholder: `The purpose of the author is to build a relationship with readers.
      You can craft an interesting synopsis,
      including who you are, what you write, why you decided to write this book,
      and the reason you think this book needed to be written.
      You can also share how readers can follow you on social media and other channels.`,
  },
  acknowledgements: {
    title: 'Acknowledgements',
    placeholder: `The acknowledgments focus is thanking and bringing attention to the person
      or people who helped bring the book to life.`,
  },
  dedication: {
    title: 'Dedication',
    placeholder: `
    The dedication lists who you are dedicating your book to and why.
    The dedication page is typically for people you love or care about.
    You can keep this page simple, without elaborating on the reasons for your dedication.
    But it is primarily meant to pull at the readers’ heartstrings, make them laugh, or make them smile. \n
    Many authors have written as little as, “For the woman I love the most in this world, my Mom.”`,
  },
  foreword: {
    title: 'Foreword',
    placeholder: `A foreword is usually written by someone other than the author.
      It introduces the writer and tells the readers why they should trust the author’s opinion, buy and read the book.
      It also serves as an endorsement.`,
  },
  introduction: {
    title: 'Introduction',
    placeholder: `
      In the introduction, you should think about the details someone will need to know to be hooked on your book.
      What will pique, and keep them interested in reading?
      The length of an introduction will vary from topic to topic, so don’t worry about the length.
      Some topics require more background information and context to hook a reader,
      especially if the value of reading the book is not immediately clear.
      Others will need less, especially if the book is about something wildly popular or unusual.
      So, you don’t need to worry too much about meeting an exact length or word count as you dive into
      how to write a book introduction.`,
  },
  preface: {
    title: 'Preface',
    placeholder: `
      The preface is meant to share the author’s experience writing the book, the inspiration behind the subject matter,
      the writing process, the purpose of the story, and historical context for the material.`,
  },
} as const;

export const apaFrontBackPageDictionary: {
  [K in ExtraFields]?: {
    title: string;
    placeholder?: string;
    example?: string;
  };
} = {
  approvedBy: {
    title: 'Approved By',
  },
  dedication: {
    title: 'Dedication',
    placeholder: `
   Write a short paragraph dedicating your work to someone special, such as family, friends,
   or mentors who have provided emotional support. Express genuine gratitude and appreciation.
   Mention how the dedicatee inspired or motivated your work.`,
  },
  acknowledgements: {
    title: 'Acknowledgements',
    placeholder: `Thank those who contributed academically or professionally, such as advisors,
    professors, and colleagues. Highlight specific help or support, like data collection, feedback,
    or financial assistance. Start with the most significant contributions and proceed in a logical order.`,
  },
  abstract: {
    title: 'Abstract',
    placeholder: `
      Write a comprehensive summary of your paper in which you briefly address the research problem, hypotheses,
      methods, results, and implications of your research.`,
  },
  references: {
    title: 'References',
    example: apaReferenceExample,
  },
} as const;
