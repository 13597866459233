import { call, put, take, takeEvery } from 'redux-saga/effects';

import { ChangeBooksLengthAction, changeBooksLengthChan } from '@entities/books-length-channel';
import { redirect } from '@entities/history';
import { createNotification, snackbarModel } from '@features/snackbar';
import * as FirestoreService from '@shared/api/firestore';
import { dictionary } from '@shared/config';
import { logger } from '@shared/lib';

import { actions } from '../reducer';

import MixpanelService from '@features/mixpanel';

function* deleteBook(deleteBookAction: ReturnType<typeof actions.deleteBookClick>) {
  const { bookId, cb } = deleteBookAction.payload;

  try {
    yield put(actions.addDeletedBook(bookId));
    if (cb) {
      yield call(cb);
    }

    yield call(redirect.toBooks);

    yield call(FirestoreService.deleteBook, bookId);

    MixpanelService.trackBookDeleted();

    yield put(
      snackbarModel.actions.addNotificationAction(createNotification('success', dictionary.success.deleteBook))
    );

    const action: ChangeBooksLengthAction | undefined = yield take(changeBooksLengthChan);

    if (!action) {
      return;
    }
    const { operation, ids } = action;
    if (operation === 'deleting' && !ids.includes(bookId)) {
      yield put(actions.removeDeletedBook(bookId));
    }
  } catch (e: unknown) {
    yield put(snackbarModel.actions.addNotificationAction(createNotification('error', dictionary.error.deleteBook)));
    yield put(actions.removeDeletedBook(bookId));
    logger.error(e);
  }
}
export function* deleteBookWatcher() {
  yield takeEvery(actions.deleteBookClick, deleteBook);
  yield takeEvery(actions.startBookDeletion, function* (action) {
    yield put(actions.setModalIsOpen(true));
    yield put(actions.setDeletedBookId(action.payload.bookId));
  });
  yield takeEvery(actions.endBookDeletion, function* () {
    yield put(actions.setModalIsOpen(false));
    yield put(actions.setDeletedBookId(undefined));
  });
}
