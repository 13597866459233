import { Button, Dropdown } from '@shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { docModel } from '@entities/doc';
import { appDataModel } from '@entities/app-data';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { folderModel } from '@entities/folder';
import { bookTypesDictionary } from '@shared/config';
import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { AddButton } from './AddButton';

export const AddDefaultStructureItem = () => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const docs = useSelector(appDataModel.selectors.selectedDocsWithError);
  const folders = useSelector(appDataModel.selectors.selectedFolders);
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);
  const getCurrentFolderId = () => {
    if (!folders) {
      return;
    }
    if (selectedSidebarItem.type === 'folder') {
      return folders.find(i => i.id === selectedSidebarItem.docId)?.id;
    } else if (selectedSidebarItem.type === 'doc') {
      return docs.find(i => i.id === selectedSidebarItem.docId)?.folderId;
    }

    return folders[folders.length - 1].id;
  };

  return book.type === 'fiction' && folders ? (
    <Dropdown
      triggerComponent={
        <Trigger>
          <AddButton>Add</AddButton>
        </Trigger>
      }
      items={[
        {
          label: (
            <span className='flex items-center whitespace-nowrap text-s p-0'>
              <Icon glyph={IconMap.Plus} className='mr-3 max-w-none text-primary-800' />
              Add {bookTypesDictionary[book.type].docName}
            </span>
          ),
          onClick: () =>
            dispatch(
              docModel.actions.addDocClick({
                title: '',
                order: docs.map(doc => doc.order).reduce((a, b) => Math.max(a, b), 0) + 1,
                bookId: book.id,
                folderId: getCurrentFolderId(),
              })
            ),
        },
        {
          label: (
            <span className='flex items-center whitespace-nowrap text-s p-0'>
              <Icon glyph={IconMap.Plus} className='mr-3 max-w-none text-primary-800' />
              Add {bookTypesDictionary[book.type].folderName}
            </span>
          ),
          onClick: () =>
            dispatch(
              folderModel.actions.addFolderClick({
                title: '',
                order: folders.map(folder => folder.order).reduce((a, b) => Math.max(a, b), 0) + 1,
                bookId: book.id,
              })
            ),
        },
      ]}
    />
  ) : (
    <Button
      variant='icon-text'
      color='tertiary'
      type='button'
      icon={<Icon glyph={IconMap.Plus} className='fill-primary-800' />}
      onClick={() => {
        dispatch(
          docModel.actions.addDocClick({
            title: '',
            order: docs.map(doc => doc.order).reduce((a, b) => Math.max(a, b), 0) + 1,
            bookId: book.id,
          })
        );
      }}
    >
      Add
    </Button>
  );
};
