import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExportBookAction } from './types';

type State = {
  exportIsLoading: boolean;
  isOpenExportModal: boolean;
  exportedBookId?: string;
};
const initialState: State = {
  exportIsLoading: false,
  isOpenExportModal: false,
  exportedBookId: undefined,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'export',
  initialState,
  reducers: {
    setExportIsLoading: (state, { payload: exportIsLoading }: PayloadAction<boolean>) => ({
      ...state,
      exportIsLoading,
    }),
    setIsOpenExportModal: (state, { payload: isOpenExportModal }: PayloadAction<boolean>) => ({
      ...state,
      isOpenExportModal,
    }),
    setExportedBookId: (state, { payload: exportedBookId }: PayloadAction<string | undefined>) => ({
      ...state,
      exportedBookId,
    }),
  },
});
export const actions = {
  ...reducerActions,
  exportBook: createAction<ExportBookAction>('export/exportBook'),
  startBookExport: createAction<{ bookId: string }>('export/start'),
  endBookExport: createAction('export/end'),
};
