import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestSupportParams } from 'blooksy-backend';

type State = {
  isRequestSending: boolean;
  error: string;
};
const initialState: State = {
  isRequestSending: false,
  error: '',
};
export const { reducer, actions: reducerActions } = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setIsRequestSending: (state, { payload: isRequestSending }: PayloadAction<boolean>) => ({
      ...state,
      isRequestSending,
    }),
    setError: (state, { payload: error }: PayloadAction<string>) => ({ ...state, error }),
  },
});
export const actions = {
  ...reducerActions,
  sendRequest: createAction<RequestSupportParams & { cb: () => void }>('support/sendRequest'),
  uploadPhoto: createAction<{ file: File; cb: (path: string) => void }>('support/uploadPhoto'),
  deletePhoto: createAction<string | undefined>('support/deletePhoto'),
};
