import { appDataModel } from '@entities/app-data';
import { docModel } from '@entities/doc';
import { folderModel } from '@entities/folder';
import { bookTypesDictionary } from '@shared/config';
import { Modal, Input } from '@shared/ui';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeletingItemType } from '../config';
import { isAppendix, isDoc, isFolder } from '../lib';
import { appendixModel } from '@entities/appendix';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deletingItemType: DeletingItemType;
  deletingDocId: string;
  confirmationNeeded?: boolean;
};
export const DeleteModal: FC<Props> = ({ isOpen, onClose, deletingItemType, deletingDocId, confirmationNeeded }) => {
  const dispatch = useDispatch();
  const book = useSelector(appDataModel.selectors.selectedBookWithError);

  const currentItemName = isDoc(deletingItemType)
    ? bookTypesDictionary[book.type].docName
    : isAppendix(deletingItemType)
    ? 'appendix'
    : bookTypesDictionary[book.type].folderName || '';

  const expectedConfirmation = `delete ${currentItemName.toLowerCase()}`;

  const deletingValidationSchema = yup.object({
    confirmation: yup.string().trim().lowercase().oneOf([expectedConfirmation]),
  });

  const { handleSubmit, reset, clearErrors, control } = useForm<{ confirmation?: string }>({
    resolver: yupResolver(deletingValidationSchema),
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
    defaultValues: { confirmation: '' },
  });

  const onItemDelete = () => {
    const payload = { bookId: book.id, id: deletingDocId };

    if (isDoc(deletingItemType)) {
      dispatch(docModel.actions.deleteDocClick(payload));
    } else if (isAppendix(deletingItemType)) {
      dispatch(appendixModel.actions.deleteAppendixClick(payload));
    } else if (isFolder(deletingItemType)) {
      dispatch(folderModel.actions.deleteFolderClick(payload));
    } else {
      throw new Error('Unknown deletingItemType');
    }

    handleCancel();
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  const onConfirmClick = confirmationNeeded ? handleSubmit(onItemDelete) : onItemDelete;

  return (
    <Modal
      opened={isOpen}
      title={`Delete ${currentItemName}`}
      description={`Are you sure you want to delete the ${currentItemName.toLowerCase()}?
        You will delete the ${currentItemName.toLowerCase()} permanently, and you cannot undo it.`}
      leftButtonVariant='text'
      leftButtonText='Cancel'
      rightButtonVariant='text'
      rightButtonColor='destructive'
      rightButtonText={`Delete`}
      onConfirmClick={onConfirmClick}
      onCancelClick={handleCancel}
    >
      {confirmationNeeded ? (
        <div className='mt-1.5 -mb-2.5'>
          <Input
            control={control}
            name='confirmation'
            type='text'
            disableAutoComplete
            label={`Type “delete ${currentItemName.toLowerCase()}” to confirm`}
            messageText=''
            onChange={() => clearErrors('confirmation')}
          />
        </div>
      ) : undefined}
    </Modal>
  );
};
