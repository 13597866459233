import { takeEvery } from 'redux-saga/effects';

import { snackbarModel } from '..';

import { addNotification } from './addNotification';
import { removeNotification } from './removeNotification';

export function* saga() {
  yield takeEvery(snackbarModel.actions.addNotificationAction, addNotification);
  yield takeEvery(snackbarModel.actions.removeNotificationAction, removeNotification);
}
