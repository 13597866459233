import { EMAIL_SCHEMA } from '@shared/config';
import { Role } from 'blooksy-backend';
import * as yup from 'yup';

export const accessLevelDictionary: { [key in Role]: string } = {
  owner: '',
  writer: 'Editor Access',
  reader: 'Read Only Access',
  commenter: 'Commenter Access',
};

export const shareAccessValidationSchema = yup.object({
  email: EMAIL_SCHEMA,
});
export type ShareAccessRequestData = yup.InferType<typeof shareAccessValidationSchema>;
