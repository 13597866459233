import { avenModel, coinsPlans } from '@features/Aven-AI';
import { Modal, RadioButton } from '@shared/ui';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MixpanelService from '@features/mixpanel';

type Props = {
  opened: boolean;
  setOpened: (value: boolean) => void;
};

export const BuyCreditModal: FC<Props> = ({ opened, setOpened }) => {
  const [checkedId, setCheckedId] = useState<number>(3);

  const isCoinsLoading = useSelector(avenModel.selectors.selectIsCoinsLoading);

  const dispatch = useDispatch();

  const onClose = () => setOpened(false);

  const onCoinsBuy = () => {
    const selectedPlan = coinsPlans.find(plan => plan.id === checkedId);
    if (!selectedPlan) {
      throw new Error('Cannot find selected plan');
    }
    dispatch(avenModel.actions.buyCoins({ count: selectedPlan.value, cb: onClose }));
    MixpanelService.trackCoinsPurchased(selectedPlan.value);
  };

  return (
    <Modal
      opened={opened}
      title='Buy Uses'
      leftButtonVariant='text'
      leftButtonText='Cancel'
      rightButtonText='Buy'
      rightButtonVariant='text'
      rightButtonColor='primary'
      isLoading={isCoinsLoading}
      classNameBody='w-120'
      onCancelClick={onClose}
      onConfirmClick={onCoinsBuy}
    >
      <div className='-my-4.5'>
        <p className='text-s text-neutrals-1000 mb-4'>
          Hire Aven, the artificial intelligence writing assistant, to write content based on your ideas. Once she
          creates it, you can save it, then customize it to fit your writing style.
        </p>
        <ul className='pl-1 space-y-1.5'>
          {coinsPlans.map(i => (
            <li key={i.id} className='flex flex-col'>
              <div className='flex items-center justify-between gap-x-4'>
                <RadioButton
                  name='coinsCount'
                  value={i.value.toFixed()}
                  onChange={() => {
                    setCheckedId(i.id);
                  }}
                  label={i.label}
                  defaultChecked={i.id === checkedId}
                />
                <p className='font-semibold text-s'>${i.price}</p>
              </div>
              <p className='text-xs text-neutrals-500 ml-7'>{i.text}</p>
            </li>
          ))}
        </ul>
        <p className='text-xs text-neutrals-1000 mt-4'>
          Aven will write a minimum of three paragraphs per use. <br />
          It may take a few minutes for new coins to appear on your balance.
        </p>
      </div>
    </Modal>
  );
};
