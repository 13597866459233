import React, { useEffect, useState } from 'react';
import { appDataModel } from '@entities/app-data';
import { bookModel } from '@entities/book';
import { BookTypeSelect } from '@entities/book-type-select';
import { redirectActions } from '@entities/history';
import { BookMetaData, bookMetadataValidationSchema, bookTypeData } from '@features/book-creating';
import { Button, Input, UnsavedModal } from '@shared/ui';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmWindowClose } from '@shared/hooks/useConfirmWindowClose';
import '@shared/fonts/subsets/subsets-fonts.css';
import { BookStyleSelector } from './BookStyleSelector';
import { BookStyleName } from '../config';
import { ChangeCover } from './ChangeCover';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const EditBookPage = () => {
  const book = useSelector(appDataModel.selectors.selectedBookWithError);

  const [bookStyle, setBookStyle] = useState<BookStyleName>(book.settings.style);
  const [unsavedOpened, setUnsavedOpened] = useState(false);
  const [hasUnsaved, setHasUnsaved] = useState(false);

  const { control, handleSubmit, reset } = useForm<BookMetaData>({
    resolver: yupResolver(bookMetadataValidationSchema),
    defaultValues: {
      title: book.title,
      subtitle: book.subtitle,
      author: book.author,
    },
  });

  useEffect(() => {
    const { title, subtitle, author } = book;
    reset({ title, subtitle, author });
  }, [book, reset]);

  const dispatch = useDispatch();

  const handleChangeStyle = (value: BookStyleName) => {
    setBookStyle(value);
    setHasUnsaved(true);
  };

  const onClose = () => {
    dispatch(redirectActions.backFromBookEditClick());
  };

  const saveChanges = ({ title, subtitle = '', author = '' }: BookMetaData) => {
    dispatch(
      bookModel.actions.saveBookDetailsAction({
        bookId: book.id,
        book: { ...book, title, subtitle, author, settings: { ...book.settings, style: bookStyle } },
      })
    );
    onClose();
  };

  const bookType = bookTypeData.find(i => i.type === book.type);
  if (!bookType) {
    throw new Error('Unknown book type');
  }

  const onBack = () => {
    if (hasUnsaved) {
      setUnsavedOpened(true);
      return;
    }
    onClose();
  };

  useConfirmWindowClose(hasUnsaved);

  return (
    <>
      <Helmet title='Edit Book' />

      <div className='grid grid-cols-12 md:grid-cols-8 sm:grid-cols-4 gap-x-4'>
        <div
          className='col-start-3 col-end-11 md:col-start-1 md:col-end-9 sm:col-start-1
                     sm:col-end-5 pt-7 px-10 md:px-6 sm:px-5'
        >
          <div className='flex items-center justify-between mb-6'>
            <h2 className='font-semibold text-l text-neutrals-1000'>Edit Book</h2>
            <Button onClick={onBack} type='reset' color='secondary' variant='text'>
              Cancel
            </Button>
          </div>
          <form
            onSubmit={handleSubmit(saveChanges)}
            className='col-start-3 col-end-11 md:col-start-1 md:col-end-9
                       md:max-w-screen sm:col-start-1 sm:col-end-5 sm:w-full'
          >
            <div className='grid grid-cols-4 gap-x-4 sm:block'>
              <div className='col-start-1 col-end-3'>
                <div className='mb-4'>
                  <Input
                    control={control}
                    name='title'
                    type='text'
                    label='Title'
                    onChange={() => setHasUnsaved(true)}
                  />
                </div>
                <div className='mb-4'>
                  <Input
                    control={control}
                    name='subtitle'
                    type='text'
                    label='Subtitle'
                    onChange={() => setHasUnsaved(true)}
                  />
                </div>
                <div className='mb-4'>
                  <Input
                    control={control}
                    name='author'
                    type='text'
                    label='Author'
                    onChange={() => setHasUnsaved(true)}
                  />
                </div>
              </div>
              <div className='col-start-4 col-end-5'>
                <ChangeCover />
              </div>
              <div className='mb-6 col-start-1 col-end-2'>
                <p className='text-neutrals-500 text-s mb-1 sm:mt-4'>Book Type</p>
                <BookTypeSelect isUnavailable={false} isActive={false} type={bookType.name} icon={bookType.img} />
              </div>
              {book.type !== 'apa' && <BookStyleSelector onChange={handleChangeStyle} activeStyle={bookStyle} />}
            </div>
            <Button variant='text' color='primary' type='submit'>
              Save Book
            </Button>
          </form>
        </div>
      </div>

      <UnsavedModal title='Unsaved Changes' opened={unsavedOpened} setOpened={setUnsavedOpened} onConfirm={onClose}>
        You have unsaved changes. Are you sure you want to leave the page?
      </UnsavedModal>
    </>
  );
};
