import { Location } from 'history';
import { call, fork, takeEvery } from 'redux-saga/effects';

import { redirect, redirectActions } from '@entities/history';
import { clearEditModeData } from '@processes/routing';
import { bookRedirect } from '@processes/routing/lib';
import { Saga } from '@shared/types';
import { stripeModel } from '@features/subscriptions';

export function withMain(saga: Saga) {
  return function* (location: Location, route: string) {
    yield fork(stripeModel.stripeWorker);
    yield takeEvery(redirectActions.toSettingsClick, redirect.toSettings);
    yield takeEvery(redirectActions.toSubscriptionsClick, redirect.toSubscriptions);
    yield takeEvery(redirectActions.toBookClick, bookRedirect);
    yield takeEvery(redirectActions.toNewBookClick, redirect.toNewBookCreation);
    yield takeEvery(redirectActions.toBooksClick, redirect.toBooks);
    yield call(clearEditModeData);
    yield call(saga, location, route);
  };
}
