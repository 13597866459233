import { EMAIL_SCHEMA } from '@shared/config';
import * as yup from 'yup';

export const EmailTextarea = {
  placeholder:
    'Enter the email addresses of the users you would like to invite to Blooksy. ' +
    'You may either enter each email separated by a comma, or enter each email on a new line.',
  errorMessage:
    'To correctly input your email addresses, you may either enter each email ' +
    'separated by a comma, or enter each email on a new line. Please make sure to double-check that ' +
    'all email addresses are entered correctly.',
};

export const signInAsSuperuserValidationSchema = yup.object({
  email: EMAIL_SCHEMA,
});
export type SignInAsSuperuserRequestData = yup.InferType<typeof signInAsSuperuserValidationSchema>;
