import { Button } from '@shared/ui';
import React, { FC } from 'react';
import { AddStructureItem } from './AddStructureItem';

import { Icon } from '@shared/ui/icon';
import { IconMap } from '@shared/sprite';
import { useHeightCssVariable } from '@shared/hooks/useHeightCssVariable';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';

type Props = {
  isEditMode: boolean;
  onEditModeChange: (state: boolean) => void;
};
export const SidebarHeader: FC<Props> = ({ isEditMode, onEditModeChange }) => {
  const ref = useHeightCssVariable<HTMLDivElement>('--left-sidebar-menu-height');
  const isOwner = useSelector(appDataModel.selectors.selectIsBookOwnerWithError);

  if (!isOwner) {
    return <div ref={ref}></div>;
  }

  return (
    <div ref={ref} className='flex gap-4 items-center justify-start pl-1 py-3 h-11 border-b border-neutrals-200'>
      {isEditMode ? (
        <Button
          variant='icon-text'
          color='tertiary'
          type='button'
          icon={<Icon glyph={IconMap.ListCheckMark} />}
          onClick={() => onEditModeChange(false)}
        >
          Done
        </Button>
      ) : (
        <>
          <AddStructureItem />
          <Button
            variant='icon-text'
            color='tertiary'
            type='button'
            icon={<Icon glyph={IconMap.Edit} className='flex items-center justify-center' />}
            onClick={() => {
              onEditModeChange(true);
            }}
          >
            Edit
          </Button>
        </>
      )}
    </div>
  );
};
