import { call, put } from 'redux-saga/effects';

import { getUserFromFb, setUserData } from '@entities/auth-user';
import { reauthorizate } from '@features/profile-settings/lib';
import { createNotification, snackbarModel } from '@features/snackbar';
import { getErrorMessage } from '@shared/lib';
import { FirebaseUser } from '@shared/types';
import { verifyBeforeUpdateEmail } from 'firebase/auth';

import { actions } from '../reducer';

export function* updateUserEmail(updateUserEmailClick: ReturnType<typeof actions.updateUserEmailClick>) {
  const {
    payload: { email = '', password = '', cb },
  } = updateUserEmailClick;

  const fbUser: FirebaseUser = yield getUserFromFb();

  if (email === fbUser.email) {
    yield put(actions.setEmailError('Email already exists'));
  } else {
    yield put(actions.setPasswordForEmailError(''));
    yield put(actions.setEmailError(''));

    try {
      yield reauthorizate(password);
      try {
        yield verifyBeforeUpdateEmail(fbUser, email);
        yield put(
          snackbarModel.actions.addNotificationAction(
            createNotification('success', `Verification email has been sent to ${email}.`)
          )
        );
        yield call(setUserData);
        cb && cb();
      } catch (e: unknown) {
        yield put(actions.setEmailError(getErrorMessage(e, 'Invalid email')));
      }
    } catch (e: unknown) {
      yield put(actions.setPasswordForEmailError(getErrorMessage(e, 'Invalid password')));
    }
  }
}
