import { Dialog, Listbox, Transition } from '@headlessui/react';
import { Attachment } from 'blooksy-backend';

import { IconMap } from '@shared/sprite';
import { Button, Input } from '@shared/ui';
import { Icon } from '@shared/ui/icon';
import React, { FC, FormEvent, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { supportModel } from '../model';
import {
  RequestTypeConfigItem,
  RequestType,
  requestTypeConfig,
  SupportFormData,
  supportFormValidationSchema,
  MAX_UPLOAD_FILES_AMOUNT,
} from '../config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const emptyRequestTypeItem: RequestTypeConfigItem = { id: 0, label: '', value: '' };

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type FileItem = { file: File; path?: string };

export const SupportForm: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const { handleSubmit, reset, control } = useForm<SupportFormData>({
    resolver: yupResolver(supportFormValidationSchema),
    defaultValues: {
      subject: '',
      message: '',
    },
  });

  const [currentRequestType, setCurrentRequestType] = useState<RequestTypeConfigItem>(emptyRequestTypeItem);
  const [requestTypeError, setRequestTypeError] = useState<string | null>(null);
  const [files, setFiles] = useState<FileItem[]>([]);
  const [filesError, setFilesError] = useState<string | null>(null);

  const isRequestSending = useSelector(supportModel.selectors.selectIsRequestSending);

  const saveFiles = (f: FileItem[]) => {
    setFiles(f);

    f.forEach((i, idx) => {
      if (!i.path) {
        dispatch(
          supportModel.actions.uploadPhoto({
            file: i.file,
            cb: path => {
              setFiles(prevFiles => {
                const newFiles = prevFiles.slice();
                newFiles[idx].path = path;
                return newFiles;
              });
            },
          })
        );
      }
    });
  };

  const onFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;

    setFilesError(null);

    if (!newFiles) {
      return;
    }

    const filesArr = Array.from(newFiles).map(i => ({ file: i }));

    const allFilesAmount = files.length + filesArr.length;
    if (allFilesAmount > MAX_UPLOAD_FILES_AMOUNT) {
      setFilesError(`You can only upload up to ${MAX_UPLOAD_FILES_AMOUNT} files`);
    }

    if (filesArr.length > MAX_UPLOAD_FILES_AMOUNT) {
      saveFiles(filesArr.slice(0, MAX_UPLOAD_FILES_AMOUNT));
    } else {
      saveFiles([...files, ...filesArr].slice(-MAX_UPLOAD_FILES_AMOUNT));
    }
  };

  const onFileDelete = ({ file, path }: FileItem) => {
    setFilesError(null);
    setFiles(files.filter(f => f.file.name !== file.name));
    dispatch(supportModel.actions.deletePhoto(path));
  };

  const clearInputs = () => {
    setCurrentRequestType(emptyRequestTypeItem);
    reset();
    setFiles([]);
    setRequestTypeError(null);
    setFilesError(null);
  };

  const onCancelClick = () => {
    clearInputs();
    onClose();
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleAddAttachment = () => {
    hiddenFileInput.current?.click();
  };

  const sendRequest = ({ subject, message }: SupportFormData) => {
    if (currentRequestType.id === 0) {
      return;
    }

    const attachments: Attachment[] = [];
    if (files.length) {
      files.map(({ file, path }) => {
        if (path) {
          attachments.push({ name: file.name, type: file.type, url: path });
        }
      });
    }
    dispatch(
      supportModel.actions.sendRequest({
        requestType: currentRequestType.value as RequestType,
        subject,
        message,
        attachments,
        cb: onCancelClick,
      })
    );
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (currentRequestType.id === 0) {
      setRequestTypeError('Please select a request type.');
    }
    handleSubmit(sendRequest)();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onCancelClick} className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen'>
        <Dialog.Overlay className='fixed inset-0 bg-neutrals-1000 opacity-30' />

        <form
          onSubmit={onSubmit}
          className='relative flex flex-col rounded p-4 mx-auto my-12 bg-neutrals-0 w-170 h-fit-content'
        >
          <Dialog.Title className='mb-4 font-semibold text-m'>Contact Support</Dialog.Title>

          <div className='mb-4'>
            <p className='text-neutrals-500 text-s'>
              Request Type
              <span className='text-s text-error-500 ml-0.5'>*</span>
            </p>
            <Listbox
              value={currentRequestType.value}
              onChange={(value: RequestType) => {
                const requestType = requestTypeConfig.find(i => i.value === value);
                if (requestType) setRequestTypeError(null);
                setCurrentRequestType(requestType || emptyRequestTypeItem);
              }}
            >
              {({ open }) => (
                <div className='relative'>
                  <Listbox.Button
                    className={`
                        box-border justify-between relative flex items-center rounded border
                        transition-colors duration-300 h-10 ease-in-out w-full px-4
                        ${open ? 'border-primary-800' : 'border-neutrals-200'}
                        ${requestTypeError ? 'border-error-700' : ''}`}
                  >
                    <span className={`mr-6 text-s text-neutrals-1000`}>{currentRequestType.label}</span>
                    <Icon
                      glyph={IconMap.ArrowSmallBottom}
                      className={`${
                        open ? 'fill-primary-800 rotate-180 transform' : 'fill-neutrals-400'
                      } transition-all duration-500`}
                    />
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options
                      className='absolute right-0 z-10 min-w-full overflow-auto border rounded  w-fit-content
                          border-neutrals-200 text-s bg-neutrals-0 focus:outline-none'
                    >
                      {requestTypeConfig.map(i => (
                        <Listbox.Option
                          key={i.id}
                          className={({ active }) =>
                            `${active ? 'bg-neutrals-100' : 'bg-neutrals-0'}
                            select-none relative py-2.5 px-4 whitespace-nowrap cursor-pointer`
                          }
                          value={i.value}
                        >
                          {i.label}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
            {requestTypeError && <p className='text-error-700 text-xs'>{requestTypeError}</p>}
          </div>
          <div className='mb-4'>
            <Input control={control} name='subject' label='Subject' isMandatory />
          </div>
          <div className='mb-4'>
            <Input
              control={control}
              name='message'
              label='Message'
              isMandatory
              isTextArea
              maxRows={5}
              className='min-h-30'
            />
          </div>

          <div>
            <p className='text-neutrals-500 text-s mb-2'>Attachments (max. {MAX_UPLOAD_FILES_AMOUNT})</p>
            {files.map(f => {
              const { file, path } = f;
              const isLoading = !path;
              return (
                <div className='mb-2 flex items-center' key={file.name}>
                  <p className={`${isLoading ? 'text-neutrals-500' : ''} text-s`}>
                    {isLoading ? 'Uploading ' : ''}
                    {file.name}
                  </p>
                  <Button
                    variant='icon'
                    color='tertiary'
                    className='ml-2'
                    disabled={isLoading}
                    type='button'
                    icon={<Icon glyph={IconMap.Cross} className='fill-primary-800' />}
                    onClick={() => onFileDelete(f)}
                  />
                </div>
              );
            })}
            {files.length < MAX_UPLOAD_FILES_AMOUNT && (
              <Button
                variant='icon-text'
                color='tertiary'
                type='button'
                icon={<Icon glyph={IconMap.Plus} className='fill-primary-800' />}
                onClick={handleAddAttachment}
              >
                Add
              </Button>
            )}
            <input
              type='file'
              className='hidden'
              onChange={onFilesSelect}
              multiple
              accept='image/*'
              ref={hiddenFileInput}
            />
            {filesError && <p className='text-error-700 text-xs'>{filesError}</p>}
          </div>

          <div className='flex justify-between mt-4'>
            <Button color='secondary' variant='text' onClick={onCancelClick}>
              Cancel
            </Button>
            <Button
              disabled={isRequestSending || files.some(f => !f.path)}
              loading={isRequestSending}
              color='primary'
              variant='text'
              type='submit'
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
