import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appDataModel } from '@entities/app-data';
import { TableOfContentsList } from '../../table-of-contents/components/TableOfContentsList';
import { TableOfContentsListElement } from '../../table-of-contents/components/TableOfContentsListElement';

type Props = {
  type: 'table' | 'img';
};

export const ListPage: FC<Props> = ({ type }) => {
  const docs = useSelector(appDataModel.selectors.selectedDocsWithError);
  const appendices = useSelector(appDataModel.selectors.selectedAppendicesWithError);

  const pageTitle = type === 'table' ? 'List of Tables' : 'List of Figures';

  const tableTitles = useMemo(
    () => [...docs.map(item => item.tableTitles), ...appendices.map(item => item.tableTitles)],
    [appendices, docs]
  );
  const imageTitles = useMemo(
    () => [...docs.map(item => item.imageTitles), ...appendices.map(item => item.imageTitles)],
    [appendices, docs]
  );

  const data = type === 'table' ? tableTitles.flat() : imageTitles.flat();

  return (
    <div className='h-full px-4 pt-4 sm:p-0 bg-neutrals-0'>
      <div className='min-h-11'></div>
      <div className='h-full max-w-170 mx-auto bg-neutrals-0 p-10 space-y-6'>
        <h2 className='title-font text-center text-base leading-8 font-bold'>{pageTitle}</h2>
        <TableOfContentsList>
          {data.map((title, idx) => (
            <TableOfContentsListElement key={idx} title={title} showDots={true} />
          ))}
        </TableOfContentsList>
      </div>
    </div>
  );
};
