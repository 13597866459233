import { call } from 'redux-saga/effects';

import { authActionHandler } from '@features/auth/code-operation';

export function authAction(isAuth = false) {
  return function* (location: Location) {
    const { search } = location;

    yield call(authActionHandler, { search, isAuth });
  };
}
