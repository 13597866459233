import { Role } from 'blooksy-backend';

export const rolesDictionary = [
  { role: 'reader', label: 'Read Only' },
  { role: 'commenter', label: 'Commenter' },
  { role: 'writer', label: 'Editor' },
  { role: 'owner', label: 'Owner' },
] as const;

export type RoleItem = { role: Role; label: string };

export const allowedRoles: RoleItem[] = rolesDictionary.filter(i => i.role !== 'owner');
