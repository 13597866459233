import { call } from 'redux-saga/effects';

import { onSnapshot, queryColChannel } from '@entities/snapshot-listener';
import { getUsersRef } from '@shared/api/firestore';
import { updateFirestoreUsers } from '@entities/app-data';

export function* watchFirestoreUsers() {
  const usersRef = getUsersRef();
  const firestoreUsersChannel = queryColChannel(usersRef, false);

  yield call(onSnapshot, firestoreUsersChannel, updateFirestoreUsers, 'Firestore Users');
}
