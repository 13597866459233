import { appDataModel } from '@entities/app-data';
import { bookTypesDictionary } from '@shared/config';
import { BookStructureValue } from '@shared/types';
import { isSelectedItem } from '@widgets/sidebar-left/lib';
import { BookType } from 'blooksy-backend';
import React, { FC } from 'react';
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { FolderItemHeading } from '../FolderItemHeading';
import { OneLevelListItem } from './OneLevelListItem';

type Props = {
  isActive: boolean;
  folder: BookStructureValue;
  label: string;
  onClick: () => void;
  onSecondLevelItemClick: (docId: string) => void;
  bookType: BookType;
  preExpanded?: string[];
};
export const TwoLevelListItem: FC<Props> = ({
  isActive,
  folder,
  label,
  onClick,
  onSecondLevelItemClick,
  bookType,
  preExpanded,
}) => {
  const selectedSidebarItem = useSelector(appDataModel.selectors.selectedSideBarItemWithError);

  return (
    <Accordion preExpanded={preExpanded} allowMultipleExpanded allowZeroExpanded>
      <AccordionItem key={`${folder.id}-item`} uuid={folder.id}>
        <FolderItemHeading
          title={label}
          className={`pl-6 ${isActive ? 'font-semibold bg-neutrals-100' : ''} `}
          onClick={onClick}
        />
        <AccordionItemPanel>
          {folder.children.map(({ id: docId, title }) => (
            <OneLevelListItem
              key={docId}
              isActive={isSelectedItem(docId, selectedSidebarItem)}
              label={title || `Untitled ${bookTypesDictionary[bookType].docName}`}
              onClick={() => onSecondLevelItemClick(docId)}
              isSecondLevel
            />
          ))}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
