type TitleFont =
  | 'font-base'
  | 'font-playfair'
  | 'font-quattrocento'
  | 'font-cormorant'
  | 'font-oswald'
  | 'font-libre-baskerville'
  | 'font-cinzel'
  | 'font-sacramento'
  | 'font-yeseva'
  | 'font-cardo'
  | 'font-spectral'
  | 'font-halant'
  | 'font-montserrat'
  | 'font-nunito'
  | 'font-source-sans-pro'
  | 'font-raleway'
  | 'font-times-new-roman';

type TextFont =
  | 'font-base'
  | 'font-source-sans-pro'
  | 'font-fanwood'
  | 'font-proza-libre'
  | 'font-garamond'
  | 'font-fauna'
  | 'font-alice'
  | 'font-josefin'
  | 'font-montserrat'
  | 'font-karla'
  | 'font-nunito-sans'
  | 'font-hind'
  | 'font-pt-sans'
  | 'font-merriweather'
  | 'font-cardo'
  | 'font-crimson'
  | 'font-source-serif-pro'
  | 'font-open-sans'
  | 'font-times-new-roman';

export type BookStyleName =
  | 'default'
  | 'playfair-source-sans'
  | 'quattrocento-fanwood'
  | 'cormorant-proza-libre'
  | 'oswald-eb-garamond'
  | 'libre-baskerville-source-sans'
  | 'cinzel-fauna'
  | 'sacramento-alice'
  | 'yeseva-josefin'
  | 'libre-baskerville-montserrat'
  | 'cardo-josefin'
  | 'spectral-karla'
  | 'halant-nunito-sans'
  | 'montserrat-hind'
  | 'nunito-pt-sans'
  | 'oswald-merriweather'
  | 'montserrat-cardo'
  | 'montserrat-crimson'
  | 'source-sans-source-serif'
  | 'raleway-open-sans'
  | 'times-new-roman';

export type BookStyles = {
  [key in BookStyleName]: {
    titleFontClass: TitleFont;
    textFontClass: TextFont;
    titleFontName: string;
    textFontName: string;
  };
};
export const bookStyles: BookStyles = {
  default: {
    titleFontClass: 'font-base',
    titleFontName: 'Inter',
    textFontName: 'Inter',
    textFontClass: 'font-base',
  },
  'playfair-source-sans': {
    titleFontClass: 'font-playfair',
    textFontClass: 'font-source-sans-pro',
    titleFontName: 'Playfair Display',
    textFontName: 'Source Sans Pro',
  },
  'quattrocento-fanwood': {
    titleFontClass: 'font-quattrocento',
    textFontClass: 'font-fanwood',
    titleFontName: 'Quattrocento',
    textFontName: 'Fanwood Text',
  },
  'cormorant-proza-libre': {
    titleFontClass: 'font-cormorant',
    textFontClass: 'font-proza-libre',
    titleFontName: 'Cormorant',
    textFontName: 'Proza Libre',
  },
  'oswald-eb-garamond': {
    titleFontClass: 'font-oswald',
    textFontClass: 'font-garamond',
    titleFontName: 'Oswald',
    textFontName: 'EB Garamond',
  },
  'libre-baskerville-source-sans': {
    titleFontClass: 'font-libre-baskerville',
    textFontClass: 'font-source-sans-pro',
    titleFontName: 'Libre Baskerville',
    textFontName: 'Source Sans Pro',
  },
  'cinzel-fauna': {
    titleFontClass: 'font-cinzel',
    textFontClass: 'font-fauna',
    titleFontName: 'Cinzel',
    textFontName: 'Fauna One',
  },
  'sacramento-alice': {
    titleFontClass: 'font-sacramento',
    textFontClass: 'font-alice',
    titleFontName: 'Sacramento',
    textFontName: 'Alice',
  },
  'yeseva-josefin': {
    titleFontClass: 'font-yeseva',
    textFontClass: 'font-josefin',
    titleFontName: 'Yeseva One',
    textFontName: 'Josefin Sans',
  },
  'libre-baskerville-montserrat': {
    titleFontClass: 'font-libre-baskerville',
    textFontClass: 'font-montserrat',
    titleFontName: 'Libre Baskerville',
    textFontName: 'Montserrat',
  },
  'cardo-josefin': {
    titleFontClass: 'font-cardo',
    textFontClass: 'font-josefin',
    titleFontName: 'Cardo',
    textFontName: 'Josefin Sans',
  },
  'spectral-karla': {
    titleFontClass: 'font-spectral',
    textFontClass: 'font-karla',
    titleFontName: 'Spectral',
    textFontName: 'Karla',
  },
  'halant-nunito-sans': {
    titleFontClass: 'font-halant',
    textFontClass: 'font-nunito-sans',
    titleFontName: 'Halant',
    textFontName: 'Nunito Sans',
  },
  'montserrat-hind': {
    titleFontClass: 'font-montserrat',
    textFontClass: 'font-hind',
    titleFontName: 'Montserrat',
    textFontName: 'Hind',
  },
  'nunito-pt-sans': {
    titleFontClass: 'font-nunito',
    textFontClass: 'font-pt-sans',
    titleFontName: 'Nunito',
    textFontName: 'PT Sans',
  },
  'oswald-merriweather': {
    titleFontClass: 'font-oswald',
    textFontClass: 'font-merriweather',
    titleFontName: 'Oswald',
    textFontName: 'Merriweather',
  },
  'montserrat-cardo': {
    titleFontClass: 'font-montserrat',
    textFontClass: 'font-cardo',
    titleFontName: 'Montserrat',
    textFontName: 'Cardo',
  },
  'montserrat-crimson': {
    titleFontClass: 'font-montserrat',
    textFontClass: 'font-crimson',
    titleFontName: 'Montserrat',
    textFontName: 'Crimson Pro',
  },
  'source-sans-source-serif': {
    titleFontClass: 'font-source-sans-pro',
    textFontClass: 'font-source-serif-pro',
    titleFontName: 'Source Sans Pro',
    textFontName: 'Source Serif Pro',
  },
  'raleway-open-sans': {
    titleFontClass: 'font-raleway',
    textFontClass: 'font-open-sans',
    titleFontName: 'Raleway',
    textFontName: 'Open Sans',
  },
  'times-new-roman': {
    /* We use Inter as the default font style in CKEditor for the APA template, but Times New Roman is added
  to APA book exports in accordance with APA requirements. Therefore, we need the key name 'times-new-roman'
  to retrieve them on the backend. */
    titleFontClass: 'font-base',
    titleFontName: 'Inter',
    textFontName: 'Inter',
    textFontClass: 'font-base',
  },
};
