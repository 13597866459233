import { call } from 'redux-saga/effects';

import { acceptInvite, rejectInvite } from '@shared/api/cloud-functions';

import { inviteAction } from '../../lib';
import { actions } from '../reducer';

export function* acceptInviteSaga(action: ReturnType<typeof actions.acceptInviteClick>) {
  const bookId = action.payload;
  yield call(inviteAction, () => acceptInvite(bookId), bookId, 'accepting');
}

export function* rejectInviteAction(action: ReturnType<typeof actions.rejectInviteClick>) {
  const bookId = action.payload;
  yield call(inviteAction, () => rejectInvite(bookId), bookId, 'rejecting');
}
