import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import classNames from 'classnames';
import React, { FC } from 'react';

export const BookTypeSelect: FC<{
  onSelect?: () => void;
  type: string;
  isUnavailable: boolean;
  isActive: boolean;
  icon: string;
}> = ({ onSelect, isUnavailable, isActive, type, icon }) => {
  return (
    <button
      disabled={isUnavailable}
      className={classNames(
        'px-7 pt-3 pb-4 md:px-2.5 sm:px-4 text-center border rounded relative disabled:cursor-auto',
        ' sm:w-30 sm:min-w-30 sm:h-42 sm:overflow-hidden',
        isActive ? 'border-primary-800' : 'border-neutrals-200',
        { 'cursor-auto': !onSelect }
      )}
      type='button'
      onClick={onSelect}
    >
      <img className={isUnavailable ? 'filter grayscale' : ''} src={icon} alt={type} />
      <p className='-mt-1 sm:-mt-2 text-s text-neutrals-1000'>{type}</p>
      {isActive && (
        <div className='absolute top-2.5 right-2.5'>
          <Icon glyph={IconMap.Check} />
        </div>
      )}
      {isUnavailable && (
        <div className='rounded absolute top-2 right-2 bg-primary-100 text-primary-800 text-xs py-0.5 px-1'>
          Coming Soon
        </div>
      )}
    </button>
  );
};
