import { appDataModel } from '@entities/app-data';
import { storage } from '@shared/config';
import { generateImageName, logger } from '@shared/lib';
import { Book } from '@shared/types';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from './reducer';
import * as FirestoreService from '@shared/api/firestore';
import { redirect, redirectActions } from '@entities/history';

function* deleteBookCover({ payload: url }: ReturnType<typeof actions.deleteBookCover>) {
  try {
    yield put(actions.setCoverError());
    yield put(actions.setIsLoading(true));
    const currentBook: Book = yield select(appDataModel.selectors.selectedBookWithError);

    yield call(deleteFileFromStorage, url);

    yield call(FirestoreService.updateBookData, currentBook.id, { coverImageURL: '' });
  } catch (e: unknown) {
    logger.error(e);
    yield put(actions.setCoverError((e as Error).message));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* deleteFileFromStorage(url: string) {
  const imageRef = ref(storage, url);
  yield call(deleteObject, imageRef);
}

function* uploadBookCover({ payload: file }: ReturnType<typeof actions.uploadBookCover>) {
  try {
    yield put(actions.setIsLoading(true));
    const currentBook: Book = yield select(appDataModel.selectors.selectedBookWithError);

    if (currentBook.coverImageURL) {
      yield call(deleteFileFromStorage, currentBook.coverImageURL);
    }
    const coversRef = ref(storage, 'bookCover');
    const imageRef = ref(coversRef, generateImageName(file.name));

    yield call(uploadBytes, imageRef, file);

    const path: string = yield call(getDownloadURL, imageRef);

    yield call(FirestoreService.updateBookData, currentBook.id, { coverImageURL: path });
  } catch (e: unknown) {
    logger.error(e);
    yield put(actions.setCoverError((e as Error).message));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

export function* bookEditWorker() {
  yield takeEvery(redirectActions.toSettingsClick, redirect.toSettings);

  yield takeLatest(actions.uploadBookCover, uploadBookCover);
  yield takeLatest(actions.deleteBookCover, deleteBookCover);
}
