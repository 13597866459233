import React, { MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authModel } from '@entities/auth-operation';
import { redirectActions } from '@entities/history';
import { TERMS_LINK, PRIVACY_LINK } from '@shared/config';
import { Input, Button, Link } from '@shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CreateAccountRequestData, createAccountValidationSchema } from '@pages/sign-up/config';

const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authModel.selectors.selectAuthError);
  const authLoading = useSelector(authModel.selectors.selectLoading);

  const { control, handleSubmit } = useForm<CreateAccountRequestData>({
    resolver: yupResolver(createAccountValidationSchema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
    },
  });

  const createAccount = ({ email, password, firstName, lastName }: CreateAccountRequestData) => {
    dispatch(
      authModel.actions.signUpClick({
        email: email,
        password: password,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      })
    );
  };

  const handleLoginClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    dispatch(redirectActions.toLoginClick());
  };

  return (
    <>
      <div className='mb-6'>
        <p className='text-s inline'>Already have an account? </p>
        <Link href='#' size='medium' onClick={handleLoginClick}>
          Log In
        </Link>
      </div>

      <form className='mb-6' onSubmit={handleSubmit(createAccount)} noValidate>
        <div className='mb-4'>
          <Input control={control} name='email' type='email' label='Email' />
        </div>
        <div className='flex justify-between gap-4 mb-4'>
          <div className='w-full'>
            <Input control={control} name='firstName' type='text' label='First Name' />
          </div>
          <div className='w-full'>
            <Input control={control} name='lastName' type='text' label='Last Name' />
          </div>
        </div>
        <div className='mb-4'>
          <Input control={control} name='password' type='password' label='Password' />
        </div>

        {authError && <p className='px-4 py-1 text-xs rounded bg-error-100 text-error-700'>{authError}</p>}

        <div className='mt-6'>
          <Button variant='text' color='primary' disabled={authLoading} loading={authLoading} type='submit' fullWidth>
            Sign Up
          </Button>
        </div>
      </form>

      <p className='text-xs'>
        By clicking the &quot;Sign Up&quot; button, you are creating a Blooksy account, and you agree to Blooksy’s{' '}
        <Link target='_blank' rel='noreferrer' href={TERMS_LINK} size='small'>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link target='_blank' rel='noreferrer' href={PRIVACY_LINK} size='small'>
          Privacy Policy
        </Link>
        .
      </p>

      {/* TODO temporally hidden*/}
      {/* <div className='my-6 border-t border-neutrals-200' />

      <div className='mb-4'>
        <Button icon={GoogleLogo} variant='text' color='secondary' type='button' fullWidth>
          Log In with Google
        </Button>
      </div>
      <Button icon={FBLogo} variant='text' color='secondary' type='button' fullWidth>
        Log In with Facebook
      </Button> */}
    </>
  );
};

export default CreateAccountForm;
