import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { appDataModel } from '@entities/app-data';

import { TableOfContentsList } from './components/TableOfContentsList';
import { TableOfContentsListElement, ToCListItemProps } from './components/TableOfContentsListElement';
import { bookTypesDictionary } from '@shared/config';

import cn from 'classnames';

type ChapterTitle = ToCListItemProps & {
  id: string;
  hidden: boolean;
};

const BASE_INDENT = 70;
const INDENT_STEP = 30;

export const TableOfContents: FC = () => {
  const docs = useSelector(appDataModel.selectors.selectedDocsWithError);
  const folders = useSelector(appDataModel.selectors.selectedFolders);
  const book = useSelector(appDataModel.selectors.selectedBookWithError);
  const isApaPaper = book.type === 'apa';

  const getChaptersTitles = (): ChapterTitle[] => {
    if (book.type === 'fiction') {
      if (!folders) {
        throw new Error('Folders not found');
      }
      return folders.map(doc => ({
        title: doc.title.trim() ? doc.title.trim() : `Untitled ${bookTypesDictionary[book.type].folderName}`,
        id: doc.id,
        hidden: false,
        showDots: true,
      }));
    } else {
      return docs.flatMap(doc => [
        {
          title: doc.title.trim() ? doc.title.trim() : `Untitled ${bookTypesDictionary[book.type].docName}`,
          id: doc.id,
          hidden: false,
          showDots: true,
        },
        ...(isApaPaper && doc.headings
          ? doc.headings.map((heading, i) => {
              return {
                title: heading.value.trim(),
                id: doc.id + i,
                hidden: false,
                showDots: true,
                indentLeft: BASE_INDENT + (heading.level - 2) * INDENT_STEP, // heading.level equals 2-5
              };
            })
          : []),
      ]);
    }
  };

  const tableOfContentsData = isApaPaper
    ? [
        [
          { title: 'List of Tables', id: 'list-of-tables', hidden: book.extra.listOfTables.hidden, showDots: true },
          {
            title: 'List of Figures',
            id: 'list-of-figures',
            hidden: book.extra.listOfFigures.hidden,
            showDots: true,
          },
          { title: 'Abstract', id: 'abstract', hidden: book.extra.abstract.hidden, showDots: true },
        ],
        [...getChaptersTitles()],
        [{ title: 'References', id: 'references', hidden: book.extra.references.hidden, showDots: false }],
        [{ title: 'Appendices', id: 'appendices', hidden: false, showDots: false }],
      ]
    : [
        [
          {
            title: 'Acknowledgements',
            id: 'acknowledgements',
            hidden: book.extra.acknowledgements.hidden,
            showDots: true,
          },
          { title: 'Foreword', id: 'foreword', hidden: book.extra.foreword.hidden, showDots: true },
          { title: 'Preface', id: 'preface', hidden: book.extra.preface.hidden, showDots: true },
          { title: 'Introduction', id: 'introduction', hidden: book.extra.introduction.hidden, showDots: true },
        ],
        [...getChaptersTitles()],
        [{ title: 'About the Author', id: 'aboutAuthor', hidden: book.extra.aboutAuthor.hidden, showDots: true }],
      ];

  return (
    <div className='h-full px-4 pt-4 sm:p-0 bg-neutrals-0'>
      <div className='min-h-11'></div>
      <div className='h-full max-w-170 mx-auto bg-neutrals-0 p-10 space-y-6'>
        <h2
          className={cn(`title-font text-center`, {
            'text-l font-semibold': !isApaPaper,
            'text-base leading-8 font-bold': isApaPaper,
          })}
        >
          Table of Contents
        </h2>
        {tableOfContentsData.map((item, index) => (
          <TableOfContentsList key={index}>
            {item.map(({ title, id, hidden, showDots, indentLeft }) =>
              !hidden ? (
                <TableOfContentsListElement key={id} title={title} showDots={showDots} indentLeft={indentLeft} />
              ) : null
            )}
          </TableOfContentsList>
        ))}
      </div>
    </div>
  );
};
