import React, { FC } from 'react';
import cn from 'classnames';

type Props = {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  defaultChecked?: boolean;
  className?: string;
  disabled?: boolean;
};

export const RadioButton: FC<Props> = ({ name, value, onChange, label, defaultChecked, className, disabled }) => {
  return (
    <label className={cn('text-sm space-x-3 flex items-center', { 'pointer-events-none': disabled })}>
      <input
        type='radio'
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={cn(
          className,
          'w-3.5 h-3.5 focus:ring-offset-0 focus:ring-0 focus:border-primary-800 text-primary-800 cursor-pointer',
          {
            'text-neutrals-300 border-neutrals-300': disabled,
          }
        )}
        defaultChecked={defaultChecked}
      />
      <span className={`cursor-pointer ${disabled ? 'text-neutrals-300' : ''}`}>{label}</span>
    </label>
  );
};
