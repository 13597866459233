import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authUserModel } from '@entities/auth-user';
import { Button, Input, Modal } from '@shared/ui';

import { ChangeEmailRequestData, changeEmailValidationSchema } from '../config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { profileSettingModel } from '../model';

export const ChangeEmailForm = () => {
  const dispatch = useDispatch();

  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const emailError = useSelector(profileSettingModel.selectors.selectEmailError);
  const passwordForEmailError = useSelector(profileSettingModel.selectors.selectPasswordForEmailError);

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isEmailChanging, setIsEmailChanging] = useState(false);

  const { control, handleSubmit, reset } = useForm<ChangeEmailRequestData>({
    resolver: yupResolver(changeEmailValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleResendVerificationClick = () => {
    dispatch(profileSettingModel.actions.sendEmailVerificationClick());
  };

  const handleEmailCancelClick = () => {
    setIsEmailChanging(false);
    setIsEmailModalOpen(false);
    reset();

    dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setEmailError' }));
    dispatch(profileSettingModel.actions.setAuthErrorAction({ type: 'setPasswordForEmailError' }));
  };

  const changeEmail = ({ email, password }: ChangeEmailRequestData) => {
    setIsEmailChanging(true);
    dispatch(
      profileSettingModel.actions.updateUserEmailClick({
        email,
        password,
        cb: handleEmailCancelClick,
      })
    );
  };

  return (
    <>
      <h3 className='mb-4 mt-6.75 font-semibold text-m'>Email</h3>
      <div
        className='flex justify-between items-center gap-4 pb-6.75
        border-b border-neutrals-200 sm:flex-col sm:items-start'
      >
        <div className='flex flex-col'>
          <p className='text-s'>
            Your email address is <strong>{user.email}</strong>.
          </p>

          {!user.isVerified && (
            <div className='px-4 py-2 mt-2 rounded text-s text-neutrals-1000 bg-warning-100'>
              Email is not verified.{' '}
              <a onClick={handleResendVerificationClick} className='cursor-pointer text-primary-800'>
                Resend Verification Email
              </a>
            </div>
          )}
        </div>

        <Button
          type='button'
          color='tertiary'
          variant='text'
          onClick={() => {
            setIsEmailModalOpen(true);
          }}
        >
          Change Email
        </Button>
      </div>
      <Modal
        opened={isEmailModalOpen}
        title='Change Email'
        leftButtonVariant='text'
        leftButtonText='Cancel'
        rightButtonVariant='text'
        rightButtonColor='primary'
        rightButtonText='Change'
        isLoading={isEmailChanging}
        onConfirmClick={handleSubmit(changeEmail)}
        onCancelClick={handleEmailCancelClick}
      >
        <>
          <form onSubmit={handleSubmit(changeEmail)} noValidate>
            <div className='mb-4'>
              <Input control={control} name='email' type='email' label='New Email' />
            </div>
            <Input control={control} name='password' type='password' label='Current Password' />

            {(emailError || passwordForEmailError) && (
              <p className='px-4 py-1 mt-5 overflow-hidden text-xs rounded bg-error-100 text-error-700'>
                {emailError || passwordForEmailError}
              </p>
            )}
            <input type='submit' className='sr-only' tabIndex={-1} aria-hidden={true} />
          </form>
        </>
      </Modal>
    </>
  );
};
