import { FirestoreUserData } from '@shared/types';
import { capitalizeFirstLetter } from '@shared/lib';

export type TableUser = Pick<FirestoreUserData, 'email' | 'name' | 'category' | 'userId' | 'photoUrl'>;
export type Category = { name: string; id: string };

export const filterByNotStripeAndNotCurrentUser = (userId: string) => (user: FirestoreUserData) =>
  (user.subscriptionInfo === null || user.role === null) && userId !== user.userId;

const getUniqueCategories = (users: TableUser[]) =>
  [...new Set(['kartra', 'KSU', ...users.map(user => user.category || '')])].filter(Boolean);

export const getUserCategories = (users: FirestoreUserData[]): Category[] => [
  { name: 'Regular User', id: 'regular' },
  ...getUniqueCategories(users).map(transformToItem),
];

const transformToItem = (name: string): Category => ({
  name: capitalizeFirstLetter(name),
  id: `${name.replace(/\s+/g, '-')}`,
});

export const extractFirestoreUsersById = (ids: string[], users: FirestoreUserData[]) => {
  const set = new Set(ids);
  return users.filter(user => set.has(user.userId));
};
