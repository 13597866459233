import { put, call, select, takeLatest } from '@redux-saga/core/effects';

import { connectivityModel } from '@entities/connectivity-indicator/';
import { createNotification, snackbarModel } from '@features/snackbar';
import { dictionary, functions, storage } from '@shared/config';
import { logger } from '@shared/lib';

import { httpsCallable } from 'firebase/functions';

import { actions } from '../reducer';
import { ExportBookType } from '../types';
import MixpanelService from '@features/mixpanel';
import { ResponseExportCloudFunction } from 'blooksy-backend';
import { getBlob, ref } from 'firebase/storage';

const downloadBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

function* exportBook(exportAction: ReturnType<typeof actions.exportBook>) {
  MixpanelService.trackBookExported(exportAction.payload);

  const bookId: string = exportAction.payload.bookId;

  const exportType: ExportBookType = exportAction.payload.exportType;
  const exportSize: string = exportAction.payload.exportSize;

  const createExportBookFunction = httpsCallable(functions, 'createExportBookRequest');
  yield put(actions.setExportIsLoading(true));
  const preparingNotification: ReturnType<typeof snackbarModel.actions.addNotificationAction> = yield put(
    snackbarModel.actions.addNotificationAction(createNotification('primary', dictionary.primary.preparingDocxExport))
  );
  const hasInternetConnection: boolean = yield select(connectivityModel.selectors.selectHasInternetConnection);
  try {
    if (!hasInternetConnection) {
      throw new Error('No internet connection');
    }
    const {
      data: { path, fileName },
    }: { data: ResponseExportCloudFunction } = yield call(createExportBookFunction, {
      bookId,
      exportType,
      exportSize,
    });

    yield put(actions.setExportIsLoading(false));
    const fileRef = ref(storage, path);

    const blob: Blob = yield getBlob(fileRef);
    downloadBlob(blob, fileName);
  } catch (e: unknown) {
    logger.error(e);
    yield put(snackbarModel.actions.removeNotificationAction(preparingNotification.payload.id));
    yield put(actions.setExportIsLoading(false));
    if (e instanceof Error)
      yield put(snackbarModel.actions.addNotificationAction(createNotification('error', e.message)));
  }
}

export function* exportBookWatcher() {
  yield takeLatest(actions.exportBook, exportBook);

  yield takeLatest(actions.startBookExport, function* (action) {
    yield put(actions.setIsOpenExportModal(true));
    yield put(actions.setExportedBookId(action.payload.bookId));
  });

  yield takeLatest(actions.endBookExport, function* () {
    yield put(actions.setIsOpenExportModal(false));
    yield put(actions.setExportedBookId(undefined));
  });
}
