import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { appDataModel } from '@entities/app-data';
import { authModel } from '@entities/auth-operation';
import { authUserModel } from '@entities/auth-user';
import { connectivityModel } from '@entities/connectivity-indicator';
import { bookCreatingModel } from '@features/book-creating';
import { bookDeletionModel } from '@features/book-deletion';
import { exportModel } from '@features/book-export';
import { inviteControlModel } from '@features/invite-control';
import { profileSettingModel } from '@features/profile-settings';
import { shareAccessModel } from '@features/share-access';
import { snackbarModel } from '@features/snackbar';

import { saga } from './saga';
import { stripeModel } from '@features/subscriptions';
import { sentryReduxEnhancer } from '@features/sentry-connect';
import { avenModel } from '@features/Aven-AI';
import { editBookModel } from '@pages/edit-book/model';
import { supportModel } from '@features/support/model';
import { adminModel } from '@features/admin/model';

const rootReducer = combineReducers({
  appData: appDataModel.reducer,
  snackbar: snackbarModel.reducer,
  connectivity: connectivityModel.reducer,
  user: authUserModel.reducer,
  export: exportModel.reducer,
  invite: inviteControlModel.reducer,
  bookDeletion: bookDeletionModel.reducer,
  shareAccess: shareAccessModel.reducer,
  bookCreation: bookCreatingModel.reducer,
  auth: authModel.reducer,
  profileSetting: profileSettingModel.reducer,
  admin: adminModel.reducer,
  stripe: stripeModel.reducer,
  aven: avenModel.reducer,
  bookEdit: editBookModel.reducer,
  support: supportModel.reducer,
});
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        'profileSetting/updateUserProfileClick',
        'profileSetting/updateUserPhotoClick',
        'bookDeletion/deleteBookClick',
        'profileSetting/updateUserEmailClick',
        'shareAccess/set',
        'aven/saveResult',
        'aven/deleteResult',
        'aven/buyCoins',
        'bookEdit/uploadBookCover',
        'support/uploadPhoto',
        'profileSetting/updatePasswordClick',
        'admin/createUsers',
      ],
    },
  }),
  sagaMiddleware,
];
const enhancers = [sentryReduxEnhancer];
const store = configureStore({ reducer: rootReducer, middleware, enhancers });
export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(saga);
