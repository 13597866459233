import { call, put } from 'redux-saga/effects';

import { DocSnap, FirestoreUserData } from '@shared/types';

import { actions } from '../model/reducer';

import { getUserData } from '@shared/api/firestore';

export function* updateUser(snap: DocSnap) {
  const user = snap.data() as FirestoreUserData;
  yield put(actions.setFirestoreUserData(user));
}

export function* setFirestoreUserData() {
  const user: FirestoreUserData = yield call(getUserData);
  yield put(actions.setFirestoreUserData(user));
}
