import { put, takeEvery } from 'redux-saga/effects';

import { authModel } from '@entities/auth-operation';
import { redirect, redirectActions } from '@entities/history';
import { signUp } from '@features/auth/sign-up';
import MixpanelService from '@features/mixpanel';

export function* signUpWorker() {
  MixpanelService.trackSignUpStarted();
  yield put(authModel.actions.setAuthError(''));
  yield takeEvery(authModel.actions.signUpClick, signUp);
  yield takeEvery(redirectActions.toLoginClick, redirect.toLogin);
}
