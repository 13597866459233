import React, { FC } from 'react';

import { ChangeEmailForm, ChangeNameForm, ChangePasswordFrom, ChangePhotoForm } from '@features/profile-settings';

const Profile: FC = () => {
  return (
    <div
      className={`col-start-3 col-end-11
    md:col-start-1 md:col-end-9 md:max-w-screen sm:col-start-1 sm:col-end-5 sm:w-full`}
    >
      <h3 className='mb-4 font-semibold text-m'>Personal Info</h3>
      <div className='flex justify-between gap-58 border-b border-neutrals-200 pb-6.75 md:gap-42 sm:flex-col sm:gap-4'>
        <ChangeNameForm />
        <ChangePhotoForm />
      </div>

      <ChangeEmailForm />
      <ChangePasswordFrom />
    </div>
  );
};

export default Profile;
