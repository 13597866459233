const defaultTheme = require('tailwindcss/defaultTheme');
const subsetsFonts = {
  playfair: ['Playfair Display Subset', ...defaultTheme.fontFamily.serif],
  'source-sans-pro': ['Source Sans Pro Subset', ...defaultTheme.fontFamily.sans],
  quattrocento: ['Quattrocento Subset', ...defaultTheme.fontFamily.serif],
  fanwood: ['Fanwood Text Subset', ...defaultTheme.fontFamily.serif],
  cormorant: ['Cormorant Subset', ...defaultTheme.fontFamily.serif],
  'proza-libre': ['Proza Libre Subset', ...defaultTheme.fontFamily.sans],
  oswald: ['Oswald Subset', ...defaultTheme.fontFamily.sans],
  garamond: ['EB Garamond Subset', ...defaultTheme.fontFamily.sans],
  'libre-baskerville': ['Libre Baskerville Subset', ...defaultTheme.fontFamily.serif],
  cinzel: ['Cinzel Subset', ...defaultTheme.fontFamily.serif],
  fauna: ['Fauna One Subset', ...defaultTheme.fontFamily.serif],
  sacramento: ['Sacramento Subset', ...defaultTheme.fontFamily.serif],
  alice: ['Alice Subset', ...defaultTheme.fontFamily.serif],
  yeseva: ['Yeseva One Subset', ...defaultTheme.fontFamily.serif],
  josefin: ['Josefin Sans Subset', ...defaultTheme.fontFamily.sans],
  cardo: ['Cardo Subset', ...defaultTheme.fontFamily.serif],
  spectral: ['Spectral Subset', ...defaultTheme.fontFamily.serif],
  halant: ['Halant Subset', ...defaultTheme.fontFamily.serif],
  montserrat: ['Montserrat Subset', ...defaultTheme.fontFamily.sans],
  karla: ['Karla Subset', ...defaultTheme.fontFamily.sans],
  'nunito-sans': ['Nunito Sans Subset', ...defaultTheme.fontFamily.sans],
  nunito: ['Nunito Subset', ...defaultTheme.fontFamily.sans],
  hind: ['Hind Subset', ...defaultTheme.fontFamily.sans],
  'pt-sans': ['PT Sans Subset', ...defaultTheme.fontFamily.sans],
  merriweather: ['Merriweather Subset', ...defaultTheme.fontFamily.serif],
  crimson: ['Crimson Pro Subset', ...defaultTheme.fontFamily.serif],
  'source-serif-pro': ['Source Serif Pro Subset', ...defaultTheme.fontFamily.serif],
  raleway: ['Raleway Subset', ...defaultTheme.fontFamily.sans],
  'open-sans': ['Open Sans Subset', ...defaultTheme.fontFamily.sans],
};

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  primary: {
    50: '#F0F7FF',
    100: '#CCE4FF',
    200: '#99C6FF',
    400: '#3F87FF',
    500: '#0057FF',
    600: '#0043DB',
    800: '#002293',
  },
  neutrals: {
    0: '#FFFFFF',
    100: '#F8F9FA',
    200: '#F2F3F5',
    300: '#E5E9ED',
    400: '#B4C2C8',
    500: '#8D9BA4',
    1000: '#111111',
  },
  success: {
    100: '#EDFBD2',
    500: '#64B522',
    600: '#4C9B18',
    700: '#378211',
  },
  warning: {
    100: '#FFF1CE',
    500: '#FF8D0A',
    600: '#DB6E07',
    700: '#B75305',
  },
  error: {
    100: '#FFE4D5',
    500: '#FF3330',
    600: '#DB2330',
    700: '#B71831',
  },
  yellow: {
    500: '#FFD20A',
  },
};

module.exports = {
  purge: {
    content: ['./src/**/*.{js,jsx,ts,tsx}', './public/*.{html}'],
    safelist: [/ck.*/],
  },
  darkMode: false,
  theme: {
    screens: {
      lg: { max: '1240px' },
      md: { max: '1023px' },
      sm: { max: '767px' },
    },
    colors,
    fill: colors,
    stroke: colors,
    fontWeight: {
      normal: '400',
      semibold: '600',
      bold: '700',
    },
    extend: {
      padding: {
        '2-width': '150%',
      },
      spacing: {
        2.75: '0.6875rem',
        3.75: '0.9375rem',
        4.5: '1.125rem',
        6.5: '1.625rem',
        6.75: '1.6875rem',
        8.5: '2.125rem',
        10.5: '2.625rem',
        29: '7.25rem',
        30: '7.5rem',
        31: '7.75rem',
        33: '8.25rem',
        34: '8.5rem',
        42: '10.5rem',
        47: '11.75rem',
        50: '12.5rem',
        58: '14.5rem',
        83: '20.75rem',
        84: '21rem',
        100: '25rem',
        104: '26rem',
        120: '30rem',
        135: '33.75rem',
        141.5: '35.375rem',
        150: '37.5rem',
        151: '37.75rem',
        170: '42.5rem',
        212: '53rem',
      },
      width: {
        4.5: '1.125rem',
        '1/100': '1%',
        '23/100': '23%',
        'fit-content': 'fit-content',
        200: '800px',
      },
      minHeight: theme => ({
        ...theme('height'),
      }),
      maxHeight: theme => ({
        ...theme('height'),
        '85/100': '85%',
      }),
      minWidth: theme => ({
        ...theme('width'),
      }),
      maxWidth: theme => ({
        ...theme('width'),
      }),

      height: {
        4.5: '1.125rem',
        'fit-content': 'fit-content',
        27.25: '27.25rem',
      },
      top: {
        18: '4.5rem',
      },
      borderRadius: {
        '2.5xl': '1.25rem',
      },
      outline: {
        transparent: '1px solid transparent',
      },
      fontFamily: {
        base: ['Inter', ...defaultTheme.fontFamily.sans],
        ...subsetsFonts,
      },
      fontSize: {
        xl: ['2.125rem', { lineHeight: '2.5rem' }],
        l: ['1.625rem', { lineHeight: '2rem' }],
        m: ['1.25rem', { lineHeight: '1.75rem' }],
        s: ['0.875rem', { lineHeight: '1.25rem' }],
        xs: ['0.6875rem', { lineHeight: '1rem' }],
        xxs: ['0.5625rem', { lineHeight: '0.8125rem' }],
        h1: ['1.375rem', { lineHeight: '2rem' }],
        h2: ['1.25rem', { lineHeight: '1.875rem' }],
        h3: ['1.125rem', { lineHeight: '1.75rem' }],
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['disabled', 'active'],
      textColor: ['disabled', 'active', 'checked', 'hover'],
      borderColor: ['disabled', 'focus-visible', 'checked', 'hover'],
      placeholderColor: ['disabled'],
      cursor: ['disabled'],
      outline: ['hover'],
      overflow: ['hover', 'focus'],
    },
  },
  plugins: [require('@tailwindcss/forms'), require('@tailwindcss/line-clamp')],
};
