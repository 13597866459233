import { call } from 'redux-saga/effects';

import { onSnapshot, queryDocChannel } from '@entities/snapshot-listener';
import { getUserRef } from '@shared/api/firestore';
import { updateUser } from '@entities/app-data';

export function* watchFirestoreUser() {
  const userRef = getUserRef();
  const userChannel = queryDocChannel(userRef);

  yield call(onSnapshot, userChannel, updateUser, 'User');
}
