import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Book,
  BookUser,
  Doc,
  Folder,
  SelectedBookId,
  SelectedSidebarItem,
  UserBook,
  BookStructure,
  FirestoreUserData,
} from '@shared/types';

type State = {
  books?: UserBook[];
  currentBook?: Book;
  selectedBookId?: SelectedBookId;
  docs?: Doc[];
  appendices?: Doc[];
  folders?: Folder[];
  selectedSidebarItem?: SelectedSidebarItem;
  currentBookUsers?: BookUser[];
  firestoreUserData?: FirestoreUserData;
  bookStructure?: BookStructure;
};
const initialState: State = {
  books: undefined,
  currentBook: undefined,
  selectedBookId: undefined,
  docs: undefined,
  appendices: undefined,
  folders: undefined,
  selectedSidebarItem: undefined,
  currentBookUsers: undefined,
  firestoreUserData: undefined,
  bookStructure: undefined,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setBooks: (state, { payload: books }: PayloadAction<UserBook[] | undefined>) => ({ ...state, books }),
    setSelectedBookId: (state, { payload: selectedBookId }: PayloadAction<SelectedBookId | undefined>) => ({
      ...state,
      selectedBookId,
    }),
    setCurrentBook: (state, { payload: currentBook }: PayloadAction<Book | undefined>) => ({
      ...state,
      currentBook,
    }),
    setDocs: (state, { payload: docs }: PayloadAction<Doc[] | undefined>) => ({
      ...state,
      docs,
    }),
    setAppendices: (state, { payload: appendices }: PayloadAction<Doc[] | undefined>) => ({
      ...state,
      appendices,
    }),
    setFolders: (state, { payload: folders }: PayloadAction<Folder[] | undefined>) => ({
      ...state,
      folders: folders && folders.slice().sort((a, b) => a.order - b.order),
    }),
    setBookStructure: (state, { payload: bookStructure }: PayloadAction<BookStructure | undefined>) => ({
      ...state,
      bookStructure,
    }),
    setSelectedSidebarItem: (
      state,
      { payload: selectedSidebarItem }: PayloadAction<SelectedSidebarItem | undefined>
    ) => ({
      ...state,
      selectedSidebarItem,
    }),
    setCurrentBookUsers: (state, { payload: currentBookUsers }: PayloadAction<BookUser[] | undefined>) => ({
      ...state,
      currentBookUsers,
    }),
    setUsersIsLoading: (state, { payload: isUsersLoading }: PayloadAction<boolean>) => ({
      ...state,
      isUsersLoading,
    }),
    setFirestoreUserData: (state, { payload: firestoreUserData }: PayloadAction<FirestoreUserData | undefined>) => ({
      ...state,
      firestoreUserData,
    }),
  },
});
export const actions = {
  ...reducerActions,
};
