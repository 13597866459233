import { Book } from '@shared/types';

import { defaultBookFolded, getServerTime } from '../utils';
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';
import { GBook, Timestamp } from 'blooksy-backend';

export const bookConverter: FirestoreDataConverter<Book> = {
  toFirestore(data: PartialWithFieldValue<Book>): DocumentData {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, createdAt, updatedAt, ...rest } = data;
    return {
      ...rest,
      ...(id ? {} : { createdAt: getServerTime() }),
      updatedAt: getServerTime(),
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Book {
    const id = snapshot.id;
    const data = snapshot.data(options) as GBook<Timestamp>;
    const folded = data.settings?.folded || defaultBookFolded;

    const settings = { style: data.settings?.style || 'default', folded };
    return {
      id,
      ...data,
      createdAt: data.createdAt?.toMillis() || 0,
      updatedAt: data.updatedAt?.toMillis() || 0,
      settings,
    };
  },
};
