export const isCloudServiceEnabled = process.env.REACT_APP_FEATURE_CLOUD_SERVICES === 'true';
export const isEmulatorsUsing = process.env.REACT_APP_USE_EMULATORS === 'true';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const isFirebaseStaging = process.env.REACT_APP_FIREBASE_ENV === 'staging';
export const publicAppUrl = process.env.REACT_APP_PUBLIC_URL || '';

export const imageUploaderAuthToken = process.env.REACT_APP_IMAGE_UPLOADER_AUTH_TOKEN;
export const imageUploaderUrl = isEmulatorsUsing
  ? `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL_WITH_EMULATORS}/${process.env.REACT_APP_IMAGE_UPLOADER_FUNCTION_NAME}`
  : `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/${process.env.REACT_APP_IMAGE_UPLOADER_FUNCTION_NAME}`;
export const csUploadUrl = process.env.REACT_APP_UPLOAD_URL;
export const csWebSocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
export const ckEditorBundleVersion = process.env.REACT_APP_CKEDITOR_BUNDLE_VERSION;

export const isMaintenanceModeEnabled = process.env.REACT_APP_MAINTENANCE_MODE_ENABLED === 'true';
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

export const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';
