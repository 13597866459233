import { authModel } from '@entities/auth-operation';
import {
  AUTH_ROUTE,
  COMPLETE_PASSWORD_RESET_ROUTE,
  INDEX_ROUTE,
  LOGIN_ROUTE,
  redirect,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
} from '@entities/history';
import { completeResetPasswordWorker } from '@pages/create-password';
import { resetPasswordWorker } from '@pages/forgot-password';
import { signInWorker } from '@pages/sign-in';
import { signUpWorker } from '@pages/sign-up';

import { RouteHandler } from './types';

export const publicRouteHandlers: RouteHandler[] = [
  { route: AUTH_ROUTE, saga: authModel.sagas.authAction() },
  { route: INDEX_ROUTE, saga: redirect.toLogin },
  { route: REGISTER_ROUTE, saga: signUpWorker },
  { route: LOGIN_ROUTE, saga: signInWorker },
  { route: RESET_PASSWORD_ROUTE, saga: resetPasswordWorker },
  { route: COMPLETE_PASSWORD_RESET_ROUTE, saga: completeResetPasswordWorker },
];
