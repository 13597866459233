import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import Wrapper from '@entities/loading-wrapper';
import { Loader, MainLayout } from '@shared/ui';
import { EditorLayout } from '@widgets/editor-layout';

import { routes } from './config';
import { EDIT_BOOK_LAYOUT_ROUTES } from './config/routes';

const { PUBLIC_ROUTES, MAIN_LAYOUT_ROUTES, EDITOR_LAYOUT_ROUTES } = routes;

export const Routing = () => {
  return (
    <>
      <Helmet title='Blooksy' titleTemplate='%s - Blooksy' defaultTitle='Blooksy' />
      <Switch>
        {Object.entries(PUBLIC_ROUTES).map(([path, render]) => (
          <Route key={path} path={path} render={render} />
        ))}
        <Route path={Object.keys(MAIN_LAYOUT_ROUTES)}>
          <Wrapper>
            <MainLayout>
              <Switch>
                {Object.entries(MAIN_LAYOUT_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </MainLayout>
          </Wrapper>
        </Route>

        <Route path={Object.keys(EDITOR_LAYOUT_ROUTES)}>
          <Wrapper isEditor>
            <EditorLayout>
              <Switch>
                {Object.entries(EDITOR_LAYOUT_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </EditorLayout>
          </Wrapper>
        </Route>

        <Route path={Object.keys(EDIT_BOOK_LAYOUT_ROUTES)}>
          <Wrapper isEditBook>
            <MainLayout>
              <Switch>
                {Object.entries(EDIT_BOOK_LAYOUT_ROUTES).map(([path, render]) => (
                  <Route key={path} path={path} render={render} />
                ))}
              </Switch>
            </MainLayout>
          </Wrapper>
        </Route>

        <Route path='*' component={Loader} />
      </Switch>
    </>
  );
};

export { ErrorPage } from './error-page';
