export const mixpanelEvents = {
  signUpStarted: 'Sign up started',
  signUpCompleted: 'Sign up completed',
  loginOpened: 'Log in opened',
  onboardingPaywallShowed: 'Onboarding paywall showed',
  onboardingBookCreationShowed: 'Onboarding book creation showed',
  bookCreated: 'Book created',
  bookDeleted: 'Book deleted',
  bookExported: 'Book exported',
  bookSharedAccess: 'Book shared access',
  bookRevokedAccess: 'Book revoked access',
  transcriptionStarted: 'Transcription started',
  aiStarted: 'AI started',
  coinsModalOpened: 'Buy coins modal window opened',
  coinsPurchased: 'Coins purchased',
} as const;

export const MIXPANEL_BOOKS_PROPERTY = 'Number of Books';
export const MIXPANEL_SHARED_BOOKS_PROPERTY = 'Number of "Shared with Me" books';
