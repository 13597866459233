import { Doc } from '@shared/types';
import { GDoc, Timestamp } from 'blooksy-backend';

import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
  PartialWithFieldValue,
} from 'firebase/firestore';

import { getServerTime } from '../utils';

export const docConverter: FirestoreDataConverter<Doc> = {
  toFirestore(data: PartialWithFieldValue<Doc>): DocumentData {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, __isDoc: _, createdAt, updatedAt, ...rest } = data;
    return {
      ...rest,
      ...(id ? {} : { createdAt: getServerTime() }),
      updatedAt: getServerTime(),
      avenSavedResults: data.avenSavedResults || [],
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Doc {
    const id = snapshot.id;
    const data = snapshot.data(options) as GDoc<Timestamp>;

    return {
      id,
      ...data,
      createdAt: data.createdAt?.toMillis() || 0,
      updatedAt: data.updatedAt?.toMillis() || 0,
      avenSavedResults: data.avenSavedResults || [],
      __isDoc: true,
    };
  },
};
