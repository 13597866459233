import { isEmulatorsUsing } from '@shared/config';

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, EmailAuthProvider } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

const providers = {
  EmailAuthProvider: EmailAuthProvider,
};

if (isEmulatorsUsing) {
  connectFirestoreEmulator(db, 'localhost', 9097);
  connectStorageEmulator(storage, 'localhost', 9096);
  connectFunctionsEmulator(functions, 'localhost', 9098);
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export { auth, db, storage, providers, functions };
