import { IconMap } from '@shared/sprite';
import { Icon } from '@shared/ui/icon';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const MaintenanceMode: FC = () => {
  return (
    <>
      <Helmet title='Blooksy Is Under Maintenance' />
      <div
        className='flex flex-col justify-start min-h-full h-fit-content
        bg-primary-50 px-10 py-10 sm:py-6 sm:px-0 md:px-6'
      >
        <div className='flex justify-center'>
          <Icon glyph={IconMap.Logo} />
        </div>
        <div className='flex-grow text-center flex flex-col justify-center items-center'>
          <h1 className='mb-4 font-semibold text-l'>Blooksy Is Under Maintenance</h1>
          <p>It will be back in no time.</p>
        </div>
      </div>
    </>
  );
};
