import { history } from '@entities/history';
import { routes } from '@pages/config';
import { init, reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { sentryDSN } from '@shared/config';
import { matchPath } from 'react-router-dom';

const sentryRoutes = Object.values(routes).flatMap(obj => {
  return Object.keys(obj).map(key => ({ path: key }));
});

init({
  dsn: sentryDSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history, sentryRoutes, matchPath),
    }),
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: process.env.REACT_APP_FIREBASE_ENV,
  release: 'blooksy@' + process.env.npm_package_version,
});

export * from './lib';
